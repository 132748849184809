import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tab,
  Tabs,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { ReactComponent as Plus } from "../../../../../assets/images/icons/plus.svg";
import { ReactComponent as Bat } from "../../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Ball } from "../../../../../assets/images/SportIcon/ball.svg";
import "./fixtureInfoSport.scss";
import InfoFixture from "./infoFixture";
import OurPartner from "src/views/component/ourPartners";
import InfoSummary from "./infoSummary";
import MatchStats from "./matchStats";
import LineUpInfo from "./lineUpInfo";
import InfoTable from "./infoTable";
import InfoNews from "./infoNews";
import InfoH2H from "./infoH2H";
import InfoOddsComparison from "./infoOddsComparison";
import InfoOddsFluctuation from "./infoOddsFluctuation";
import SportMatchup from "src/views/component/sportMatchupsPage/sportsDetails/nbaMatchup";
import moment from "moment";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";

const data = [
  {
    id: 287,
    eventName: "New York Giants VS Green Bay Packers",
    awayTeamId: 126,
    homeTeamId: 135,
    startTime: "2023-12-12T01:15:00.000Z",
    AFLTournamentId: 4,
    AFLMatchups: [
      {
        id: 261,
        eventId: 287,
        ApiProviderId: 16,
        awayRecord: {
          logo: "https://rical-images.s3.amazonaws.com/team-logos/nfl/NYG.png",
          records: {
            total: "0-0 O/U",
            spread: "4-7-1 ATS",
            moneyline: "4-8 SU",
          },
          rotation: " 132",
          shortName: "Giants",
        },
        homeRecord: {
          logo: "https://rical-images.s3.amazonaws.com/team-logos/nfl/GB.png",
          records: {
            total: "0-0 O/U",
            spread: "7-5 ATS",
            moneyline: "6-6 SU",
          },
          rotation: " 131",
          shortName: "Packers",
        },
        consensus: {
          consensusMoney: {
            total: "o37",
            spread: "GB -5.5",
            moneyline: "-260",
          },
          consensusBetting: {
            total: "o37",
            spread: "GB -5.5",
            moneyline: "-260",
          },
        },
        bettingTrendsConsensus: {
          total: {
            awayOrOverMoneyVolume: "81%",
            homeOrUnderMoneyVolume: "19%",
            awayOrOverBettingVolume: "57%",
            homeOrUnderBettingVolume: "43%",
          },
          spread: {
            awayOrOverMoneyVolume: "52%",
            homeOrUnderMoneyVolume: "48%",
            awayOrOverBettingVolume: "45%",
            homeOrUnderBettingVolume: "55%",
          },
          moneyline: {
            awayOrOverMoneyVolume: "86%",
            homeOrUnderMoneyVolume: "14%",
            awayOrOverBettingVolume: "20%",
            homeOrUnderBettingVolume: "80%",
          },
        },
        openline: {
          openMoney: {
            total: "o37",
            spread: "GB -5.5",
            moneyline: "-250",
          },
          openBetting: {
            total: "o37",
            spread: "GB -5.5",
            moneyline: "-250",
          },
        },
        startTime: "2023-12-11T14:15:00.000Z",
      },
    ],
    awayTeam: {
      id: 126,
      name: "Green Bay Packers",
      gender: "M",
      flag: "uploads/1700752508107.png",
    },
    homeTeam: {
      id: 135,
      name: "New York Giants",
      gender: "M",
      flag: null,
    },
    AFLTournament: {
      id: 4,
      name: "NFL",
      rapidTournamentId: null,
      sportKey: "americanfootball_nfl",
      gender: "M",
      AFLCategoryId: null,
      SportId: 15,
      AFLCategory: null,
    },
    Sport: {
      id: 15,
      sportName: "American Football",
    },
  },
];

const RLFixtureInfoSport = () => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [fixtureInfoData, setFixtureInfoData] = useState({});
  const [MarketName, setMarketName] = useState("");
  const [MarketType, setMarketType] = useState("");
  const [isMultipleTeam, setIsMultipleTeam] = useState(false);
  const [individualTeamData, setindividualTeamData] = useState({});
  const [providerOption, setProviderOption] = useState([]);
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [singleMatchData, setSingleMatchData] = useState([]);
  const [matchLoading, setMatchLoading] = useState(false);
  const [tabvalue, setTabValue] = useState(0);

  const fixturesData = [
    {
      id: 0,
      name: "Info",
    },
    // {
    //   id: 1,
    //   name: "Summary"
    // },
    // {
    //   id: 2,
    //   name: "Stats"
    // },
    {
      id: 3,
      name: "Line-Ups",
    },
    {
      id: 4,
      name: "Table",
    },
    {
      id: 5,
      name: "News",
    },
    {
      id: 6,
      name: "H2H info",
    },
    {
      id: 7,
      name: "Odds Comparison",
    },
    {
      id: 8,
      name: "Odds fluctuation",
    },
    // {
    //   id: 9,
    //   name: "Betting Trends"
    // }
  ];
  const cricketLiveTab = [
    {
      id: 0,
      name: "Info",
    },
    {
      id: 1,
      name: "Summary",
    },
    {
      id: 2,
      name: "Stats",
    },
    {
      id: 3,
      name: "Line-Ups",
    },
    {
      id: 4,
      name: "Table",
    },
    {
      id: 5,
      name: "News",
    },
    {
      id: 6,
      name: "H2H info",
    },
    {
      id: 7,
      name: "Odds Comparison",
    },
    {
      id: 8,
      name: "Odds fluctuation",
    },
  ];
  const cricketTabData = [
    {
      id: 0,
      name: "Info",
    },
    {
      id: 1,
      name: "Summary",
    },
    {
      id: 4,
      name: "Table",
    },
    {
      id: 5,
      name: "News",
    },
    {
      id: 6,
      name: "H2H info",
    },
    {
      id: 7,
      name: "Odds Comparison",
    },
    {
      id: 8,
      name: "Odds fluctuation",
    },
  ];

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    fetchBookKeeper();
  }, []);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const handleSportName = () => {
    return params?.type === "cricket"
      ? "Cricket"
      : params?.type === "rugbyleague"
        ? "Rugby League"
        : params?.type === "rugbyunion"
          ? "Rugby Union"
          : params?.type === "basketball"
            ? "Basketball "
            : params?.type === "americanfootball"
              ? "American Football"
              : params?.type === "australianrules"
                ? "Australian Rules "
                : params?.type === "golf"
                  ? "Golf"
                  : params?.type === "tennis"
                    ? "Tennis"
                    : params?.type === "baseball"
                      ? "Baseball"
                      : params?.type === "icehockey"
                        ? "Ice Hockey"
                        : params?.type === "boxing"
                          ? "Boxing"
                          : params?.type === "mma"
                            ? "mma"
                            : params?.type === "soccer"
                              ? "soccer"
                              : "Rugby Union Sevens";
  };

  useEffect(() => {
    fetchIndividualMarket();
    fetchSingleMatchData();
  }, [params]);

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`,
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchIndividualMarket = async () => {
    setIsEventLoading(true);
    try {
      let SportsType =
        params?.type === "cricket"
          ? `crickets`
          : params?.type === "rugbyleague"
            ? `rls`
            : params?.type === "rugbyunion"
              ? `rls`
              : params?.type === "basketball"
                ? `nba`
                : params?.type === "americanfootball"
                  ? `afl`
                  : params?.type === "australianrules"
                    ? `ar`
                    : params?.type === "golf"
                      ? `golf`
                      : params?.type === "tennis"
                        ? `tennis`
                        : params?.type === "baseball"
                          ? `baseball`
                          : params?.type === "icehockey"
                            ? `icehockey`
                            : params?.type === "boxing"
                              ? `boxing`
                              : params?.type === "mma"
                                ? `mma`
                                : params?.type === "soccer"
                                  ? `soccer`
                                  : `rls`;
      const { status, data } = await axiosInstance.get(
        `public/${SportsType}/event/${params?.id}?marketId=&SportId=${
          params?.type === "rugbyleague"
            ? 12
            : params?.type === "rugbyunion"
              ? 13
              : ""
        }&smartBook=true`,
      );
      if (status === 200) {
        let allTeamOdds =
          params?.type === "cricket"
            ? data?.result?.CricketBetOffers
            : params?.type === "basketball"
              ? data?.result?.NBABetOffers
              : params?.type === "americanfootball"
                ? data?.result?.AFLBetOffers
                : params?.type === "australianrules"
                  ? data?.result?.ARBetOffers
                  : params?.type === "golf"
                    ? data?.result?.GolfBetOffers
                    : params?.type === "tennis"
                      ? data?.result?.TennisBetOffers
                      : params?.type === "baseball"
                        ? data?.result?.BaseballBetOffers
                        : params?.type === "icehockey"
                          ? data?.result?.IceHockeyBetOffers
                          : params?.type === "boxing"
                            ? data?.result?.BoxingBetOffers
                            : params?.type === "mma"
                              ? data?.result?.MMABetOffers
                              : params?.type === "soccer"
                                ? data?.result?.SoccerBetOffers
                                : data?.result?.RLBetOffers;
        let newdata = [];
        let dropdownData = allTeamOdds?.map((item) => {
          let Market =
            params?.type === "cricket"
              ? item?.CricketMarket
              : params?.type === "basketball"
                ? item?.NBAMarket
                : params?.type === "americanfootball"
                  ? item?.AFLMarket
                  : params?.type === "australianrules"
                    ? item?.ARMarket
                    : params?.type === "golf"
                      ? item?.GolfMarket
                      : params?.type === "tennis"
                        ? item?.TennisMarket
                        : params?.type === "baseball"
                          ? item?.BaseballMarket
                          : params?.type === "icehockey"
                            ? item?.IceHockeyMarket
                            : params?.type === "boxing"
                              ? item?.BoxingMarket
                              : params?.type === "mma"
                                ? item?.MMAMarket
                                : params?.type === "soccer"
                                  ? item?.SoccerMarket
                                  : item?.RLMarket;
          if (Market) {
            newdata.push({
              label: item?.replacer
                ? Market?.displayName
                    .replace("{homeTeam}", item?.replacer)
                    .replace("{awayTeam}", item?.replacer)
                : Market?.displayName,
              value: Market?.id,
            });
          }
        });
        const marketId = newdata?.[0]?.value ? newdata?.[0]?.value : 1;
        fetchIndividualTeamdata(marketId);
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const fetchIndividualTeamdata = async (marketId) => {
    try {
      setIsEventLoading(true);
      const passApi =
        params?.type === "cricket"
          ? `public/crickets/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
          : params?.type === "rugbyleague"
            ? `public/rls/event/${params?.id}?SportId=12&marketId=${marketId}&isAdmin=false&isHome=false`
            : params?.type === "rugbyunion"
              ? `public/rls/event/${params?.id}?SportId=13&marketId=${marketId}&isAdmin=false&isHome=false`
              : params?.type === "basketball"
                ? `public/nba/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                : params?.type === "americanfootball"
                  ? `public/afl/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                  : params?.type === "australianrules"
                    ? `public/ar/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                    : params?.type === "golf"
                      ? `public/golf/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                      : params?.type === "tennis"
                        ? `public/tennis/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                        : params?.type === "baseball"
                          ? `public/baseball/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                          : params?.type === "icehockey"
                            ? `public/icehockey/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                            : params?.type === "boxing"
                              ? `public/boxing/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                              : params?.type === "mma"
                                ? `public/mma/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                                : params?.type === "soccer"
                                  ? `public/soccer/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false`
                                  : `public/rls/event/${params?.id}?SportId=14&marketId=${marketId}&isAdmin=false&isHome=false`;
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setFixtureInfoData(data?.result);
        let fullData = [];
        let teamdata = [data?.result];
        let datas = teamdata?.map((item) => {
          let newData =
            teamdata?.length > 0
              ? params?.type === "cricket"
                ? teamdata?.[0]?.CricketBetOffers
                : params?.type === "basketball"
                  ? teamdata?.[0]?.NBABetOffers
                  : params?.type === "americanfootball"
                    ? teamdata?.[0]?.AFLBetOffers
                    : params?.type === "australianrules"
                      ? teamdata?.[0]?.ARBetOffers
                      : params?.type === "golf"
                        ? teamdata?.[0]?.GolfBetOffers
                        : params?.type === "tennis"
                          ? teamdata?.[0]?.TennisBetOffers
                          : params?.type === "baseball"
                            ? teamdata?.[0]?.BaseballBetOffers
                            : params?.type === "icehockey"
                              ? teamdata?.[0]?.IceHockeyBetOffers
                              : params?.type === "boxing"
                                ? teamdata?.[0]?.BoxingBetOffers
                                : params?.type === "mma"
                                  ? teamdata?.[0]?.MMABetOffers
                                  : params?.type === "soccer"
                                    ? teamdata?.[0]?.SoccerBetOffers
                                    : teamdata?.[0]?.RLBetOffers
              : [];
          let SportsOdds =
            params?.type === "cricket"
              ? newData?.[0]?.CricketOdds
              : params?.type === "basketball"
                ? newData?.[0]?.NBAOdds
                : params?.type === "americanfootball"
                  ? newData?.[0]?.AFLOdds
                  : params?.type === "australianrules"
                    ? newData?.[0]?.AROdds
                    : params?.type === "golf"
                      ? newData?.[0]?.GolfOdds
                      : params?.type === "tennis"
                        ? newData?.[0]?.TennisOdds
                        : params?.type === "baseball"
                          ? newData?.[0]?.BaseballOdds
                          : params?.type === "icehockey"
                            ? newData?.[0]?.IceHockeyOdds
                            : params?.type === "boxing"
                              ? newData?.[0]?.BoxingOdds
                              : params?.type === "mma"
                                ? newData?.[0]?.MMAOdds
                                : params?.type === "soccer"
                                  ? newData?.[0]?.SoccerOdds
                                  : newData?.[0]?.RLOdds;
          let SportMarket =
            params?.type === "cricket"
              ? newData?.[0]?.CricketMarket
              : params?.type === "basketball"
                ? newData?.[0]?.NBAMarket
                : params?.type === "americanfootball"
                  ? newData?.[0]?.AFLMarket
                  : params?.type === "australianrules"
                    ? newData?.[0]?.ARMarket
                    : params?.type === "golf"
                      ? newData?.[0]?.GolfMarket
                      : params?.type === "tennis"
                        ? newData?.[0]?.TennisMarket
                        : params?.type === "baseball"
                          ? newData?.[0]?.BaseballMarket
                          : params?.type === "icehockey"
                            ? newData?.[0]?.IceHockeyMarket
                            : params?.type === "boxing"
                              ? newData?.[0]?.BoxingMarket
                              : params?.type === "mma"
                                ? newData?.[0]?.MMAMarket
                                : params?.type === "soccer"
                                  ? newData?.[0]?.SoccerMarket
                                  : newData?.[0]?.RLMarket;
          setMarketName(
            SportMarket?.displayName
              ? SportMarket?.displayName
              : SportMarket?.name,
          );
          setMarketType(SportMarket?.type);
          const teamKey =
            params?.type === "cricket"
              ? "CricketTeamId"
              : params?.type === "basketball"
                ? "NBATeamId"
                : params?.type === "americanfootball"
                  ? "AFLTeamId"
                  : params?.type === "australianrules"
                    ? "ARTeamId"
                    : params?.type === "golf"
                      ? "GolfTeamId"
                      : params?.type === "tennis"
                        ? "TennisTeamId"
                        : params?.type === "baseball"
                          ? "BaseballTeamId"
                          : params?.type === "icehockey"
                            ? "IceHockeyTeamId"
                            : params?.type === "boxing"
                              ? "BoxingTeamId"
                              : params?.type === "mma"
                                ? "MMATeamId"
                                : params?.type === "soccer"
                                  ? "SoccerTeamId"
                                  : "RLTeamId";
          const isTeamdata = SportsOdds?.some(
            (item) => item?.[teamKey] !== null,
          );
          const LabelName =
            params?.type === "cricket"
              ? SportsOdds?.[0]?.CricketOddLabel?.displayName
                ? SportsOdds?.[0]?.CricketOddLabel?.displayName
                : SportsOdds?.[0]?.CricketOddLabel?.name
              : params?.type === "basketball"
                ? SportsOdds?.[0]?.NBAOddLabel?.displayName
                  ? SportsOdds?.[0]?.NBAOddLabel?.displayName
                  : SportsOdds?.[0]?.NBAOddLabel?.name
                : params?.type === "americanfootball"
                  ? SportsOdds?.[0]?.AFLOddLabel?.displayName
                    ? SportsOdds?.[0]?.AFLOddLabel?.displayName
                    : SportsOdds?.[0]?.AFLOddLabel?.name
                  : params?.type === "australianrules"
                    ? SportsOdds?.[0]?.AROddLabel?.displayName
                      ? SportsOdds?.[0]?.AROddLabel?.displayName
                      : SportsOdds?.[0]?.AROddLabel?.name
                    : params?.type === "golf"
                      ? SportsOdds?.[0]?.GolfOddLabel?.displayName
                        ? SportsOdds?.[0]?.GolfOddLabel?.displayName
                        : SportsOdds?.[0]?.GolfOddLabel?.name
                      : params?.type === "tennis"
                        ? SportsOdds?.[0]?.TennisOddLabel?.displayName
                          ? SportsOdds?.[0]?.TennisOddLabel?.displayName
                          : SportsOdds?.[0]?.TennisOddLabel?.name
                        : params?.type === "baseball"
                          ? SportsOdds?.[0]?.BaseballOddLabel?.displayName
                            ? SportsOdds?.[0]?.BaseballOddLabel?.displayName
                            : SportsOdds?.[0]?.BaseballOddLabel?.name
                          : params?.type === "icehockey"
                            ? SportsOdds?.[0]?.IceHockeyOddLabel?.displayName
                              ? SportsOdds?.[0]?.IceHockeyOddLabel?.displayName
                              : SportsOdds?.[0]?.IceHockeyOddLabel?.name
                            : params?.type === "boxing"
                              ? SportsOdds?.[0]?.BoxingOddLabel?.displayName
                                ? SportsOdds?.[0]?.BoxingOddLabel?.displayName
                                : SportsOdds?.[0]?.BoxingOddLabel?.name
                              : params?.type === "mma"
                                ? SportsOdds?.[0]?.MMAOddLabel?.displayName
                                  ? SportsOdds?.[0]?.MMAOddLabel?.displayName
                                  : SportsOdds?.[0]?.MMAOddLabel?.name
                                : params?.type === "soccer"
                                  ? SportsOdds?.[0]?.SoccerOddLabel?.displayName
                                    ? SportsOdds?.[0]?.SoccerOddLabel
                                        ?.displayName
                                    : SportsOdds?.[0]?.SoccerOddLabel?.name
                                  : SportsOdds?.[0]?.RLOddLabel?.displayName
                                    ? SportsOdds?.[0]?.RLOddLabel?.displayName
                                    : SportsOdds?.[0]?.RLOddLabel?.name;
          const name = LabelName ? LabelName : SportsOdds?.[0]?.label;
          setIsMultipleTeam(
            SportsOdds
              ? !isTeamdata &&
                  !(
                    name?.toLowerCase()?.includes("over") ||
                    name?.toLowerCase()?.includes("under") ||
                    name?.toLowerCase()?.includes("totalover") ||
                    name?.toLowerCase()?.includes("totalunder") ||
                    name?.toLowerCase()?.includes("total")
                  )
              : false,
          );
          let Bookkeeper = [];
          let BookkeeperList = SportsOdds?.map((element) => {
            return Bookkeeper?.push(element?.BookKeeperId);
          });
          fetchTableHeading([...new Set(Bookkeeper)]);
          setindividualTeamData(item);
          fullData.push(item);
          setTimeout(() => {
            setIsEventLoading(false);
          }, 4000);
        });
      }
    } catch {
      setIsEventLoading(false);
    }
  };

  const fetchTableHeading = async (Bookkeeper) => {
    try {
      let SportId =
        params?.type === "cricket"
          ? 4
          : params?.type === "rugbyleague"
            ? 12
            : params?.type === "rugbyunion"
              ? 13
              : params?.type === "basketball"
                ? 10
                : params?.type === "americanfootball"
                  ? 15
                  : params?.type === "australianrules"
                    ? 9
                    : params?.type === "golf"
                      ? 16
                      : params?.type === "tennis"
                        ? 7
                        : params?.type === "baseball"
                          ? 11
                          : params?.type === "icehockey"
                            ? 17
                            : params?.type === "boxing"
                              ? 6
                              : params?.type === "mma"
                                ? 5
                                : params?.type === "soccer"
                                  ? 8
                                  : 14;
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders?SportId=${SportId}`,
      );
      if (status === 200) {
        let filteredHeading = data?.result?.filter((item) =>
          Bookkeeper?.includes(item?.BookKeeperId),
        );
        let providerList = [];
        const list = data?.result?.map((item) => {
          return providerList?.push({
            label: item?.BookKeeper?.name,
            value: item?.BookKeeperId,
          });
        });
        let alldatas = providerList?.unshift(
          {
            label: "Current Best",
            value: -1,
          },
          {
            label: "Best At Open",
            value: -2,
          },
        );
        setProviderOption(providerList);
        setPageHeadingData(filteredHeading);
      } else {
      }
    } catch (err) {}
  };

  const fetchSingleMatchData = async () => {
    setMatchLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/rl/event/${params?.id}`,
      );
      if (status === 200) {
        setSingleMatchData(data?.result);
        setTabValue(data?.result?.status !== "notstarted" ? 2 : 0);
        setMatchLoading(false);
      } else {
        setMatchLoading(false);
      }
    } catch (err) {
      setMatchLoading(false);
    }
  };

  const fetchRLscore = () => {
    const HomeTeamScores = individualTeamData?.RLScores?.filter(
      (item) => item?.teamId == individualTeamData?.homeTeamId,
    );
    const AwayTeamScores = individualTeamData?.RLScores?.filter(
      (item) => item?.teamId == individualTeamData?.awayTeamId,
    );
    return (
      <>
        <Table className="match-point-table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                {individualTeamData?.status == "inprogress"
                  ? "Score"
                  : "Result"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="player-name">
                {individualTeamData?.homeTeam?.name}
              </TableCell>
              <TableCell>{HomeTeamScores?.[0]?.score?.current}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="player-name">
                {individualTeamData?.awayTeam?.name}
              </TableCell>
              <TableCell>{AwayTeamScores?.[0]?.score?.current}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };

  const teamScore = individualTeamData?.status;

  const NewTabData =
    individualTeamData?.status == "inprogress" ||
    individualTeamData?.status == "finished"
      ? (singleMatchData?.rlPlayerIncident
          ? cricketLiveTab
          : cricketLiveTab?.filter((x) => x?.name !== "Summary")) ||
        (singleMatchData?.tableExists
          ? cricketLiveTab
          : cricketLiveTab?.filter((x) => x?.name !== "Table"))
      : (singleMatchData?.rlPlayerIncident
          ? cricketTabData
          : cricketTabData?.filter((x) => x?.name !== "Summary")) ||
        (singleMatchData?.tableExists
          ? cricketTabData
          : cricketTabData?.filter((x) => x?.name !== "Table"));

  const OddsComparisonTab =
    release[Config.release]?.oddsCheck === false ? "Odds Comparison" : "";
  const OddsFluctuationGraph =
    release[Config.release]?.oddsCheck === false ? "Odds fluctuation" : "";
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release == "IN" ? bannerIndia : banner,
          )}
        </Box>
        {matchLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <Box className="rl-fixture-info-wrap">
            <Box className="fixture-info-header">
              <Box className="page-bredcrumn-wrap">
                <Box className="bredcrumn-wrap">
                  <Breadcrumbs
                    separator="/"
                    aria-label="breadcrumb"
                    className="breadcrumb"
                  >
                    <Link underline="hover" color="inherit" to="/">
                      {localesData?.MENU?.HOME}
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      to="#"
                      className="cursor-default"
                    >
                      {localesData?.MENU?.SPORTS}
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/all-sports/${params?.type}/${params?.SportId}/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=${Number(params?.SportId) === 4 ? "All+Cricket" : "All+Leagues"}}`}
                    >
                      {handleSportName()}
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/sport/statistics/${individualTeamData?.SportId}/${
                        individualTeamData?.RLTournamentId
                      }/league-data${
                        individualTeamData?.RLSeasonId
                          ? `?seasonId=${individualTeamData?.RLSeasonId}`
                          : ""
                      }`}
                    >
                      {individualTeamData?.RLTournament?.name}
                    </Link>
                    <Typography>{individualTeamData?.eventName}</Typography>
                  </Breadcrumbs>
                </Box>
              </Box>
              <Box className="d-flex align-center header-wrap">
                <Box className="d-flex align-center header-text">
                  <Typography variant="h1">
                    {individualTeamData?.eventName}
                  </Typography>
                  {individualTeamData?.status == "inprogress" && (
                    <span className="live-indicator">LIVE</span>
                  )}
                </Box>

                {/* <Box className="samrtBook-button-wrap">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="samrtBook-button"
                >
                  <span style={{ display: "flex" }}>
                    <Plus />{" "}
                  </span>{" "}
                  <span style={{ paddingLeft: "8px" }}>
                    {localesData?.SMARTBOOK?.ADD_TO_MY_SMARTBOOK}
                  </span>
                </Button>
              </Box> */}
              </Box>
            </Box>
            {(individualTeamData?.status == "inprogress" ||
              individualTeamData?.status == "finished") &&
              fetchRLscore()}

            <Box className="team-sports-tab">
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons={false}
                indicatorColor="primary"
                textColor="primary"
                className="teamsport-tab-detail"
              >
                {NewTabData?.filter(
                  (item) =>
                    item?.name !== OddsComparisonTab &&
                    item?.name !== OddsFluctuationGraph,
                )?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={
                          <Box className="tab-label">
                            <span className="label-name">{item?.name}</span>
                            <span className="label-icon">{item?.icon}</span>
                          </Box>
                        }
                        value={item?.id}
                        className={item?.id === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>
            {tabvalue === 0 ? (
              <InfoFixture
                fixtureInfoData={fixtureInfoData}
                singleMatchData={singleMatchData}
              />
            ) : tabvalue === 1 ? (
              <InfoSummary
                fixtureInfoData={fixtureInfoData}
                singleMatchData={singleMatchData}
              />
            ) : tabvalue === 2 ? (
              <MatchStats
                fixtureInfoData={fixtureInfoData}
                singleMatchData={singleMatchData}
                individualTeamData={individualTeamData}
              />
            ) : tabvalue === 3 ? (
              <LineUpInfo fixtureInfoData={fixtureInfoData} />
            ) : tabvalue === 4 ? (
              <InfoTable singleMatchData={singleMatchData} />
            ) : tabvalue === 5 ? (
              <InfoNews individualTeamData={individualTeamData} />
            ) : tabvalue === 6 ? (
              <InfoH2H
                fixtureInfoData={fixtureInfoData}
                singleMatchData={individualTeamData}
              />
            ) : tabvalue === 7 ? (
              <InfoOddsComparison
                pageHeadingData={pageHeadingData}
                individualTeamData={individualTeamData}
                MarketName={MarketName}
                BookkeeperData={BookkeeperData}
                fetchIndividualMarket={fetchIndividualMarket}
              />
            ) : tabvalue === 8 ? (
              <InfoOddsFluctuation
                fixtureInfoData={fixtureInfoData}
                individualTeamData={individualTeamData}
                teamSportType={params?.type}
                BookkeeperData={BookkeeperData}
              />
            ) : tabvalue === 9 ? (
              <SportMatchup matchUpData={data} teamSportType={params?.type} />
            ) : (
              ""
            )}
            <OurPartner />
          </Box>
        )}
      </Box>
    </>
  );
};

export default RLFixtureInfoSport;
