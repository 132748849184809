import React from "react";

const MobileMenuWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g id="menuIconWhite" transform="translate(-8 -58.691)">
        <g
          id="Mobile_responsive_-_Menu_Tab"
          data-name="Mobile responsive - Menu Tab"
          transform="translate(12 62.293)"
        >
          <rect
            id="Rectangle_6967"
            data-name="Rectangle 6967"
            width="28"
            height="28"
            transform="translate(-4 -3.602)"
            fill="none"
          />
          <path
            id="Path_12123"
            data-name="Path 12123"
            d="M5,6h9.722M5,10.166h9.722M5,14.333h9.722"
            transform="translate(0.499 -0.102)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default MobileMenuWhite;
