import { SPORTS_TYPE } from "../teamCommonComponent/newSportsUtils";
import PlayerTable from "./PlayerTable";
import { useLocation, useParams } from "react-router";
import NRLStatsTable from "./NRLStatsTable";
import { usePlayerStats } from "src/helpers/context/PlayersStats/PlayerStatsContext";
import useCloumn from "src/components/Common/Hooks/useColumn";
import { useEffect, useState } from "react";

const StatsLandingTable = ({ selectedStats }) => {
  const params = useParams();
  const sportsId = Number(params?.sportsId);
  const { playerStats, loading } = usePlayerStats() || {};
  const { cricketColumn, NRLLadderColum, NRLStatsColumn } = useCloumn();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const menuValue = searchParams.get("menu"); // Extracts the value of "menu"
  const [NRLColumn, setNRLColumn] = useState([]);

  useEffect(() => {
    if (menuValue === "ladder") {
      setNRLColumn(NRLLadderColum);
    } else {
      setNRLColumn(NRLStatsColumn);
    }
  }, [menuValue]);

  return (
    <>
      {/* Cricket */}
      {sportsId === SPORTS_TYPE.circket && (
        <PlayerTable tableData={playerStats?.body ?? []} loading={loading} />
      )}

      {/* Rugby League */}
      {sportsId === SPORTS_TYPE.rugbyLeague && (
        <NRLStatsTable loading={false} selectedStats={selectedStats} />
      )}
    </>
  );
};

export default StatsLandingTable;
