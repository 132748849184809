import LadderStatsProvider from "../Ladder/LadderContext";
import PlayerStatsProvider from "../PlayersStats/PlayerStatsContext";

const StatsProvider = ({ children }) => {
  return (
    <LadderStatsProvider>
      <PlayerStatsProvider>{children}</PlayerStatsProvider>
    </LadderStatsProvider>
  );
};

export default StatsProvider;
