import React from "react";

const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.783"
      height="12.985"
      viewBox="0 0 14.783 12.985"
    >
      <g
        id="Group_103038"
        data-name="Group 103038"
        transform="translate(0.5 0.5)"
      >
        <circle
          id="Ellipse_14534"
          data-name="Ellipse 14534"
          cx="1.198"
          cy="1.198"
          r="1.198"
          transform="translate(6.891 9.588)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_2205"
          data-name="Line 2205"
          x2="4.494"
          transform="translate(9.288 10.786)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_2206"
          data-name="Line 2206"
          x2="6.891"
          transform="translate(0 10.786)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <circle
          id="Ellipse_14535"
          data-name="Ellipse 14535"
          cx="1.198"
          cy="1.198"
          r="1.198"
          transform="translate(2.097 4.794)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_2207"
          data-name="Line 2207"
          x2="9.288"
          transform="translate(4.494 5.992)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_2208"
          data-name="Line 2208"
          x2="2.097"
          transform="translate(0 5.992)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <circle
          id="Ellipse_14536"
          data-name="Ellipse 14536"
          cx="1.198"
          cy="1.198"
          r="1.198"
          transform="translate(8.689)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_2209"
          data-name="Line 2209"
          x2="2.697"
          transform="translate(11.086 1.198)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_2210"
          data-name="Line 2210"
          x2="8.689"
          transform="translate(0 1.198)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default SettingsIcon;
