import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as SubscriptionActive } from "../../../../assets/images/icons/planActive.svg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import useMembershipStore from "src/helpers/zustand/useMembershipStore";
import "./planDetails.scss";

const PlanDetails = () => {
  const { setSelectedPlanDetailsStore } = useMembershipStore();
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    fetchGetPlanDetails();
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchGetPlanDetails = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/plan-list`,
      );
      if (status === 200) {
        setLoading(false);
        const planDetails = data?.getPlans;
        setSelectedPlanDetails(planDetails?.[0]);
        setSelectedPlanDetailsStore(planDetails?.[0]);
        setPlanDetails(planDetails);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const toggleShowMore = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <Box className="plan-details-wrap">
        {loading && (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        )}
        {!loading &&
          (planDetails?.length > 0 ? (
            planDetails?.map((card, index) => {
              return (
                <Box key={index} className="membership-card-data">
                  <Box className="membership-plan">
                    <Typography className="plan-title">
                      {card?.name ? card?.name : "Dummy"}
                    </Typography>
                    <Box className="plan-title-box">
                      <Box className="plan-title-box-wrap">
                        <Box className="plan-text-flex">
                          <Box className="bronze-wrap">Bronze</Box>
                          <Typography className="plan-text-details">
                            {card?.subTitle ?? ""}
                          </Typography>
                        </Box>
                        <Box className="price-wrap">
                          <Typography className="price">
                            {"$" + Number(card?.amount)?.toFixed(2)}
                            <span className="suffix">/{card?.duration}</span>
                          </Typography>
                        </Box>
                      </Box>
                      {/* <Typography className="plan-text-details price-change-text">
                        The price will change to $29.99 from April 1st 2025
                      </Typography> */}
                    </Box>

                    <Box className="plan-info">
                      <Box className="des-wrap">
                        {(screenWidth > 800
                          ? card?.featured
                          : card?.featured?.slice(
                              0,
                              showAll ? card?.featured?.length : 3,
                            )
                        )?.map((item) => {
                          return (
                            <Box className="des-box">
                              <Box className="tic-box">
                                <SubscriptionActive />
                              </Box>
                              <Box className="des">{item}</Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                    {screenWidth < 800 && (
                      <Box className="show-more-btn-wrap">
                        <Typography
                          className="show-more-btn"
                          onClick={() => toggleShowMore()}
                        >
                          {!showAll ? (
                            <span>
                              Show more <KeyboardArrowDownIcon />
                            </span>
                          ) : (
                            <span>
                              Show less <KeyboardArrowUpIcon />
                            </span>
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box> No data available</Box>
          ))}
      </Box>
    </>
  );
};

export default PlanDetails;
