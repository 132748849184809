import { ReactComponent as SubsBadge } from "../../../assets/images/icons/subscription-badge.svg";

export const dayOptions = [
  {
    label: "Sunday",
    value: 1,
  },
  {
    label: "Monday",
    value: 2,
  },
  {
    label: "Tuesday",
    value: 3,
  },
  {
    label: "Wednesday",
    value: 4,
  },
  {
    label: "Thursday",
    value: 5,
  },
  {
    label: "Friday",
    value: 6,
  },
  {
    label: "Saturday",
    value: 7,
  },
];
export const privacyOptions = [
  {
    label: "Public",
    value: 1,
  },
  {
    label: "Private",
    value: 2,
  },
];
export const tipDeadlineOptions = [
  {
    label: "Round Based",
    value: 1,
  },
  {
    label: "Game Based",
    value: 2,
  },
];
export const ifNoTipOptions = [
  {
    label: "Zero Score for round",
    value: 1,
  },
  {
    label: "Lowest Score for round",
    value: 2,
  },
  {
    label: "All Away Teams auto picked",
    value: 3,
  },
  {
    label: "All Home Teams auto picked",
    value: 4,
  },
];

export const tippingTypeOption = [
  {
    type: "Winner Tipping",
    description:
      "Points will be awarded based on the number of correct tips you make for each round.",
  },
  {
    type: " Odds Tipping",
    description:
      "The odds are locked when you select the team to win and earn points based on those odds.",
  },
  {
    type: "Spread/Line tipping",
    description: "You will get win/points based on the handicap.",
  },
];

export const tippingTypeRadio = [
  {
    label: "Winner tipping",
    value: "winning",
    icon: null,
    id: 1,
  },
  {
    label: "Odds tipping",
    value: "odds",
    icon: <SubsBadge className="subs-badge" />,
    id: 2,
  },
  {
    label: "Spread/Line tipping",
    value: "spread",
    icon: <SubsBadge className="subs-badge" />,
    id: 3,
  },
];

export const isTipsterShown = [
  {
    id: 1,
    isTipsterShown: "Show",
    value: true,
  },
  {
    id: 2,
    isTipsterShown: "Hide",
    value: false,
  },
];

export const isTipsterTipping = [
  {
    id: 1,
    isTipsterTippingShown: "Always",
    value: "always",
  },
  {
    id: 2,
    isTipsterTippingShown: "After lockout",
    value: "afterLockout",
  },
  {
    id: 3,
    isTipsterTippingShown: "Never",
    value: "never",
  },
];

export const roundBonus = [
  {
    id: 1,
    Roundvalue: "Yes",
    isRoundBonus: false,
  },
  {
    id: 2,
    Roundvalue: "No",
    isRoundBonus: false,
  },
];

export const includeFinals = [
  {
    id: 1,
    IncludeFinalsValue: "Yes",
    isIncludeFinals: false,
  },
  {
    id: 2,
    IncludeFinalsValue: "No",
    isIncludeFinals: false,
  },
];

export const editincludeFinals = [
  {
    id: 1,
    IncludeFinalsValue: "Yes",
  },
  {
    id: 0,
    IncludeFinalsValue: "No",
  },
];

export const jokerBonus = [
  {
    id: 1,
    Jokervalue: "Yes",
    isJokerBonus: false,
  },
  {
    id: 2,
    Jokervalue: "No",
    isJokerBonus: false,
  },
];

export const marginSystem = [
  {
    id: 1,
    MarginSystemvalue: "Yes",
    isMarginSystem: false,
  },
  {
    id: 2,
    MarginSystemvalue: "No",
    isMarginSystem: false,
  },
];

export const entryFee = [
  {
    id: 1,
    EntryFeeValue: "Yes",
    isEntryFee: false,
  },
  {
    id: 2,
    EntryFeeValue: "No",
    isEntryFee: false,
  },
];

export const editentryFee = [
  {
    id: 1,
    EntryFeeValue: "Yes",
  },
  {
    id: 0,
    EntryFeeValue: "No",
  },
];

export const prizeInformation = [
  {
    id: 1,
    PrizeInformationValue: "Yes",
    isPrizeInformation: false,
  },
  {
    id: 2,
    PrizeInformationValue: "No",
    isPrizeInformation: false,
  },
];

export const editprizeInformation = [
  {
    id: 1,
    PrizeInformationValue: "Yes",
  },
  {
    id: 0,
    PrizeInformationValue: "No",
  },
];
