import React from "react";
import { Grid2, Typography } from "@mui/material";

const StatsLegend = ({ stats }) => {
  const uniqueColumns = [...new Set(stats?.map((item) => item?.column))]; // Get unique columns

  return (
    <Grid2 container spacing={8}>
      {uniqueColumns?.map((col) => (
        <Grid2 item xs={12 / uniqueColumns?.length} key={col}>
          {" "}
          {/* Dynamic column width */}
          {stats
            .filter((item) => item?.column === col)
            .map(({ label, text }) => (
              <Typography key={label} variant="body1" className="legend-text">
                <strong>{label}:</strong> {text}
              </Typography>
            ))}
        </Grid2>
      ))}
    </Grid2>
  );
};

export default StatsLegend;
