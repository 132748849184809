import React, { useContext } from "react";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as RightArrow } from "src/assets/images/icons/bb-white-arrow-right.svg";
import { ReactComponent as EditIcon } from "src/assets/images/icons/editIcon.svg";
import Bet365 from "../../../../../assets/images/bookmakers/bet365-thumb.svg";
import AUS from "../../../../../assets/images/flag/au.png";
import moment from "moment";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../../../helpers/context/config";
import Tooltip from "@mui/material/Tooltip";
import TrackRaceResult from "../../../allsports/racing/racingList/trackRaceResult";
import TrackListCountdown from "../../../allsports/racing/racingList/trackListCountdown";
import Loader from "src/components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import DialogBox from "../../../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import BlackBookModal from "../../../UI/blackBookModal";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NoDataComp from "../../../UI/NoData";
import { Routes } from "../../../../../helpers/constants/routeConstants";
import RaceOdds from "../../../raceOdds/RaceOdds";
import FixedMark from "../../../../../assets/images/Sport/fixed_mark.svg";
import Close from "../../../../../assets/images/Sport/close.svg";
import CloseSecondary from "../../../../../assets/images/Sport/close_secondary_mark.svg";
import Interim from "../../../../../assets/images/Sport/interim_mark.svg";
import Paying from "../../../../../assets/images/Sport/paying_mark.svg";
import Runner_Default from "../../../../../assets/images/runner-default.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IntlContext } from "src/App";

const Results = ({
  resultData,
  isresultLoading,
  fetchResultBlackBook,
  countryId,
  stateId,
  handlePaginationClick,
  resultCount,
  resultPage,
  pageNumbers,
  isToggle,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [editComment, setEditComment] = useState("");
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [raceMenuCollapse, setRaceMenuCollapse] = useState(-1);
  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const handleTracklist = (ele) => {
    let data = ele?.race;
    let a = data.map((a) => a.raceNumber) || [];

    for (var i = 1; i <= 12; i++) {
      if (a.indexOf(i) == -1) {
        let dummy_ele = { startTimeDate: null, raceNumber: i };
        data.push(dummy_ele);
      }
    }

    ele["race"] = data
      .sort((a, b) => {
        return a?.raceNumber - b?.raceNumber;
      })
      ?.filter((race) => race?.raceNumber !== 0);
    const track = ele?.race?.slice(0, 12)?.map((obj, i) => {
      if (
        obj?.startDate !== null &&
        moment(new Date(obj?.startDate)).isBefore(new Date())
      ) {
        return (
          <TrackRaceResult // if race is close
            race={ele}
            race_obj={obj}
            key={i}
            intl={
              ele?.countryId === 157 || ele?.countryId === 13 ? false : true
            }
            selectedDate={moment(obj?.startDate).format("YYYY-MM-DD")}
            // raceData={
            //   item?.RaceData
            //     ?.intlData
            // }
            isMobile={false}
            isBlackBook={true}
          />
        );
      } else {
        return (
          <TrackListCountdown // if race is upcoming
            race={ele}
            race_obj={obj}
            key={i}
            intl={
              ele?.countryId === 157 || ele?.countryId === 13 ? false : true
            }
            selectedDate={moment(obj?.startDate).format("YYYY-MM-DD")}
            // raceData={
            //   item?.RaceData
            //     ?.intlData
            // }
            expiryTimestamp={
              obj?.startDate !== null
                ? new Date(new Date(obj?.startDate).toUTCString()).getTime()
                : new Date().getTime()
            }
            checkRaceMinCell={checkRaceMinCell}
            isMobile={false}
            isBlackBook={true}
          />
        );
      }
    });
    return track;
  };
  const fetchNewMobileViewRacingData = (ele, intl, index) => {
    let haveRace = ele?.race
      ?.filter((data) => data?.startDate !== null)
      ?.filter((race) => race?.raceNumber !== 0);
    let upnextRaces = ele?.race
      ?.filter(
        (item) =>
          item?.startDate !== null &&
          moment(new Date()).isBefore(new Date(item?.startDate)),
      )
      ?.filter((race) => race?.raceNumber !== 0);
    let completedRaces = ele?.race
      ?.filter(
        (item) =>
          item?.startDate !== null &&
          moment(new Date(item?.startDate)).isBefore(new Date()),
      )
      ?.filter((race) => race?.raceNumber !== 0);

    return (
      <Box className="Mobile-racing-data">
        <React.Fragment>
          <Box
            className={`${
              raceMenuCollapse === index ? "racing-menu-collapse" : ""
            } racing-data-row`}
          >
            <Box
            // onClick={
            //   upnextRaces?.length > 0
            //     ? () => {
            //       navigate(
            //         // Redirect to Runner Details
            //         Routes.RunnerDetails(
            //           race?.sportId === 1
            //             ? "horse"
            //             : race?.sportId === 2
            //               ? "harness"
            //               : "greyhounds",
            //           race?.sportId,
            //           upnextRaces[0].id,
            //           race?.track?.id,
            //           race?.id,
            //           selectedDate,
            //           intl
            //         ),
            //         {
            //           state: {
            //             raceData: data,
            //             CurrentData: race,
            //             SelectedRaceid: haveRace[0].id,
            //           },
            //         }
            //       );
            //     }
            //     : haveRace[0]?.raceNumber !== undefined
            //       ? () => {
            //         navigate(
            //           Routes.RunnerDetails(
            //             race?.sportId === 1
            //               ? "horse"
            //               : race?.sportId === 2
            //                 ? "harness"
            //                 : "greyhounds",
            //             race?.sportId,
            //             haveRace[0].id,
            //             race?.track?.id,
            //             race?.id,
            //             selectedDate,
            //             intl
            //           ),
            //           {
            //             state: {
            //               raceData: data,
            //               CurrentData: race,
            //               SelectedRaceid: haveRace[0].id,
            //             },
            //           }
            //         );
            //       }
            //       : () => { }
            // }
            >
              <Typography className="track-title" variant="subtitle1">
                <Box className="track-country-wrap">
                  <span
                    className="track-name"
                    onClick={() => handleNavigate(ele)}
                  >
                    {ele?.trackName}
                  </span>
                  <span className="track-code">
                    {" "}
                    {ele?.countryCode ? `(${ele?.countryCode})` : ""}
                  </span>
                </Box>
                <Box className="track-country"></Box>
              </Typography>
            </Box>
            <Box className="upcoming-list">
              {upnextRaces?.length > 0 ? (
                <Box className="collapse-menu">
                  {fetchMobileMenuTrackList(
                    [],
                    ele,
                    upnextRaces?.[0],
                    upnextRaces?.[0]?.raceNumber,
                    intl,
                  )}
                  <span className={`race-tag race-tag-upcoming`}>
                    Race {upnextRaces?.[0]?.raceNumber}
                  </span>
                </Box>
              ) : (
                <></>
              )}
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  return setRaceMenuCollapse(
                    raceMenuCollapse === index ? -1 : index,
                  );
                  // setDropDownSportId(race?.sportId),
                  // setIsIntl(intl)
                }}
              >
                {raceMenuCollapse === index ? (
                  // &&
                  //   dropDownSportId === race?.sportId &&
                  //   isintl === intl
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Box>
          </Box>
          <Collapse
            in={raceMenuCollapse === index}
            timeout="auto"
            unmountOnExit
            className={`${
              raceMenuCollapse !== index ? "" : "collapse-section"
            }`}
          >
            {upnextRaces?.length > 0 ? (
              upnextRaces?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Box
                      className={`collapse-menu collapse-td ${fetchFixedOdds(
                        item,
                      )}`}
                    >
                      {fetchMobileMenuTrackList(
                        [],
                        ele,
                        item,
                        item?.raceNumber,
                        intl,
                      )}
                      <span
                        className={
                          index === 0
                            ? `race-tag race-tag-close`
                            : `race-tag race-tag-secondary-close`
                        }
                      >
                        Race {item?.raceNumber}
                      </span>
                    </Box>
                  </React.Fragment>
                );
              })
            ) : (
              <></>
            )}
            {completedRaces?.length > 0 ? (
              completedRaces?.map((item, index) => {
                return (
                  <Box
                    className={`collapse-menu collapse-td ${fetchFixedOdds(
                      item,
                    )}`}
                    key={index}
                  >
                    {fetchMobileMenuTrackList(
                      [],
                      ele,
                      item,
                      item?.raceNumber,
                      intl,
                    )}
                    <span className="race-tag race-tag-result">
                      Race {item?.raceNumber}
                    </span>
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </Collapse>
        </React.Fragment>
      </Box>
    );
  };
  const fetchFixedOdds = (race_obj) => {
    if (race_obj?.id) {
      if (race_obj?.isOdd) {
        return "fixed";
      } else {
        return "";
      }
    }
  };
  const fetchMobileMenuTrackList = (data, race, obj, racetrack, intl) => {
    if (
      obj?.startDate !== null &&
      moment(new Date(obj?.startDate)).isBefore(new Date())
    ) {
      return (
        <TrackRaceResult // if race is close
          race={race}
          race_obj={obj}
          selectedDate={moment(obj?.startDate).format("YYYY-MM-DD")}
          key={racetrack}
          raceData={data}
          intl={intl}
          isMobile={false}
          isBlackBook={true}
        />
      );
    } else {
      return (
        <TrackListCountdown // if race is upcoming
          race={race}
          race_obj={obj}
          selectedDate={moment(obj?.startDate).format("YYYY-MM-DD")}
          key={racetrack}
          intl={intl}
          raceData={data}
          expiryTimestamp={
            obj?.startDate !== null
              ? new Date(new Date(obj?.startDate).toUTCString()).getTime()
              : new Date().getTime()
          }
          checkRaceMinCell={checkRaceMinCell}
          isMobile={false}
          isBlackBook={true}
        />
      );
    }
  };
  const checkRaceMinCell = (time, type) => {
    let current_time_string = moment().format("YYYY/MM/DD HH:mm:ss");
    let end_time_string = moment(time).format("YYYY/MM/DD HH:mm:ss");
    let diff_sec = moment(end_time_string).diff(current_time_string, "second");
    let class_to_pass = "";
    if (type === "desktop") {
      class_to_pass = !isNaN(diff_sec)
        ? // ? diff_sec > 1800
          //   ? "interim"
          diff_sec <= 1800 && diff_sec > 300
          ? "close-secondary"
          : diff_sec <= 300 && diff_sec > 0
            ? "close"
            : diff_sec < 0
              ? "upcoming_race_cell_close"
              : "white"
        : "";
    } else {
      class_to_pass = !isNaN(diff_sec)
        ? diff_sec <= 600 && diff_sec > 0
          ? "upcoming_race_mobile"
          : ""
        : "";
    }
    return class_to_pass;
  };

  const handleDelete = (type, id) => {
    setSelectedType(type);
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedType("");
    setSelectedId("");
  };
  const handleuserDelete = async (type, id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `blackbook/favoriteData/${id}?type=${type}`,
      );
      if (status === 200) {
        toast.success("Deleted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        handleDeleteClose();
        fetchResultBlackBook(countryId, stateId, resultPage);
      } else {
      }
    } catch (error) {}
  };
  const handleEdit = async (type, id, item) => {
    setSelectedType(type);
    const typeId =
      type === "Runner"
        ? item?.RunnerId
        : type === "Jockey"
          ? item?.JockeyId
          : type === "Trainer"
            ? item?.TrainerId
            : type === "Track"
              ? item?.trackId
              : "";
    setTypeId(typeId);
    setSelectedId(id);
    const title =
      type === "Runner"
        ? item?.runnerName
        : type === "Jockey"
          ? item?.jockeyName
          : type === "Trainer"
            ? item?.TrainerName
            : type === "Track"
              ? item?.trackName
              : "";

    const comment = item?.comment;
    setEditComment(comment);
    setSelectedTitle(title);
    handleModal();
  };
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedType("");
    setSelectedId("");
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleNavigate = (race) => {
    let haveRace = race?.race?.filter((data) => data?.startDate !== null);
    let upnextRaces = race?.race?.filter(
      (item) =>
        item?.startDate !== null &&
        moment(new Date()).isBefore(new Date(item?.startDate)),
    );
    let completedRaces = race?.race?.filter(
      (item) =>
        item?.startDate !== null &&
        moment(new Date(item?.startDate)).isBefore(new Date()),
    );
    const selectedDate = race?.startDate
      ? moment(race?.startDate).format("YYYY-MM-DD")
      : null;
    const intl =
      race?.countryId === 157 || race?.countryId === 13 ? false : true;
    if (upnextRaces?.length > 0) {
      navigate(
        // Redirect to Runner Details
        Routes.RunnerDetails(
          race?.sportId === 1
            ? "horse"
            : race?.sportId === 2
              ? "harness"
              : "greyhounds",
          race?.sportId,
          upnextRaces[0].id,
          race?.trackId,
          race?.eventId,
          selectedDate,
          intl,
        ),
      );
    } else if (haveRace[0]?.raceNumber !== undefined) {
      navigate(
        Routes.RunnerDetails(
          race?.sportId === 1
            ? "horse"
            : race?.sportId === 2
              ? "harness"
              : "greyhounds",
          race?.sportId,
          haveRace[0].id,
          race?.trackId,
          race?.eventId,
          selectedDate,
          intl,
        ),
      );
    } else {
    }
  };
  // const handleRunnerNavigate = item => {
  //   const selectedDate = item?.startDate
  //     ? moment(item?.startDate).format("YYYY-MM-DD")
  //     : null;
  //   const intl =
  //     item?.countryId === 157 || item?.countryId === 13
  //       ? false
  //       : true;
  //   navigate(
  //     // Redirect to Runner Details
  //     Routes.RunnerDetails(
  //       item?.sportId === 1
  //         ? "horse"
  //         : item?.sportId === 2
  //         ? "harness"
  //         : "greyhounds",
  //       item?.sportId,
  //       item?.id,
  //       item?.trackId,
  //       item?.eventId,
  //       selectedDate,
  //       intl
  //     )
  //   );
  // };

  const handleRunnerInfoNavigate = (item) => {
    navigate(`/statistics/runnerprofiles/0/runner/0/${item?.RunnerId}`);
  };

  const handleJockeyNavigate = (item) => {
    navigate(`/statistics/jockeyprofiles/0/jockey/${item?.JockeyId}`);
  };
  const handleTrainnerNavigate = (item) => {
    navigate(`/statistics/trainerprofiles/0/trainer/${item?.TrainerId}`);
  };

  const handleRaceEventNavigate = (race) => {
    navigate(
      Routes.RunnerDetails(
        race?.sportId === 1
          ? "horse"
          : race?.sportId === 2
            ? "harness"
            : "greyhounds",
        race?.sportId,
        race?.raceId,
        race?.trackId,
        race?.eventId,
        race?.startDate ? moment(race?.startDate).format("YYYY-MM-DD") : null,
        race?.countryId === 13 || race?.countryId === 157 ? false : true,
      ),
    );
  };

  const fetchIsResult = (race, raceNumber) => {
    const selectedRace = race?.filter(
      (item) => item?.raceNumber === raceNumber,
    );

    if (selectedRace?.length > 0) {
      return selectedRace?.[0]?.resultDisplay ? true : false;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box className="page-content-wrap-v2">
        {isresultLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : resultData && resultData?.length > 0 ? (
          resultData?.map((item, index) => {
            let RaceResultSummary = item?.summary
              ? JSON.parse(item?.summary)
              : [];
            let fetchRunnerPosition = RaceResultSummary?.filter(
              (runner) => runner?.runner_number == item?.runnerNumber,
            )?.[0]?.position;
            return (
              <>
                <Box className="blackbook-runner-details" key={index}>
                  <Box className="top-header-details">
                    <Box className="header-details">
                      <Typography variant="h6" className="day-time-details">
                        <span className="dark-color">
                          {item?.startDate ? fetchDayName(item?.startDate) : ""}{" "}
                          {item?.startDate
                            ? moment
                                .utc(item?.startDate)
                                .local()
                                .format("DD/MM/YYYY")
                            : ""}{" "}
                        </span>
                        |{" "}
                        <span className="dark-color">
                          {item?.startDate
                            ? moment
                                .utc(item?.startDate)
                                .local()
                                .format("hh:mm A")
                            : ""}{" "}
                        </span>
                        {item?.distance ? "|" : ""}{" "}
                        <span className="gray-color">
                          {" "}
                          {item?.distance ? item?.distance + "m" : ""}
                        </span>
                      </Typography>
                    </Box>
                    <Box className="Updated-date">
                      <Typography className="date">
                        Updated:{" "}
                        {moment
                          .utc(item?.updatedAt)
                          .local()
                          .format("DD/MM/YYYY")}
                      </Typography>
                      <Box
                        className="delete-box cursor-pointer"
                        onClick={() => handleDelete(item?.type, item?.id)}
                      >
                        <Delete />
                      </Box>
                    </Box>
                  </Box>

                  {item?.type !== "Track" ? (
                    <>
                      <Box className="runner-details-v2">
                        <Box className="runner-info-v2">
                          <Box
                            className={
                              screenWidth < 939
                                ? "mobile-runner-data-v2 icon-details-wrap"
                                : "icon-details-wrap"
                            }
                          >
                            {/* <Box className="player-icon-wrap">
                              <img src={Runner_Default} alt="player-icon" />
                            </Box> */}
                            <Box
                              className="player-icon"
                              style={{
                                backgroundImage: `url(${item?.silk_url})`,
                                backgroundPosition: `${
                                  (Number(item?.runnerNumber) - 1) * -32
                                }px 0`,
                                width: "32px",
                                height: "32px",
                                minWidth: "32px",
                                backgroundSize: "auto 100%",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></Box>
                            <Box>
                              <Typography
                                className="runner-name cursor-pointer"
                                onClick={() => handleRunnerInfoNavigate(item)}
                              >
                                {item?.runnerNumber}
                                {"."} {item?.runnerName} ({item?.barrierNumber})
                              </Typography>
                              <span
                                className={
                                  fetchRunnerPosition
                                    ? fetchRunnerPosition == 1
                                      ? "postion first-postion"
                                      : "postion second-postion"
                                    : ""
                                }
                              >
                                {fetchRunnerPosition
                                  ? fetchRunnerPosition == 1
                                    ? "1st"
                                    : fetchRunnerPosition == 2
                                      ? "2nd"
                                      : fetchRunnerPosition == 3
                                        ? "3rd"
                                        : fetchRunnerPosition + "th"
                                  : ""}
                              </span>
                              <Box className="info-details">
                                <Box>
                                  <Typography className="runner-info-details mb-5">
                                    {item?.jockeyWeight ? (
                                      <>
                                        <span className="bold">W:</span>
                                        <span>
                                          {" "}
                                          {`${
                                            Number(item?.jockeyWeight).toFixed(
                                              2,
                                            ) + "Kg"
                                          }`}
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    className="runner-info-details mb-5 color-purple cursor-pointer"
                                    onClick={() => handleJockeyNavigate(item)}
                                  >
                                    {item?.jockeyName ? (
                                      <>
                                        <span className="bold">J:</span>
                                        <span> {item?.jockeyName}</span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                  <Typography
                                    className="runner-info-details color-purple cursor-pointer"
                                    onClick={() => handleTrainnerNavigate(item)}
                                  >
                                    {item?.TrainerName ? (
                                      <>
                                        <span className="bold">T:</span>
                                        <span> {item?.TrainerName}</span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          className="race-wrap cursor-pointer"
                          onClick={() => handleRaceEventNavigate(item)}
                        >
                          <Box className="race-details-left">
                            <Typography className="race-name">
                              {item?.raceNumber ? "R" + item?.raceNumber : ""}{" "}
                              {item?.raceName}
                            </Typography>
                            <Typography className="race-time">
                              {item?.resultDisplay}
                            </Typography>
                          </Box>
                          <Box className="arrow-right">
                            <RightArrow />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box className="track-info">
                        <ul>
                          <li>
                            <LazyLoadImage
                              src={FixedMark}
                              alt="fixed-indicator"
                            />{" "}
                            {localesData?.RACING?.FIXED_OODS_OFFERED}
                          </li>
                          <li>
                            <LazyLoadImage src={Close} alt="close-icon" /> -5{" "}
                            {localesData?.RACING?.MINS_TO_JUMP}
                          </li>
                          <li>
                            <LazyLoadImage
                              src={CloseSecondary}
                              alt="close-icon"
                            />{" "}
                            -30 {localesData?.RACING?.MINS_TO_JUMP}
                          </li>
                          <li>
                            <LazyLoadImage src={Interim} alt="interim" />{" "}
                            {localesData?.RACING?.INTERIM}
                          </li>
                          <li>
                            <LazyLoadImage src={Paying} alt="paying" />{" "}
                            {localesData?.RACING?.PAYING}
                          </li>
                        </ul>
                      </Box>
                      <Box className="runner-deatils track-details">
                        {" "}
                        {screenWidth > 899 ? (
                          <>
                            <Box className="runner-info">
                              <Typography
                                className="runner-name track-flag"
                                onClick={() => handleNavigate(item)}
                              >
                                {item?.flag?.includes("uploads") ? (
                                  <img
                                    src={Config.mediaURL + item?.flag}
                                    alt="Race Contry"
                                    className="flag"
                                  />
                                ) : (
                                  <img
                                    src={item?.flag}
                                    alt="Race Contry"
                                    className="flag"
                                  />
                                )}
                                {item?.trackName}
                              </Typography>
                              <Box>
                                <Box>
                                  {/* <Typography className="runner-info-details">
                                    Distance: N/A
                                  </Typography> */}
                                  <Typography className="runner-info-details">
                                    Track: N/A
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Table className="track-table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 1) ? "completed-race" : ""}`}
                                    >
                                      R1
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 2) ? "completed-race" : ""}`}
                                    >
                                      R2
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 3) ? "completed-race" : ""}`}
                                    >
                                      R3
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 4) ? "completed-race" : ""}`}
                                    >
                                      R4
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 5) ? "completed-race" : ""}`}
                                    >
                                      R5
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 6) ? "completed-race" : ""}`}
                                    >
                                      R6
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 7) ? "completed-race" : ""}`}
                                    >
                                      R7
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 8) ? "completed-race" : ""}`}
                                    >
                                      R8
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 9) ? "completed-race" : ""}`}
                                    >
                                      R9
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 10) ? "completed-race" : ""}`}
                                    >
                                      R10
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 11) ? "completed-race" : ""}`}
                                    >
                                      R11
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      className={`track-runner ${fetchIsResult(item?.race, 12) ? "completed-race" : ""}`}
                                    >
                                      R12
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>{handleTracklist(item)}</TableRow>
                              </TableBody>
                            </Table>
                          </>
                        ) : (
                          <>
                            {fetchNewMobileViewRacingData(
                              item,
                              item?.countryId === 157 || item?.countryId === 13
                                ? false
                                : true,
                              index,
                            )}
                            <Box className="runner-info">
                              <Box className="distance-track-wrap">
                                {/* <Typography className="runner-info-details">
                                  Distance: N/A
                                </Typography> */}
                                <Typography className="runner-info-details">
                                  Track: N/A
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    </>
                  )}

                  {isToggle ? (
                    <Box className="comment-section">
                      <Box className="runner-comment">
                        <Typography className="runner">
                          {" "}
                          {item?.type}:
                        </Typography>
                        <Typography className="runner comment">
                          {item?.comment}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className="edit-comment cursor-pointer"
                          onClick={() => handleEdit(item?.type, item?.id, item)}
                        >
                          <EditIcon />
                          Edit comment
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            );
          })
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              <Box
                style={{
                  textAlign: "center",
                  border: "transparent",
                  fontFamily: "sans-serif",
                }}
              >
                {/* No Data Available */}
                <NoDataComp />
              </Box>
            </Box>
          </>
        )}
        {!isresultLoading && resultData && resultData?.length > 0 ? (
          <Box className="pagination-section">
            {/* <Pagination count={1} /> */}
            <Pagination
              // hideNextButton
              // hidePrevButton
              disabled={resultCount / 10 > 1 ? false : true}
              page={resultPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              // count={10}
              size="small"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handleuserDelete(selectedType, selectedId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        id={selectedId}
        typeId={typeId}
        editComment={editComment}
        isEdit={true}
        tabType="Result"
        listingFunction={() =>
          fetchResultBlackBook(countryId, stateId, resultPage)
        }
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
};

export default Results;
