// PaymentForm.jsx
import React from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import MasterCard from "src/assets/images/mastercard.png";
import AmericanExpress from "src/assets/images/americanExpress.png";
import Visa from "src/assets/images/visa.png";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";

const validationSchema = Yup.object().shape({
  cardname: Yup.string().required("Cardholder name is required"),
  cardNumber: Yup.string()
    .matches(/^\d{4} \d{4} \d{4} \d{4}$/, "Enter a valid card number")
    .required("Card number is required"),
  expirationDate: Yup.string()
    .matches(/^(0[1-9]|1[0-2])\/\d{4}$/, "Enter a valid expiry date (MM/YYYY)")
    .required("Expiry date is required"),
  cvv: Yup.string()
    .matches(/^\d{3}$/, "Enter a valid 3-digit CVV")
    .required("CVV is required"),
  isFeatured: Yup.boolean().notRequired(),
});

const PaymentForm = ({
  selectedCard,
  paymentMode,
  cardsave,
  handleCardSave,
  onSubmit,
  formRef,
  hideSubmitButton = false,
  setPaymentMode,
  setSelectedCard,
  setCardFormValues,
  setCardFormError,
}) => {
  return (
    <>
      <Box className="left-card-details">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            size="small"
            icon={<UnChecked className="radio-icon" />}
            checkedIcon={<Checked className="radio-icon" />}
            disableRipple
            checked={paymentMode === "new"}
            onClick={() => {
              setPaymentMode("new");
              setSelectedCard("");
            }}
          />
        </Box>
        <Typography className="card-text">Use a different card</Typography>
        <Box className="card-section" sx={{ display: "flex" }}>
          <img src={MasterCard} alt="card" />
          <img src={Visa} alt="card" />
          <img src={AmericanExpress} alt="card" />
        </Box>
      </Box>
      <Box className="card-details">
        <Formik
          initialValues={{
            cardname:
              paymentMode === "new" ? selectedCard?.cardHolderName || "" : "",
            cardNumber: "",
            expirationDate: "",
            cvv: "",
            isFeatured: false,
          }}
          enableReinitialize
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values); // Send form values to parent
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box className="card-form">
                <Box className="card-info mb-19">
                  <TextField
                    disabled={selectedCard?.id}
                    value={values.cardname}
                    variant="outlined"
                    label="Name on Card*"
                    className="details-textfield"
                    error={Boolean(touched.cardname && errors.cardname)}
                    helperText={touched.cardname && errors.cardname}
                    name="cardname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Box>
                <Box className="card-info mb-19">
                  <TextField
                    variant="outlined"
                    label="Card Number*"
                    className="details-textfield"
                    disabled={selectedCard?.id}
                    name="cardNumber"
                    value={values.cardNumber}
                    placeholder="XXXX XXXX XXXX XXXX" // Preserve masking when empty
                    error={Boolean(touched.cardNumber && errors.cardNumber)}
                    helperText={touched.cardNumber && errors.cardNumber}
                    inputProps={{ maxLength: 19 }}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue(
                        "cardNumber",
                        e.target.value
                          .replace(/[^\d]/g, "")
                          .replace(/(.{4})/g, "$1 ")
                          .trim(),
                      );
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9 ]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box className="date-cvv-wrap mb-19">
                  <Box className="card-info date-card-info">
                    <TextField
                      variant="outlined"
                      disabled={selectedCard?.id}
                      label="Expiry Date*"
                      className="details-textfield"
                      value={values.expirationDate}
                      placeholder="MM/YYYY" // Preserve masking when empty
                      error={Boolean(
                        touched.expirationDate && errors.expirationDate,
                      )}
                      helperText={
                        touched.expirationDate && errors.expirationDate
                      }
                      name="expirationDate"
                      onChange={(e) => {
                        setFieldValue(
                          "expirationDate",
                          e.target.value
                            .replace(/[^0-9]/g, "")
                            .replace(/^([2-9])$/, "0$1")
                            .replace(/^(1{1})([3-9]{1})$/, "0$1/$2")
                            .replace(/^0{1,}/, "0")
                            .replace(
                              /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/,
                              "$1/$2",
                            ),
                        );
                      }}
                      inputProps={{ maxLength: 7 }}
                      onBlur={handleBlur}
                    />
                  </Box>

                  <Box className="card-info cvv-card-info">
                    <TextField
                      variant="outlined"
                      className="details-textfield"
                      name="cvv"
                      label="CVV*"
                      type="password"
                      value={values.cvv}
                      error={Boolean(touched.cvv && errors.cvv)}
                      helperText={touched.cvv && errors.cvv}
                      onChange={(e) => {
                        if (/^\d{0,3}$/.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                      inputProps={{ maxLength: 3 }}
                      onBlur={handleBlur}
                    />
                  </Box>
                </Box>

                <Box className="checkBox-wrap">
                  <FormControlLabel
                    className="documentsRead-check"
                    control={
                      <Checkbox
                        className="documentsRead-checkbox"
                        checked={values.isFeatured}
                        onChange={(e) =>
                          setFieldValue("isFeatured", e.target.checked)
                        }
                        color="primary"
                        name="isFeatured"
                      />
                    }
                    label={
                      <Typography>Save the card for future use</Typography>
                    }
                  />
                </Box>

                {!hideSubmitButton && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    Pay Now
                  </Button>
                )}
              </Box>
              {(setCardFormValues(values), setCardFormError(errors))}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default PaymentForm;
