import { useState } from "react";
import { Box, Paper, Typography, Chip, Button, Collapse } from "@mui/material";
import SettingsIcon from "../../../../components/Common/Icons/SettingsIcon";
import DoneArrowIcon from "src/components/Common/Icons/DoneArrowIcon";

const CustomizeSection = ({ categories, selectedStats, onToggleStat }) => (
  <Box
    sx={{
      mb: 4,
      display: "grid",
      gap: 1,
      gridTemplateColumns: "1fr 1fr 1fr",
      fontFamily: "Inter",
    }}
  >
    {Object.entries(categories).map(([category, items]) => (
      <Box
        key={category}
        sx={{
          mb: 3,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, fontSize: 12 }}
        >
          {category}
        </Typography>
        <Box sx={{ display: "grid", gap: 1 }}>
          {items.map((item) => (
            <Chip
              key={item}
              label={item}
              onClick={() => onToggleStat(item)}
              sx={{
                backgroundColor: selectedStats.includes(item)
                  ? "#7FCFAD"
                  : "#f0f0f0",
                color: selectedStats.includes(item) ? "white" : "black",
                "&:hover": {
                  backgroundColor: selectedStats.includes(item)
                    ? "#6BB996"
                    : "#e0e0e0",
                },
                height: "40px",
                borderRadius: "8px",
                cursor: "pointer",
                "& .MuiChip-label": {
                  fontSize: "14px",
                  px: 2,
                },
                justifyContent: "left",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              icon={selectedStats.includes(item) ? <DoneArrowIcon /> : null}
            />
          ))}
        </Box>
      </Box>
    ))}
  </Box>
);

const StatsDashboard = () => {
  const categories = {
    SCORING: [
      "Points",
      "Tries",
      "Goals",
      "Two Point Field Goal",
      "One Point Field Goal",
    ],
    KICKING: [
      "Conversion %",
      "Kicks Metres",
      "Total Kicks",
      "40/20",
      "Attacking Kicks",
      "Short Dropouts",
    ],
    "NEGATIVE PLAY": [
      "Errors",
      "Penalties",
      "Handling Errors",
      "Ineffective Tackles",
      "Ruck Infringement",
      "Set Restarts",
    ],
    ATTACK: [
      "Linebreaks",
      "Post Contact Metres",
      "Line Engaged",
      "Tackle Breaks",
      "Half Breaks",
    ],
    DEFENCE: ["Tackles", "Missed Tackles", "Charge Downs", "Intercepts"],
    "SUPPORT PLAY": ["Player in Support", "Decoy Runs"],
    PASSING: [
      "Try Assists",
      "Offloads",
      "Linebreak Assists",
      "Linebreak Involvement",
      "All Receipts",
    ],
    RUNNING: [
      "All Runs",
      "Run Metres",
      "Dummy Half Runs",
      "Kick Return Metres",
    ],
  };

  const [isCustomizeOpen, setIsCustomizeOpen] = useState(false);
  const [selectedStats, setSelectedStats] = useState([]);

  const handleToggleStat = (stat) => {
    setSelectedStats((prev) =>
      prev.includes(stat) ? prev.filter((s) => s !== stat) : [...prev, stat],
    );
  };

  return (
    <Paper
      sx={{
        p: 1,
        display: "inline-block",
        margin: "auto",
        position: "relative",
        borderColor: "none",
        boxShadow: "none",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Button
          variant="outlined"
          startIcon={<SettingsIcon />}
          onClick={() => setIsCustomizeOpen(!isCustomizeOpen)}
          sx={{
            textTransform: "none",
            borderColor: "#D4D6D8",
            color: "#191919",
            backgroundColor: "#E7E9EC",
            fontSize: "16px",
            borderRadius: "8px",
          }}
        >
          Customise
        </Button>

        <Collapse
          in={isCustomizeOpen}
          sx={{
            position: "absolute",
            top: "100%",
            right: "20%",
            zIndex: 1,
          }}
        >
          <Paper
            sx={{
              mt: 1,
              p: 2,
              boxShadow: 3,
              width: "max-content",
              minWidth: "100%",
              maxWidth: "1200px",
              maxHeight: "60vh",
              overflow: "auto",
            }}
          >
            <CustomizeSection
              categories={categories}
              selectedStats={selectedStats}
              onToggleStat={handleToggleStat}
            />
          </Paper>
        </Collapse>
      </Box>
    </Paper>
  );
};

export default StatsDashboard;
