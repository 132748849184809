import React, { useEffect, useState, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Checkbox,
  TextareaAutosize,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as RightArrow } from "../../../assets/images/orange-right-arrow.svg";
import { ReactComponent as OrangeDelete } from "../../../assets/images/orange-delete.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { ReactComponent as Timepicker } from "src/assets/images/eventcalendar/Timepicker.svg";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/smartb-check.svg";
import { ReactComponent as AddButton } from "../../../assets/images/round-add-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/icons/delete-icon.svg";
import compImage from "../../../assets/images/round-dummy-image.png";
import Select, { components } from "react-select";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";
import { useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import DialogBox from "../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import DateFnsUtils from "@date-io/date-fns";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
//   DatePicker,
//   KeyboardTimePicker,
//   TimePicker
// } from "@material-ui/pickers";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import AdBannner from "../AdBanner/AdBanner";
import tippingAbove from "../../../assets/images/ad-placeholder/tipping1.png";
import tippingBelow from "../../../assets/images/ad-placeholder/tipping2.png";
import BannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment-timezone";
import UploadImageModal from "../UI/uploadImage";
import DefaultImg from "src/assets/images/smartb_default_icon.png";
import { parseISO } from "date-fns";
import {
  editentryFee,
  editincludeFinals,
  isTipsterShown,
  isTipsterTipping,
  editprizeInformation,
} from "../createComp/createCompUtils";
import { use } from "react";

const dayOptions = [
  {
    label: "Sunday",
    value: 1,
  },
  {
    label: "Monday",
    value: 2,
  },
  {
    label: "Tuesday",
    value: 3,
  },
  {
    label: "Wednesday",
    value: 4,
  },
  {
    label: "Thursday",
    value: 5,
  },
  {
    label: "Friday",
    value: 6,
  },
  {
    label: "Saturday",
    value: 7,
  },
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const privacyOptions = [
  {
    label: "Public",
    value: "public",
  },
  {
    label: "Private",
    value: "private",
  },
];
const tipDeadlineOptions = [
  {
    label: "Round Based",
    value: "round",
  },
  {
    label: "Game Based",
    value: "game",
  },
];
const ifNoTipOptions = [
  {
    label: "Zero Score for round",
    value: "ZERO_SCORE_ROUND",
  },
  {
    label: "Lowest Score for round",
    value: "LOWEST_SCORE_ROUND",
  },
  {
    label: "All Away Teams auto picked",
    value: "AWAY_TEAM_PICKED",
  },
  {
    label: "All Home Teams auto picked",
    value: "HOME_TEAM_PICKED",
  },
];

const roundOptions = [
  {
    label: "Round 1",
    value: 1,
  },
];

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CompDetailsTab = ({
  eventByIdData,
  redux_userData,
  roundsData,
  newRoundOptions,
  noRoundData,
  getEventByID,
  stepperCount,
}) => {
  const params = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDoneModal, setOpenDoneModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [compName, setCompName] = useState("");
  const [open, setOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [openSocialShareModal, setOpenSocialShareModal] = useState(false);
  const [formvalues, setformvalues] = useState({});
  const [dateSelected, setDateSelected] = useState("");
  const [selectedTime, handleTimeChange] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [dateToCheck, setDateToCheck] = useState(null);
  const [roundBonus, setRoundBonus] = useState([
    {
      id: 1,
      Roundvalue: "Yes",
    },
    {
      id: 0,
      Roundvalue: "No",
    },
  ]);
  const [jokerBonus, setJokerBonus] = useState([
    {
      id: 1,
      Jokervalue: "Yes",
    },
    {
      id: 0,
      Jokervalue: "No",
    },
  ]);
  const [marginSystem, setMarginSystem] = useState([
    {
      id: 1,
      MarginSystemvalue: "Yes",
    },
    {
      id: 0,
      MarginSystemvalue: "No",
    },
  ]);
  const [rounds, setRounds] = useState(newRoundOptions);
  const [remainingRoundsCount, setRemainingRoundsCount] = useState("");
  const [compAbout, setCompAbout] = useState(eventByIdData?.about);
  const [companyName, setCompanyName] = useState(eventByIdData?.companyName);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [AdData, setAdData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamOption, setTeamOption] = useState([]);
  const [finalCustomPoints, setFinalCustomPoints] = useState(
    eventByIdData?.finalsRoundPoints?.length > 0
      ? eventByIdData?.finalsRoundPoints
      : [],
  );
  const [apiFinalRoundArray, setApiFinalRoundArray] = useState([]);
  const [prizes, setPrizes] = useState(
    eventByIdData?.prizeMoney?.length > 0
      ? eventByIdData?.prizeMoney
      : [{ position: 1, prize: "", label: "", description: "" }],
  );

  const [prizeInformationText, setPrizeInformationText] = useState(
    eventByIdData?.prizeInfo ? eventByIdData?.prizeInfo : "",
  );
  const [managePrizeInformation, setManagePrizeInformation] = useState(
    eventByIdData?.prizes ? eventByIdData?.prizes : "",
  );

  let isUser = redux_userData?.id !== eventByIdData?.UserId;

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
    fetchTeams();
  }, []);
  // useEffect(() => {
  //   if (eventByIdData) {
  //     // setformvalues("startingRound", eventByIdData?.startingRound);
  //   }
  // }, []);
  // useEffect(() => {
  //   // if (newRoundOptions?.length > 0) {
  //   //   setRounds(newRoundOptions);
  //   // } else {
  //   //   setRounds(roundOptions);
  //   // }
  // }, [newRoundOptions]);

  useEffect(() => {
    if (eventByIdData?.tournamentId && eventByIdData?.SportId) {
      fetchRoundData(
        eventByIdData?.tournamentId,
        eventByIdData?.SportId,
        eventByIdData?.id,
      );
    }
  }, [eventByIdData?.tournamentId, eventByIdData?.SportId]);

  const onHandleTimeChange = (newTime) => {
    handleTimeChange(newTime);
  };

  const getFields = (list, field) => {
    return list.reduce(function (carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const handleConfirm = async () => {
    // setSelectedItem(item);
    handleOpenConfirmModal();
    // setSelectedId(id);
  };
  const handleOpenConfirmModal = () => {
    setOpenConfirm(true);
  };

  const paymentHandelar = () => {
    navigate("/tipscompetition/payment", { state: eventByIdData });
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };
  const handleDoneModal = async () => {
    // setSelectedItem(item);
    handleOpenDoneModal();
    // setSelectedId(id);
  };
  const handleOpenDoneModal = () => {
    setOpenDoneModal(true);
  };
  const handleDoneClose = () => {
    setOpenDoneModal(false);
  };

  const fetchTeams = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/teamList?tournamentId=${eventByIdData?.tournamentId}&SportId=${eventByIdData?.SportId}&limit=500&offset=`,
      );
      if (status === 200) {
        let newData = [];
        const teamData = data?.result?.map((item) => {
          newData.push({
            label: item?.name,
            value: item?.id,
            flag: item?.flag,
          });
        });
        setTeamOption(newData);
        setSelectedTeam(eventByIdData?.selectedTeam?.id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRoundData = async (tID, sID, compId) => {
    const date = moment(Date()).format("YYYY-MM-DD");
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/rounds?timezone=${timezone}&tournamentId=${tID}&SportId=${sID}&startDate=${date}&competitionId=${compId}${
          sID === 9 || sID === 12
            ? `&seasonId=${eventByIdData?.seasonId ? eventByIdData?.seasonId : ""}`
            : ""
        }`,
      );
      if (status === 200) {
        let newdata = [];
        const filteredRound = data?.roundList?.filter(
          (round) => !data?.completedRound.includes(round?.round),
        );
        let track = filteredRound?.map((item) => {
          newdata.push({
            label: `${
              item?.displayName
                ? item?.displayName
                : (sID == 4 ? "Day" : "Round") + " " + item?.round
            } ${
              item?.startTime
                ? "  (" +
                  moment(item?.startTime)
                    .tz(timezone)
                    .format("DD-MM-YYYY hh:mm A") +
                  ")"
                : ""
            }`,
            value: item?.round,
            roundStartDate: item?.startTime,
            displayName: item?.displayName,
            numberOfWeeks: item?.numberOfWeeks,
          });
        });
        const startDate = newdata?.find(
          (item) =>
            Number(item?.value) === Number(eventByIdData?.startingRound),
        );

        setDateToCheck(
          moment(startDate?.roundStartDate)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm A"),
        );
        setRounds(newdata);
        setRemainingRoundsCount(newdata?.length);
        let finalsRound = [];
        let finalsRoundList = data?.finalsRoundList?.map((item) => {
          finalsRound.push({
            round: item?.round,
            value: "",
          });
        });

        if (finalsRound && finalsRound?.length > 0) {
          setApiFinalRoundArray(finalsRound);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (eventByIdData?.finalsRoundPoints?.length <= 0) {
      setFinalCustomPoints(apiFinalRoundArray);
    }
  }, [rounds, eventByIdData?.finalsRoundPoints, apiFinalRoundArray]);

  const clearDate = () => {
    setDateSelected(null);
    setOpen(false);
  };
  const clearTime = () => {
    handleTimeChange(null);
    setTimeOpen(false);
  };

  const handleLiveDateChange = (date) => {
    setDateSelected(
      date ? moment(date).tz(timezone).format("YYYY-MM-DD") : null,
    );
    // setselectedDate(
    //   date
    //     ? moment(date)
    //         .tz(timezone)
    //         .format("YYYY-MM-DD")
    //     : null
    // );
  };

  const ifNoTipData = (data) => {
    if (data !== null && data === "ZERO_SCORE_ROUND") {
      return "Zero Score for Round";
    } else if (data !== null && data === "LOWEST_SCORE_ROUND") {
      return "Lowest Score for Round";
    } else if (data !== null && data === "AWAY_TEAM_PICKED") {
      return "All Away Teams auto picked";
    } else if (data !== null && data === "HOME_TEAM_PICKED") {
      return "All Home Teams auto picked";
    } else {
      return "-";
    }
  };

  // const ifNoTipOptionsFunc = value => {
  //   if (value === 1) {
  //     return "ZERO_SCORE_ROUND";
  //   } else if (value === 2) {
  //     return "LOWEST_SCORE_ROUND";
  //   } else if (value === 3) {
  //     return "AWAY_TEAM_PICKED";
  //   } else {
  //     return "HOME_TEAM_PICKED";
  //   }
  // };

  const fetchAdsData = async () => {
    try {
      const passPageId =
        params?.SportId == 4 ? 41 : params?.SportId == 12 ? 42 : 43;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${passPageId}&type=web&timeZone=${timezone}`,
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.page_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const deleteOrLeaveComp = async (type) => {
    // const date = moment(Date()).format("YYYY-MM-DD");
    try {
      const { status, data } = await axiosInstance.delete(
        `/tipping/delete/${eventByIdData?.id}`,
      );
      if (status === 200) {
        toast.success(
          type === "delete"
            ? "Competition Deleted"
            : "You have left the competition",
          {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          },
        );

        setTimeout(() => {
          navigate("/tipscompetition/my-comps");
          // navigate(
          //   type === "delete"
          //     ? "/tipscompetition/my-comps"
          //     : `/tipscompetition/private-comps?code=${eventByIdData?.code}`
          // );
        }, 2000);
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };
  const ifNoTipOptionsFunc = (value) => {
    if (value === 1) {
      return "ZERO_SCORE_ROUND";
    } else if (value === 2) {
      return "LOWEST_SCORE_ROUND";
    } else if (value === 3) {
      return "AWAY_TEAM_PICKED";
    } else {
      return "HOME_TEAM_PICKED";
    }
  };
  const handleUpdate = async () => {
    // setisLoading(true);
    let payload = {
      SportId: eventByIdData?.SportId,
      tournamentId: eventByIdData?.tournamentId,
      competitionName: formvalues?.compName,
      startingRound: formvalues?.startingRound,
      privacy: formvalues?.privacySettings,
      tipDeadline: formvalues?.tipDeadline,
      // cutOffDate:
      //   moment(formvalues?.selectDay).format("YYYY-MM-DD") +
      //   " " +
      //   moment(formvalues?.selectTime).format("hh:mm:ss"),
      noTip: formvalues?.ifNoTip,
      numberOfTippers: formvalues?.numberOfTippers,
      perfectRound: formvalues?.roundBonus === 1 ? true : false,
      perfectRoundPoints:
        formvalues?.customRoundBonusOption === 1
          ? formvalues?.roundBonusPoint
          : null,
      tippingShow: formvalues?.showTipstersTipping,
      JokerRound: formvalues?.jokerRound === 1 ? true : false,
      noOfJokerRound:
        formvalues?.jokerRound === 1
          ? Number(formvalues?.numberOfJockerRound)
          : null,
      marginRound: formvalues?.marginSystem === 1 ? true : false,
      about: compAbout,
      companyName: companyName,
      MediaId: cropImage?.id,
      fees: formvalues?.entryFee === 1 ? true : false,
      entryFee:
        formvalues?.entryFee === 1 ? Number(formvalues?.selectEntryFee) : null,
      prizeInfo: prizeInformationText ? prizeInformationText : "",
      prizes: managePrizeInformation === 1 ? true : false,
      prizeMoney: managePrizeInformation === 1 ? prizes : [],
      finalsRound: formvalues?.includeFinalsValue === 1 ? true : false,
      finalsRoundPoints:
        formvalues?.includeFinalsValue === 1 &&
        formvalues?.includeFinalsOption === 1
          ? finalCustomPoints
          : [],
      ...(selectedTeam && { selectedTeam: selectedTeam }),
    };
    if (formvalues?.tipDeadline == "round") {
      payload = {
        ...payload,
        cutOffWeek: formvalues?.selectDay,
        cutOffTime: formvalues?.selectTime
          ? moment(formvalues?.selectTime).tz(timezone).format("HH:mm:ss")
          : "",
      };
    }
    if (eventByIdData?.SportId === 9 || eventByIdData?.SportId === 12) {
      payload = {
        ...payload,
        seasonId: eventByIdData?.seasonId ? eventByIdData?.seasonId : "",
      };
    }

    // if (handleValidation()) {
    try {
      const { status, data } = await axiosInstance.put(
        `tipping/updateCompetition/${eventByIdData?.id}?timezone=${timezone}`,
        payload,
      );
      if (status === 200) {
        toast.success("Competition Updated", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        getEventByID(stepperCount);
        setisLoading(false);
        setCompName("");
        setTimeout(() => {
          setOpenSocialShareModal(true);
        }, 2000);
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      setisLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
    // }
  };

  const handleImageModal = () => {
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
  };

  const getImage = (data) => {
    setCropImage(data);
  };

  const formatOptionLabel = ({ label, flag }) => {
    // if (isCountryPhoneSearch) {
    //   return label;
    // } else {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={Config?.mediaURL + flag}
          onError={(e) => (e.target.src = DefaultImg)}
          style={{ width: "32px", height: "32px", borderRadius: "50%" }}
        />
        <span
          style={{
            margin: "0px 5px",
            display: "inline-block",
          }}
        >
          {label}
        </span>
      </div>
    );
    // }
  };

  const handleUpdateTeam = async (selectedTeam) => {
    const payload = {
      selectedTeam: selectedTeam,
    };
    try {
      const { status, data } = await axiosInstance.put(
        `/tipping/addLadder/${eventByIdData?.code}`,
        payload,
      );
      if (status === 200) {
        toast.success("Team updated successfully!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinalChange = (index, value) => {
    setFinalCustomPoints((prev) =>
      prev.map((item, i) => (i === index ? { ...item, value } : item)),
    );
  };

  const handlePrizeChange = (index, value) => {
    const updatedPrizes = [...prizes];
    updatedPrizes[index].prize = value;
    setPrizes(updatedPrizes);
  };

  const handleLabelChange = (index, value) => {
    setPrizes((prevPrizes) => {
      const updatedPrizes = [...prevPrizes];
      updatedPrizes[index].label = value;
      return updatedPrizes;
    });
  };

  const handleDescChange = (index, value) => {
    setPrizes((prevPrizes) => {
      const updatedPrizes = [...prevPrizes];
      updatedPrizes[index].description = value;
      return updatedPrizes;
    });
  };

  const addPosition = () => {
    const maxPosition =
      prizes.length > 0 ? Math.max(...prizes?.map((p) => p?.position)) : 0;
    setPrizes([
      ...prizes,
      { position: maxPosition + 1, prize: "", description: "", label: "" },
    ]);
  };
  const removePosition = (index) => {
    if (prizes?.length > 1) {
      const updatedPrizes = prizes
        ?.filter((_, i) => i !== index)
        ?.map((item, i) => ({ ...item, position: i + 1 })); // Renumber positions
      setPrizes(updatedPrizes);
    }
  };
  return (
    <>
      <Box className="comp-details-sec">
        {loading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <>
            {
              eventByIdData !== null && (
                <>
                  {isUser ? (
                    <Box className="edit-comp-details">
                      <Accordion
                        defaultExpanded
                        className="create-comp-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography className="accordion-header-text">
                            Comp Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className="details-sec-header">
                            <Box className="details-sec">
                              <Box className="comp-details detail-child details">
                                <Typography className="textfield-text left">
                                  Team supported:
                                  {/* <span className="danger">*</span>: */}
                                </Typography>
                                <Box className="field-container right">
                                  <Select
                                    className="React sort-select"
                                    // value={
                                    //   selectedPrivacy !== "" &&
                                    //   privacyOptions?.find(item => {
                                    //     return (
                                    //       item?.value === selectedPrivacy
                                    //     );
                                    //   })
                                    // }
                                    // onChange={e =>
                                    //   setSelectedPrivacy(e?.value)
                                    // }
                                    // options={privacyOptions}
                                    classNamePrefix="select"
                                    placeholder="- Select Team -"
                                    components={{ DropdownIndicator }}
                                    isSearchable={false}
                                    onChange={(e) => {
                                      setSelectedTeam(e?.value);
                                      handleUpdateTeam(e?.value);
                                    }}
                                    value={
                                      selectedTeam &&
                                      teamOption?.find((item) => {
                                        return item?.value == selectedTeam;
                                      })
                                    }
                                    options={teamOption}
                                    getOptionLabel={formatOptionLabel}
                                  />
                                  {/* <span className="text-danger">
                                    {touched?.privacySettings &&
                                    errors?.privacySettings
                                      ? errors?.privacySettings
                                      : ""}
                                  </span> */}
                                </Box>
                              </Box>
                              <Box className="details">
                                <Typography className="left">Sport:</Typography>
                                <Typography className="right">
                                  {eventByIdData &&
                                    eventByIdData?.Sport?.sportName}
                                </Typography>
                              </Box>
                              <Box className="details">
                                <Typography className="left">
                                  Comp code#:
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData && eventByIdData?.code}
                                </Typography>
                              </Box>
                              <Box className="details">
                                <Typography className="left">
                                  League:
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData &&
                                  eventByIdData?.tournament?.name
                                    ? eventByIdData?.tournament?.name
                                    : "-"}
                                </Typography>
                              </Box>
                              <Box className="details">
                                <Typography className="left">
                                  Competition name:
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData &&
                                    eventByIdData?.competitionName}
                                </Typography>
                              </Box>
                              <Box className="details">
                                <Typography className="left">
                                  Company name:
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData && eventByIdData?.companyName}
                                </Typography>
                              </Box>
                              <Box className="details">
                                <Typography className="left">
                                  Starting Round:
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData &&
                                  eventByIdData?.startingRound != null
                                    ? `${
                                        eventByIdData?.SportId == 4
                                          ? "Day"
                                          : "Round"
                                      } ${eventByIdData?.startingRound}`
                                    : "-"}
                                </Typography>
                              </Box>
                              <Box className="details">
                                <Typography className="left">
                                  Privacy:
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData &&
                                  eventByIdData?.privacy === "public"
                                    ? "Public"
                                    : "Private"}
                                </Typography>
                              </Box>{" "}
                              <Box className="details">
                                <Button
                                  disableElevation
                                  disableFocusRipple
                                  disableRipple
                                  // type="submit"

                                  className="setting-btn"
                                  endIcon={
                                    <RightArrow className="right-arrow" />
                                  }
                                  onClick={() => handleConfirm()}
                                >
                                  Leave competition
                                </Button>
                              </Box>{" "}
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        defaultExpanded
                        className="create-comp-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography className="accordion-header-text">
                            Tipping and Points
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className="details-sec-header">
                            <Box className="details-sec">
                              <Box className="details">
                                <Typography className="left">
                                  Tipping
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData?.tippingType == "winning"
                                    ? "Winner Tipping"
                                    : eventByIdData?.tippingType == "odds"
                                      ? "Odds Tipping"
                                      : eventByIdData?.tippingType == "spread"
                                        ? "SpreadLine Tipping"
                                        : ""}
                                </Typography>
                              </Box>
                              <Box className="details">
                                <Typography className="left">
                                  Tip deadline:{" "}
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData &&
                                  eventByIdData?.tipDeadline === "round"
                                    ? "Round Based"
                                    : "Game Based"}
                                </Typography>
                              </Box>
                              <Box className="details">
                                <Typography className="left">
                                  If no tip is entered:
                                </Typography>
                                <Typography className="right">
                                  {/* {eventByIdData && eventByIdData?.noTip} */}
                                  {ifNoTipData(eventByIdData?.noTip)}
                                </Typography>
                              </Box>

                              {Boolean(eventByIdData?.numberOfTippers) && (
                                <Box className="details">
                                  <Typography className="left">
                                    Number of Tipsters:
                                  </Typography>
                                  <Typography className="right">
                                    {eventByIdData &&
                                      eventByIdData?.totalTipper}
                                  </Typography>
                                </Box>
                              )}
                              <Box className="details">
                                <Typography className="left">
                                  Show Tipster's Tipping
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData && eventByIdData?.tippingShow}
                                </Typography>
                              </Box>
                              {eventByIdData?.tippingType === "winning" && (
                                <Box className="details details-flex-start">
                                  <Typography className="left">
                                    Perfect round Bonus:
                                  </Typography>
                                  <Box className="right">
                                    <Typography>
                                      {eventByIdData &&
                                      eventByIdData?.perfectRound === 1
                                        ? "Yes"
                                        : "No"}
                                    </Typography>
                                    <Box className="dummy-text-box">
                                      <Typography className="dummy-text">
                                        Custom Perfect round Bonus Points
                                      </Typography>
                                      <Typography className="dummy-input">
                                        {eventByIdData &&
                                          eventByIdData?.perfectRoundPoints}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                              {eventByIdData?.tippingType === "winning" && (
                                <Box className="details details-flex-start">
                                  <Typography className="left">
                                    Finals
                                  </Typography>
                                  <Box className="right">
                                    <Typography>
                                      {eventByIdData &&
                                        (eventByIdData?.finalsRound === 1
                                          ? "Yes"
                                          : "No")}
                                    </Typography>
                                    <Box className="dummy-text-box">
                                      <Typography className="dummy-text">
                                        Custom Points for Finals
                                      </Typography>
                                      {eventByIdData?.finalsRoundPoints
                                        ?.length > 0 && (
                                        <Box className="final-points-grid">
                                          {eventByIdData?.finalsRoundPoints?.map(
                                            (item, index) => (
                                              <Box
                                                className="final-points"
                                                key={index}
                                              >
                                                <Typography className="dummy-input week-dummy-input">
                                                  Week {item?.round}
                                                </Typography>
                                                <Typography className="dummy-input final-dummy-input">
                                                  {item?.value}
                                                </Typography>
                                              </Box>
                                            ),
                                          )}
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      {eventByIdData?.tippingType == "winning" && (
                        <Accordion
                          defaultExpanded
                          className="create-comp-accordion"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography className="accordion-header-text">
                              Other
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="details-sec-header">
                              <Box className="details-sec">
                                {eventByIdData?.tippingType == "winning" && (
                                  <Box className="details details-flex-start">
                                    <Typography className="left">
                                      Include Joker Round:
                                    </Typography>

                                    <Box className="right">
                                      <Typography>
                                        {eventByIdData &&
                                        eventByIdData?.jokerRound === 1
                                          ? "Yes"
                                          : "No"}
                                      </Typography>
                                      <Box className="dummy-text-box">
                                        <Typography className="dummy-text">
                                          Number of Joker Rounds
                                        </Typography>
                                        <Typography className="dummy-input">
                                          {eventByIdData &&
                                            (eventByIdData?.noOfJokerRound ??
                                              "")}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                                {eventByIdData?.tippingType == "winning" && (
                                  <Box className="details">
                                    <Typography className="left">
                                      Include Margin System:
                                    </Typography>
                                    <Typography className="right">
                                      {eventByIdData &&
                                      eventByIdData?.marginRound === 1
                                        ? "Yes"
                                        : "No"}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      )}
                      <Accordion
                        defaultExpanded
                        className="create-comp-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography className="accordion-header-text">
                            Entry Fee and Prizes
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className="details-sec-header">
                            <Box className="details-sec">
                              <Box className="details details-flex-start">
                                <Typography className="left">
                                  Entry fee
                                </Typography>
                                <Box className="right">
                                  <Typography>
                                    {eventByIdData &&
                                      (eventByIdData?.entryFee
                                        ? "$" + eventByIdData?.entryFee
                                        : "")}
                                  </Typography>
                                  {eventByIdData?.fees === 1 &&
                                    eventByIdData?.entryFee > 0 && (
                                      <Button
                                        disableElevation
                                        disableFocusRipple
                                        disableRipple
                                        variant="contained"
                                        disabled={
                                          eventByIdData?.paymentStatus ===
                                          "paid"
                                        }
                                        className={
                                          eventByIdData?.paymentStatus ===
                                          "paid"
                                            ? "paid-btn pay-entry-fee"
                                            : "setting-btn pay-entry-fee"
                                        }
                                        onClick={() => {
                                          if (
                                            eventByIdData?.paymentStatus !==
                                            "paid"
                                          ) {
                                            paymentHandelar();
                                          }
                                        }}
                                      >
                                        {eventByIdData?.paymentStatus ===
                                        "unpaid"
                                          ? "Pay Entry Fee"
                                          : "Paid"}
                                      </Button>
                                    )}
                                </Box>
                              </Box>
                              <Box className="details details-flex-start">
                                <Typography className="left">
                                  Prize information
                                </Typography>

                                <Box className="right">
                                  <Typography className="text-16 mb-10">
                                    {eventByIdData &&
                                      (eventByIdData?.prizeInfo ?? "")}
                                  </Typography>
                                  {eventByIdData &&
                                    eventByIdData?.prizeMoney?.length > 0 && (
                                      <Box className="dummy-text-box">
                                        <Box className="prize-flex prize-flex-header">
                                          <Typography className="position-text">
                                            Position
                                          </Typography>
                                          <Typography className="">
                                            Prize money
                                          </Typography>
                                        </Box>
                                        {eventByIdData?.prizeMoney?.map(
                                          (item, index) => (
                                            <Box className="prize-flex-bottom">
                                              <Box
                                                className="prize-flex"
                                                key={index}
                                              >
                                                <Typography className="position-text">
                                                  {item?.position}
                                                </Typography>
                                                <Typography className="dummy-input prize-dummy-input">
                                                  {item?.prize
                                                    ? "$" + item?.prize
                                                    : ""}
                                                </Typography>
                                              </Box>
                                              <Box
                                                className="prize-flex"
                                                key={index}
                                              >
                                                <Typography className="position-text"></Typography>

                                                <Typography className="dummy-input prize-dummy-input">
                                                  {item?.label
                                                    ? item?.label
                                                    : ""}
                                                </Typography>
                                              </Box>
                                              <Box
                                                className="prize-flex"
                                                key={index}
                                              >
                                                <Typography className="position-text"></Typography>

                                                <Typography className="dummy-input prize-dummy-input">
                                                  {item?.description
                                                    ? item?.description
                                                    : ""}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          ),
                                        )}
                                      </Box>
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ) : (
                    <Formik
                      ref={formikRef}
                      enableReinitialize
                      initialValues={{
                        compName: eventByIdData?.competitionName,
                        startingRound: eventByIdData?.startingRound,
                        privacySettings: eventByIdData?.privacy,
                        tipDeadline: eventByIdData?.tipDeadline,
                        selectDay: eventByIdData?.cutOffWeek,
                        // moment
                        //   .utc(eventByIdData?.cutOffDate)
                        //   .local()
                        //   .format("YYYY-MM-DD")
                        selectTime: moment
                          .utc(`${eventByIdData?.cutOffTime}`, "HH:mm:ss")
                          .tz(timezone)
                          .format("YYYY-MM-DD HH:mm:ss"),
                        // moment
                        //   .utc(eventByIdData?.cutOffDate)
                        //   .local()
                        //   .format("hh:mm")
                        ifNoTip: eventByIdData?.noTip,
                        numberOfTippers: Boolean(
                          eventByIdData?.numberOfTippers,
                        ),
                        showTipstersTipping: eventByIdData?.tippingShow,

                        roundBonus: eventByIdData?.perfectRound,
                        customRoundBonusOption: eventByIdData?.perfectRound,
                        roundBonusPoint: eventByIdData?.perfectRoundPoints,
                        includeFinalsValue: eventByIdData?.finalsRound,
                        includeFinalsOption: eventByIdData?.finalsRound,
                        jokerRound: eventByIdData?.jokerRound,
                        numberOfJockerRound: eventByIdData?.noOfJokerRound,
                        marginSystem: eventByIdData?.marginRound,
                        entryFee: eventByIdData?.fees,
                        selectEntryFee: eventByIdData?.entryFee,
                      }}
                      validationSchema={Yup.object().shape({
                        compName: Yup.string()
                          .trim()
                          .required("Competition name is required"),
                        // .matches(
                        //   /^[^0-9]+$/,
                        //   "Numbers are not allowed for Competition Name"
                        // ),
                        // .test(
                        //   "compName",
                        //   "Only letters and Spacial characters are allowed for Competition Name",
                        //   value => value && value.match(/^[a-zA-Z\s'()-]+$/)
                        //   // value.match(
                        //   //   /^\p{L}[\p{L}'.\s]*(?:[\-]{0,1}[\p{L}.'\s]*\p{L}+)*$/gu
                        //   // )
                        // )
                        // startingRound: Yup.string().required(
                        //   "Starting Round is required"
                        // ),
                        privacySettings: Yup.string().required(
                          "Privacy settings is required",
                        ),
                        tipDeadline: Yup.string().required(
                          "Tip deadline is required",
                        ),
                        ifNoTip: Yup.string().required(
                          "This field is required",
                        ),
                        // selectDay: Yup.string().required("Choose a Day"),
                        // selectTime: Yup.string().required("Choose a Time"),

                        selectDay: Yup.string()
                          .nullable()
                          .when("tipDeadline", (tipDeadline) => {
                            return tipDeadline?.includes("round")
                              ? Yup.string().required("Choose a Day")
                              : Yup.string().notRequired();
                          }),
                        selectTime: Yup.string()
                          .nullable()
                          .when("tipDeadline", (tipDeadline) => {
                            return tipDeadline?.includes("round")
                              ? Yup.string().required("Choose a Time")
                              : Yup.string().notRequired();
                          }),
                        numberOfTippers: Yup.boolean()
                          .nullable()
                          .required("This field is required"),
                        showTipstersTipping: Yup.string()
                          .nullable()
                          .required("This field is required"),
                        roundBonus:
                          eventByIdData?.tippingType == "winning"
                            ? Yup.string().required("This field is required")
                            : Yup.string().nullable().notRequired(),
                        customRoundBonusOption: Yup.string().notRequired(),
                        roundBonusPoint: Yup.string()
                          .nullable()
                          .when(
                            "customRoundBonusOption",
                            (customRoundBonusOption) => {
                              return customRoundBonusOption?.includes("1")
                                ? Yup.string()
                                    .trim()
                                    .required("Rounds points is required")
                                : Yup.string().notRequired();
                            },
                          ),
                        includeFinalsValue:
                          eventByIdData?.tippingType == "winning"
                            ? Yup.string().required("This field is required")
                            : Yup.string().nullable().notRequired(),
                        jokerRound:
                          eventByIdData?.tippingType == "winning"
                            ? Yup.string().required("This field is required")
                            : Yup.string().nullable().notRequired(),
                        // numberOfJockerRound: Yup.string()
                        //   .nullable()
                        //   .when("jokerRound", (jokerRound) => {
                        //     return jokerRound?.includes("1")
                        //       ? Yup.string()
                        //           .trim()
                        //           .required("No. of Joker Rounds is required")
                        //       : Yup.string().notRequired();
                        //   }),
                        numberOfJockerRound: Yup.number()
                          .nullable()
                          .when("jokerRound", (jokerRound, schema) => {
                            return jokerRound?.includes("1")
                              ? schema
                                  .required("This field is required")
                                  .min(1, "Minimum value is 1")
                                  .max(
                                    remainingRoundsCount,
                                    `Max limit is ${remainingRoundsCount}`,
                                  )
                              : schema.notRequired();
                          }),
                        marginSystem:
                          eventByIdData?.tippingType == "winning"
                            ? Yup.string().required("This field is required")
                            : Yup.string().nullable().notRequired(),
                        entryFee: Yup.string().required(
                          "This field is required",
                        ),
                        selectEntryFee: Yup.string()
                          .nullable()
                          .when("entryFee", (entryFee) => {
                            return entryFee?.includes("1")
                              ? Yup.string()
                                  .trim()
                                  .required("Entry Fee is required")
                              : Yup.string().notRequired();
                          }),
                      })}
                      onSubmit={(reqdata) => {
                        // setformvalues({ ...formvalues, ...reqdata });
                        // handleValidation();
                        handleUpdate();
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Box className="edit-comp-details">
                            <Accordion
                              defaultExpanded
                              className="create-comp-accordion"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Typography className="accordion-header-text">
                                  Comp Details
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="details-sec-header">
                                  <Box className="details-sec">
                                    <Box className="comp-field-container">
                                      <Box className="comp-image-name-wrap detail-child">
                                        <Box
                                          className="comp-image-wrap"
                                          onClick={() => handleImageModal()}
                                        >
                                          <img
                                            className="comp-image"
                                            src={
                                              cropImage?.filePath
                                                ? Config?.countryMediaURL +
                                                  cropImage?.filePath
                                                : eventByIdData?.Media?.filePath
                                                  ? Config?.countryMediaURL +
                                                    eventByIdData?.Media
                                                      ?.filePath
                                                  : compImage
                                            }
                                            alt="comp-image"
                                          />
                                          <AddButton className="add-button" />
                                        </Box>
                                        <Box
                                          className="comp-image-text-wrap"
                                          onClick={() => handleImageModal()}
                                        >
                                          <Typography className="image-text">
                                            Upload Comp Image
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box className="comp-details detail-child">
                                        <Typography className="textfield-text left">
                                          Team supported:
                                          {/* <span className="danger">*</span>: */}
                                        </Typography>
                                        <Box className="field-container right">
                                          <Select
                                            className="React sort-select"
                                            classNamePrefix="select"
                                            placeholder="- Select Team -"
                                            components={{ DropdownIndicator }}
                                            onChange={(e) => {
                                              setSelectedTeam(e?.value);
                                            }}
                                            isSearchable={false}
                                            value={
                                              selectedTeam &&
                                              teamOption?.find((item) => {
                                                return (
                                                  item?.value == selectedTeam
                                                );
                                              })
                                            }
                                            options={teamOption}
                                            getOptionLabel={formatOptionLabel}
                                          />
                                        </Box>
                                      </Box>
                                      <Box className="comp-details detail-child about-comp-title">
                                        <Typography className="textfield-text left">
                                          About Comp:
                                        </Typography>
                                        <Box className="field-container">
                                          <TextareaAutosize
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="details-textarea-field"
                                            placeholder="- About -"
                                            name="compAbout"
                                            maxRows={3}
                                            minRows={2}
                                            onChange={(e) =>
                                              setCompAbout(e?.target?.value)
                                            }
                                            value={compAbout}
                                            // error={Boolean(touched?.compAbout && errors?.compAbout)}
                                            // helperText={touched?.compAbout ? errors?.compAbout : ""}
                                          />
                                        </Box>
                                      </Box>
                                      <Box className="details detail-child">
                                        <Typography className="left">
                                          Sport:
                                        </Typography>
                                        <Typography className="right">
                                          {eventByIdData &&
                                            eventByIdData?.Sport?.sportName}
                                        </Typography>
                                      </Box>
                                      <Box className="details detail-child">
                                        <Typography className="left">
                                          Comp code#:
                                        </Typography>
                                        <Typography className="right">
                                          {eventByIdData && eventByIdData?.code}
                                        </Typography>
                                      </Box>
                                      <Box className="details detail-child">
                                        <Typography className="left">
                                          League:
                                        </Typography>
                                        <Typography className="right">
                                          {eventByIdData &&
                                          eventByIdData?.tournament?.name
                                            ? eventByIdData?.tournament?.name
                                            : "-"}
                                        </Typography>
                                      </Box>
                                      {/* <Box className="details">
                                <Typography className="left">
                                  Starting Round:
                                </Typography>
                                <Typography className="right">
                                  {eventByIdData &&
                                  eventByIdData?.startingRound != null
                                    ? `Round ${eventByIdData?.startingRound}`
                                    : "-"}
                                </Typography>
                              </Box> */}
                                      <Box className="comp-details mt-17 detail-child">
                                        <Typography className="textfield-text left">
                                          Competition name:
                                          {/* <span className="danger">*</span>: */}
                                        </Typography>
                                        <Box className="field-container right">
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="comp-textfield"
                                            placeholder="Choose a name"
                                            name="compName"
                                            // onChange={e =>
                                            //   setCompName(e?.target?.value)
                                            // }
                                            // value={compName}
                                            // error={Boolean(
                                            //   touched?.compName && errors?.compName
                                            // )}
                                            // helperText={
                                            //   touched?.compName
                                            //     ? errors?.compName
                                            //     : ""
                                            // }
                                            error={Boolean(
                                              touched?.compName &&
                                                errors?.compName,
                                            )}
                                            helperText={
                                              touched?.compName
                                                ? errors?.compName
                                                : ""
                                            }
                                            onChange={handleChange}
                                            value={values?.compName}
                                            // disabled
                                          />
                                        </Box>
                                      </Box>
                                      <Box className="comp-details mt-17 detail-child">
                                        <Typography className="textfield-text left">
                                          Company name:
                                          {/* <span className="danger">*</span>: */}
                                        </Typography>
                                        <Box className="field-container right">
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="comp-textfield"
                                            placeholder="Choose a company name"
                                            name="companyName"
                                            onChange={(e) =>
                                              setCompanyName(e?.target?.value)
                                            }
                                            value={companyName}
                                          />
                                        </Box>
                                      </Box>
                                      <Box className="comp-details detail-child">
                                        <Typography className="textfield-text left">
                                          Starting Round
                                          <span className="danger">*</span>:
                                        </Typography>
                                        <Box className="field-container right">
                                          {moment(
                                            dateToCheck,
                                            "DD-MM-YYYY hh:mm A",
                                          ).tz(timezone) <
                                          moment().tz(timezone) ? (
                                            <Typography className="right">
                                              {eventByIdData &&
                                              eventByIdData?.startingRound !=
                                                null
                                                ? `${
                                                    eventByIdData?.SportId == 4
                                                      ? `Day`
                                                      : `Round`
                                                  } ${eventByIdData?.startingRound}`
                                                : "-"}
                                            </Typography>
                                          ) : (
                                            <>
                                              <Select
                                                className="React sort-select"
                                                // value={
                                                //   selectedRound !== "" &&
                                                //   rounds?.find(item => {
                                                //     return item?.value === selectedRound;
                                                //   })
                                                // }
                                                // options={rounds}
                                                // onChange={e => setSelectedRound(e?.value)}
                                                classNamePrefix="select"
                                                placeholder="- Select Round -"
                                                components={{
                                                  DropdownIndicator,
                                                }}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "startingRound",
                                                    e?.value,
                                                  );
                                                  // Count of remaining rounds
                                                  const selectedIndex =
                                                    rounds.findIndex(
                                                      (item) =>
                                                        item.value === e?.value,
                                                    );
                                                  const remainingRounds =
                                                    rounds.length -
                                                    selectedIndex;

                                                  setRemainingRoundsCount(
                                                    remainingRounds,
                                                  ); // Store in state if needed
                                                }}
                                                value={rounds?.find(
                                                  (item) =>
                                                    Number(item?.value) ===
                                                    Number(
                                                      values?.startingRound,
                                                    ),
                                                )}
                                                options={rounds}
                                                isDisabled={
                                                  moment(
                                                    dateToCheck,
                                                    "DD-MM-YYYY hh:mm A",
                                                  ).tz(timezone) <
                                                  moment().tz(timezone)
                                                }
                                              />
                                              <span className="text-danger">
                                                {rounds?.length === 0 &&
                                                noRoundData
                                                  ? noRoundData
                                                  : ""}
                                              </span>
                                              <span className="text-danger">
                                                {touched?.startingRound &&
                                                errors?.startingRound
                                                  ? errors?.startingRound
                                                  : ""}
                                              </span>
                                            </>
                                          )}
                                        </Box>
                                      </Box>
                                      <Box className="comp-details detail-child">
                                        <Typography className="textfield-text left">
                                          Privacy settings:
                                          {/* <span className="danger">*</span>: */}
                                        </Typography>
                                        <Box className="field-container right">
                                          <Select
                                            className="React sort-select"
                                            // value={
                                            //   selectedPrivacy !== "" &&
                                            //   privacyOptions?.find(item => {
                                            //     return (
                                            //       item?.value === selectedPrivacy
                                            //     );
                                            //   })
                                            // }
                                            // onChange={e =>
                                            //   setSelectedPrivacy(e?.value)
                                            // }
                                            // options={privacyOptions}
                                            classNamePrefix="select"
                                            placeholder="- Select -"
                                            components={{ DropdownIndicator }}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "privacySettings",
                                                e?.value,
                                              );
                                            }}
                                            value={privacyOptions?.find(
                                              (item) => {
                                                return (
                                                  item?.value ==
                                                  values?.privacySettings
                                                );
                                              },
                                            )}
                                            options={privacyOptions}
                                            isDisabled={
                                              moment(
                                                dateToCheck,
                                                "DD-MM-YYYY hh:mm A",
                                              ).tz(timezone) <
                                              moment().tz(timezone)
                                            }
                                          />
                                          <span className="text-danger">
                                            {touched?.privacySettings &&
                                            errors?.privacySettings
                                              ? errors?.privacySettings
                                              : ""}
                                          </span>
                                        </Box>
                                      </Box>
                                      <Box className="comp-details detail-child">
                                        <Button
                                          disableElevation
                                          disableFocusRipple
                                          disableRipple
                                          // type="submit"
                                          className="setting-btn delete-setting-btn"
                                          startIcon={<OrangeDelete />}
                                          onClick={() => handleConfirm()}
                                        >
                                          Delete competition
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              defaultExpanded
                              className="create-comp-accordion"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Typography className="accordion-header-text">
                                  Tipping and Points
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="details-sec-header">
                                  <Box className="details-sec">
                                    <Box className="comp-field-container">
                                      <Box className="details detail-child">
                                        <Typography className="left">
                                          Tipping:
                                        </Typography>
                                        <Typography className="right">
                                          {eventByIdData?.tippingType ==
                                          "winning"
                                            ? "Winner Tipping"
                                            : eventByIdData?.tippingType ==
                                                "odds"
                                              ? "Odds Tipping"
                                              : eventByIdData?.tippingType ==
                                                  "spread"
                                                ? "SpreadLine Tipping"
                                                : ""}
                                        </Typography>
                                      </Box>
                                      <Box className="detail-child">
                                        <Box className="comp-details">
                                          <Typography className="textfield-text left">
                                            Tip deadline:
                                            {/* <span className="danger">*</span>: */}
                                          </Typography>
                                          <Box className="field-container right">
                                            <Select
                                              className="React sort-select"
                                              // value={
                                              //   selectedTipDeadline !== "" &&
                                              //   tipDeadlineOptions?.find(item => {
                                              //     return (
                                              //       item?.value === selectedTipDeadline
                                              //     );
                                              //   })
                                              // }
                                              // onChange={e =>
                                              //   setSelectedTipDeadline(e?.value)
                                              // }
                                              // options={tipDeadlineOptions}
                                              classNamePrefix="select"
                                              placeholder="- Select Deadline -"
                                              components={{ DropdownIndicator }}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "tipDeadline",
                                                  e?.value,
                                                );
                                              }}
                                              value={tipDeadlineOptions?.find(
                                                (item) => {
                                                  return (
                                                    item?.value ==
                                                    values?.tipDeadline
                                                  );
                                                },
                                              )}
                                              options={tipDeadlineOptions}
                                              // isDisabled
                                            />
                                            <span className="text-danger">
                                              {touched?.tipDeadline &&
                                              errors?.tipDeadline
                                                ? errors?.tipDeadline
                                                : ""}
                                            </span>
                                          </Box>
                                        </Box>
                                        {values?.tipDeadline == "round" && (
                                          <>
                                            <Box className="comp-details">
                                              <Typography className="textfield-text left"></Typography>
                                              <Box className="day-time-section">
                                                <Box className="day-time-box">
                                                  <Typography className="cutoff-txt">
                                                    Select a day and time for
                                                    cut off
                                                  </Typography>

                                                  <Box className="day-time">
                                                    <Box className="mw-236 comp-date-selected-wrap">
                                                      {/* <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <KeyboardDatePicker
                                          clearable
                                          autoOk
                                          // disableToolbar
                                          // variant="inline"
                                          format="dd/MM/yyyy"
                                          placeholder="- Select day -"
                                          margin="normal"
                                          id="date-picker-inline"
                                          inputVariant="outlined"
                                          // value={
                                          //   dateSelected ? dateSelected : null
                                          // }
                                          value={values?.selectDay}
                                          // onChange={e =>
                                          //   handleLiveDateChange(e)
                                          // }
                                          disabled={true}
                                          onChange={e =>
                                            setFieldValue("selectDay", e)
                                          }
                                          onClear={() => {
                                            setFieldValue("selectDay", "");
                                            setOpen(false);
                                          }}
                                          // onClear={clearDate}
                                          open={open}
                                          onOpen={() => setOpen(true)}
                                          onClose={() => setOpen(false)}
                                          views={["year", "month", "date"]}
                                          KeyboardButtonProps={{
                                            "aria-label": "Select Date"
                                          }}
                                          keyboardIcon={
                                            <Datepicker className="keyboard-icon" />
                                          }
                                          // className="comp-comp-details-search-picker"
                                        />
                                      </MuiPickersUtilsProvider> */}
                                                      <Box className="field-container">
                                                        <Select
                                                          className="React sort-select"
                                                          // value={rounds?.find(item => {
                                                          //   return (
                                                          //     item ==
                                                          //     eventByIdData?.startingRound
                                                          //   );
                                                          // })}
                                                          // options={tipDeadlineOptions}
                                                          value={
                                                            // selectedDay !== "" &&
                                                            dayOptions?.find(
                                                              (item) => {
                                                                return (
                                                                  item?.label ===
                                                                  values?.selectDay
                                                                );
                                                              },
                                                            )
                                                          }
                                                          onChange={(e) =>
                                                            setFieldValue(
                                                              "selectDay",
                                                              e?.label,
                                                            )
                                                          }
                                                          // value={eventByIdData?.cutOffWeek}
                                                          // isDisabled={true}
                                                          options={dayOptions}
                                                          classNamePrefix="select"
                                                          placeholder="Select Day"
                                                          components={{
                                                            DropdownIndicator,
                                                          }}
                                                        />
                                                      </Box>
                                                      <span className="text-danger">
                                                        {touched?.selectDay &&
                                                        errors?.selectDay
                                                          ? errors?.selectDay
                                                          : ""}
                                                      </span>
                                                    </Box>
                                                    <Box
                                                      className="mw-236 comp-date-selected-wrap border-gray"
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <LocalizationProvider
                                                        dateAdapter={
                                                          AdapterDateFns
                                                        }
                                                      >
                                                        <MobileTimePicker
                                                          clearable
                                                          autoOk
                                                          placeholder="--:--"
                                                          // value={
                                                          //   values?.selectTime
                                                          //     ? parseISO(
                                                          //         values?.selectTime,
                                                          //       )
                                                          //     : null
                                                          // }
                                                          value={
                                                            values?.selectTime
                                                              ? typeof values.selectTime ===
                                                                "string"
                                                                ? parseISO(
                                                                    values?.selectTime,
                                                                  )
                                                                : values.selectTime
                                                              : null
                                                          }
                                                          onChange={(e) =>
                                                            setFieldValue(
                                                              "selectTime",
                                                              e,
                                                            )
                                                          }
                                                          format="hh:mm"
                                                          slots={{
                                                            openPickerIcon:
                                                              Timepicker,
                                                          }}
                                                          slotProps={{
                                                            field: {
                                                              id: "date-picker-inline",
                                                              placeholder:
                                                                "--:--",
                                                            },
                                                          }}
                                                        />
                                                      </LocalizationProvider>

                                                      {/* <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          className="comp-textfield"
                                          placeholder="Choose a name"
                                          name="compName"
                                          value={
                                            eventByIdData &&
                                            eventByIdData?.cutOffTime
                                              ? moment
                                                  .utc(
                                                    `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
                                                    "dddd HH:mm:ss"
                                                  )
                                                  .tz(timezone)
                                                  .format("HH:mm:ss")
                                              : null
                                          }
                                          // isDisabled={true}
                                        /> */}
                                                      <span
                                                        className="text-danger"
                                                        style={{
                                                          position: "absolute",
                                                          bottom: "-20px",
                                                        }}
                                                      >
                                                        {touched?.selectTime &&
                                                        errors?.selectTime
                                                          ? errors?.selectTime
                                                          : ""}
                                                      </span>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                      <Box className="comp-details detail-child">
                                        <Typography className="textfield-text left">
                                          If no tip is entered:
                                          {/* <span className="danger">*</span>: */}
                                        </Typography>
                                        <Box className="field-container right">
                                          <Select
                                            className="React sort-select"
                                            // value={
                                            //   selectedNoTip !== "" &&
                                            //   ifNoTipOptions?.find(item => {
                                            //     return item?.value === selectedNoTip;
                                            //   })
                                            // }
                                            // onChange={e => setSelectedNoTip(e?.value)}
                                            // options={ifNoTipOptions}
                                            classNamePrefix="select"
                                            placeholder="- Select -"
                                            components={{ DropdownIndicator }}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "ifNoTip",
                                                e?.value,
                                              );
                                            }}
                                            value={ifNoTipOptions?.find(
                                              (item) => {
                                                return (
                                                  item?.value == values?.ifNoTip
                                                );
                                              },
                                            )}
                                            options={ifNoTipOptions}
                                            isDisabled={
                                              moment(
                                                dateToCheck,
                                                "DD-MM-YYYY hh:mm A",
                                              ).tz(timezone) <
                                              moment().tz(timezone)
                                            }
                                          />
                                          <span className="text-danger">
                                            {touched?.ifNoTip && errors?.ifNoTip
                                              ? errors?.ifNoTip
                                              : ""}
                                          </span>
                                        </Box>
                                      </Box>

                                      <Box className="comp-details tipster-number detail-child">
                                        <Typography className="textfield-text left">
                                          Number of Tipsters:
                                          {/* <span className="danger">*</span>: */}
                                        </Typography>
                                        <Box className="field-container right">
                                          <Typography className="tipper-number">
                                            {eventByIdData?.totalTipper}
                                          </Typography>
                                          <Box className="Filteritemlist-wrap">
                                            <Box className="field-container">
                                              <Box className="filter-list-racing">
                                                <ul className="Filteritemlist-racing">
                                                  {isTipsterShown?.length > 0 &&
                                                    isTipsterShown?.map(
                                                      (obj, i) => (
                                                        <li key={i}>
                                                          <FormControl>
                                                            <label>
                                                              <Checkbox
                                                                className="filter-racing"
                                                                icon={
                                                                  <Unchecked />
                                                                }
                                                                checkedIcon={
                                                                  <Checked />
                                                                }
                                                                name="filter"
                                                                onChange={() =>
                                                                  // setRoundBonusOption(
                                                                  //   obj?.id
                                                                  // )
                                                                  setFieldValue(
                                                                    "numberOfTippers",
                                                                    obj.value,
                                                                  )
                                                                }
                                                                checked={
                                                                  values?.numberOfTippers ===
                                                                  obj?.value
                                                                }
                                                                disabled={
                                                                  moment(
                                                                    dateToCheck,
                                                                    "DD-MM-YYYY hh:mm A",
                                                                  ).tz(
                                                                    timezone,
                                                                  ) <
                                                                  moment().tz(
                                                                    timezone,
                                                                  )
                                                                }
                                                              />
                                                              {
                                                                obj?.isTipsterShown
                                                              }
                                                            </label>
                                                          </FormControl>
                                                        </li>
                                                      ),
                                                    )}
                                                </ul>
                                              </Box>
                                              <span className="text-danger">
                                                {touched?.numberOfTippers &&
                                                errors?.numberOfTippers
                                                  ? errors?.numberOfTippers
                                                  : ""}
                                              </span>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box className="comp-details tipster-number detail-child">
                                        <Typography className="textfield-text left">
                                          Show Tipster's Tipping
                                          {/* <span className="danger">*</span>: */}
                                        </Typography>
                                        <Box className="field-container right">
                                          <Box className="Filteritemlist-wrap">
                                            <Box className="field-container">
                                              <Box className="filter-list-racing">
                                                <ul className="Filteritemlist-racing show-tipsters-tipping">
                                                  {isTipsterTipping?.length >
                                                    0 &&
                                                    isTipsterTipping?.map(
                                                      (obj, i) => (
                                                        <li key={i}>
                                                          <FormControl>
                                                            <label>
                                                              <Checkbox
                                                                className="filter-racing"
                                                                icon={
                                                                  <Unchecked />
                                                                }
                                                                checkedIcon={
                                                                  <Checked />
                                                                }
                                                                name="filter"
                                                                onChange={() =>
                                                                  setFieldValue(
                                                                    "showTipstersTipping",
                                                                    obj.value,
                                                                  )
                                                                }
                                                                checked={
                                                                  values?.showTipstersTipping ===
                                                                  obj?.value
                                                                }
                                                                disabled={
                                                                  moment(
                                                                    dateToCheck,
                                                                    "DD-MM-YYYY hh:mm A",
                                                                  ).tz(
                                                                    timezone,
                                                                  ) <
                                                                  moment().tz(
                                                                    timezone,
                                                                  )
                                                                }
                                                              />
                                                              {
                                                                obj?.isTipsterTippingShown
                                                              }
                                                            </label>
                                                          </FormControl>
                                                        </li>
                                                      ),
                                                    )}
                                                </ul>
                                              </Box>
                                              <span className="text-danger">
                                                {touched?.showTipstersTipping &&
                                                errors?.showTipstersTipping
                                                  ? errors?.showTipstersTipping
                                                  : ""}
                                              </span>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box className=" detail-child">
                                        {eventByIdData?.tippingType ==
                                          "winning" && (
                                          <Box className="comp-details">
                                            <Typography className="textfield-text left">
                                              Perfect round Bonus:
                                              {/* <span className="danger">*</span>: */}
                                            </Typography>
                                            <Box className="Filteritemlist-wrap">
                                              <Box className="field-container">
                                                <Box className="filter-list-racing">
                                                  <ul className="Filteritemlist-racing">
                                                    {roundBonus?.length > 0 &&
                                                      roundBonus?.map(
                                                        (obj, i) => (
                                                          <li key={i}>
                                                            <FormControl>
                                                              <label>
                                                                <Checkbox
                                                                  className="filter-racing"
                                                                  icon={
                                                                    <Unchecked />
                                                                  }
                                                                  checkedIcon={
                                                                    <Checked />
                                                                  }
                                                                  name="filter"
                                                                  onChange={() =>
                                                                    setFieldValue(
                                                                      "roundBonus",
                                                                      obj.id,
                                                                    )
                                                                  }
                                                                  checked={
                                                                    values?.roundBonus ===
                                                                    obj?.id
                                                                  }
                                                                  disabled={
                                                                    moment(
                                                                      dateToCheck,
                                                                      "DD-MM-YYYY hh:mm A",
                                                                    ).tz(
                                                                      timezone,
                                                                    ) <
                                                                    moment().tz(
                                                                      timezone,
                                                                    )
                                                                  }
                                                                />
                                                                {
                                                                  obj?.Roundvalue
                                                                }
                                                              </label>
                                                            </FormControl>
                                                          </li>
                                                        ),
                                                      )}
                                                  </ul>
                                                </Box>
                                                <span className="text-danger">
                                                  {touched?.roundBonus &&
                                                  errors?.roundBonus
                                                    ? errors?.roundBonus
                                                    : ""}
                                                </span>
                                              </Box>
                                            </Box>
                                          </Box>
                                        )}
                                        {values?.roundBonus === 1 && (
                                          <>
                                            <Box className="comp-details">
                                              <Typography className="textfield-text left"></Typography>
                                              <Box className="day-time-section">
                                                <Box className="day-time-box yes-no-box-wrap">
                                                  <Typography className="cutoff-txt">
                                                    Custom Perfect round Bonus
                                                    Points
                                                  </Typography>

                                                  <Box className="Filteritemlist-wrap">
                                                    <Box className="field-container">
                                                      <Box className="filter-list-racing">
                                                        <ul className="Filteritemlist-racing">
                                                          {roundBonus?.length >
                                                            0 &&
                                                            roundBonus?.map(
                                                              (obj, i) => (
                                                                <li key={i}>
                                                                  <FormControl>
                                                                    <label>
                                                                      <Checkbox
                                                                        className="filter-racing"
                                                                        icon={
                                                                          <Unchecked />
                                                                        }
                                                                        checkedIcon={
                                                                          <Checked />
                                                                        }
                                                                        name="filter"
                                                                        onChange={() =>
                                                                          setFieldValue(
                                                                            "customRoundBonusOption",
                                                                            obj.id,
                                                                          )
                                                                        }
                                                                        checked={
                                                                          values?.customRoundBonusOption ===
                                                                          obj?.id
                                                                        }
                                                                        disabled={
                                                                          moment(
                                                                            dateToCheck,
                                                                            "DD-MM-YYYY hh:mm A",
                                                                          ).tz(
                                                                            timezone,
                                                                          ) <
                                                                          moment().tz(
                                                                            timezone,
                                                                          )
                                                                        }
                                                                      />
                                                                      {
                                                                        obj?.Roundvalue
                                                                      }
                                                                    </label>
                                                                  </FormControl>
                                                                </li>
                                                              ),
                                                            )}
                                                        </ul>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box className="field-container inner-field-container">
                                                    <TextField
                                                      id="outlined-basic"
                                                      variant="outlined"
                                                      className="comp-textfield"
                                                      placeholder="Custom Points"
                                                      name="roundBonusPoint"
                                                      type="number"
                                                      error={Boolean(
                                                        touched?.roundBonusPoint &&
                                                          errors?.roundBonusPoint,
                                                      )}
                                                      helperText={
                                                        touched?.roundBonusPoint
                                                          ? errors?.roundBonusPoint
                                                          : ""
                                                      }
                                                      onChange={handleChange}
                                                      value={
                                                        values?.roundBonusPoint
                                                      }
                                                      disabled={
                                                        values?.customRoundBonusOption !==
                                                          1 ||
                                                        moment(
                                                          dateToCheck,
                                                          "DD-MM-YYYY hh:mm A",
                                                        ).tz(timezone) <
                                                          moment().tz(timezone)
                                                      }
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                      <Box className="detail-child">
                                        {finalCustomPoints?.length > 0 &&
                                          eventByIdData?.tippingType ===
                                            "winning" && (
                                            <>
                                              <Box className="comp-details">
                                                <Typography className="textfield-text left">
                                                  Include Finals
                                                  <span className="danger">
                                                    *
                                                  </span>
                                                  :
                                                </Typography>
                                                <Box className="Filteritemlist-wrap">
                                                  <Box className="field-container">
                                                    <Box className="filter-list-racing">
                                                      <ul className="Filteritemlist-racing">
                                                        {editincludeFinals?.length >
                                                          0 &&
                                                          editincludeFinals?.map(
                                                            (obj, i) => (
                                                              <li key={i}>
                                                                <FormControl>
                                                                  <label>
                                                                    <Checkbox
                                                                      className="filter-racing"
                                                                      icon={
                                                                        <Unchecked />
                                                                      }
                                                                      checkedIcon={
                                                                        <Checked />
                                                                      }
                                                                      name="filter"
                                                                      onChange={() =>
                                                                        setFieldValue(
                                                                          "includeFinalsValue",
                                                                          obj.id,
                                                                        )
                                                                      }
                                                                      checked={
                                                                        values?.includeFinalsValue ===
                                                                        obj?.id
                                                                      }
                                                                      disabled={
                                                                        moment(
                                                                          dateToCheck,
                                                                          "DD-MM-YYYY hh:mm A",
                                                                        ).tz(
                                                                          timezone,
                                                                        ) <
                                                                        moment().tz(
                                                                          timezone,
                                                                        )
                                                                      }
                                                                    />
                                                                    {
                                                                      obj?.IncludeFinalsValue
                                                                    }
                                                                  </label>
                                                                </FormControl>
                                                              </li>
                                                            ),
                                                          )}
                                                      </ul>
                                                    </Box>
                                                    <span className="text-danger">
                                                      {touched?.includeFinalsValue &&
                                                      errors?.includeFinalsValue
                                                        ? errors?.includeFinalsValue
                                                        : ""}
                                                    </span>
                                                  </Box>
                                                </Box>
                                              </Box>
                                              {values?.includeFinalsValue ===
                                                1 && (
                                                <Box className="comp-details">
                                                  <Typography className="textfield-text left"></Typography>
                                                  <Box className="day-time-section">
                                                    <Box className="day-time-box yes-no-box-wrap">
                                                      <Typography className="cutoff-txt">
                                                        Custom Points for Finals
                                                      </Typography>
                                                      <Box className="Filteritemlist-wrap">
                                                        <Box className="field-container">
                                                          <Box className="filter-list-racing">
                                                            <ul className="Filteritemlist-racing">
                                                              {editincludeFinals?.length >
                                                                0 &&
                                                                editincludeFinals?.map(
                                                                  (obj, i) => (
                                                                    <li key={i}>
                                                                      <FormControl>
                                                                        <label>
                                                                          <Checkbox
                                                                            className="filter-racing"
                                                                            icon={
                                                                              <Unchecked />
                                                                            }
                                                                            checkedIcon={
                                                                              <Checked />
                                                                            }
                                                                            name="filter"
                                                                            onChange={() =>
                                                                              setFieldValue(
                                                                                "includeFinalsOption",
                                                                                obj.id,
                                                                              )
                                                                            }
                                                                            checked={
                                                                              values?.includeFinalsOption ===
                                                                              obj?.id
                                                                            }
                                                                            disabled={
                                                                              moment(
                                                                                dateToCheck,
                                                                                "DD-MM-YYYY hh:mm A",
                                                                              ).tz(
                                                                                timezone,
                                                                              ) <
                                                                              moment().tz(
                                                                                timezone,
                                                                              )
                                                                            }
                                                                          />
                                                                          {
                                                                            obj?.IncludeFinalsValue
                                                                          }
                                                                        </label>
                                                                      </FormControl>
                                                                    </li>
                                                                  ),
                                                                )}
                                                            </ul>
                                                          </Box>
                                                        </Box>
                                                      </Box>
                                                      <Box className="field-container inner-field-container finals-point-container">
                                                        {finalCustomPoints?.map(
                                                          (item, index) => (
                                                            <Box key={index}>
                                                              <label>
                                                                Week{" "}
                                                                {item?.round}
                                                              </label>
                                                              <TextField
                                                                id={`outlined-basic-${index}`}
                                                                variant="outlined"
                                                                className="comp-textfield"
                                                                placeholder="Custom Points"
                                                                name={`customPoints-${index}`}
                                                                value={
                                                                  item?.value
                                                                }
                                                                type="number"
                                                                onChange={(e) =>
                                                                  handleFinalChange(
                                                                    index,
                                                                    e?.target
                                                                      ?.value,
                                                                  )
                                                                }
                                                                disabled={
                                                                  values?.includeFinalsOption !==
                                                                    1 ||
                                                                  moment(
                                                                    dateToCheck,
                                                                    "DD-MM-YYYY hh:mm A",
                                                                  ).tz(
                                                                    timezone,
                                                                  ) <
                                                                    moment().tz(
                                                                      timezone,
                                                                    )
                                                                }
                                                              />
                                                            </Box>
                                                          ),
                                                        )}
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              )}
                                            </>
                                          )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                            {eventByIdData?.tippingType === "winning" && (
                              <Accordion
                                defaultExpanded
                                className="create-comp-accordion"
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Typography className="accordion-header-text">
                                    Other
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box className="details-sec-header">
                                    <Box className="details-sec">
                                      <Box className="comp-field-container">
                                        <Box className="detail-child">
                                          {eventByIdData?.tippingType ==
                                            "winning" && (
                                            <Box className="comp-details ">
                                              <Typography className="textfield-text left">
                                                Include Joker Round:
                                                {/* <span className="danger">*</span>: */}
                                              </Typography>
                                              <Box className="Filteritemlist-wrap">
                                                <Box className="field-container">
                                                  <Box className="filter-list-racing">
                                                    <ul className="Filteritemlist-racing">
                                                      {jokerBonus?.length > 0 &&
                                                        jokerBonus?.map(
                                                          (obj, i) => (
                                                            <li key={i}>
                                                              <FormControl>
                                                                <label>
                                                                  <Checkbox
                                                                    className="filter-racing"
                                                                    icon={
                                                                      <Unchecked />
                                                                    }
                                                                    checkedIcon={
                                                                      <Checked />
                                                                    }
                                                                    name="filter"
                                                                    onChange={() =>
                                                                      setFieldValue(
                                                                        "jokerRound",
                                                                        obj.id,
                                                                      )
                                                                    }
                                                                    checked={
                                                                      values?.jokerRound ===
                                                                      obj?.id
                                                                    }
                                                                    disabled={
                                                                      moment(
                                                                        dateToCheck,
                                                                        "DD-MM-YYYY hh:mm A",
                                                                      ).tz(
                                                                        timezone,
                                                                      ) <
                                                                      moment().tz(
                                                                        timezone,
                                                                      )
                                                                    }
                                                                  />
                                                                  {
                                                                    obj?.Jokervalue
                                                                  }
                                                                </label>
                                                              </FormControl>
                                                            </li>
                                                          ),
                                                        )}
                                                    </ul>
                                                  </Box>
                                                  <span className="text-danger">
                                                    {touched?.jokerRound &&
                                                    errors?.jokerRound
                                                      ? errors?.jokerRound
                                                      : ""}
                                                  </span>
                                                </Box>
                                              </Box>
                                            </Box>
                                          )}
                                          {values?.jokerRound === 1 && (
                                            <>
                                              <Box className="comp-details">
                                                <Typography className="textfield-text left"></Typography>
                                                <Box className="day-time-section">
                                                  <Box className="day-time-box yes-no-box-wrap">
                                                    <Typography className="cutoff-txt">
                                                      Number of Joker Rounds
                                                    </Typography>

                                                    <Box className="field-container inner-field-container">
                                                      <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        className="comp-textfield"
                                                        placeholder="No. of Joker Rounds"
                                                        name="numberOfJockerRound"
                                                        type="number"
                                                        error={Boolean(
                                                          touched?.numberOfJockerRound &&
                                                            errors?.numberOfJockerRound,
                                                        )}
                                                        helperText={
                                                          touched?.numberOfJockerRound
                                                            ? errors?.numberOfJockerRound
                                                            : ""
                                                        }
                                                        onChange={handleChange}
                                                        value={
                                                          values?.numberOfJockerRound
                                                        }
                                                        disabled={
                                                          moment(
                                                            dateToCheck,
                                                            "DD-MM-YYYY hh:mm A",
                                                          ).tz(timezone) <
                                                          moment().tz(timezone)
                                                        }
                                                      />
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </>
                                          )}
                                        </Box>

                                        <Box className="comp-details detail-child">
                                          <Typography className="textfield-text left">
                                            Include Margin System:
                                            {/* <span className="danger">*</span>: */}
                                          </Typography>
                                          <Box className="Filteritemlist-wrap">
                                            <Box className="field-container">
                                              <Box className="filter-list-racing">
                                                <ul className="Filteritemlist-racing">
                                                  {marginSystem?.length > 0 &&
                                                    marginSystem?.map(
                                                      (obj, i) => (
                                                        <li key={i}>
                                                          <FormControl>
                                                            <label>
                                                              <Checkbox
                                                                className="filter-racing"
                                                                icon={
                                                                  <Unchecked />
                                                                }
                                                                checkedIcon={
                                                                  <Checked />
                                                                }
                                                                name="filter"
                                                                onChange={() =>
                                                                  // setMarginSystemOption(
                                                                  //   obj.id
                                                                  // )
                                                                  setFieldValue(
                                                                    "marginSystem",
                                                                    obj?.id,
                                                                  )
                                                                }
                                                                checked={
                                                                  values?.marginSystem ===
                                                                  obj?.id
                                                                  // marginSystemOption ===
                                                                  // obj?.id
                                                                }
                                                                disabled={
                                                                  moment(
                                                                    dateToCheck,
                                                                    "DD-MM-YYYY hh:mm A",
                                                                  ).tz(
                                                                    timezone,
                                                                  ) <
                                                                  moment().tz(
                                                                    timezone,
                                                                  )
                                                                }
                                                              />
                                                              {
                                                                obj?.MarginSystemvalue
                                                              }
                                                            </label>
                                                          </FormControl>
                                                        </li>
                                                      ),
                                                    )}
                                                </ul>
                                              </Box>
                                              <span className="text-danger">
                                                {touched?.marginSystem &&
                                                errors?.marginSystem
                                                  ? errors?.marginSystem
                                                  : ""}
                                              </span>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            )}
                            <Accordion
                              defaultExpanded
                              className="create-comp-accordion"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Typography className="accordion-header-text">
                                  Entry Fee and Prizes
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="details-sec-header">
                                  <Box className="details-sec">
                                    <Box className="comp-field-container">
                                      <Box className="detail-child">
                                        <Box className="comp-details entry-fee-section">
                                          <Box className="textfield-text left entry-flex">
                                            <Typography className="textfield-text left">
                                              Entry fee
                                              {/* <span className="danger">*</span>: */}
                                            </Typography>
                                            {eventByIdData?.fees === 1 &&
                                              eventByIdData?.entryFee > 0 && (
                                                <Button
                                                  className={
                                                    eventByIdData?.paymentStatus ===
                                                    "paid"
                                                      ? "paid-btn"
                                                      : "setting-btn"
                                                  }
                                                  onClick={() => {
                                                    if (
                                                      eventByIdData?.paymentStatus !==
                                                      "paid"
                                                    ) {
                                                      paymentHandelar();
                                                    }
                                                  }}
                                                  disabled={
                                                    eventByIdData?.paymentStatus ===
                                                    "paid"
                                                  }
                                                >
                                                  {eventByIdData?.paymentStatus ===
                                                  "unpaid"
                                                    ? "Pay Entry Fee"
                                                    : "Paid"}
                                                </Button>
                                              )}
                                          </Box>
                                          <Box className="Filteritemlist-wrap">
                                            <Box className="field-container">
                                              <Box className="filter-list-racing">
                                                <ul className="Filteritemlist-racing">
                                                  {editentryFee?.length > 0 &&
                                                    editentryFee?.map(
                                                      (obj, i) => (
                                                        <li key={i}>
                                                          <FormControl>
                                                            <label>
                                                              <Checkbox
                                                                className="filter-racing"
                                                                icon={
                                                                  <Unchecked />
                                                                }
                                                                checkedIcon={
                                                                  <Checked />
                                                                }
                                                                name="filter"
                                                                onChange={() =>
                                                                  setFieldValue(
                                                                    "entryFee",
                                                                    obj.id,
                                                                  )
                                                                }
                                                                checked={
                                                                  values?.entryFee ===
                                                                  obj?.id
                                                                }
                                                                disabled={
                                                                  moment(
                                                                    dateToCheck,
                                                                    "DD-MM-YYYY hh:mm A",
                                                                  ).tz(
                                                                    timezone,
                                                                  ) <
                                                                  moment().tz(
                                                                    timezone,
                                                                  )
                                                                }
                                                              />
                                                              {
                                                                obj?.EntryFeeValue
                                                              }
                                                            </label>
                                                          </FormControl>
                                                        </li>
                                                      ),
                                                    )}
                                                </ul>
                                              </Box>
                                              <span className="text-danger">
                                                {touched?.entryFee &&
                                                errors?.entryFee
                                                  ? errors?.entryFee
                                                  : ""}
                                              </span>
                                            </Box>
                                          </Box>
                                        </Box>
                                        {values?.entryFee === 1 && (
                                          <Box className="comp-details">
                                            <Typography className="textfield-text left"></Typography>
                                            <Box className="day-time-section">
                                              <Box className="day-time-box yes-no-box-wrap">
                                                <Typography className="cutoff-txt">
                                                  Entry fee
                                                </Typography>

                                                <Box className="field-container inner-field-container">
                                                  <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    className="comp-textfield"
                                                    placeholder="Entry fee"
                                                    name="selectEntryFee"
                                                    type="number"
                                                    error={Boolean(
                                                      touched?.selectEntryFee &&
                                                        errors?.selectEntryFee,
                                                    )}
                                                    helperText={
                                                      touched?.selectEntryFee
                                                        ? errors?.selectEntryFee
                                                        : ""
                                                    }
                                                    onChange={handleChange}
                                                    value={
                                                      values?.selectEntryFee
                                                    }
                                                    disabled={
                                                      moment(
                                                        dateToCheck,
                                                        "DD-MM-YYYY hh:mm A",
                                                      ).tz(timezone) <
                                                      moment().tz(timezone)
                                                    }
                                                  />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        )}
                                      </Box>
                                      <Box className="detail-child">
                                        <Box className="comp-details">
                                          <Typography className="textfield-text left">
                                            Prize information
                                            {/* <span className="danger">*</span>: */}
                                          </Typography>
                                          <Box className="field-container">
                                            <TextareaAutosize
                                              id="outlined-basic"
                                              variant="outlined"
                                              className="details-textarea-field"
                                              placeholder="Prize Information"
                                              name="compAbout"
                                              maxRows={3}
                                              minRows={2}
                                              onChange={(e) =>
                                                setPrizeInformationText(
                                                  e?.target?.value,
                                                )
                                              }
                                              value={prizeInformationText}
                                            />
                                          </Box>
                                        </Box>
                                        <Box className="comp-details">
                                          <Typography className="textfield-text left"></Typography>
                                          <Box className="day-time-section">
                                            <Box className="day-time-box yes-no-box-wrap">
                                              <Typography className="cutoff-txt">
                                                Manage Prizes
                                              </Typography>

                                              <Box className="Filteritemlist-wrap">
                                                <Box className="field-container">
                                                  <Box className="filter-list-racing">
                                                    <ul className="Filteritemlist-racing">
                                                      {editprizeInformation?.length >
                                                        0 &&
                                                        editprizeInformation?.map(
                                                          (obj, i) => (
                                                            <li key={i}>
                                                              <FormControl>
                                                                <label>
                                                                  <Checkbox
                                                                    className="filter-racing"
                                                                    icon={
                                                                      <Unchecked />
                                                                    }
                                                                    checkedIcon={
                                                                      <Checked />
                                                                    }
                                                                    name="filter"
                                                                    onChange={() =>
                                                                      setManagePrizeInformation(
                                                                        obj?.id,
                                                                      )
                                                                    }
                                                                    checked={
                                                                      managePrizeInformation ===
                                                                      obj?.id
                                                                    }
                                                                  />
                                                                  {
                                                                    obj?.PrizeInformationValue
                                                                  }
                                                                </label>
                                                              </FormControl>
                                                            </li>
                                                          ),
                                                        )}
                                                    </ul>
                                                  </Box>
                                                </Box>
                                              </Box>
                                              {/* {managePrizeInformation === 1 && ( */}
                                              <Box className="prize-info-container">
                                                <Box className="prize-header-section">
                                                  <Typography className="w-80 cutoff-txt">
                                                    Position
                                                  </Typography>
                                                  <Typography className="cutoff-txt">
                                                    Prize money
                                                  </Typography>
                                                </Box>
                                                {prizes?.map((prize, index) => (
                                                  <Box
                                                    key={index}
                                                    sx={{
                                                      gap: 2,
                                                      mb: 2,
                                                    }}
                                                    className="prize-info-item"
                                                  >
                                                    <Box className="w-80">
                                                      {/* <Typography className="cutoff-txt">
                                                      Position
                                                    </Typography> */}
                                                      <Typography className="prize-position">
                                                        {prize?.position}
                                                      </Typography>
                                                    </Box>
                                                    <Box className="field-container prize-field-container">
                                                      {/* <Typography className="cutoff-txt">
                                                      Prize money
                                                    </Typography> */}
                                                      <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        className="comp-textfield"
                                                        placeholder="Prize Money"
                                                        name="customPoints"
                                                        type="number"
                                                        onChange={(e) =>
                                                          handlePrizeChange(
                                                            index,
                                                            e?.target?.value,
                                                          )
                                                        }
                                                        value={prize?.prize}
                                                      />

                                                      <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        className="comp-textfield"
                                                        placeholder="Prize label"
                                                        name="customPoints"
                                                        type="text"
                                                        onChange={(e) =>
                                                          handleLabelChange(
                                                            index,
                                                            e?.target?.value,
                                                          )
                                                        }
                                                        value={prize?.label}
                                                      />

                                                      <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        className="comp-textfield"
                                                        placeholder="Prize description"
                                                        name="customPoints"
                                                        type="text"
                                                        onChange={(e) =>
                                                          handleDescChange(
                                                            index,
                                                            e?.target?.value,
                                                          )
                                                        }
                                                        value={
                                                          prize?.description
                                                        }
                                                      />
                                                    </Box>

                                                    <IconButton
                                                      color="error"
                                                      onClick={() =>
                                                        removePosition(index)
                                                      }
                                                      disabled={
                                                        prizes?.length === 1
                                                      }
                                                      className="delete-icon"
                                                    >
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  </Box>
                                                ))}

                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      color: "red",
                                                      fontSize: "14px",
                                                      marginBottom: "12px",
                                                    }}
                                                  >
                                                    Note for price
                                                    discriptation: Need to add $
                                                    sign before amount and write
                                                    text as
                                                    'prize'/'price'/'Prize'/'price'
                                                    and then (Add your text).
                                                    For example sentence must be
                                                    start with e.g. $500 prize
                                                    (Add your text).
                                                  </Typography>
                                                </Box>

                                                <Button
                                                  variant="contained"
                                                  startIcon={<AddIcon />}
                                                  onClick={addPosition}
                                                  className="add-position-btn"
                                                >
                                                  Add position
                                                </Button>
                                              </Box>
                                              {/* )} */}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>

                            <Box className="edit-comp-button">
                              <Box className="comp-btn">
                                <Button
                                  variant="contained"
                                  className="btn-save"
                                  type="submit"
                                >
                                  {isLoading ? (
                                    <CircularProgress
                                      size={24}
                                      color="inherit"
                                    />
                                  ) : (
                                    "Update changes"
                                  )}
                                </Button>
                              </Box>
                            </Box>
                          </Box>

                          {setformvalues(values)}
                        </form>
                      )}
                    </Formik>
                  )}
                </>
              )
              // : (
              //   <Box
              //     style={{
              //       textAlign: "center"
              //     }}
              //   >
              //     <NoDataComp />
              //   </Box>
              // )
            }
          </>
        )}
      </Box>
      <DialogBox
        open={openConfirm}
        onClose={handleConfirmClose}
        handleClose={handleConfirmClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                {isUser
                  ? "Are you sure you want to leave the competition?"
                  : "Are you sure you want to delete the competition?"}
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={(e) => {
                    handleConfirmClose();
                    handleDoneModal();
                    deleteOrLeaveComp(isUser ? "leave" : "delete");
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleConfirmClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <DialogBox
        open={openDoneModal}
        onClose={handleDoneClose}
        handleClose={handleDoneClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="restriction-title">Success!</Typography>
              <Typography className="restriction-text">
                {isUser
                  ? `You have been removed from the competition. All of your entries are no longer available.`
                  : `You have deleted the tipping competition ${eventByIdData?.tournament?.name}. All of your entries and member tips are no longer available.`}
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-Close-Modal btn-cancel blackbook-delete"
                  onClick={(e) => {
                    handleDoneClose();
                  }}
                >
                  Close
                </Button>
                {/* <Button
                disableElevation
                disableFocusRipple
                disableRipple
                className="btn-save"
                onClick={() => handleConfirmClose()}
              >
                No
              </Button> */}
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      {/* <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    /> */}
      <UploadImageModal
        open={imageModalOpen}
        handleClose={handleImageClose}
        title={"Upload Comp Image"}
        closeIcon={false}
        getImage={getImage}
        //  listingFunction={() =>
        //    setTimeout(() => {
        //      getEventByID(stepperCount);
        //    }, 3000)
        //  }
      />
      {fetchAds(
        1,
        "100%",
        Config?.release == "IN" ? BannerIndia : tippingBelow,
      )}
    </>
  );
};

export default CompDetailsTab;
