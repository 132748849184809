import React from "react";
import { useParams } from "react-router";
import CricketSportDataPage from "../cricketSportData";
import SoccerSportDataPage from "../soccerSportData";

const SportDataPage = () => {
  const params = useParams();
  return (
    <>
      {params?.type === "cricket" ? (
        <CricketSportDataPage />
      ) : params?.type === "soccer" ? (
        <SoccerSportDataPage />
      ) : (
        ""
      )}
    </>
  );
};

export default SportDataPage;
