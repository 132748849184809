import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as RightArrow } from "../../../../../../assets/images/icons/rightArrowThick.svg";
import { IntlContext } from "src/App";
import { useLocation, useNavigate, useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import "../../../../Home/HomeComponents/expertsTips.scss";
import CompExpertsTips from "src/views/component/Home/HomeComponents/CompExpertsTips";
import "./landingExpertTips.scss";

const LeadingExpertTips = () => {
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const touranamentId = searchParamsMenu.get("touranamentId");
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [featureSportData, setFeatureSportData] = useState([]);
  const [featureSportLoader, setFeatureSportLoader] = useState(true);

  const [sportDataCount, setSportDataCount] = useState(0);

  const [sportOffset, setSportOffset] = useState(1);
  const [selectedSport, setSelectedSport] = useState(0);
  const sliderLimit = 20;

  const settingsSport = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: false,
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    arrows: featureSportData?.length > 3,
    responsive: [
      {
        breakpoint: 1361,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 923,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // with tournamentId

  useEffect(() => {
    fetchFeaturedSport(params?.sportsId, [], 1, touranamentId);
  }, [touranamentId]);

  // useEffect(() => {
  //   fetchFeaturedSport(params?.sportsId, [], 1);
  // }, []);

  const handleAfterChange = (current, type) => {
    if (current === featureSportData?.length - 3) {
      if (sportDataCount !== sportOffset) {
        fetchFeaturedSport(
          selectedSport,
          featureSportData,
          sportOffset + 1,
          touranamentId,
        );
      }
    }
  };

  const fetchFeaturedSport = async (
    sportId,
    listData,
    offset,
    tournamentIds,
  ) => {
    offset === 1 && setFeatureSportLoader(true);

    try {
      const { status, data } = await axiosInstance.get(
        `expertTips/web/getAllTips?SportId=${sportId ? sportId : ""}&limit=${sliderLimit}&page=${offset}&tournamentId=${
          tournamentIds === "0" ? "" : tournamentIds
        }&timeZone=Asia/Kolkata`,
        // `expertTips/web/getAllTips?SportId=${sportId ? sportId : ""}&limit=${sliderLimit}&page=${offset}&tournamentId=&timeZone=Asia/Kolkata`,
      );
      if (status === 200) {
        setFeatureSportLoader(false);
        const sorteddata = data?.result?.rows;
        setFeatureSportData([...listData, ...sorteddata]);
        setSportDataCount(Math?.ceil(data?.result?.count / sliderLimit));
        setSelectedSport(sportId);
        setSportOffset(offset);
      }
    } catch (error) {
      setFeatureSportLoader(false);
    }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleNavigate = (data, type) => {
    if (handleRestrictedUser()) {
      if (type === "sports") {
        let SportType =
          data?.SportId === 4
            ? "cricket"
            : data?.SportId === 12
              ? "rugbyleague"
              : data?.SportId === 13
                ? "rugbyunion"
                : data?.SportId === 10
                  ? "basketball"
                  : data?.SportId === 15
                    ? "americanfootball"
                    : data?.SportId === 9
                      ? "australianrules"
                      : data?.SportId === 16
                        ? "golf"
                        : data?.SportId === 7
                          ? "tennis"
                          : data?.SportId === 11
                            ? "baseball"
                            : data?.SportId === 17
                              ? "icehockey"
                              : data?.SportId === 6
                                ? "boxing"
                                : data?.SportId === 5
                                  ? "mma"
                                  : data?.SportId === 8
                                    ? "soccer"
                                    : "";

        const nameSlug = data?.tournamentName
          ? data?.tournamentName.trim().toLowerCase().replaceAll(" ", "-")
          : "name";

        navigate(
          `/teamsports/${SportType}/sport-expert-tips/${nameSlug}/${data?.round}/${data?.id}/${data?.tournamentId}`,
        );
      } else {
        navigate(`/gettips/${data?.SportId}/${data?.id}`);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
    }
  };

  return (
    <>
      {(featureSportData?.length > 0 || featureSportLoader) && (
        <Box className="component-wrap experts-tips-slider-wrap landing-expert-tips-slider-wrap">
          <Box className="experts-tips-slider-container single-expert-tips-slider-container">
            <Box className="slider-data-container">
              <Box className="landing-header">
                <Typography
                  variant="h3"
                  className="component-header hander-text"
                >
                  {localesData?.LANDING_HOME?.FREE_EXPERTS_SPORTS_TIPS}
                </Typography>
                <Typography
                  className="view-all-text"
                  onClick={() =>
                    navigate(
                      `/teamsports/${params?.sportsTypeName}/sport-expert-tips`,
                    )
                  }
                >
                  View All <RightArrow />
                </Typography>
              </Box>

              <CompExpertsTips
                featureRaceLoader={featureSportLoader}
                featureRaceData={featureSportData}
                handleNavigate={handleNavigate}
                settingsRace={settingsSport}
                type="sports"
                afterChange={handleAfterChange}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LeadingExpertTips;
