import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import pageHeaderIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import AdBannner from "../../AdBanner/AdBanner";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import { Config } from "src/helpers/context";
import SportsHeader from "../teamCommonComponent/sportsHeader";
import ViewExpertTips from "../teamCommonComponent/viewExpertTips";
import OurPartner from "../../ourPartners";
import RankingsLandingTable from "./rankingTable";
import TournamnetListSelect from "../tournamnetListSelect";
import SeasonListSelect from "../seasonListSelect";
import "./rankingLanding.scss";

const RankingsLanding = () => {
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const urlTouranamentId = Number(searchParamsMenu.get("touranamentId"));
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const expertTipsCount = useSelector(
    (state) => state?.reduxData?.viewExpertTipsCount,
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [leagueOption, setLeagueOption] = useState([]);
  const [seasonOption, setSeasonOption] = useState([]);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    if (urlTouranamentId !== 0) {
      setSelectedLeague(urlTouranamentId);
    }
    setSelectedSeason(null);
    setSeasonOption([]);
  }, [urlTouranamentId]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleleagueChange = (e) => {
    setSelectedLeague(e?.value);
    setSelectedSeason(null);
    setSeasonOption([]);
  };

  const handleSeasonChange = (e) => {
    setSelectedSeason(e?.value);
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release === "IN" ? pageHeaderIndia : banner,
          )}
        </Box>
        <Box>
          <SportsHeader
            localesData={localesData}
            params={params}
            expertTipsCount={expertTipsCount}
            screenWidth={screenWidth}
          />
        </Box>
        <Box className="homepage-landing-page-wrap ranking-landing-page-wrap">
          <Box className="homepage-landing-header ranking-landing-header">
            <Box className="ranking-team-select-wrap">
              {urlTouranamentId === 0 && (
                <Box className="table-select league-table-select">
                  <TournamnetListSelect
                    selectOption={leagueOption}
                    setSelectOption={setLeagueOption}
                    selectedValue={selectedLeague}
                    setSelectedValue={setSelectedLeague}
                    handleSelectedValueChange={handleleagueChange}
                  />
                </Box>
              )}
              <Box className="table-select season-table-select">
                <SeasonListSelect
                  selectOption={seasonOption}
                  setSelectOption={setSeasonOption}
                  selectedValue={selectedSeason}
                  setSelectedValue={setSelectedSeason}
                  handleSelectedValueChange={handleSeasonChange}
                  selectedLeague={selectedLeague}
                />
              </Box>
            </Box>
            {screenWidth >= 1023 && expertTipsCount > 0 && (
              <Box>
                <ViewExpertTips />
              </Box>
            )}
          </Box>
          <Box>
            <RankingsLandingTable
              selectedLeague={selectedLeague}
              selectedSeason={selectedSeason}
            />
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default RankingsLanding;
