import { createColumnHelper } from "@tanstack/react-table";
import { Box, Typography } from "@mui/material";
import { Config } from "src/helpers/context";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import SortIcons from "src/components/Common/Icons/SortIcons";
const columnHelper = createColumnHelper();

export const AFLStatsColumns = [
  columnHelper.accessor("rank", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        # <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
  }),
  columnHelper.accessor("team", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        Team <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Box className="team-wrap">
          <Box className="team-image-wrap">
            <img
              src={
                item?.team?.flag
                  ? item?.team?.flag?.includes("uploads")
                    ? Config.mediaURL + item?.team?.flag
                    : item?.team?.flag
                  : DefaultTeam
              }
              onError={(e) => {
                e.target.src = DefaultTeam;
              }}
              alt="Team 1"
              className="team-image"
            />
          </Box>
          <Box className="team-name-wrap">
            <Typography className="team-name">{item?.team}</Typography>
          </Box>
        </Box>
      );
    },
  }),
  columnHelper.accessor("disp", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        DISP <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.disp}</Typography>;
    },
    meta: {
      isHighLighted: true,
    },
  }),

  columnHelper.accessor("k", {
    header: ({ column }) => (
      <Box
        className="sorting-cell text-align-center"
        onClick={column.getToggleSortingHandler()}
      >
        T <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.k}</Typography>;
    },
  }),
  columnHelper.accessor("hb", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        HB <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.hb}</Typography>;
    },
  }),
  columnHelper.accessor("i50", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        I50 <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.i50}</Typography>;
    },
  }),
  columnHelper.accessor("dePercent", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        DE% <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Typography sx={{ textAlign: "center" }}>{item?.dePercent}</Typography>
      );
    },
  }),
  columnHelper.accessor("cl", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        CL <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.cl}</Typography>;
    },
  }),
  columnHelper.accessor("r50", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        R50 <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.r50}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("ek", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        EK <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.ek}</Typography>
      );
    },
  }),
  columnHelper.accessor("kePercent", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        KE% <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.kePercent}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("kh", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        K:H <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.kh}</Typography>
      );
    },
  }),
  columnHelper.accessor("ed", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        ED <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.ed}</Typography>
      );
    },
  }),

  columnHelper.accessor("tkl", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        TKL <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.tkl}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("t50", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        T50 <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.t50}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("pa", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        PA <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.pa}</Typography>
      );
    },
  }),
];
