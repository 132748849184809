import { Helmet } from "react-helmet";
import { Config } from "src/helpers/context";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import moment from "moment-timezone";

export const getSportsTitle = (sportsTypeName) => {
  switch (sportsTypeName) {
    case "cricket":
      return "Cricket";
    case "rugbyleague":
      return "Rugby League";
    case "rugbyunion":
      return "Rugby Union";
    case "basketball":
      return "Basketball";
    case "americanfootball":
      return "American Football";
    case "australianrules":
      return "Australian Rules";
    case "golf":
      return "Golf";
    case "tennis":
      return "Tennis";
    case "baseball":
      return "Baseball";
    case "icehockey":
      return "Ice Hockey";
    case "boxing":
      return "Boxing";
    case "mma":
      return "MMA";
    case "soccer":
      return "Soccer";
    case "rugbyunionsevens":
      return "Rugby Union Sevens";
    default:
      return sportsTypeName;
  }
};

export const renderHelmet = (teamSportType) => {
  const titles = {
    cricket: "SmartB | More on Team Sports Cricket | Learn More",
    rugbyleague: "SmartB | More On Team Sports | Rugby league Information",
    rugbyunion: "SmartB | More On Team Sports | Rugby Union Information",
    basketball: "SmartB | More on Team Sports Basketball | Learn More",
    americanfootball:
      "SmartB | More on Team Sports American Football | Learn More",
    australianrules:
      "SmartB | More on Team Sports Australian Rules | Learn More",
    baseball: "SmartB | More on Team Sports Baseball | Learn More",
    boxing: "SmartB | More on Team Sports Boxing | Learn More",
    icehockey: "SmartB | More on Team Sports Ice Hockey | Learn More",
    mma: "SmartB | More on Team Sports Mixed Martial Arts | Learn More",
    soccer: "SmartB | More on Team Sports Soccer | Learn More",
    tennis: "SmartB | More on Team Sports Tennis | Learn More",
    golf: "SmartB | More on Team Sports Golf | Learn More",
  };

  const descriptions = {
    cricket:
      "Cricket and other sports. Find out everything you need to know with smartb. Best Odds comparison platform on the web for cricket in all formats.",
    rugbyleague:
      "Rugby league and other sports. Find out everything you need to know with smartb. Best Odds comparison platform on the web for all.",
    rugbyunion:
      "More on Rugby Union. Find out everything you need to know with Smartb. Best Odds comparison platform on the web for all.",
    basketball:
      "Unlock the latest news on Basketball and other sports. Find out everything you need to know with the smartest sports ecosystem called SmartB.",
    americanfootball:
      "American Football and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
    australianrules:
      "Australian Rules and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
    baseball:
      "Baseball and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
    boxing:
      "Boxing and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
    icehockey:
      "Ice Hockey and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
    mma: "Mixed Martial Arts and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
    soccer:
      "Soccer and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
    tennis:
      "Tennis and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
    golf: "Golf and other sports. Find out everything you need to know with SmartB. Best Odds comparison platform on the web for cricket in all formats.",
  };

  if (titles[teamSportType] && descriptions[teamSportType]) {
    return (
      <Helmet>
        <title>{titles[teamSportType]}</title>
        <meta name="description" content={descriptions[teamSportType]} />
      </Helmet>
    );
  }
  return null;
};

export const getTeamSportId = (teamSportType) => {
  switch (teamSportType) {
    case "cricket":
      return 4;
    case "rugbyleague":
      return 12;
    case "rugbyunion":
      return 13;
    case "basketball":
      return 10;
    case "americanfootball":
      return 15;
    case "australianrules":
      return 9;
    case "golf":
      return 16;
    case "tennis":
      return 7;
    case "baseball":
      return 11;
    case "icehockey":
      return 17;
    case "boxing":
      return 6;
    case "mma":
      return 5;
    case "soccer":
      return 8;
    default:
      return 14;
  }
};

export const getSportsType = (teamSportType) => {
  let SportsType;

  switch (teamSportType) {
    case "cricket":
      SportsType = "crickets";
      break;
    case "rugbyleague":
    case "rugbyunion":
      SportsType = "rls";
      break;
    case "basketball":
      SportsType = "nba";
      break;
    case "americanfootball":
      SportsType = "afl";
      break;
    case "australianrules":
      SportsType = "ar";
      break;
    case "golf":
      SportsType = "golf";
      break;
    case "tennis":
      SportsType = "tennis";
      break;
    case "baseball":
      SportsType = "baseball";
      break;
    case "icehockey":
      SportsType = "icehockey";
      break;
    case "boxing":
      SportsType = "boxing";
      break;
    case "mma":
      SportsType = "mma";
      break;
    case "soccer":
      SportsType = "soccer";
      break;
    default:
      SportsType = "rls";
      break;
  }

  return SportsType;
};

export const getIdentifiers = (teamSportType, fullData) => {
  switch (teamSportType) {
    case "cricket":
      return fullData?.CricketIdentifiers;
    case "basketball":
      return fullData?.NBAIdentifiers;
    case "americanfootball":
      return fullData?.AFLIdentifiers;
    case "australianrules":
      return fullData?.ARIdentifiers;
    case "golf":
      return fullData?.GolfIdentifiers;
    case "tennis":
      return fullData?.TennisIdentifiers;
    case "baseball":
      return fullData?.BaseballIdentifiers;
    case "icehockey":
      return fullData?.IceHockeyIdentifiers;
    case "boxing":
      return fullData?.BoxingIdentifiers;
    case "mma":
      return fullData?.MMAIdentifiers;
    case "soccer":
      return fullData?.SoccerIdentifiers;
    default:
      return fullData?.RLIdentifiers;
  }
};

export const getPassTeamId = (teamSportType, oddsInfo) => {
  const getId = (teamId, oddLabelId, playerId) =>
    teamId ?? oddLabelId ?? playerId;

  switch (teamSportType) {
    case "cricket":
      return getId(
        oddsInfo?.CricketTeamId,
        oddsInfo?.CricketOddLabelId,
        oddsInfo?.CricketPlayerId,
      );
    case "basketball":
      return getId(
        oddsInfo?.NBATeamId,
        oddsInfo?.NBAOddLabelId,
        oddsInfo?.NBAPlayerId,
      );
    case "americanfootball":
      return getId(
        oddsInfo?.AFLTeamId,
        oddsInfo?.AFLOddLabelId,
        oddsInfo?.AFLPlayerId,
      );
    case "australianrules":
      return getId(
        oddsInfo?.ARTeamId,
        oddsInfo?.AROddLabelId,
        oddsInfo?.ARPlayerId,
      );
    case "golf":
      return getId(
        oddsInfo?.GolfTeamId,
        oddsInfo?.GolfOddLabelId,
        oddsInfo?.GolfPlayerId,
      );
    case "tennis":
      return getId(
        oddsInfo?.TennisTeamId,
        oddsInfo?.TennisOddLabelId,
        oddsInfo?.TennisPlayerId,
      );
    case "baseball":
      return getId(
        oddsInfo?.BaseballTeamId,
        oddsInfo?.BaseballOddLabelId,
        oddsInfo?.BaseballPlayerId,
      );
    case "icehockey":
      return getId(
        oddsInfo?.IceHockeyTeamId,
        oddsInfo?.IceHockeyOddLabelId,
        oddsInfo?.IceHockeyPlayerId,
      );
    case "boxing":
      return getId(
        oddsInfo?.BoxingTeamId,
        oddsInfo?.BoxingOddLabelId,
        oddsInfo?.BoxingPlayerId,
      );
    case "mma":
      return getId(
        oddsInfo?.MMATeamId,
        oddsInfo?.MMAOddLabelId,
        oddsInfo?.MMAPlayerId,
      );
    case "soccer":
      return getId(
        oddsInfo?.SoccerTeamId,
        oddsInfo?.SoccerOddLabelId,
        oddsInfo?.SoccerPlayerId,
      );
    default:
      return getId(
        oddsInfo?.RLTeamId,
        oddsInfo?.RLOddLabelId,
        oddsInfo?.RLPlayerId,
      );
  }
};

export const getBetOffers = (teamSportType, betOfferData) => {
  switch (teamSportType) {
    case "cricket":
      return betOfferData?.CricketBetOffers;
    case "basketball":
      return betOfferData?.NBABetOffers;
    case "americanfootball":
      return betOfferData?.AFLBetOffers;
    case "australianrules":
      return betOfferData?.ARBetOffers;
    case "golf":
      return betOfferData?.GolfBetOffers;
    case "tennis":
      return betOfferData?.TennisBetOffers;
    case "baseball":
      return betOfferData?.BaseballBetOffers;
    case "icehockey":
      return betOfferData?.IceHockeyBetOffers;
    case "boxing":
      return betOfferData?.BoxingBetOffers;
    case "mma":
      return betOfferData?.MMABetOffers;
    case "soccer":
      return betOfferData?.SoccerBetOffers;
    default:
      return betOfferData?.RLBetOffers;
  }
};

export const getSportsOdds = (teamSportType, sportOddsData) => {
  switch (teamSportType) {
    case "cricket":
      return sportOddsData?.CricketOdds;
    case "basketball":
      return sportOddsData?.NBAOdds;
    case "americanfootball":
      return sportOddsData?.AFLOdds;
    case "australianrules":
      return sportOddsData?.AROdds;
    case "golf":
      return sportOddsData?.GolfOdds;
    case "tennis":
      return sportOddsData?.TennisOdds;
    case "baseball":
      return sportOddsData?.BaseballOdds;
    case "icehockey":
      return sportOddsData?.IceHockeyOdds;
    case "boxing":
      return sportOddsData?.BoxingOdds;
    case "mma":
      return sportOddsData?.MMAOdds;
    case "soccer":
      return sportOddsData?.SoccerOdds;
    default:
      return sportOddsData?.RLOdds;
  }
};

export const getAllTeamOdds = (teamSportType, data) => {
  switch (teamSportType) {
    case "cricket":
      return data?.CricketBetOffers?.[0]?.CricketOdds;

    case "basketball":
      return data?.NBABetOffers?.[0]?.NBAOdds;

    case "americanfootball":
      return data?.AFLBetOffers?.[0]?.AFLOdds;

    case "australianrules":
      return data?.ARBetOffers?.[0]?.AROdds;

    case "golf":
      return data?.GolfBetOffers?.[0]?.GolfOdds;

    case "tennis":
      return data?.TennisBetOffers?.[0]?.TennisOdds;

    case "baseball":
      return data?.BaseballBetOffers?.[0]?.BaseballOdds;

    case "icehockey":
      return data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds;

    case "boxing":
      return data?.BoxingBetOffers?.[0]?.BoxingOdds;

    case "mma":
      return data?.MMABetOffers?.[0]?.MMAOdds;

    case "soccer":
      return data?.SoccerBetOffers?.[0]?.SoccerOdds;

    default:
      return data?.RLBetOffers?.[0]?.RLOdds;
  }
};

export const getSportMarket = (teamSportType, marketData) => {
  switch (teamSportType) {
    case "cricket":
      return marketData?.CricketMarket;
    case "basketball":
      return marketData?.NBAMarket;
    case "americanfootball":
      return marketData?.AFLMarket;
    case "australianrules":
      return marketData?.ARMarket;
    case "golf":
      return marketData?.GolfMarket;
    case "tennis":
      return marketData?.TennisMarket;
    case "baseball":
      return marketData?.BaseballMarket;
    case "icehockey":
      return marketData?.IceHockeyMarket;
    case "boxing":
      return marketData?.BoxingMarket;
    case "mma":
      return marketData?.MMAMarket;
    case "soccer":
      return marketData?.SoccerMarket;
    default:
      return marketData?.RLMarket;
  }
};

export const getOutRightTeams = (teamSportType, obj) => {
  switch (teamSportType) {
    case "cricket":
      return obj.CricketOutRightTeams;
    case "basketball":
      return obj.NBAOutRightTeams;
    case "americanfootball":
      return obj.AFLOutRightTeams;
    case "australianrules":
      return obj.AROutRightTeams;
    case "golf":
      return obj.GolfOutRightTeams;
    case "tennis":
      return obj.TennisOutRightTeams;
    case "baseball":
      return obj.BaseballOutRightTeams;
    case "icehockey":
      return obj.IceHockeyOutRightTeams;
    case "boxing":
      return obj.BoxingOutRightTeams;
    case "mma":
      return obj.MMAOutRightTeams;
    case "soccer":
      return obj.SoccerOutRightTeams;
    default:
      return obj.RLOutRightTeams;
  }
};

export const getOutRightTeamKey = (teamSportType) => {
  switch (teamSportType) {
    case "cricket":
      return "CricketTeam";
    case "basketball":
      return "NBATeam";
    case "americanfootball":
      return "AFLTeam";
    case "australianrules":
      return "ARTeam";
    case "golf":
      return "GolfTeam";
    case "tennis":
      return "TennisTeam";
    case "baseball":
      return "BaseballTeam";
    case "icehockey":
      return "IceHockeyTeam";
    case "boxing":
      return "BoxingTeam";
    case "mma":
      return "MMATeam";
    case "soccer":
      return "SoccerTeam";
    default:
      return "RLTeam";
  }
};

export const getLabelName = (teamSportType, SportsOddsData) => {
  let label;

  switch (teamSportType) {
    case "cricket":
      label = SportsOddsData?.CricketOddLabel;
      break;
    case "basketball":
      label = SportsOddsData?.NBAOddLabel;
      break;
    case "americanfootball":
      label = SportsOddsData?.AFLOddLabel;
      break;
    case "australianrules":
      label = SportsOddsData?.AROddLabel;
      break;
    case "golf":
      label = SportsOddsData?.GolfOddLabel;
      break;
    case "tennis":
      label = SportsOddsData?.TennisOddLabel;
      break;
    case "baseball":
      label = SportsOddsData?.BaseballOddLabel;
      break;
    case "icehockey":
      label = SportsOddsData?.IceHockeyOddLabel;
      break;
    case "boxing":
      label = SportsOddsData?.BoxingOddLabel;
      break;
    case "mma":
      label = SportsOddsData?.MMAOddLabel;
      break;
    case "soccer":
      label = SportsOddsData?.SoccerOddLabel;
      break;
    default:
      label = SportsOddsData?.RLOddLabel;
      break;
  }

  return label?.displayName ? label.displayName : label?.name;
};

export const getPlayerName = (teamSportType, playerData) => {
  let PlayerName;

  switch (teamSportType) {
    case "cricket":
      PlayerName = playerData?.CricketPlayer?.name;
      break;
    case "basketball":
      PlayerName = playerData?.NBAPlayer?.name;
      break;
    case "americanfootball":
      PlayerName = playerData?.AFLPlayer?.name;
      break;
    case "australianrules":
      PlayerName = playerData?.ARPlayer?.name;
      break;
    case "golf":
      PlayerName = playerData?.GolfPlayer?.name;
      break;
    case "tennis":
      PlayerName = playerData?.TennisPlayer?.name;
      break;
    case "baseball":
      PlayerName = playerData?.BaseballPlayer?.name;
      break;
    case "icehockey":
      PlayerName = playerData?.IceHockeyPlayer?.name;
      break;
    case "boxing":
      PlayerName = playerData?.BoxingPlayer?.name;
      break;
    case "mma":
      PlayerName = playerData?.MMAPlayer?.name;
      break;
    case "soccer":
      PlayerName = playerData?.SoccerPlayer?.name;
      break;
    default:
      PlayerName = playerData?.RLPlayer?.name;
      break;
  }

  return PlayerName;
};

export const getTeamIdKey = (teamSportType) => {
  switch (teamSportType) {
    case "cricket":
      return "CricketTeamId";
    case "basketball":
      return "NBATeamId";
    case "americanfootball":
      return "AFLTeamId";
    case "australianrules":
      return "ARTeamId";
    case "golf":
      return "GolfTeamId";
    case "tennis":
      return "TennisTeamId";
    case "baseball":
      return "BaseballTeamId";
    case "icehockey":
      return "IceHockeyTeamId";
    case "boxing":
      return "BoxingTeamId";
    case "mma":
      return "MMATeamId";
    case "soccer":
      return "SoccerTeamId";
    default:
      return "RLTeamId";
  }
};

export const getLabelKey = (teamSportType) => {
  switch (teamSportType) {
    case "cricket":
      return "CricketOddLabelId";
    case "basketball":
      return "NBAOddLabelId";
    case "americanfootball":
      return "AFLOddLabelId";
    case "australianrules":
      return "AROddLabelId";
    case "golf":
      return "GolfOddLabelId";
    case "tennis":
      return "TennisOddLabelId";
    case "baseball":
      return "BaseballOddLabelId";
    case "icehockey":
      return "IceHockeyOddLabelId";
    case "boxing":
      return "BoxingOddLabelId";
    case "mma":
      return "MMAOddLabelId";
    case "soccer":
      return "SoccerOddLabelId";
    default:
      return "RLOddLabelId";
  }
};

export const getPlayerKey = (teamSportType) => {
  switch (teamSportType) {
    case "cricket":
      return "CricketPlayerId";
    case "basketball":
      return "NBAPlayerId";
    case "americanfootball":
      return "AFLPlayerId";
    case "australianrules":
      return "ARPlayerId";
    case "golf":
      return "GolfPlayerId";
    case "tennis":
      return "TennisPlayerId";
    case "baseball":
      return "BaseballPlayerId";
    case "icehockey":
      return "IceHockeyPlayerId";
    case "boxing":
      return "BoxingPlayerId";
    case "mma":
      return "MMAPlayerId";
    case "soccer":
      return "SoccerPlayerId";
    default:
      return "RLPlayerId";
  }
};

export const getTournamentData = (teamSportType, obj) => {
  switch (teamSportType) {
    case "cricket":
      return {
        label: obj?.CricketTournament?.name,
        value: obj?.CricketTournament?.id,
      };
    case "basketball":
      return {
        label:
          obj?.NBATournament?.NBACategory === null
            ? obj?.NBATournament?.name
            : obj?.NBATournament?.name +
              " " +
              obj?.NBATournament?.NBACategory?.name,
        value: obj?.NBATournament?.id,
      };
    case "americanfootball":
      return {
        label: obj?.AFLTournament?.name,
        value: obj?.AFLTournament?.id,
      };
    case "australianrules":
      return {
        label: obj?.ARTournament?.name,
        value: obj?.ARTournament?.id,
      };
    case "golf":
      return {
        label: obj?.GolfTournament?.name,
        value: obj?.GolfTournament?.id,
      };
    case "tennis":
      return {
        label: obj?.TennisTournament?.name,
        value: obj?.TennisTournament?.id,
      };
    case "baseball":
      return {
        label: obj?.BaseballTournament?.name,
        value: obj?.BaseballTournament?.id,
      };
    case "icehockey":
      return {
        label: obj?.IceHockeyTournament?.name,
        value: obj?.IceHockeyTournament?.id,
      };
    case "boxing":
      return {
        label: obj?.BoxingTournament?.name,
        value: obj?.BoxingTournament?.id,
      };
    case "mma":
      return {
        label: obj?.MMATournament?.name,
        value: obj?.MMATournament?.id,
      };
    case "soccer":
      return {
        label: obj?.SoccerTournament?.name,
        value: obj?.SoccerTournament?.id,
      };
    default:
      return {
        label: obj?.RLTournament?.name,
        value: obj?.RLTournament?.id,
      };
  }
};

export const tournamentMapping = {
  4: "CricketTournament",
  10: "NBATournament",
  15: "AFLTournament",
  9: "ARTournament",
  16: "GolfTournament",
  7: "TennisTournament",
  11: "BaseballTournament",
  17: "IceHockeyTournament",
  6: "BoxingTournament",
  5: "MMATournament",
  8: "SoccerTournament",
};

export const getIdWiseTournamentName = (id) => {
  switch (id) {
    case 4:
      return "CricketTournamentId";
    case 10:
      return "NBATournamentId";
    case 15:
      return "AFLTournamentId";
    case 9:
      return "ARTournamentId";
    case 16:
      return "GolfTournamentId";
    case 7:
      return "TennisTournamentId";
    case 11:
      return "BaseballTournamentId";
    case 17:
      return "IceHockeyTournamentId";
    case 6:
      return "BoxingTournamentId";
    case 5:
      return "MMATournamentId";
    case 8:
      return "SoccerTournamentId";
    default:
      return "RLTournamentId";
  }
};

// TournamentName for sport

export const getTournamentName = (teamSportType, item) => {
  switch (teamSportType) {
    case "cricket":
      return item?.CricketTournament?.name;

    case "rugbyleague":
    case "rugbyunion":
    case "rugbyunionsevens":
      return item?.RLTournament?.name;

    case "americanfootball":
      return item?.AFLTournament?.name;

    case "australianrules":
      return item?.ARTournament?.name;

    case "golf":
      return item?.GolfTournament?.name;

    case "tennis":
      return item?.TennisTournament?.name;

    case "baseball":
      return item?.BaseballTournament?.name;

    case "icehockey":
      return item?.IceHockeyTournament?.name;

    case "boxing":
      return item?.BoxingTournament?.name;

    case "mma":
      return item?.MMATournament?.name;

    case "soccer":
      return item?.SoccerTournament?.name;

    case "basketball":
      const { NBATournament } = item || {};
      return NBATournament?.NBACategory === null
        ? NBATournament?.name
        : `${NBATournament?.name} ${NBATournament?.NBACategory?.name}`;

    default:
      return null;
  }
};

// Logo

export const fetchTeamlogo = (item, type) => {
  const team = type === "hometeam" ? item?.homeTeam : item?.awayTeam;
  const defaultImg = type === "hometeam" ? Brisbane : Melbourne;

  if (team) {
    const teamLogo = team?.flag?.includes("uploads")
      ? Config.mediaURL + team?.flag
      : team?.flag || defaultImg;

    return <img src={teamLogo} className="team-img" alt="team Icon" />;
  } else {
    return <img className="team-img" src={defaultImg} alt="team Icon" />;
  }
};

// fetchDayName

export const fetchDayName = (date) => {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var d = new Date(date);
  var dayName = days[d.getDay()];
  return dayName;
};

export const getIndividualTeamPassApi = (
  teamSportType,
  params,
  marketId,
  isPublic,
  isSmartBook,
) => {
  switch (teamSportType) {
    case "cricket":
      return `${isPublic}crickets/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "rugbyleague":
      return `${isPublic}rls/event/${params?.id}?SportId=12&marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "rugbyunion":
      return `${isPublic}rls/event/${params?.id}?SportId=13&marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "basketball":
      return `${isPublic}nba/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "americanfootball":
      return `${isPublic}afl/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "australianrules":
      return `${isPublic}ar/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "golf":
      return `${isPublic}golf/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "tennis":
      return `${isPublic}tennis/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "baseball":
      return `${isPublic}baseball/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "icehockey":
      return `${isPublic}icehockey/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "boxing":
      return `${isPublic}boxing/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "mma":
      return `${isPublic}mma/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    case "soccer":
      return `${isPublic}soccer/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
    default:
      return `${isPublic}rls/event/${params?.id}?SportId=14&marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
  }
};

// teamscore

export const getTeamScoreType = (teamSportType) => {
  switch (teamSportType) {
    case "cricket":
    case "soccer":
      return "ScoreBoard";
    case "basketball":
      return "NBAScores";
    case "americanfootball":
      return "AFLScores";
    case "australianrules":
      return "ARScores";
    case "golf":
      return "GolfScores";
    case "tennis":
      return "TennisScores";
    case "baseball":
      return "BaseballScores";
    case "icehockey":
      return "IceHockeyScores";
    case "boxing":
      return "BoxingScores";
    case "mma":
      return "MMAScores";
    default:
      return "RLScores";
  }
};

export const getApiUrl = (
  teamSportType,
  isPublic,
  selectedDate,
  IsLive,
  timezone,
  selectedseries = 0,
  selectedteam = 0,
  stepperCount = 0,
  outrights,
  marketId,
  EventPage,
  isFixture,
  showAllFixture,
  menuValue,
  isSmartBook,
) => {
  const endpoint =
    {
      cricket: "crickets",
      rugbyleague: "rls",
      rugbyunion: "rls",
      basketball: "nba",
      americanfootball: "afl",
      australianrules: "ar",
      golf: "golf",
      tennis: "tennis",
      baseball: "baseball",
      icehockey: "icehockey",
      boxing: "boxing",
      mma: "mma",
      soccer: "soccer",
    }[teamSportType] || "rls";

  const tournamentId = selectedseries
    ? `&${teamSportType.charAt(0).toUpperCase() + teamSportType.slice(1)}TournamentId=${selectedseries}`
    : "";
  const team = selectedteam ? `&teamId=${selectedteam}` : "";
  const round =
    stepperCount && ["rugbyleague", "rugbyunion"].includes(teamSportType)
      ? `&round=${stepperCount}`
      : "";
  const sport = ["rugbyleague", "rugbyunion"].includes(teamSportType)
    ? `&SportId=${teamSportType === "rugbyleague" ? "12" : "13"}`
    : "";

  const startDate =
    selectedDate ||
    (IsLive === "Future"
      ? moment().tz(timezone).add(1, "days").format("YYYY-MM-DD")
      : moment().tz(timezone).format("YYYY-MM-DD"));

  const endDate =
    selectedDate ||
    (IsLive === "IsLive" ? moment().tz(timezone).format("YYYY-MM-DD") : "");
  const status = IsLive === "IsLive" ? "inprogress" : "";

  return `${isPublic}${endpoint}/event?startDate=${startDate}&endDate=${endDate}${tournamentId}${team}${round}${sport}&timezone=${timezone}&oddCheck=true${outrights ? "&isOuright=true" : marketId ? `&marketId=${marketId}` : ""}&limit=20&offset=${EventPage}&isAdmin=${isFixture !== undefined ? isFixture : showAllFixture}&isHome=false&isGraph=${menuValue === "odds_flucs" ? "true" : ""}&status=${status}&${isSmartBook}`;
};

export const getApiUrlScrollToBottom = (
  teamSportType,
  isPublic,
  selectedDate,
  timezone,
  selectedseries,
  selectedteam,
  stepperCount,
  outrights,
  marketId,
  EventPage,
  showAllFixture,
  menuValue,
  isSmartBook,
) => {
  const endpoints = {
    cricket: ["crickets", "Cricket"],
    rugbyleague: ["rls", "RL", "12"],
    rugbyunion: ["rls", "RL", "13"],
    basketball: ["nba", "NBA"],
    americanfootball: ["afl", "AFL"],
    australianrules: ["ar", "AR"],
    golf: ["golf", "Golf"],
    tennis: ["tennis", "Tennis"],
    baseball: ["baseball", "Baseball"],
    icehockey: ["icehockey", "IceHockey"],
    boxing: ["boxing", "Boxing"],
    mma: ["mma", "MMA"],
    soccer: ["soccer", "Soccer"],
  };

  const [endpoint, tournamentPrefix, sportId] = endpoints[teamSportType] || [
    "rls",
    "RL",
    "14",
  ];
  const startDate =
    selectedDate === null
      ? moment().tz(timezone).format("YYYY-MM-DD")
      : selectedDate;
  const endDate = selectedDate === null ? "" : selectedDate;

  return `${isPublic}${endpoint}/event?startDate=${startDate}&endDate=${endDate}&${tournamentPrefix}TournamentId=${selectedseries === 0 ? "" : selectedseries}&teamId=${selectedteam === 0 ? "" : selectedteam}${sportId ? `&round=${stepperCount === 0 ? "" : stepperCount}&SportId=${sportId}` : ""}&timezone=${timezone}&oddCheck=true&marketId=${outrights ? "" : marketId}&isOuright=${outrights ? true : ""}&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${menuValue === "odds_flucs" ? "true" : ""}&${isSmartBook}`;
};

export const statsData = [
  { label: "Batting Stats", value: "batting" },
  { label: "Bowling Stats", value: "bowling" },
  { label: "Team Stats", value: "team" },
];

export const rugbyLeageStateData = [
  { label: "Player Stats", value: 0 },
  { label: "Team Stats", value: 1 },
];

export const battingStatsData = [
  { label: "Most Runs", value: "mostRuns" },
  { label: "Highest Scores", value: "highestScore" },
  { label: "Best Batting Average", value: "highestAvg" },
  { label: "Best Batting Strike Rate", value: "highestSr" },
  { label: "Most Hundreds", value: "mostHundreds" },
  { label: "Most Fifties", value: "mostFifties" },
  { label: "Most Fours", value: "mostFours" },
  { label: "Most Sixes", value: "mostSixes" },
];

export const bowlingStatsData = [
  { label: "Most Wickets", value: "mostWickets" },
  { label: "Best Bowling Average", value: "lowestAvg" },
  { label: "Best Bowling Figures", value: "bestBowlingInnings" },
  { label: "Most 5 Wickets Haul", value: "mostFiveWickets" },
  { label: "Best Bowling Economy", value: "lowestEcon" },
  { label: "Best Bowling Strike Rate", value: "lowestSr" },
];

export const teamStatsData = [
  { label: "Most Runs", value: "mostRuns" },
  { label: "Most Sixes", value: "mostSixes" },
  { label: "Most Fours", value: "mostFours" },
  { label: "Most Hundreds", value: "mostHundreds" },
  { label: "Most Fifties", value: "mostFifties" },
];

// dummy data

export const statsMainData = [
  {
    rank: 1,
    player: "Player 2",
    team: "Hobart Hurricanes",
    runs: 398,
    avg: 12,
    innings: 13,
    matches: 10,
    sr: 124,
    fours: 23,
    sixes: 2,
    hundreds: 0,
    fifties: 2,
  },
  {
    rank: 2,
    player: "Chris Lynn",
    team: "Melbourne Stars",
    runs: 473,
    avg: 26,
    innings: 11,
    matches: 10,
    sr: 136,
    fours: 20,
    sixes: 20,
    hundreds: 2,
    fifties: 5,
  },
  {
    rank: 3,
    player: "Player 6",
    team: "Hobart Hurricanes",
    runs: 417,
    avg: 34,
    innings: 7,
    matches: 10,
    sr: 102,
    fours: 43,
    sixes: 18,
    hundreds: 0,
    fifties: 2,
  },
  {
    rank: 4,
    player: "Player 7",
    team: "Sydney Thunder",
    runs: 310,
    avg: 25,
    innings: 12,
    matches: 10,
    sr: 87,
    fours: 17,
    sixes: 2,
    hundreds: 2,
    fifties: 2,
  },
  {
    rank: 5,
    player: "Aaron Hardie",
    team: "Hobart Hurricanes",
    runs: 376,
    avg: 25,
    innings: 7,
    matches: 10,
    sr: 105,
    fours: 49,
    sixes: 2,
    hundreds: 1,
    fifties: 4,
  },
  {
    rank: 6,
    player: "Chris Lynn",
    team: "Melbourne Renegades",
    runs: 280,
    avg: 8,
    innings: 7,
    matches: 10,
    sr: 99,
    fours: 21,
    sixes: 20,
    hundreds: 0,
    fifties: 3,
  },
  {
    rank: 7,
    player: "Josh Brown",
    team: "Brisbane Heat",
    runs: 330,
    avg: 39,
    innings: 7,
    matches: 10,
    sr: 145,
    fours: 22,
    sixes: 18,
    hundreds: 2,
    fifties: 1,
  },
  {
    rank: 8,
    player: "Player 3",
    team: "Sydney Sixers",
    runs: 382,
    avg: 50,
    innings: 9,
    matches: 10,
    sr: 112,
    fours: 35,
    sixes: 15,
    hundreds: 1,
    fifties: 4,
  },
  {
    rank: 9,
    player: "Matthew Short",
    team: "Adelaide Strikers",
    runs: 230,
    avg: 24,
    innings: 11,
    matches: 10,
    sr: 128,
    fours: 37,
    sixes: 5,
    hundreds: 2,
    fifties: 0,
  },
  {
    rank: 10,
    player: "Player 5",
    team: "Hobart Hurricanes",
    runs: 570,
    avg: 48,
    innings: 13,
    matches: 10,
    sr: 112,
    fours: 30,
    sixes: 17,
    hundreds: 0,
    fifties: 3,
  },
  {
    rank: 11,
    player: "Player 3",
    team: "Melbourne Stars",
    runs: 267,
    avg: 20,
    innings: 6,
    matches: 10,
    sr: 104,
    fours: 36,
    sixes: 18,
    hundreds: 1,
    fifties: 1,
  },
  {
    rank: 12,
    player: "Player 6",
    team: "Brisbane Heat",
    runs: 583,
    avg: 43,
    innings: 9,
    matches: 10,
    sr: 105,
    fours: 48,
    sixes: 19,
    hundreds: 2,
    fifties: 5,
  },
  {
    rank: 13,
    player: "Player 1",
    team: "Melbourne Renegades",
    runs: 600,
    avg: 12,
    innings: 5,
    matches: 10,
    sr: 139,
    fours: 30,
    sixes: 5,
    hundreds: 0,
    fifties: 4,
  },
  {
    rank: 14,
    player: "Matthew Short",
    team: "Hobart Hurricanes",
    runs: 591,
    avg: 34,
    innings: 9,
    matches: 10,
    sr: 82,
    fours: 8,
    sixes: 14,
    hundreds: 2,
    fifties: 1,
  },
  {
    rank: 15,
    player: "Player 5",
    team: "Melbourne Renegades",
    runs: 400,
    avg: 7,
    innings: 11,
    matches: 10,
    sr: 106,
    fours: 31,
    sixes: 8,
    hundreds: 0,
    fifties: 4,
  },
  {
    rank: 16,
    player: "Player 7",
    team: "Melbourne Renegades",
    runs: 392,
    avg: 33,
    innings: 14,
    matches: 10,
    sr: 142,
    fours: 38,
    sixes: 4,
    hundreds: 1,
    fifties: 1,
  },
  {
    rank: 17,
    player: "Player 5",
    team: "Brisbane Heat",
    runs: 506,
    avg: 10,
    innings: 14,
    matches: 10,
    sr: 122,
    fours: 22,
    sixes: 13,
    hundreds: 2,
    fifties: 2,
  },
  {
    rank: 18,
    player: "Chris Lynn",
    team: "Melbourne Stars",
    runs: 571,
    avg: 42,
    innings: 15,
    matches: 10,
    sr: 140,
    fours: 49,
    sixes: 14,
    hundreds: 0,
    fifties: 0,
  },
  {
    rank: 19,
    player: "Chris Lynn",
    team: "Perth Scorchers",
    runs: 298,
    avg: 39,
    innings: 13,
    matches: 10,
    sr: 127,
    fours: 35,
    sixes: 6,
    hundreds: 2,
    fifties: 5,
  },
  {
    rank: 20,
    player: "Player 7",
    team: "Melbourne Stars",
    runs: 324,
    avg: 49,
    innings: 8,
    matches: 10,
    sr: 81,
    fours: 37,
    sixes: 2,
    hundreds: 2,
    fifties: 3,
  },
  {
    rank: 21,
    player: "Chris Lynn",
    team: "Perth Scorchers",
    runs: 459,
    avg: 13,
    innings: 10,
    matches: 10,
    sr: 84,
    fours: 25,
    sixes: 9,
    hundreds: 2,
    fifties: 3,
  },
  {
    rank: 22,
    player: "Player 8",
    team: "Brisbane Heat",
    runs: 371,
    avg: 9,
    innings: 13,
    matches: 10,
    sr: 112,
    fours: 23,
    sixes: 4,
    hundreds: 2,
    fifties: 3,
  },
  {
    rank: 23,
    player: "Player 7",
    team: "Hobart Hurricanes",
    runs: 283,
    avg: 26,
    innings: 15,
    matches: 10,
    sr: 139,
    fours: 39,
    sixes: 6,
    hundreds: 2,
    fifties: 5,
  },
  {
    rank: 24,
    player: "Player 1",
    team: "Hobart Hurricanes",
    runs: 547,
    avg: 16,
    innings: 5,
    matches: 10,
    sr: 100,
    fours: 16,
    sixes: 18,
    hundreds: 2,
    fifties: 4,
  },
  {
    rank: 25,
    player: "Player 6",
    team: "Sydney Thunder",
    runs: 390,
    avg: 12,
    innings: 13,
    matches: 10,
    sr: 136,
    fours: 23,
    sixes: 0,
    hundreds: 1,
    fifties: 1,
  },
  {
    rank: 26,
    player: "Aaron Hardie",
    team: "Perth Scorchers",
    runs: 361,
    avg: 36,
    innings: 13,
    matches: 10,
    sr: 93,
    fours: 30,
    sixes: 19,
    hundreds: 1,
    fifties: 3,
  },
  {
    rank: 27,
    player: "Player 3",
    team: "Adelaide Strikers",
    runs: 474,
    avg: 31,
    innings: 10,
    matches: 10,
    sr: 83,
    fours: 11,
    sixes: 8,
    hundreds: 0,
    fifties: 0,
  },
  {
    rank: 28,
    player: "Chris Lynn",
    team: "Brisbane Heat",
    runs: 304,
    avg: 23,
    innings: 7,
    matches: 10,
    sr: 98,
    fours: 32,
    sixes: 10,
    hundreds: 2,
    fifties: 3,
  },
  {
    rank: 29,
    player: "Aaron Hardie",
    team: "Sydney Sixers",
    runs: 425,
    avg: 30,
    innings: 11,
    matches: 10,
    sr: 156,
    fours: 23,
    sixes: 18,
    hundreds: 0,
    fifties: 3,
  },
  {
    rank: 30,
    player: "Josh Brown",
    team: "Melbourne Stars",
    runs: 238,
    avg: 27,
    innings: 12,
    matches: 10,
    sr: 154,
    fours: 6,
    sixes: 8,
    hundreds: 0,
    fifties: 3,
  },
  {
    rank: 31,
    player: "Player 6",
    team: "Sydney Thunder",
    runs: 550,
    avg: 10,
    innings: 5,
    matches: 10,
    sr: 113,
    fours: 41,
    sixes: 19,
    hundreds: 2,
    fifties: 0,
  },
  {
    rank: 32,
    player: "Player 6",
    team: "Perth Scorchers",
    runs: 535,
    avg: 38,
    innings: 11,
    matches: 10,
    sr: 140,
    fours: 42,
    sixes: 3,
    hundreds: 2,
    fifties: 0,
  },
  {
    rank: 33,
    player: "Josh Brown",
    team: "Adelaide Strikers",
    runs: 301,
    avg: 45,
    innings: 5,
    matches: 10,
    sr: 102,
    fours: 41,
    sixes: 3,
    hundreds: 2,
    fifties: 1,
  },
  {
    rank: 34,
    player: "Player 8",
    team: "Melbourne Stars",
    runs: 211,
    avg: 29,
    innings: 15,
    matches: 10,
    sr: 151,
    fours: 26,
    sixes: 16,
    hundreds: 1,
    fifties: 3,
  },
  {
    rank: 35,
    player: "Player 5",
    team: "Adelaide Strikers",
    runs: 546,
    avg: 8,
    innings: 7,
    matches: 10,
    sr: 94,
    fours: 33,
    sixes: 15,
    hundreds: 2,
    fifties: 0,
  },
  {
    rank: 36,
    player: "Player 1",
    team: "Melbourne Renegades",
    runs: 279,
    avg: 16,
    innings: 6,
    matches: 10,
    sr: 125,
    fours: 32,
    sixes: 3,
    hundreds: 1,
    fifties: 5,
  },
  {
    rank: 37,
    player: "Aaron Hardie",
    team: "Sydney Thunder",
    runs: 524,
    avg: 34,
    innings: 7,
    matches: 10,
    sr: 135,
    fours: 21,
    sixes: 17,
    hundreds: 1,
    fifties: 2,
  },
  {
    rank: 38,
    player: "Josh Brown",
    team: "Perth Scorchers",
    runs: 545,
    avg: 33,
    innings: 11,
    matches: 10,
    sr: 103,
    fours: 19,
    sixes: 9,
    hundreds: 2,
    fifties: 3,
  },
  {
    rank: 39,
    player: "Player 5",
    team: "Melbourne Stars",
    runs: 347,
    avg: 34,
    innings: 9,
    matches: 10,
    sr: 117,
    fours: 30,
    sixes: 1,
    hundreds: 0,
    fifties: 4,
  },
  {
    rank: 40,
    player: "Josh Brown",
    team: "Hobart Hurricanes",
    runs: 323,
    avg: 43,
    innings: 14,
    matches: 10,
    sr: 94,
    fours: 23,
    sixes: 6,
    hundreds: 2,
    fifties: 4,
  },
  {
    rank: 41,
    player: "Player 5",
    team: "Melbourne Stars",
    runs: 544,
    avg: 37,
    innings: 14,
    matches: 10,
    sr: 104,
    fours: 32,
    sixes: 20,
    hundreds: 1,
    fifties: 1,
  },
  {
    rank: 42,
    player: "Player 2",
    team: "Melbourne Stars",
    runs: 352,
    avg: 34,
    innings: 6,
    matches: 10,
    sr: 82,
    fours: 33,
    sixes: 5,
    hundreds: 0,
    fifties: 1,
  },
  {
    rank: 43,
    player: "Player 6",
    team: "Hobart Hurricanes",
    runs: 419,
    avg: 9,
    innings: 11,
    matches: 10,
    sr: 81,
    fours: 42,
    sixes: 0,
    hundreds: 1,
    fifties: 5,
  },
  {
    rank: 44,
    player: "Player 2",
    team: "Melbourne Stars",
    runs: 385,
    avg: 31,
    innings: 12,
    matches: 10,
    sr: 140,
    fours: 50,
    sixes: 6,
    hundreds: 2,
    fifties: 2,
  },
  {
    rank: 45,
    player: "Josh Brown",
    team: "Perth Scorchers",
    runs: 372,
    avg: 18,
    innings: 13,
    matches: 10,
    sr: 128,
    fours: 16,
    sixes: 18,
    hundreds: 2,
    fifties: 4,
  },
  {
    rank: 46,
    player: "Player 1",
    team: "Sydney Sixers",
    runs: 578,
    avg: 17,
    innings: 13,
    matches: 10,
    sr: 129,
    fours: 32,
    sixes: 11,
    hundreds: 2,
    fifties: 0,
  },
  {
    rank: 47,
    player: "Player 1",
    team: "Hobart Hurricanes",
    runs: 341,
    avg: 50,
    innings: 8,
    matches: 10,
    sr: 81,
    fours: 38,
    sixes: 5,
    hundreds: 0,
    fifties: 4,
  },
  {
    rank: 48,
    player: "Player 5",
    team: "Perth Scorchers",
    runs: 586,
    avg: 36,
    innings: 11,
    matches: 10,
    sr: 128,
    fours: 15,
    sixes: 6,
    hundreds: 1,
    fifties: 2,
  },
  {
    rank: 49,
    player: "Aaron Hardie",
    team: "Adelaide Strikers",
    runs: 472,
    avg: 46,
    innings: 11,
    matches: 10,
    sr: 108,
    fours: 10,
    sixes: 13,
    hundreds: 2,
    fifties: 4,
  },
  {
    rank: 50,
    player: "Player 5",
    team: "Sydney Sixers",
    runs: 347,
    avg: 18,
    innings: 10,
    matches: 10,
    sr: 156,
    fours: 17,
    sixes: 0,
    hundreds: 1,
    fifties: 3,
  },
  {
    rank: 51,
    player: "Player 5",
    team: "Sydney Thunder",
    runs: 383,
    avg: 23.9,
    innings: 7,
    matches: 10,
    sr: 129,
    fours: 45,
    sixes: 18,
    hundreds: 1,
    fifties: 2,
  },
  {
    rank: 52,
    player: "Matthew Short",
    team: "Sydney Sixers",
    runs: 285,
    avg: 14,
    innings: 12,
    matches: 10,
    sr: 94,
    fours: 49,
    sixes: 9,
    hundreds: 2,
    fifties: 4,
  },
  {
    rank: 53,
    player: "Player 2",
    team: "Sydney Sixers",
    runs: 366,
    avg: 34,
    innings: 6,
    matches: 10,
    sr: 83,
    fours: 33,
    sixes: 10,
    hundreds: 0,
    fifties: 2,
  },
  {
    rank: 54,
    player: "Matthew Short",
    team: "Brisbane Heat",
    runs: 458,
    avg: 36,
    innings: 5,
    matches: 10,
    sr: 143,
    fours: 39,
    sixes: 16,
    hundreds: 0,
    fifties: 4,
  },
  {
    rank: 55,
    player: "Player 7",
    team: "Adelaide Strikers",
    runs: 241,
    avg: 23,
    innings: 10,
    matches: 10,
    sr: 125,
    fours: 18,
    sixes: 3,
    hundreds: 2,
    fifties: 0,
  },
  {
    rank: 56,
    player: "Player 7",
    team: "Melbourne Stars",
    runs: 356,
    avg: 18,
    innings: 7,
    matches: 10,
    sr: 128,
    fours: 5,
    sixes: 5,
    hundreds: 2,
    fifties: 5,
  },
  {
    rank: 57,
    player: "Player 8",
    team: "Adelaide Strikers",
    runs: 266,
    avg: 33,
    innings: 13,
    matches: 10,
    sr: 122,
    fours: 21,
    sixes: 3,
    hundreds: 1,
    fifties: 2,
  },
  {
    rank: 58,
    player: "Player 4",
    team: "Adelaide Strikers",
    runs: 449,
    avg: 39,
    innings: 5,
    matches: 10,
    sr: 92,
    fours: 27,
    sixes: 18,
    hundreds: 2,
    fifties: 4,
  },
  {
    rank: 59,
    player: "Player 2",
    team: "Brisbane Heat",
    runs: 283,
    avg: 44,
    innings: 15,
    matches: 10,
    sr: 127,
    fours: 49,
    sixes: 3,
    hundreds: 0,
    fifties: 0,
  },
  {
    rank: 60,
    player: "Aaron Hardie",
    team: "Adelaide Strikers",
    runs: 541,
    avg: 48,
    innings: 9,
    matches: 10,
    sr: 92,
    fours: 48,
    sixes: 16,
    hundreds: 0,
    fifties: 1,
  },
];

export const dummyData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const headerCricketMenuData = [
  {
    id: 1,
    menu: "Home",
    urlValue: "home",
  },
  {
    id: 2,
    menu: "News",
    urlValue: "news",
  },
  {
    id: 3,
    menu: "Fixtures & Results",
    urlValue: "fixtures_results",
  },
  {
    id: 4,
    menu: "Odds Flucs",
    urlValue: "odds_flucs",
  },
  {
    id: 5,
    menu: "Stats",
    urlValue: "stats",
  },
  {
    id: 6,
    menu: "Rankings",
    urlValue: "rankings",
  },
];

export const headerRugbyLeagueMenu = [
  {
    id: 1,
    menu: "Home",
    urlValue: "home",
  },

  {
    id: 2,
    menu: "News",
    urlValue: "news",
  },

  {
    id: 3,
    menu: "Fixtures & Results",
    urlValue: "fixtures_results",
  },
  {
    id: 4,
    menu: "Odds Flucs",
    urlValue: "odds_flucs",
  },

  // {
  //   id: 5,
  //   menu: "Stats",
  //   urlValue: "stats",
  // },

  // {
  //   id: 6,
  //   menu: "Ladder",
  //   urlValue: "ladder",
  // },
  // {
  //   id: 7,
  //   menu: "Past Data",
  //   urlValue: "past_data",
  // },
];

export const australianrulesMenu = [
  {
    id: 1,
    menu: "Home",
    urlValue: "home",
  },

  {
    id: 2,
    menu: "News",
    urlValue: "news",
  },

  {
    id: 3,
    menu: "Fixtures & Results",
    urlValue: "fixtures_results",
  },
  {
    id: 4,
    menu: "Odds Flucs",
    urlValue: "odds_flucs",
  },
  // {
  //   id: 5,
  //   menu: "Stats",
  //   urlValue: "stats",
  // },

  // {
  //   id: 6,
  //   menu: "Ladder",
  //   urlValue: "ladder",
  // },
];

export const NRLRightSampleData = [
  {
    position: 1,
    team: "Melbourne Storm",
    played: 19,
    wins: 19,
    losses: 5,
    draws: 0,
    points: 44,
    difference: 243,
  },
  {
    position: 2,
    team: "Penrith Panthers",
    played: 19,
    wins: 17,
    losses: 7,
    draws: 0,
    points: 40,
    difference: 186,
  },
  {
    position: 3,
    team: "Sydney Roosters",
    played: 19,
    wins: 16,
    losses: 8,
    draws: 0,
    points: 38,
    difference: 275,
  },
  {
    position: 4,
    team: "Cronulla-Sutherland Sharks",
    played: 19,
    wins: 16,
    losses: 8,
    draws: 0,
    points: 36,
    difference: 222,
  },
  {
    position: 5,
    team: "North Queensland Cowboys",
    played: 19,
    wins: 15,
    losses: 8,
    draws: 0,
    points: 34,
    difference: 89,
  },
  {
    position: 6,
    team: "Melbourne Storm",
    played: 19,
    wins: 14,
    losses: 9,
    draws: 0,
    points: 33,
    difference: 96,
  },
  {
    position: 7,
    team: "Melbourne Storm",
    played: 19,
    wins: 13,
    losses: 10,
    draws: 0,
    points: 33,
    difference: 113,
  },
];

export const AFLRightSampleData = [
  {
    position: 1,
    team: "Melbourne Storm",
    played: 19,
    wins: 19,
    losses: 5,
  },
  {
    position: 2,
    team: "Penrith Panthers",
    played: 19,
    wins: 17,
    losses: 7,
  },
  {
    position: 3,
    team: "Sydney Roosters",
    played: 19,
    wins: 16,
    losses: 8,
  },
  {
    position: 4,
    team: "Cronulla-Sutherland Sharks",
    played: 19,
    wins: 16,
    losses: 8,
  },
  {
    position: 5,
    team: "North Queensland Cowboys",
    played: 19,
    wins: 15,
    losses: 8,
  },
  {
    position: 6,
    team: "Melbourne Storm",
    played: 19,
    wins: 14,
    losses: 9,
  },
  {
    position: 7,
    team: "Melbourne Storm",
    played: 19,
    wins: 13,
    losses: 10,
  },
];

export const NRLMockData = [
  {
    rank: 1,
    team: "Melbourne Storm",
    pts: 14,
    T: 14,
    GLS: 14,
    FG: 14,
    "P%": 14,
    "SC%": 14,
    SUP: 14,
    LE: 14,
    LB: 14,
    PCM: 14,
    TB: 14,
    DR: 14,
    RM: 14,
    RUN: 14,
    KRM: 14,
    DHR: 14,
    TAC: 14,
  },
  {
    rank: 2,
    team: "Penrith Panthers",
    pts: 13,
    T: 13,
    GLS: 13,
    FG: 13,
    "P%": 13,
    "SC%": 13,
    SUP: 13,
    LE: 13,
    LB: 13,
    PCM: 13,
    TB: 13,
    DR: 13,
    RM: 13,
    RUN: 13,
    KRM: 13,
    DHR: 13,
    TAC: 13,
  },
  {
    rank: 3,
    team: "Sydney Roosters",
    pts: 11,
    T: 11,
    GLS: 11,
    FG: 11,
    "P%": 11,
    "SC%": 11,
    SUP: 11,
    LE: 11,
    LB: 11,
    PCM: 11,
    TB: 11,
    DR: 11,
    RM: 11,
    RUN: 11,
    KRM: 11,
    DHR: 11,
    TAC: 11,
  },
  {
    rank: 4,
    team: "Cronulla-Sutherland Sharks",
    pts: 8,
    T: 8,
    GLS: 8,
    FG: 8,
    "P%": 8,
    "SC%": 8,
    SUP: 8,
    LE: 8,
    LB: 8,
    PCM: 8,
    TB: 8,
    DR: 8,
    RM: 8,
    RUN: 8,
    KRM: 8,
    DHR: 8,
    TAC: 8,
  },
  {
    rank: 5,
    team: "North Queensland Cowboys",
    pts: 8,
    T: 8,
    GLS: 8,
    FG: 8,
    "P%": 8,
    "SC%": 8,
    SUP: 8,
    LE: 8,
    LB: 8,
    PCM: 8,
    TB: 8,
    DR: 8,
    RM: 8,
    RUN: 8,
    KRM: 8,
    DHR: 8,
    TAC: 8,
  },
  {
    rank: 6,
    team: "Canterbury-Bankstown Bulldogs",
    pts: 6,
    T: 6,
    GLS: 6,
    FG: 6,
    "P%": 6,
    "SC%": 6,
    SUP: 6,
    LE: 6,
    LB: 6,
    PCM: 6,
    TB: 6,
    DR: 6,
    RM: 6,
    RUN: 6,
    KRM: 6,
    DHR: 6,
    TAC: 6,
  },
];

export const aflStats = [
  {
    rank: 1,
    team: "Brisbane Lions",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 2,
    team: "Essendon",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 3,
    team: "Western Bulldogs",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 4,
    team: "Melbourne",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 5,
    team: "Sydney",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 6,
    team: "Collingwood",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 7,
    team: "Hawthorn Football",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 8,
    team: "Gold Coast Suns",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 9,
    team: "Port Adelaide",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 10,
    team: "Dockers",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 11,
    team: "Brisbane Broncos",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 12,
    team: "Adelaide Crows",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 13,
    team: "Richmonds",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 14,
    team: "West Coast Eagles",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 15,
    team: "North Melbourne",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 16,
    team: "Greater Western Sydney Giants",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
  {
    rank: 17,
    team: "Geelong Cats",
    disp: 0,
    k: 0,
    hb: 0,
    i50: 0,
    dePercent: 0,
    cl: 0,
    r50: 0,
    ek: 0,
    kePercent: 0,
    kH: 0,
    ed: 0,
    tkl: 0,
    t50: 0,
    pa: 0,
  },
];

export const SPORTS_TYPE = {
  rugbyLeague: getTeamSportId("rugbyleague"),
  circket: getTeamSportId("cricket"),
  australianrules: getTeamSportId("australianrules"),
};
