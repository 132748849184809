import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import WhiteBulb from "../../../../assets/images/icons/whiteBulb.png";
import { fetchFromStorage } from "src/helpers/context";
import { useDispatch } from "react-redux";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";

const ViewExpertTips = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleViewExpertTips = () => {
    if (handleRestrictedUser()) {
      navigate(`/teamsports/${params?.sportsTypeName}/sport-expert-tips`);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        `/teamsports/${params?.sportsTypeName}/sport-expert-tips`,
      );
    }
  };

  return (
    <>
      {(params?.sportsTypeName === "cricket" ||
        params?.sportsTypeName === "rugbyleague" ||
        params?.sportsTypeName === "australianrules") && (
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          className="exprt-tips-btn"
          onClick={() => {
            handleViewExpertTips();
          }}
        >
          <Box className="exprt-btn-wrap">
            <img src={WhiteBulb} alt="white bulb" className="white-bulb-img" />
            <span className="btn-txt">View Expert Tips</span>
          </Box>
        </Button>
      )}
    </>
  );
};

export default ViewExpertTips;
