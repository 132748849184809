import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import { Config } from "src/helpers/context";
import moment from "moment";
import "./oddsStatusModal.scss";
import Loader from "src/components/Loader";

const ToteData = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: 10 },
  { id: 11 },
  { id: 12 },
  { id: 13 },
  { id: 14 },
  { id: 15 },
];

const OddsStatusModal = ({ handleOddsStatusModalClose }) => {
  const [oddsStatusLoading, setOddsStatusLoading] = useState(false);
  const [oddsStatusList, setOddsStatusList] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [sortType, setSortType] = useState("name");
  const [sortName, setSortName] = useState(true);
  const [sortStatus, setSortStatus] = useState(false);

  useEffect(() => {
    fetchOddsStatusList("name", true);
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchOddsStatusList = async (type, order) => {
    setOddsStatusLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `odds/oddStatus?sortBy=${type ? type : ""}&order=${
          order ? "ASC" : "DESC"
        }`,
      );
      if (status === 200) {
        setOddsStatusList(data?.result);
        setOddsStatusLoading(false);
      } else {
        setOddsStatusLoading(false);
      }
    } catch (err) {
      setOddsStatusLoading(false);
    }
  };

  /**
   * Sort odds status list based on type (name or status)
   * @param {string} type - name or status
   */
  const sortLabelHandler = (type) => {
    setSortType(type);
    if (type === "name") {
      fetchOddsStatusList(type, !sortName);
      setSortName(!sortName);
      setSortStatus(true); // reset status sort
    } else {
      fetchOddsStatusList(type, !sortStatus);
      setSortName(true); // reset name sort
      setSortStatus(!sortStatus);
    }
  };
  return (
    <>
      <Box className="tote-table-wrap">
        {oddsStatusLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : (
          <TableContainer id="details-table" className="tote-details-table">
            <Table stickyHeader className="tote-table">
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => sortLabelHandler("name")}
                    align="left"
                  >
                    <Box className="sorting-cell sorting-cell-bookmakers">
                      Bookmakers{" "}
                      <span className="sorting-arrow-wrap">
                        <SortingArrow
                          className={`${
                            sortType == "name" && sortName == true
                              ? "active"
                              : ""
                          }`}
                        />
                        <SortingArrow
                          className={`${
                            sortType == "name" && sortName == false
                              ? "active"
                              : ""
                          } down-arrow`}
                        />
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => sortLabelHandler("status")}>
                    <Box className="sorting-cell">
                      Status{" "}
                      <span className="sorting-arrow-wrap">
                        <SortingArrow
                          className={`${
                            sortType == "status" && sortStatus == true
                              ? "active"
                              : ""
                          }`}
                        />
                        <SortingArrow
                          className={`${
                            sortType == "status" && sortStatus == false
                              ? "active"
                              : ""
                          } down-arrow`}
                        />
                      </span>
                    </Box>
                  </TableCell>
                  {screenWidth > 799 && <TableCell>Updated</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {oddsStatusList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="bookkeeper-logo-wrap">
                      <Box className="bookkeeper-wrap">
                        <Box className="bookkeeper-icon">
                          {item?.BookKeeper?.small_logo?.includes("uploads") ? (
                            <img
                              src={
                                Config.mediaURL + item?.BookKeeper?.small_logo
                              }
                              alt="icon"
                            />
                          ) : (
                            <img
                              src={item?.BookKeeper?.small_logo}
                              alt="icon"
                            />
                          )}
                        </Box>
                        <Box className="mobile-bookkeeper-list">
                          <span className="bookkeeper-name">
                            {item?.BookKeeper?.name}
                          </span>
                          {screenWidth > 799 ? (
                            <></>
                          ) : (
                            <span className="bookkeeper-updatedAt">
                              {item?.updatedAt
                                ? `Updated - ${moment(item?.updatedAt).format("DD/MM/YYYY")}`
                                : ""}
                            </span>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        className={
                          item?.status
                            ? "status-code available-status-code"
                            : "status-code not-status-code"
                        }
                      >
                        <span
                          className={
                            item?.status ? "available" : "not-available"
                          }
                        >
                          {item?.status ? "Available" : "Not available"}
                        </span>
                      </Box>
                    </TableCell>
                    {screenWidth > 799 && (
                      <TableCell>
                        {item?.updatedAt
                          ? moment(item?.updatedAt).format("DD/MM/YYYY")
                          : ""}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default OddsStatusModal;
