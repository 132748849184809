import { Box } from "@mui/material";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";

const SortIcons = ({ isSorted }) => {
  return (
    <Box className="sort-icon-wrap">
      {/* Ascending Arrow */}
      <span>
        <SortingArrow className={isSorted === "asc" ? "asc-sort" : ""} />
      </span>
      {/* Descending Arrow */}
      <span className="down-arrow">
        <SortingArrow className={isSorted === "desc" ? "desc-sort" : ""} />
      </span>
    </Box>
  );
};
export default SortIcons;
