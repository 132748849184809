import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Loader from "src/components/Loader";
import DefaultImg from "../../../../assets/images/smartb_default.png";
import { Config, fetchFromStorage } from "src/helpers/context";
import { useDispatch, useSelector } from "react-redux";

import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useNavigate } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { toast } from "react-toastify";
import DialogBox from "../../UI/dialogBox";
import "../../Home/HomeComponents/publicTippingComp.scss";
import TippingSupportedTeamModal from "../../UI/tippingSupportedTeamModal";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

const JoinCompTable = ({
  tableData,
  isLoading,
  compCount,
  compPage,
  handleMoreData,
  handleLessData,
}) => {
  const { setApiInfo } = useAutoSaveStore();
  const [joinOpen, setJoinOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openSupportedTeamModal, setOpenSupportedTeamModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redux_userData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };
  const handleJoin = async (e, item, id) => {
    if (handleRestrictedUser()) {
      setSelectedItem(item);
      // handleDeleteModal();
      setSelectedId(id);
      handleSupportedTeamModal();
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        window.location.pathname + window.location.search,
      );
      setApiInfo({
        api: `/tipping/addLadder/${item?.code}`,
        payload: { selectedTeam: null },
        method: "put",
      });
    }
  };

  const handleDeleteModal = () => {
    setJoinOpen(true);
  };
  const handleJoinClose = () => {
    setJoinOpen(false);
    setSelectedItem("");
    setSelectedId("");
  };
  const NavigateTocomp = (item) => {
    const tournamentSlug = item?.Tournament?.name
      ? item?.Tournament?.name.trim().toLowerCase().replaceAll(" ", "-")
      : "tournament";
    const sportSlug = item?.Sport?.sportName
      ? item?.Sport?.sportName.trim().toLowerCase().replaceAll(" ", "-")
      : "sports";

    setTimeout(() => {
      navigate(
        `/tipscompetition/my-comps/${sportSlug}/${tournamentSlug}/${item?.SportId}/${item?.Tournament?.id}/${item?.id}?userId=${item?.UserId}`,
      );
    }, 1000);
  };

  const handleNavigateToPublicComp = () => {
    navigate("/tipscompetition/public/tips");
  };

  const handleAddComps = async (item) => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;

    if (isLogin) {
      try {
        const { status, data } = await axiosInstance.put(
          `/tipping/addLadder/${item?.code}`,
        );
        if (status === 200) {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
          NavigateTocomp(item);
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        window.location.pathname + window.location.search,
      );
    }
  };

  const handleSupportedTeamModal = () => {
    setOpenSupportedTeamModal(true);
  };

  const handleSupportedTeamModalClose = () => {
    setOpenSupportedTeamModal(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : (
        <Box className="comp-component-wrap">
          <TableContainer className="comp-container">
            <Table className="comp-table">
              <TableHead className="comp-table-header-wrap">
                <TableRow>
                  <TableCell className="comp-header comp-name-header">
                    {screenWidth > 799 ? "Comp name" : "Comp name/Owner"}
                  </TableCell>
                  {screenWidth > 799 && (
                    <TableCell className="comp-header align-left">
                      Owner
                    </TableCell>
                  )}
                  <TableCell className="comp-header">Starting Round</TableCell>
                  <TableCell className="comp-header">Tipsters</TableCell>
                  <TableCell className="comp-header">Join</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="comp-table-body-wrap">
                {tableData?.length > 0 ? (
                  tableData?.map((row) => (
                    <TableRow key={row?.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="comp-name-wrap"
                      >
                        <Box className="name-img-wrap">
                          <Box className="comp-img-wrap">
                            <img
                              src={
                                row?.Media?.filePath
                                  ? Config.countryMediaURL +
                                    row?.Media?.filePath
                                  : DefaultImg
                              }
                              onError={(e) => {
                                e.target.src = DefaultImg;
                              }}
                              alt="comp"
                            />
                          </Box>
                          <Box className="comp-owner-wrap">
                            <Box className="comp-name">
                              {row?.competitionName}
                            </Box>
                            {screenWidth < 800 && (
                              <Box className="owner-name">
                                {row?.User?.firstName
                                  ? row?.User?.firstName +
                                    " " +
                                    row?.User?.lastName
                                  : ""}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                      {screenWidth > 799 && (
                        <TableCell className="owner-name">
                          {row?.User?.firstName
                            ? row?.User?.firstName + " " + row?.User?.lastName
                            : ""}
                        </TableCell>
                      )}
                      <TableCell>
                        {row?.startingRound ? row?.startingRound : "-"}
                      </TableCell>
                      <TableCell>
                        {row?.totalTipper ? row?.totalTipper : "-"}
                      </TableCell>
                      <TableCell>
                        {row?.isJoined || redux_userData?.id == row?.UserId ? (
                          <Button
                            className="comp-btn view-btn"
                            onClick={() => NavigateTocomp(row)}
                          >
                            {" "}
                            View{" "}
                          </Button>
                        ) : (
                          <Button
                            className="comp-btn join-btn"
                            onClick={(e) => {
                              handleJoin(e, row, row?.id);
                            }}
                          >
                            Join
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ padding: "12px 6px" }}>
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {tableData?.length > 0 ? (
            <Box className="view-more">
              {compPage === Number(tableData?.length / 10) ? (
                <span
                  className="view-more-text"
                  onClick={() => handleMoreData()}
                >
                  View More
                </span>
              ) : (
                <></>
              )}
              {compPage >= 1 && tableData?.length > 10 ? (
                <span
                  className="view-more-text view-lese-text"
                  onClick={() => handleLessData()}
                >
                  View Less
                </span>
              ) : (
                <></>
              )}
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )}
      <DialogBox
        open={joinOpen}
        onClose={handleJoinClose}
        handleClose={handleJoinClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to Join?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={(e) => {
                    handleAddComps(e, selectedItem);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleJoinClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <TippingSupportedTeamModal
        open={openSupportedTeamModal}
        handleClose={handleSupportedTeamModalClose}
        title={""}
        closeIcon={true}
        // handleJoin={handleAddComps}
        tournamentId={selectedItem?.tournamentId}
        sportId={selectedItem?.SportId}
        compId={selectedItem?.id}
        compCode={selectedItem?.code}
        callBackFunc={() => NavigateTocomp(selectedItem)}
      />
    </>
  );
};

export default JoinCompTable;
