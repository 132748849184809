import { createColumnHelper } from "@tanstack/react-table";
import { Box, Typography } from "@mui/material";
import { Config } from "src/helpers/context";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import SortIcons from "src/components/Common/Icons/SortIcons";
const columnHelper = createColumnHelper();

export const BowlingStatsCloumn = [
  columnHelper.accessor("rank", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        style={{ cursor: "pointer" }} // Add pointer cursor to indicate clickable
      >
        # <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => {
      // Use row.index to get the index of the row
      return (
        <Typography sx={{ textAlign: "center" }}>{row.index + 1}</Typography>
      );
    },
    enableSorting: true, // Ensure sorting is enabled

    sortingFn: (rowA, rowB, columnId) => {
      // Sort by row index
      return rowA.index - rowB.index;
    },
  }),
  columnHelper.accessor("name", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        Player <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => {
      const item = row.original;
      return (
        <Box className="team-wrap">
          <Box className="team-image-wrap">
            <img
              src={
                item?.image
                  ? item?.image?.includes("uploads")
                    ? Config.mediaURL + item?.image
                    : item?.image
                  : DefaultTeam
              }
              onError={(e) => {
                e.target.src = DefaultTeam;
              }}
              alt="Team 1"
              className="team-image"
            />
          </Box>
          <Box className="team-name-wrap">
            <Typography className="team-name">{item?.name}</Typography>
          </Box>
        </Box>
      );
    },
    enableSorting: true,
  }),
  columnHelper.accessor("teamName", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        Team <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    enableSorting: true,

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Box className="team-wrap">
          <Box className="team-image-wrap">
            <img
              src={
                item?.flag
                  ? item?.flag?.includes("uploads")
                    ? Config.mediaURL + item?.flag
                    : item?.flag
                  : DefaultTeam
              }
              onError={(e) => {
                e.target.src = DefaultTeam;
              }}
              alt="Team 1"
              className="team-image"
            />
          </Box>
          <Box className="team-name-wrap">
            <Typography className="team-name">{item?.teamName}</Typography>
          </Box>
        </Box>
      );
    },
  }),
  columnHelper.accessor("totalWktes", {
    header: ({ column }) => (
      <Box
        className="sorting-cell text-align-center"
        onClick={column.getToggleSortingHandler()}
      >
        Wkt <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    enableSorting: true,

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Typography sx={{ textAlign: "center" }}>{item?.totalWktes}</Typography>
      );
    },
  }),
  columnHelper.accessor("totalBalls", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Balls <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    enableSorting: true,

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Typography sx={{ textAlign: "center" }}>{item?.totalBalls}</Typography>
      );
    },
  }),
  columnHelper.accessor("totalInnings", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Inns <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Typography sx={{ textAlign: "center" }}>
          {item?.totalInnings}
        </Typography>
      );
    },

    enableSorting: true,
  }),
  columnHelper.accessor("totalMatch", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        M <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Typography sx={{ textAlign: "center" }}>{item?.totalMatch}</Typography>
      );
    },

    enableSorting: true,
  }),
  columnHelper.accessor("totalOv", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        O <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Typography sx={{ textAlign: "center" }}>{item?.totalOv}</Typography>
      );
    },

    enableSorting: true,
  }),
  columnHelper.accessor("bowllingAverage", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Avg <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.bowllingAverage}
        </Typography>
      );
    },

    enableSorting: true,
  }),
  columnHelper.accessor("totalruns", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Runs <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.totalruns}
        </Typography>
      );
    },
    enableSorting: true,
  }),
  columnHelper.accessor("totalfourFersInnings", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        4W <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.totalfourFersInnings}
        </Typography>
      );
    },

    enableSorting: true,
  }),
  columnHelper.accessor("totalfiveFersInnings", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        5W <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.totalfiveFersInnings}
        </Typography>
      );
    },

    enableSorting: true,
  }),
];
