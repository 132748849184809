const SubractIcon = ({ fill, className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 18C18 8.05888 9.94112 0 0 0H18V18Z"
      fill={fill || "#D9D9D9"}
    />
  </svg>
);

export default SubractIcon;
