import React, { useState, useEffect, useContext, createRef } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
} from "@mui/material";
import "./KYCForm.scss";
import { ReactComponent as InfoIcon } from "src/assets/images/bankVerification/infoIcon.svg";
import { ReactComponent as VerificationCheckedIcon } from "src/assets/images/bankVerification/verificationCheckedIcon.svg";
import { ReactComponent as VerificationCrossIcon } from "src/assets/images/bankVerification/verificationCrossIcon.svg";
import { ReactComponent as VerificationDeleteIcon } from "src/assets/images/bankVerification/verificationDeleteIcon.svg";
import { ReactComponent as VerifyDocIcon } from "src/assets/images/bankVerification/verifyDocIcon.svg";
import { ReactComponent as VerifiedIcon } from "src/assets/images/bankVerification/verifiedIcon.svg";
import { ReactComponent as ProcessIcon } from "src/assets/images/bankVerification/processIcon.svg";

import { Config } from "src/helpers/context";
import { setApiMessage } from "src/helpers/commonFunctions";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import DialogBox from "../../UI/dialogBox";

const KYCVerificationForm = ({
  profileData,
  handleUserProfile,
  verificationDetails,
  setVerificationStatus,
  verificationStatus,
}) => {
  const [requirementScore, setRequirementScore] = useState(0);
  const [isPhotoID, setIsPhotoID] = useState(false);
  const [scoreError, setScoreError] = useState("");
  const [photoIdError, setPhotoIdError] = useState("");
  const [verificationList, setVerificationList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [toggleModal, setToggleModal] = useState(false);
  const [removeImgId, setRemoveImgId] = useState();
  const [removeImgIndexId, setRemoveImgIndexId] = useState();
  const [isProgressToggle, setIsProgressToggle] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const requiredScore = 100;

  const verificationData = [
    {
      id: 1,
      name: "Foreign Passport",
      photoID: "Yes",
      pointsWorth: 70,
      imageName: [],
      type: "foreignPassport",
    },
    {
      id: 2,
      name: "Australian Passport",
      photoID: "Yes",
      pointsWorth: 70,
      imageName: [],
      type: "ausPassport",
    },
    {
      id: 3,
      name: "Australian Driver Licence/Learner’s Permit",
      photoID: "Yes",
      pointsWorth: 70,
      imageName: [],
      type: "ausDrivingLic",
    },
    {
      id: 4,
      name: "Government employee ID (Australian Federal/State/Territory)",
      photoID: "Yes",
      pointsWorth: 40,
      imageName: [],
      type: "govEmpId",
    },
    {
      id: 5,
      name: "Defence Force Identity Card (with photo or signature)",
      photoID: "Yes",
      pointsWorth: 40,
      imageName: [],
      type: "defForceId",
    },
    {
      id: 6,
      name: "Department of Veterans Affairs (DVA) card",
      photoID: "No",
      pointsWorth: 40,
      imageName: [],
      type: "dvaCard",
    },
    {
      id: 7,
      name: "Centrelink card (with reference number)",
      photoID: "No",
      pointsWorth: 40,
      imageName: [],
      type: "centrlinkCard",
    },
    {
      id: 8,
      name: "Medicare card",
      photoID: "No",
      pointsWorth: 25,
      imageName: [],
      type: "medicareCardt",
    },
    {
      id: 9,
      name: "Taxation assessment notice",
      photoID: "No",
      pointsWorth: 25,
      imageName: [],
      type: "TaxAssessNotice",
    },
    {
      id: 10,
      name: "Utility Bill - electricity, gas, telephone - Current address (less than 12 months old)",
      photoID: "No",
      pointsWorth: 25,
      imageName: [],
      type: "utiliyBill",
    },
    {
      id: 11,
      name: "",
      photoID: "No",
      pointsWorth: 0,
      imageName: [],
      type: "utiliyBill",
    },
  ];

  useEffect(() => {
    setVerificationList(verificationData);
    if (verificationDetails === "") {
      getVerificationData();
    } else {
      // update score and photo id and status
      setRequirementScore(verificationDetails?.requirementScore);
      setIsPhotoID(
        verificationDetails?.isPhotoIdSupplied === 1 ||
          verificationDetails?.verificationStatus === "verified",
      );

      // Parse identificationDocuments if it's a string
      let identificationDocuments =
        verificationDetails?.identificationDocuments;
      if (typeof identificationDocuments === "string") {
        try {
          identificationDocuments = JSON.parse(identificationDocuments);
        } catch (error) {
          console.error(error);
          identificationDocuments = [];
        }
      }

      // Set updated data in verification list
      const newVerificationArray = verificationData?.map((item) => {
        return {
          ...item,
          imageName: [
            ...(identificationDocuments?.filter(
              (i) => i?.type === item?.type,
            ) ?? []),
          ],
        };
      });

      setVerificationList(newVerificationArray);
    }
  }, [verificationDetails]);

  // fetch verification document data
  const getVerificationData = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        "user/identity/document",
      );
      if (status === 200 || status === 201) {
        const verifyData = data?.result;

        if (verifyData) {
          // update score and photo id and status
          setRequirementScore(verifyData?.requirementScore);
          setIsPhotoID(
            verifyData?.isPhotoIdSupplied === 1 ||
              verifyData?.verificationStatus === "verified",
          );
          setVerificationStatus(verifyData?.verificationStatus);

          // Parse identificationDocuments if it's a string
          let identificationDocuments = verifyData?.identificationDocuments;
          if (typeof identificationDocuments === "string") {
            try {
              identificationDocuments = JSON.parse(identificationDocuments);
            } catch (error) {
              identificationDocuments = [];
            }
          }

          // Set updated data in verification list
          const newVerificationArray = verificationData?.map((item) => {
            return {
              ...item,
              imageName: [
                ...(identificationDocuments?.filter(
                  (i) => i?.type === item?.type,
                ) ?? []),
              ],
            };
          });
          setVerificationList(newVerificationArray);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const fileInput = verificationList?.map(() => createRef());

  const handleUploadFile = (id, files) => {
    uploadImage(files, id);
  };

  // FILE UPLOAD
  const uploadImage = async (file, id) => {
    const body = new FormData();
    const url = "public/media";

    setLoading(true);
    for (const element of file) {
      body.append("image", element);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const { status, data } = await axiosInstance.post(url, body, config);
      if (status === 200 || status === 201) {
        setLoading(false);
        if (data?.success) {
          setApiMessage("success", "File uploaded successfully");
        } else {
          setApiMessage("error", "File upload failed");
        }
        // added uploaded original file name and view path
        const filterList = verificationList.find((item) => item?.id === id);
        if (filterList) {
          filterList.imageName.push({
            originalName: file[0]?.name, // Name of the uploaded file
            fileName: data?.image?.filePath, // Path returned by the server
          });
        }

        // update score and photo id
        const getScore = verificationList
          ?.filter((item) => item?.imageName?.length !== 0)
          ?.map((obj) => obj?.pointsWorth);
        // Update `requirementScore` based on the uploaded item's `pointsWorth`
        const totalScore = getScore?.reduce((acc, current) => acc + current, 0);

        setRequirementScore(totalScore);

        const getIsPhotoId = verificationList
          ?.filter((item) => item?.imageName?.length !== 0)
          ?.filter((obj) => obj?.photoID === "Yes");
        // Update `isPhotoID` based on the uploaded item's `photoID` value
        const isPhotoIDValue = getIsPhotoId?.length !== 0;

        setIsPhotoID(isPhotoIDValue);
        setIsLoad(!isLoad);
        checkValidation(totalScore, isPhotoIDValue);
      }
    } catch (err) {
      setLoading(false);
      // @ts-expect-error
      setApiMessage("error", err?.response?.data?.msg);
    }
  };

  // view uploaded file
  const openPreview = (urls) => {
    const url = Config?.mediaURL + urls;

    window.open(url, "_blank");
  };

  // handle delete file modal
  const handleRemoveToggle = (removeId, imageIndex) => {
    setToggleModal(true);
    setRemoveImgId(removeId);
    setRemoveImgIndexId(imageIndex);
  };

  // delete file
  const handleRemoveDoc = () => {
    const filterRemoveList = verificationList?.map((obj) => {
      if (obj?.id === removeImgId) {
        const updatedImageNames = obj.imageName
          ? obj.imageName.filter((_, index) => index !== removeImgIndexId)
          : [];
        if (fileInput[obj?.id]?.current) {
          // @ts-expect-error
          fileInput[obj?.id].current.value = "";
        }
        return {
          ...obj,
          imageName: updatedImageNames,
        };
      } else {
        return obj;
      }
    });

    // update score and photo id
    const getScore = filterRemoveList
      ?.filter((item) => item?.imageName?.length !== 0)
      ?.map((item) => item?.pointsWorth || 0);

    // Update `requirementScore` based on the uploaded item's `pointsWorth`
    const totalScore =
      getScore?.reduce((acc, current) => acc + current, 0) || 0;

    setRequirementScore(totalScore);

    // Determine if Photo ID is supplied
    const getIsPhotoId = filterRemoveList
      ?.filter((item) => item?.imageName?.length !== 0)
      ?.filter((item) => item?.photoID === "Yes");
    // Update `isPhotoID` based on the uploaded item's `photoID` value
    const isPhotoIDValue = getIsPhotoId?.length > 0;

    setIsPhotoID(isPhotoIDValue);

    setVerificationList(filterRemoveList);
    setToggleModal(false);
  };

  const checkValidation = (score, photoID) => {
    if (score <= requiredScore) {
      setScoreError("Score of 100 or more is required.");
    } else {
      setScoreError("");
    }

    if (!photoID) {
      setPhotoIdError(
        "At least one identification document with a photo is required.",
      );
    } else {
      setPhotoIdError("");
    }
  };
  // submit verification upload data and check validation
  const handleSubmitVerification = async () => {
    checkValidation(requirementScore, isPhotoID);
    if (requirementScore >= requiredScore && isPhotoID) {
      setLoading(true);
      // manage payload data
      const newArray = verificationList?.flatMap((item) =>
        item?.imageName?.map((obj) => ({ ...obj, type: item?.type })),
      );
      try {
        const sendData = {
          requirementScore: requirementScore,
          isPhotoIdSupplied: isPhotoID ? 1 : 0,
          identificationDocuments: newArray,
        };
        const { status, data } = await axiosInstance.post(
          "user/identity/document/submit",
          sendData,
        );
        if (status === 200 || status === 201) {
          setLoading(false);
          setApiMessage("success", data?.message);
          setIsProgressToggle(true);
          getVerificationData();
        } else {
          setApiMessage("error", data?.data);
        }
      } catch (err) {
        setLoading(false);
        // @ts-expect-error
        setApiMessage("error", err?.response?.data?.msg);
      }
    } else {
      setApiMessage(
        "error",
        "Requirements missing. Upload all required identification documents.",
      );
    }
  };

  const handleDocDeleteClose = () => {
    setToggleModal(false);
  };
  return (
    <>
      <Box className="verify-your-identity-wrap">
        {screenWidth >= 799 && (
          <Box className="useredit-details verify-useredit-details">
            <h4>Verify Your Identity </h4>
            {verificationStatus === "verified" ? (
              <Button className="verified-btn" startIcon={<VerifiedIcon />}>
                Verified
              </Button>
            ) : verificationStatus === "pending" ? (
              <Button className="process-btn" startIcon={<ProcessIcon />}>
                Verification in process
              </Button>
            ) : (
              ""
            )}
          </Box>
        )}
        {/* // {loading && <PreLoader />} */}
        {screenWidth < 799 &&
          (verificationStatus === "verified" ? (
            <Button className="verified-btn" startIcon={<VerifiedIcon />}>
              Verified
            </Button>
          ) : verificationStatus === "pending" ? (
            <Button className="process-btn" startIcon={<ProcessIcon />}>
              Verification in process
            </Button>
          ) : (
            ""
          ))}
        <Box className="verification-title-wrapper">
          <Box>
            <InfoIcon />
          </Box>
          <Box className="left-verification-section">
            <Typography className="text-header">
              Identification requirements
            </Typography>
            <Typography className="text-sub mb-18">
              A minimum of 100 points of identification must be provided and you
              must provide at least one document containing photo
              identification. Ensure to provide both sides of the document if
              applicable.
            </Typography>
            <Typography className="text-sub">
              SmartB protects your privacy by automatically destroying
              identification documents once your event is finished and payout
              has been confirmed
            </Typography>
          </Box>
        </Box>
        <Box className="verification-score-container ">
          <Box className="verification-score-inner-container">
            <Box className="">
              <Typography
                className={`verification-text fw-600 vt-mb-10  ${scoreError ? "vt-mb-24" : "vt-mb-9"} ${requirementScore >= requiredScore ? "text-success-100" : "text-black-100"}`}
              >
                ID Requirement Score:
              </Typography>
              {screenWidth < 767 && scoreError && (
                <Typography variant="h6" className="text-xs vt-mb-9">
                  {scoreError}
                </Typography>
              )}
              <Typography
                className={`verification-text fw-600  ${isPhotoID ? "text-success-100" : "text-black-100"}`}
              >
                Photo ID:
              </Typography>
              {screenWidth < 767 && photoIdError && (
                <Typography variant="h6" className="text-xs vt-mb-9">
                  {photoIdError}
                </Typography>
              )}
            </Box>
            <Box>
              <Box>
                <Box className={`${scoreError ? "vt-mb-6" : "vt-mb-9"} flex`}>
                  {requirementScore >= requiredScore ? (
                    <VerificationCheckedIcon />
                  ) : (
                    <VerificationCrossIcon />
                  )}
                  <Typography
                    className={`verification-text  ${requirementScore >= requiredScore ? "text-success-100" : "text-black-100"}`}
                  >
                    {requirementScore}/{requiredScore}
                  </Typography>
                </Box>
                {screenWidth > 799 && scoreError && (
                  <Typography variant="h6" className="text-xs vt-mb-9">
                    {scoreError}
                  </Typography>
                )}
              </Box>
              <Box>
                <Box className="flex items-center">
                  {isPhotoID ? (
                    <VerificationCheckedIcon />
                  ) : (
                    <VerificationCrossIcon />
                  )}
                  <Typography
                    className={`verification-text text-base leading-5 ml-2 ${isPhotoID ? "text-success-100" : "text-black-100"}`}
                  >
                    {isPhotoID ? "Yes" : "No"}
                  </Typography>
                </Box>
                {screenWidth > 799 && photoIdError && (
                  <Typography variant="h6" className="text-xs vt-mb-9">
                    {photoIdError}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="max-767:-mx-3">
          <Table width="100%" className="verification-table">
            <TableHead>
              <TableRow>
                <TableCell>Document Type</TableCell>
                <TableCell>Photo ID</TableCell>
                <TableCell>Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {verificationList
                ?.filter((item) => item?.id !== 11)
                ?.map((obj) => (
                  <>
                    <TableRow key={obj?.name} className="no-border">
                      <TableCell>
                        <Typography
                          className={` ${obj?.imageName?.length !== 0 ? "text-success-100" : ""}`}
                        >
                          {obj?.name}
                        </Typography>

                        <Box>
                          <Button
                            className="choose-btn"
                            onClick={() => {
                              fileInput[obj?.id]?.current?.click();
                            }}
                          >
                            Choose
                          </Button>
                          <input
                            ref={fileInput[obj?.id]}
                            accept="image/*,application/pdf"
                            type="file"
                            style={{ display: "none" }}
                            id={`upload-${obj?.id}`}
                            multiple
                            onChange={(e) =>
                              handleUploadFile(obj?.id, e.target.files)
                            }
                          />
                          {screenWidth > 767 && (
                            <>
                              {obj?.imageName &&
                                obj?.imageName?.length !== 0 &&
                                obj?.imageName?.map((img, index) => (
                                  <Box key={index} className="mt-[10px]">
                                    <Box className="verfiy-doc-container flex items-center cursor-pointer">
                                      <VerifyDocIcon />

                                      <Button
                                        className="file-name"
                                        onClick={() =>
                                          openPreview(img?.fileName)
                                        }
                                      >
                                        {img?.originalName}
                                      </Button>

                                      <VerificationDeleteIcon
                                        className="cursor-pointer"
                                        onClick={() =>
                                          handleRemoveToggle(obj?.id, index)
                                        }
                                      />
                                    </Box>
                                  </Box>
                                ))}
                            </>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell className="text-center">
                        <Typography
                          className={`${obj?.imageName?.length !== 0 ? "text-success-100" : ""}`}
                        >
                          {obj?.photoID}
                        </Typography>
                      </TableCell>
                      <TableCell className="text-center">
                        <Typography
                          className={` ${
                            obj?.imageName?.length !== 0
                              ? "text-success-100"
                              : ""
                          }`}
                        >
                          {obj?.pointsWorth}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {screenWidth <= 767 && (
                      <TableRow className="border-b border-b-black-300 text-base leading-5">
                        <TableCell colSpan={3}>
                          {obj?.imageName &&
                            obj?.imageName?.length !== 0 &&
                            obj?.imageName?.map((img, index) => (
                              <Box key={index} className="mobile-file-section">
                                <Box className="mobile-verfiy-doc-container">
                                  <Box className="first-section">
                                    <VerifyDocIcon />
                                    <Button
                                      className="file-name"
                                      onClick={() => openPreview(img?.fileName)}
                                    >
                                      {img?.originalName}
                                    </Button>
                                  </Box>

                                  <VerificationDeleteIcon
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleRemoveToggle(obj?.id, index)
                                    }
                                  />
                                </Box>
                              </Box>
                            ))}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
            </TableBody>
          </Table>
        </Box>
        <Box className="submit-btn-container">
          <Button
            onClick={() => handleSubmitVerification()}
            type="button"
            className="submit-btn"
          >
            Submit Verification
          </Button>
        </Box>
      </Box>

      <DialogBox
        open={toggleModal}
        onClose={handleDocDeleteClose}
        handleClose={handleDocDeleteClose}
        title={false}
        className={"delete-doc-modal"}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete this document?
              </Typography>
              <Typography className="sub-title">
                Doing so may lower your identification score and require
                re-verification.
              </Typography>
              <Box className="delete-btn-wrap">
                <Button
                  className="delete-btn"
                  onClick={() => handleRemoveDoc()}
                >
                  Delete
                </Button>
              </Box>
              <Box className="cancel-btn-wrap">
                <Button
                  variant="outline"
                  className="cancel-btn"
                  onClick={() => setToggleModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={true}
      />
      {/* cancel Modal code */}
      <DialogBox
        isOpen={toggleModal}
        // onClose={() => setToggleModal(false)}
        // handleClose={() => setToggleModal(false)}
        title={false}
        content={
          <>
            <Box className="text-center">
              <p className="text-[22.4px] max-799:text-[14px] leading-[27px] max-799:leading-[17px] font-medium font-inter text-black-100 mb-[18px] max-799:mb-[12px]">
                Are you sure you want to delete this document?
              </p>
              <p className="text-base max-799:text-[12px] leading-5 max-799:leading-[17px] font-inter text-black-100 mb-[33px] max-799:mb-[23px]">
                Doing so may lower your identification score and require
                re-verification.
              </p>
              <Box className="mb-[18px] max-799:mb-3">
                <Button className="w-full" onClick={() => handleRemoveDoc()}>
                  Delete
                </Button>
              </Box>
              <Box>
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => setToggleModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />

      {/* Progress dialog /}
    <CustomDialog
      isOpen={isProgressToggle}
      onClose={() => setIsProgressToggle(false)}
      title="Verification in process"
      maxWidth={570}
      className="verification-required-modal"
      outerClickClose={true}
    >
      <Box className="">
        <p className="text-[22.4px] max-799:text-[14px] leading-[27px] max-799:leading-[17px] font-semibold font-inter text-black-100 mb-[23px] max-799:mb-[13px]">
          We are reviewing your ID details
        </p>
        <p className="text-[16px] leading-[22.4px] font-inter font-normal text-black-100 mb-[37px]">
          Your ID details will be processed within 48 to 72 hours. You will
          receive an email notification as soon as the process is complete.
        </p>
        <Box className="mb-[18px]">
          <Button
            className="w-full"
            onClick={() => setIsProgressToggle(false)}
          >
            Close
          </Button>
        </Box>
      </Box>
    </CustomDialog> */}
    </>
  );
};

export default KYCVerificationForm;
