import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Collapse,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { styled } from "@mui/system";
import MasterCard from "../../../../../src/assets/images/mastercard.png";
import Visa from "../../../../../src/assets/images/visa.png";
import AmericanExpress from "../../../../../src/assets/images/americanExpress.png";
import "./paymentCard.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { setApiMessage } from "src/helpers/commonFunctions";
import Loader from "src/components/Loader";
import { formatExpiryDate } from "src/helpers/utils";

const formatExpiry = (expiry) => {
  if (!expiry) return "";
  const [year, month] = expiry.split("-");
  return `${month}/${year}`;
};

// Custom styled components
const StyledCard = styled(Card)(({ theme, isDefault }) => ({
  background:
    "linear-gradient(135deg, #4c5ec6, #5e6ed5 53.5%, #4354c6 79%, #3242a7)",
  color: "white",
  width: 316,
  marginBottom: theme.spacing(2),
  position: "relative",
  "&:hover": {
    boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
  },
  borderRadius: 8,
}));

const CardNumberDisplay = styled(Typography)({
  fontSize: "1rem",
  letterSpacing: "2px",
  fontWeight: 500,
});

const ExpiryDisplay = styled(Typography)({
  fontSize: "0.875rem",
});

const DefaultBadge = styled(Box)({
  position: "absolute",
  top: 16,
  right: 16,
  backgroundColor: "#4caf50",
  color: "white",
  padding: "4px 8px",
  borderRadius: 4,
  fontSize: "0.75rem",
});

// Validation schema for adding a new card
const cardValidationSchema = Yup.object({
  cardNumber: Yup.string()
    .required("Card number is required")
    .test(
      "is-credit-card",
      "Card number must be a valid credit card number",
      (value) => {
        const cleaned = value ? value.replace(/\s+/g, "") : "";
        return cleaned.length === 16 && !isNaN(cleaned);
      },
    ),
  expiryDate: Yup.string()
    .required("Expiry date is required")
    .matches(
      /^(0[1-9]|1[0-2])\/\d{4}$/,
      "Expiry date must be in MM/YYYY format",
    ),
  cvv: Yup.string()
    .required("CVV is required")
    .matches(/^\d{3,4}$/, "CVV must be 3 or 4 digits"),
});

// Format card number with spaces for better readability
const formatCardNumber = (value) => {
  if (!value) return "";
  const cleaned = value.replace(/\s+/g, "");
  const match = cleaned.match(/\d{1,4}/g);
  return match ? match.join(" ") : "";
};

// Format card display for saved cards
const formatCardDisplay = (cardNumber) => {
  // Show only last 4 digits of card
  const lastFour = cardNumber.slice(-4);
  return `**** **** **** ${lastFour}`;
};

const PaymentCardManagement = () => {
  // Initial state with two example cards
  const [cards, setCards] = useState([]);
  const [nextId, setNextId] = useState(3);
  const [showAddForm, setShowAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCardsLoading, setIsCardsLoading] = useState(false);

  const addFormStyles = {
    mb: 3,
    color: "#4455C7",
    borderColor: "#4455C7",
  };
  const addFormStylesActive = {
    mb: 3,
    color: "#ffffff",
    backgroundColor: "#4455C7",
    borderColor: "#4455C7",
  };

  // Handle setting a card as default
  const handleSetDefault = (id) => {
    updateCard({ cardId: id, isDefault: true });
    setCards(
      cards.map((card) => ({
        ...card,
        is_default: card.id === id,
      })),
    );
  };

  // Handle removing a card
  const handleRemove = (id) => {
    deleteCard({ id });
  };

  // Handle adding a new card
  const handleAddCard = async (values, { resetForm }) => {
    const cleanedCardNumber = values.cardNumber.replace(/\s+/g, "");
    const card_exp = values.expiryDate.split("/").reverse().join("-");
    const newCard = {
      id: nextId,
      card_number: cleanedCardNumber,
      card_exp: card_exp,
      cvv: values.cvv,
    };
    createCard(
      {
        card: newCard,
        isFeatured: true,
        is_default: values.setAsDefault,
      },
      resetForm,
    );
  };

  // Format the expiry date input

  // Handle card number input formatting
  const handleCardNumberChange = (e, handleChange) => {
    const { value } = e.target;
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, "");
    // Limit to 16 digits
    const truncated = cleaned.slice(0, 16);
    // Format with spaces
    e.target.value = formatCardNumber(truncated);
    // Trigger formik's handleChange
    handleChange(e);
  };

  const getCards = async () => {
    try {
      setIsCardsLoading(true);
      const { data } = await axiosInstance.get(`/subscription/get-card`);
      setCards(data?.card);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCardsLoading(false);
    }
  };

  const updateCard = async ({ cardId, isDefault }) => {
    try {
      const { status, data } = await axiosInstance.put(
        `/subscription/update-card/${cardId}`,
        {
          is_default: isDefault,
        },
      );
      if (status === 200) {
        setApiMessage("success", data?.message);
        getCards();
      }
    } catch (error) {
      console.error(error);
      setApiMessage("error", error?.response?.data?.message);
    }
  };

  const deleteCard = async ({ id }) => {
    try {
      setLoading(true);
      const { data, status } = await axiosInstance.delete(
        `/subscription/delete-card/${id}`,
        {
          body: {
            type: "web",
          },
        },
      );
      if (status === 200) {
        setApiMessage("success", data?.message);
        getCards();
      }
    } catch (error) {
      console.error(error);
      setApiMessage("error", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const createCard = async (values, resetForm) => {
    try {
      // Set loading to true
      setLoading(true);
      // Post to create card endpoint
      // Pass values and type: "web"
      const { data, status } = await axiosInstance.post(
        `/subscription/create-card`,
        values,
      );
      // If status is 200
      // Set API message to success with data?.message
      // Call getCards to get all cards
      if (status === 200) {
        setApiMessage("success", data?.message);
        getCards();
        resetForm();
      }
    } catch (error) {
      // Catch any errors
      console.error(error);
      setApiMessage("error", error?.response?.data?.message);
    } finally {
      // Set loading to false
      setLoading(false);
    }
  };
  /******  b95267e7-1aa6-42ab-a4d1-99475a0d76f7  *******/

  useEffect(() => {
    getCards();
  }, []);

  return (
    <Container>
      <Box>
        <Typography
          sx={{
            textTransform: "unset",
            fontSize: 22.4,
            fontWeight: 600,
            marginBottom: 2,
          }}
        >
          My Cards
        </Typography>

        {/* Existing cards */}
        {isCardsLoading ? (
          <Loader />
        ) : (
          <Box sx={{ mb: 3, display: "flex", flexWrap: "wrap", gap: 2 }}>
            {cards?.map((card) => (
              <StyledCard key={card?.id} isDefault={card?.is_default}>
                <CardContent>
                  <Box display="flex" alignItems="center" gap={1} mb={1}>
                    <Box>
                      <img src={Visa} alt="card" />
                    </Box>
                    <CardNumberDisplay variant="body1">
                      {formatCardDisplay(card?.last4)}
                    </CardNumberDisplay>
                  </Box>
                  <ExpiryDisplay variant="body2" sx={{ mb: 2 }}>
                    Expiry: {formatExpiry(card?.cardExp)}
                  </ExpiryDisplay>

                  <Grid container spacing={1}>
                    <Grid item>
                      {card?.is_default ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="success"
                          sx={{
                            pointerEvents: "none",
                            background: "#78C2A7",
                            borderRadius: 2,
                            textTransform: "unset",
                          }}
                        >
                          Default
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleSetDefault(card.id)}
                          sx={{
                            bgcolor: "rgba(255, 255, 255, 0.1)",
                            color: "white",
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                            borderRadius: 2,
                          }}
                          style={{ textTransform: "unset" }}
                        >
                          Set as default
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleRemove(card.id)}
                        sx={{
                          color: "white",
                          border: "none",
                        }}
                        style={{ textTransform: "capitalize" }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            ))}
          </Box>
        )}

        {/* Add new card button */}
        <Button
          variant="outlined"
          startIcon={showAddForm ? <RemoveIcon /> : <AddIcon />}
          onClick={() => setShowAddForm(!showAddForm)}
          sx={{
            width: { xs: "100%", sm: "auto" }, // 100% on extra-small screens, auto on small and larger screens
            ...(showAddForm ? addFormStylesActive : addFormStyles),
          }}
          style={{ textTransform: "unset" }}
        >
          Add new card
        </Button>

        {/* Add new card form */}
        <Collapse in={showAddForm}>
          <Card variant="outlined" sx={{ mb: 3 }}>
            <CardContent>
              <Box
                mb={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Typography>Credit or debit card</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ml: 1,
                  }}
                >
                  <img src={MasterCard} alt="card" />
                  <img src={Visa} alt="card" />
                  <img src={AmericanExpress} alt="card" />
                </Box>
              </Box>

              <Formik
                initialValues={{
                  cardNumber: "",
                  expiryDate: "",
                  cvv: "",
                  setAsDefault: false,
                }}
                validationSchema={cardValidationSchema}
                onSubmit={handleAddCard}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <Grid
                      container
                      spacing={2}
                      className="payment-card-details"
                    >
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          name="cardNumber"
                          className="details-textfield"
                          label="Card number"
                          fullWidth
                          variant="outlined"
                          placeholder="XXXX XXXX XXXX XXXX"
                          onChange={(e) =>
                            handleCardNumberChange(e, handleChange)
                          }
                          error={
                            touched.cardNumber && Boolean(errors.cardNumber)
                          }
                          helperText={touched.cardNumber && errors.cardNumber}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          name="expiryDate"
                          label="Expiry (MM/YYYY)"
                          className="details-textfield"
                          fullWidth
                          variant="outlined"
                          placeholder="MM/YYYY"
                          onChange={(e) => formatExpiryDate(e, handleChange)}
                          error={
                            touched.expiryDate && Boolean(errors.expiryDate)
                          }
                          helperText={touched.expiryDate && errors.expiryDate}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          type="password"
                          name="cvv"
                          label="CVV"
                          fullWidth
                          variant="outlined"
                          className="details-textfield"
                          placeholder="***"
                          inputProps={{ maxLength: 3 }}
                          error={touched.cvv && Boolean(errors.cvv)}
                          helperText={touched.cvv && errors.cvv}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Field
                              as={Checkbox}
                              name="setAsDefault"
                              color="primary"
                              checked={values.setAsDefault}
                            />
                          }
                          label="Set card as default"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{ background: "#4455C7" }}
                          style={{ textTransform: "capitalize" }}
                        >
                          Save card
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Collapse>
      </Box>
    </Container>
  );
};

export default PaymentCardManagement;
