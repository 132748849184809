import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { fetchFromStorage } from "src/helpers/context";
import { useSelector } from "react-redux";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import { ReactComponent as Plus } from "../../../assets/images/icons/plus.svg";
import { ReactComponent as Minus } from "../../../assets/images/icons/minus.svg";
import AdBannner from "../AdBanner/AdBanner";
import { Link } from "react-router-dom";
import OurPartner from "../ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import "./tippingPrize.scss";
import { Pagination } from "@mui/material";
import NoDataComp from "../UI/NoData";

const TippingPrizePage = () => {
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [tabvalue, setTabValue] = useState(9);
  const [loading, setLoading] = useState(false);
  const [tippingPrizeList, setTippingPrizeList] = useState([]);
  const [tippingPrizeCount, setTippingPrizeCount] = useState(0);
  const [prizePage, setPrizePage] = useState(1);
  const rowPerPage = 10;

  const sportTabData = [
    {
      id: 9,
      name: "Australian Rules",
    },
    {
      id: 12,
      name: "Rugby League",
    },
    {
      id: 4,
      name: "Cricket",
    },
  ];

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = (ReduxAdsData) => {
    let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
    setTippingPrizeCount(0);
    setPrizePage(1);
  };

  useEffect(() => {
    fetchTippingPrizeDetails(prizePage);
  }, [tabvalue]);

  const fetchTippingPrizeDetails = async (page) => {
    setLoading(true);
    setPrizePage(page);
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/competition/list?SportId=${tabvalue}&tournamentId=${""}&limit=${rowPerPage}&page=${page}&privacy=`,
      );
      if (status === 200) {
        setLoading(false);
        setTippingPrizeList(data?.result);
        setTippingPrizeCount(data?.count);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const scrollToTopSection = () => {
    const targetSection = document.getElementById("tipping-prize");

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handlePaginationClick = (event, page) => {
    fetchTippingPrizeDetails(Number(page));
    scrollToTopSection();
  };
  const pageNumbers = [];

  if (tippingPrizeCount > 0) {
    for (let i = 1; i <= Math.ceil(tippingPrizeCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const prizeExtract = (str) => {
    const regex =
      /(\$\d+\s(?:[Pp][Rr][Ii][Zz][Ee]|[Pp][Rr][Ii][Cc][Ee]))\s(.+)/i;
    const match = str?.match(regex);

    const part1 = match ? match[1] : null;
    const part2 = match ? match[2] : null;

    return (
      <>
        <span className="bold-text">{part1}</span>{" "}
        <span className="light-text">{match === null ? str : part2}</span>
      </>
    );
  };
  return (
    <>
      <Box className="content-wrap prize-content">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="tipping-prize-wrap" id="tipping-prize">
          <Box className="tipping-prize-data-header">
            <Box className="bredcrumn-details">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to=""
                    className="cursor-default"
                  >
                    Tipping Competition
                  </Link>
                  <Typography>Prizes</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">Prizes</Typography>
          </Box>
          <Box className="team-sports-tab">
            <Tabs
              value={tabvalue}
              variant="scrollable"
              scrollButtons={false}
              indicatorColor="primary"
              textColor="primary"
              className="teamsport-tab-detail"
            >
              {sportTabData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={
                        <Box className="tab-label">
                          <span className="label-name">{item?.name}</span>
                        </Box>
                      }
                      value={item?.id}
                      className={item?.id === tabvalue ? "active " : ""}
                      onChange={(event, newValue) =>
                        handleTabChange(event, item?.id)
                      }
                    />
                  </Box>
                );
              })}
            </Tabs>
          </Box>
          {loading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : tippingPrizeList?.length > 0 ? (
            tippingPrizeList?.map((item, index) => {
              return (
                <>
                  <Box className="prize-card-wrap" key={index}>
                    <Typography className="competition-name">
                      {item?.competitionName}
                    </Typography>
                    <Typography className="about-competition">
                      {item?.about}
                    </Typography>
                    <Typography className="powered-text">
                      {item?.competitionName}{" "}
                      {item?.companyName && ` powered by ${item?.companyName}`}
                    </Typography>
                    {item?.prizeMoney && (
                      <Table className="prize-table">
                        <TableBody>
                          {/* <TableRow>
                        <TableCell>Weekly Prize</TableCell>
                        <TableCell>
                          {prizeExtract(item?.weeklyPrize)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Major Prize</TableCell>
                        <TableCell>
                          {prizeExtract(item?.majorPrize)}
                        </TableCell>
                      </TableRow> */}

                          {item?.prizeMoney?.map((item, index) => {
                            return (
                              item?.label &&
                              item.description && (
                                <TableRow key={index}>
                                  <TableCell>{item?.label}</TableCell>
                                  <TableCell>
                                    {prizeExtract(item?.description)}
                                  </TableCell>
                                </TableRow>
                              )
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </Box>
                </>
              );
            })
          ) : (
            <Box style={{ textAlign: "center" }}>
              <NoDataComp />
            </Box>
          )}
          {!loading && tippingPrizeList && tippingPrizeList?.length > 0 ? (
            <Box className="pagination-section">
              <Pagination
                disabled={tippingPrizeCount / rowPerPage > 1 ? false : true}
                page={prizePage}
                onChange={(e, value) => handlePaginationClick(e, value)}
                count={pageNumbers[pageNumbers?.length - 1]}
                siblingCount={2}
                boundaryCount={1}
                size="small"
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default TippingPrizePage;
