import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IntlContext } from "src/App";
import Select, { components } from "react-select";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import "./purchasedHistory.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ReactComponent as SubsBadge } from "src/assets/images/icons/subscription-badge.svg";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Loader from "src/components/Loader";

const SortData = [
  { value: "DESC", label: "Date (Newest to Oldest)" },
  { value: "ASC", label: "Date (Oldest to Newest)" },
];

const paymentBaseOption = [
  { value: null, label: "All" },
  { value: "tippingCompetition", label: "Tipping Comp" },
  { value: "oddComparison", label: "SOC" },
];

const paymentStatusOption = [
  { value: null, label: "All" },
  { value: "success", label: "Success" },
  { value: "failed", label: "Failed" },
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const PurchasedHistory = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [selectedSort, setselectedSort] = useState("DESC");
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [historyCount, setHistoryCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [paymentBase, setPaymentBase] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentPage, setcurrentPage] = useState(1);
  const rowPerPage = 10;

  const handleSortchange = (e) => {
    setselectedSort(e?.value);
    fetchHistoryData(0, paymentBase, paymentStatus, e?.value);
    setcurrentPage(1);
    setOffset(0);
  };

  const handlePaymentBasechange = (e) => {
    setPaymentBase(e?.value);
    fetchHistoryData(0, e?.value, paymentStatus, selectedSort);
    setcurrentPage(1);
    setOffset(0);
  };

  const handleStatuschange = (e) => {
    setPaymentStatus(e?.value);
    fetchHistoryData(0, paymentBase, e?.value, selectedSort);
    setcurrentPage(1);
    setOffset(0);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const fetchHistoryData = async (offset, paymentBase, paymentStatus, sort) => {
    setIsLoading(true);
    setSelectedId(null);
    try {
      const { status, data } = await axiosInstance.get(
        `/user/payment/history?limit=${rowPerPage}&offset=${offset}&paymentBase=${
          paymentBase ? paymentBase : ""
        }&status=${
          paymentStatus ? paymentStatus : ""
        }&paymentType=&sort=createdAt&orderBy=${sort}`,
      );
      if (status === 200) {
        setIsLoading(false);
        setHistoryData(data?.result);
        setHistoryCount(data?.count);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handlePaginationClick = (event, page) => {
    setcurrentPage(Number(page));
    setOffset((Number(page) - 1) * rowPerPage);

    fetchHistoryData(
      (Number(page) - 1) * rowPerPage,
      paymentBase,
      paymentStatus,
      selectedSort,
    );
  };

  const pageNumbers = [];

  if (historyCount > 0) {
    for (let i = 1; i <= Math.ceil(historyCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  useEffect(() => {
    fetchHistoryData(0, null, null, "DESC");
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const CollapseComponent = ({ row }) => {
    return (
      <>
        <Collapse
          in={open && selectedId === row?.id}
          timeout="auto"
          unmountOnExit
        >
          {row?.paymentBase === "tippingCompetition" ||
          row?.paymentBase === "tippingCompetitionEntryFee" ? (
            <Box className="history-extra-info-wrap">
              <Typography className="history-extra-info info-label">
                Details:
              </Typography>
              <Typography className="history-extra-info">
                <span className="info-label">Tipping Comp : </span>
                <span>
                  {row?.TippingCompetition
                    ? row?.TippingCompetition?.competitionName
                    : ""}
                </span>
              </Typography>
              <Typography className="history-extra-info badge-icon-wrap">
                <span className="info-label">Tipping : </span>
                <span className="icon-wrap">
                  <SubsBadge className="subs-badge" />
                  {row?.TippingCompetition?.tippingType == "winning"
                    ? "Winner Tipping"
                    : row?.TippingCompetition?.tippingType == "odds"
                      ? "Odds Tipping"
                      : row?.TippingCompetition?.tippingType == "spread"
                        ? "SpreadLine Tipping"
                        : ""}
                </span>
              </Typography>
              <Typography className="history-extra-info">
                <span className="info-label">Total paid : </span>
                <span>{row?.amount ? formatter.format(row?.amount) : ""}</span>
              </Typography>
              <Typography className="history-extra-info">
                <span className="info-label">Platform : </span>
                <span>
                  {row?.paymentType === "stripe"
                    ? "Web"
                    : row?.paymentType === "ios" ||
                        row?.paymentType === "android"
                      ? "Mobile"
                      : row?.paymentType === "bank"
                        ? "Bank"
                        : ""}
                </span>
              </Typography>
            </Box>
          ) : row?.paymentBase === "oddComparison" ? (
            <Box className="history-extra-info-wrap">
              <Typography className="history-extra-info info-label">
                Details:
              </Typography>
              <Typography className="history-extra-info">
                <span className="info-label">Plan : </span>
                <span>
                  {row?.SubscriptionPurchased?.SubscriptionPlan?.name}
                </span>
              </Typography>

              <Typography className="history-extra-info">
                <span className="info-label">Total paid : </span>
                <span>{row?.amount ? formatter.format(row?.amount) : ""}</span>
              </Typography>
              <Typography className="history-extra-info">
                <span className="info-label">Platform : </span>
                <span>
                  {row?.paymentType === "stripe"
                    ? "Web"
                    : row?.paymentType === "ios" ||
                        row?.paymentType === "android"
                      ? "Mobile"
                      : ""}
                </span>
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Collapse>
      </>
    );
  };

  const Row = (props) => {
    const { row } = props;

    return (
      <React.Fragment>
        <TableRow className={open && selectedId === row?.id ? "no-border" : ""}>
          <TableCell component="th" scope="row">
            {row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : ""}
          </TableCell>
          <TableCell>{row?.id}</TableCell>
          <TableCell>
            {row?.amount ? formatter.format(row?.amount) : ""}
          </TableCell>
          <TableCell className="text-capitalize">
            {row?.paymentBase === "tippingCompetition" ||
            row?.paymentBase === "tippingCompetitionEntryFee"
              ? row?.TippingCompetition?.competitionName
              : row?.paymentBase === "oddComparison"
                ? row?.SubscriptionPurchased?.SubscriptionPlan?.name
                : ""}
          </TableCell>
          <TableCell
            className={`text-capitalize ${
              row?.status === "success"
                ? "success-msg"
                : row?.status === "failed"
                  ? "failed-msg"
                  : ""
            }`}
          >
            {row?.status}
          </TableCell>
          <TableCell className="details-btn">
            <span
              onClick={() => {
                setOpen(selectedId === row?.id ? !open : true);
                setSelectedId(row?.id);
              }}
            >
              Details
              <IconButton aria-label="expand row" size="small">
                {open && selectedId === row?.id ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </span>
          </TableCell>
        </TableRow>
        {open && selectedId === row?.id && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <CollapseComponent row={row} />
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };
  return (
    <>
      <Box className="purchase-history-wrapper">
        <Box className="useredit-details">
          <h4>{localesData?.EDITPROFILE?.PURCHASE}</h4>
        </Box>

        <Box className="filter-section">
          <Select
            className="React sort-select"
            value={SortData?.find((item) => {
              return item?.value === selectedSort;
            })}
            onChange={(e) => handleSortchange(e)}
            options={SortData}
            classNamePrefix="select"
            placeholder="Sort: Date (Newest to Oldest)"
            components={{ DropdownIndicator }}
          />
          <Select
            className="React sort-select"
            value={paymentBaseOption?.find((item) => {
              return item?.value === paymentBase;
            })}
            onChange={(e) => handlePaymentBasechange(e)}
            options={paymentBaseOption}
            classNamePrefix="select"
            placeholder="Entry"
            components={{ DropdownIndicator }}
          />
          <Select
            className="React sort-select"
            value={paymentStatusOption?.find((item) => {
              return item?.value === selectedSort;
            })}
            onChange={(e) => handleStatuschange(e)}
            options={paymentStatusOption}
            classNamePrefix="select"
            placeholder="Status"
            components={{ DropdownIndicator }}
          />
        </Box>
        <Box className="payment-section-wrapper">
          {isLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : historyData?.length > 0 ? (
            screenWidth > 799 ? (
              <TableContainer className="payment-table-container">
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Date</TableCell>
                      <TableCell>Invoice</TableCell>
                      <TableCell>Cost</TableCell>
                      <TableCell>Discription</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historyData?.map((row) => (
                      <Row key={row?.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box>
                {historyData?.map((row) => (
                  <Box className="payment-table-container">
                    <Box className="res-history-header-wrap">
                      <Typography className="history-header text-capitalize">
                        {row?.paymentBase === "tippingCompetition" ||
                        row?.paymentBase === "tippingCompetitionEntryFee"
                          ? row?.TippingCompetition?.competitionName
                          : row?.paymentBase === "oddComparison"
                            ? row?.SubscriptionPurchased?.SubscriptionPlan?.name
                            : ""}
                      </Typography>
                      <Typography className="details-btn">
                        <span
                          onClick={() => {
                            setOpen(selectedId === row?.id ? !open : true);
                            setSelectedId(row?.id);
                          }}
                        >
                          Details
                          <IconButton aria-label="expand row" size="small">
                            {open && selectedId === row?.id ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </span>
                      </Typography>
                    </Box>
                    <Box className="res-history-data-wrap">
                      <Box className="info-wrap">
                        <Typography className="info-label">Date:</Typography>
                        <Typography className="info-des">
                          {" "}
                          {row?.createdAt
                            ? moment(row?.createdAt).format("DD/MM/YYYY")
                            : ""}
                        </Typography>
                      </Box>
                      <Box className="info-wrap">
                        <Typography className="info-label">Invoice:</Typography>
                        <Typography className="info-des">{row?.id}</Typography>
                      </Box>
                      <Box className="info-wrap">
                        <Typography className="info-label">Cost:</Typography>
                        <Typography className="info-des">
                          {" "}
                          {row?.amount ? formatter.format(row?.amount) : ""}
                        </Typography>
                      </Box>
                      <Box className="info-wrap">
                        <Typography className="info-label">Status:</Typography>
                        <Typography
                          className={`info-des text-capitalize ${
                            row?.status === "success"
                              ? "success-msg"
                              : row?.status === "failed"
                                ? "failed-msg"
                                : ""
                          }`}
                        >
                          {" "}
                          {row?.status}
                        </Typography>
                      </Box>
                    </Box>
                    {open && selectedId === row?.id && (
                      <Box className="res-details-wrap">
                        <CollapseComponent row={row} />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )
          ) : (
            <Box className="no-data-wrapper">
              <Typography className="no-data-text f-16">
                No Data Available
              </Typography>
            </Box>
          )}
        </Box>
        {!isLoading && historyData?.length > 0 ? (
          <Box className="pagination-section">
            <Pagination
              disabled={historyCount / rowPerPage > 1 ? false : true}
              page={currentPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              size="small"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default PurchasedHistory;
