import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { identifiers } from "src/helpers/constants/identifier";
import { ReactComponent as DownArrow } from "src/assets/images/icons/menuArrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/eventcalendar/arrowRight.svg";
import { CustomTooltip } from "./CustomMenuTooltip";
import "./headerMenuList.scss";
import { Config, fetchFromStorage } from "src/helpers/context";
import { useDispatch } from "react-redux";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import SmartPlayLogoPng from "src/assets/images/newHeaderIcon/smartPlay2x.png";
import SmartTippingLogo from "src/assets/images/newHeaderIcon/smartTipping2x.png";
import SmartInfoLogo from "src/assets/images/newHeaderIcon/smartInfo2x.png";
import { release } from "src/helpers/context/release";

const HeaderMenuList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  let searchParamsType = new URLSearchParams(window.location.search).get(
    "type"
  );
  const [isTippingSection, setIsTippingSection] = useState(false);
  const [isSmartInfoSection, setIsSmartInfoSection] = useState(false);
  const [menuItems, setMenuItems] = useState(identifiers?.Menu);

  // Update section states based on current location
  useEffect(() => {
    const isTippingPage = location?.pathname?.includes("/tipscompetition");
    const isSmartInfoPage = location?.pathname?.includes("/smartinfo");
    setIsTippingSection(isTippingPage);
    setIsSmartInfoSection(isSmartInfoPage);
  }, [location?.pathname]);

  const isActiveMenuItem = (item) => {
    if (location?.pathname !== "/comingsoon") {
      if (location?.pathname.includes("/racing")) {
        return item?.url === "/racing";
      } else if (
        item?.submenu?.length &&
        item?.submenu?.find(
          (obj) =>
            obj?.url?.includes(location.pathname) &&
            obj?.parentId == item?.id &&
            location?.pathname !== "/"
        )
      ) {
        return true;
      } else {
        return (
          item?.url === location.pathname ||
          ((location.pathname.includes("/responsible-gambling") ||
            location.pathname.includes("/privacy-policy") ||
            location.pathname.includes("/terms-and-conditions")) &&
            item?.id === 8) ||
          (location.pathname.includes("/statistics/trackprofiles") &&
            item?.id === 9) ||
          (location.pathname.includes("/teamsports") && item?.id === 3) ||
          (location.pathname.includes("/news") && item?.id === 10) ||
          (location.pathname.includes("/tipscompetition") && item?.id === 12)
        );
      }
    }
    return false;
  };

  const isActiveSubMenuItem = (menuData, item, sub) => {
    if (menuData?.id === 2) {
      return (
        ((sub?.url === location.pathname ||
          location.pathname.includes(sub?.url) ||
          location.pathname.includes(sub?.routeName)) &&
          menuData?.id === sub?.parentId) ||
        (menuData?.id === sub?.raceParentId &&
          location.pathname.includes(sub?.url)) ||
        (location?.pathname?.includes("/racing") &&
          (sub?.id == searchParamsType || sub?.id == params.sportId)) ||
        sub?.name === "Show All Sports" ||
        sub?.name === "Statistics"
      );
    } else {
      return (
        (sub?.url === location.pathname && menuData?.id === sub?.parentId) ||
        location.pathname.includes(sub?.url) ||
        (location.pathname.includes(sub?.routeName) &&
          menuData?.id === sub?.parentId) ||
        sub?.name === "Show All Sports" ||
        sub?.name === "Statistics"
      );
    }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  // OPEN SUBMENUS

  const handleNavigate = (navData, data) => {
    // Check if clicking on tipping competition logo
    if (navData?.id === 12 && navData?.logo) {
      if (handleRestrictedUser()) {
        setIsTippingSection(true);
        setIsSmartInfoSection(false);
        navigate("/tipscompetition/my-comps");
      } else {
        setIsTippingSection(true);
        setIsSmartInfoSection(false);
        dispatch(fetchRestrictedRoute(true));
        localStorage.setItem("prev_authroute", navData?.url || "");
      }
      return;
    }

    // Handle SmartInfo Logo Click
    if (navData?.id === 10 && navData?.logo) {
      setIsSmartInfoSection(true);
      setIsTippingSection(false);
      navigate("/smartinfo/news/0");
      return;
    }

    if (
      data?.sportParentId === 98 ||
      (navData?.id === 99 && data?.parentId === 3) ||
      (data?.parentId === 12 && navData?.name === "Create Comps") ||
      (data?.parentId === 12 && navData?.name === "My Comps") ||
      (data?.parentId === 12 && navData?.name === "Tipping FAQs") ||
      (data?.parentId === 12 && navData?.name === "Prizes") ||
      (data?.parentId === 10 && data?.id === 11) ||
      (data?.parentId === 10 && data?.id === 12) ||
      (data?.parentId === 10 && data?.id === 13) ||
      (navData?.raceParentId === 2 && navData?.id === 100)
    ) {
      if (handleRestrictedUser()) {
        if (navData?.url) {
          navData?.raceParentId === 2 && navData?.id === 100
            ? navigate(navData?.url, { state: true })
            : navigate(navData?.url);
        }
      } else {
        if (navData?.raceParentId === 2 && navData?.id === 100) {
          navigate("/soc-signup");
        } else {
          dispatch(fetchRestrictedRoute(true));
          localStorage.setItem("prev_authroute", navData?.url);
        }
      }
    } else {
      if (
        !handleRestrictedUser() &&
        navData?.parentId === 10 &&
        navData?.id === 10
      ) {
        navigate("/soc-signup");
      } else {
        navigate(navData?.url);
      }
    }
  };

  const subMenuList = (menuData, item) => {
    return item?.map((sub, i) => (
      <CustomTooltip
        placement="right-start"
        interactive
        title={
          sub?.secondSubMenu && sub?.secondSubMenu?.length > 0 ? (
            <Box className="v3-header-sub-menu-wrapper">
              {subMenuList(menuData, sub?.secondSubMenu)}
            </Box>
          ) : (
            ""
          )
        }
        // open={sub?.secondSubMenu?.length === 9 ? true : false}
        key={i}
        classes={{
          tooltip: "custom-sub-menu-tooltip",
        }}
        PopperProps={{
          style: {
            zIndex: 1200,
          },
        }}
      >
        <Box className="v3-header-sub-menu-list">
          <Box
            // to={sub?.url}
            // className="v3-header-sub-menu-btn"
            className={`v3-header-sub-menu-btn ${
              isActiveSubMenuItem(menuData, item, sub) &&
              sub?.name !== "Show All Sports"
                ? "active"
                : "inactive"
            }`}
            onClick={() => {
              handleNavigate(sub, sub);
            }}
          >
            {sub?.name}
            {sub?.secondSubMenu && sub?.secondSubMenu?.length > 0 && (
              <RightArrow className="v3-header-sub-menu-arrow-icon" />
            )}
          </Box>
        </Box>
      </CustomTooltip>
    ));
  };

  const handleRedirectfantasy = () => {
    const localAuth = fetchFromStorage("auth_token");
    if (handleRestrictedUser()) {
      // window.location.href = `${Config.fantasySiteBaseURL}?login_token=${localAuth}`;
      window.location.href = `${Config.fantasySiteBaseURL}`;
    } else {
      window.location.href = Config.fantasySiteBaseURL;
    }
  };
  return (
    <Box className="v3-header-menu-wrapper">
      {isTippingSection ? (
        <>
          <Box className="v3-header-menu-list">
            <Box className="v3-header-menu-btn">
              <img src={SmartTippingLogo} alt="logo" />
            </Box>
            {release[Config.release]?.tippingSubMenuItems?.map(
              (item, index) => (
                <Box
                  key={index}
                  className={`v3-header-menu-btn ${
                    location?.pathname === item?.url ? "active" : "inactive"
                  }`}
                  onClick={() => handleNavigate(item, item)}
                >
                  {item?.name}
                </Box>
              )
            )}
          </Box>
        </>
      ) : isSmartInfoSection ? (
        <>
          <Box className="v3-header-menu-list">
            <Box className="v3-header-menu-btn">
              <img src={SmartInfoLogo} alt="logo" />
            </Box>
            {release[Config.release]?.smartInfoSubMenuItems?.map(
              (item, index) => (
                <Box
                  key={index}
                  className={`v3-header-menu-btn ${
                    location?.pathname?.includes(item?.routeName)
                      ? "active"
                      : "inactive"
                  }`}
                  onClick={() => handleNavigate(item, item)}
                >
                  {item?.name}
                </Box>
              )
            )}
          </Box>
        </>
      ) : (
        <>
          {menuItems
            ?.filter((item) => item?.name !== "SmartPlay")
            ?.map((item, index) => {
              return (
                <Box key={index} className="v3-header-menu-list">
                  <CustomTooltip
                    placement="bottom-start"
                    interactive
                    title={
                      item?.submenu && item?.submenu?.length > 0 ? (
                        <Box className="v3-header-sub-menu-wrapper">
                          {subMenuList(item, item?.submenu)}
                        </Box>
                      ) : (
                        ""
                      )
                    }
                    PopperProps={{
                      style: {
                        zIndex: 1200,
                      },
                    }}
                  >
                    <Box
                      className={`v3-header-menu-btn ${
                        isActiveMenuItem(item) ? "active" : "inactive"
                      }`}
                      onClick={() => handleNavigate(item)}
                    >
                      {item?.logo ? (
                        <Box className="logo-img-header">
                          <Link to={`${window.location?.origin + item?.url}`}>
                            <img src={item?.logo} alt="logo" />
                          </Link>
                        </Box>
                      ) : (
                        item?.name
                      )}
                      {item?.submenu && item?.submenu?.length > 0 && (
                        <DownArrow className="v3-header-menu-arrow-icon" />
                      )}
                    </Box>
                  </CustomTooltip>
                </Box>
              );
            })}
        </>
      )}
      {!(
        location?.pathname?.includes("/tipscompetition") ||
        location?.pathname?.includes("/smartinfo")
      ) && (
        <Box
          onClick={() => handleRedirectfantasy()}
          sx={{ cursor: "pointer" }}
          className="v3-header-menu-list"
        >
          <Box className="v3-header-menu-btn">
            <Box className="logo-img-header">
              <Link to={`${window.location?.origin + "/fantasy"}`}>
                <img
                  src={SmartPlayLogoPng}
                  // style={{ height: 24 }}
                  alt="SmartPlay"
                />
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HeaderMenuList;
