import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Breadcrumbs,
  Collapse,
  ClickAwayListener,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Search } from "../../../../assets/images/icons/search.svg";
import IndividualNews from "../leftNewsSiderBar/individualNews";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import RightNewsSideBar from "../rightNewsSideBar";
import { useSelector } from "react-redux";
import AdBannner from "../../AdBanner/AdBanner";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import "../news.scss";
import "./saveArticle.scss";
import ScriptLoader from "../../scriptLoader/ScriptLoader";
import { Config } from "src/helpers/context";
import LeftSaveArticle from "./leftSaveArticle";

const SaveArticle = () => {
  // const silderRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [categoryList, setCategoryList] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [isArticleLoading, setisArticleLoading] = useState(false);
  const [savedArticleData, setSavedArticleData] = useState([]);
  const [categoryCount, setcategoryCount] = useState(0);
  const rowPerPage = 10;
  const [currentPage, setcurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [moreTabData, setMoreTabData] = useState(false);
  const [newsActiveTab, setNewsActiveTab] = useState(0);
  const tabsRef = useRef(null);
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  useEffect(() => {
    let box = document.querySelector(".custom-news-wrap");
    let width = box?.offsetWidth;
    fetchNewsCategoryList();

    fetchSavedArticle("", 0, searchValue);

    // setTimeout(() => {
    //   const activeTab = document.querySelector(".custom-news-wrap");
    //   if (activeTab) {
    //     activeTab.scrollIntoView({ behavior: "smooth", block: "center" });
    //   }
    //   setNewsActiveTab(Number(params?.categoryId));
    // }, 100);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const activeTab = document.querySelector(".custom-news-wrap");
      if (activeTab) {
        activeTab.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 100);
  }, [newsActiveTab, params]);

  const fetchSavedArticle = async (id, page, searchValue) => {
    try {
      setisArticleLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/articles?NewsCategoryId=${
          id ? id : ""
        }&limit=${rowPerPage}&offset=${page}&search=${
          searchValue && searchValue !== "" ? searchValue : ""
        }&IsSaved=true`,
      );
      if (status === 200) {
        setSavedArticleData(data?.result?.raw);
        setcategoryCount(data?.result?.count);
        setisArticleLoading(false);
      } else {
        setisArticleLoading(false);
      }
    } catch (err) {
      setisArticleLoading(false);
    }
  };
  const handleSearch = (searchValue) => {
    fetchSavedArticle("", 0, searchValue);
    setcurrentPage(1);
    searchValue != "" ? setIsSearch(true) : setIsSearch(false);
  };
  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch(e.target.value);
    }
  };

  const fetchNewsCategoryList = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `v2/news/category?All=0`,
      );
      if (status === 200) {
        setLoading(false);
        let response = data?.result;
        let newdata = [];
        let FinalData = response?.map((item) => {
          newdata.push({
            label: item?.title?.includes("-")
              ? item?.title?.replace("-", " ")
              : item?.title,
            value: item?.id,
          });
        });

        const sortedData = newdata?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        let alldatas = sortedData?.unshift({
          label: "all",
          value: 0,
        });
        setCategoryList(sortedData);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleTabClick = (index) => {
    // navigate(`/smartinfo/news/${index}`);
    setNewsActiveTab(index);
    setMoreTabData(false);

    fetchSavedArticle(index, 0, searchValue);
    setcurrentPage(1);
  };

  const handleOutsideClick = () => {
    if (moreTabData) {
      setMoreTabData(false);
    }
  };

  const pageNumbers = [];

  if (categoryCount > 0) {
    for (let i = 1; i <= Math.ceil(categoryCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const handlePaginationClick = (event, page) => {
    setcurrentPage(Number(page));
    fetchSavedArticle(
      newsActiveTab,
      (Number(page) - 1) * rowPerPage,
      searchValue,
    );
  };

  return (
    <>
      <ScriptLoader scriptCode={scriptCode} />
      <Box>
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerIndia : banner,
          )}
        </Box>
        <Grid container className="news-section">
          <Grid item size={{ lg: 12 }} className="left-section all-cat-section">
            <Box className="left-section-wrap">
              <Box className="news-header">
                <Box className="bredcrumn-wrap">
                  <Breadcrumbs
                    separator="/"
                    aria-label="breadcrumb"
                    className="breadcrumb"
                  >
                    <Link underline="hover" color="inherit" to="/">
                      {/* {localesData?.MENU?.HOME} */}
                      Home
                    </Link>

                    <Link
                      underline="hover"
                      color="inherit"
                      to="#"
                      className="cursor-default"
                    >
                      {/* {localesData?.MENU?.HOME} */}
                      Smart Info
                    </Link>
                    <Typography>Saved articles</Typography>
                  </Breadcrumbs>
                </Box>
                <Typography variant="h1">Saved articles</Typography>
              </Box>
              <Box className="news-details">
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  type="text"
                  name="search"
                  className="search_input"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyPress={(e) => handleSearchKeyPress(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Search onClick={() => handleSearch(searchValue)} />
                      </InputAdornment>
                    ),
                  }}
                />
                <ClickAwayListener onClickAway={() => handleOutsideClick()}>
                  {params?.id ? (
                    <Box className="custom-news-wrap">
                      <Box className="custom-news-tabs">
                        {categoryList?.slice(0, 8)?.map((item) => (
                          <Box
                            key={item?.value}
                            className={`tab ${
                              newsActiveTab === item?.value ? "active" : ""
                            }`}
                            onClick={() => {
                              handleTabClick(item?.value);
                            }}
                          >
                            {item?.label}
                          </Box>
                        ))}
                        {categoryList?.length > 8 ? (
                          <Box
                            className="tab tab-more"
                            onClick={() => setMoreTabData(!moreTabData)}
                          >
                            more <SelectIndicator />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Collapse in={moreTabData} className="more-news-tab">
                        {categoryList?.slice(8)?.map((item) => {
                          return (
                            <>
                              <Box
                                key={item?.value}
                                className={`tab ${
                                  newsActiveTab === item?.value
                                    ? categoryList?.slice(8)?.length % 2 === 0
                                      ? "active last-active"
                                      : "active last-second"
                                    : ""
                                }`}
                                onClick={() => handleTabClick(item?.value)}
                              >
                                {item?.label}
                              </Box>
                            </>
                          );
                        })}
                      </Collapse>
                    </Box>
                  ) : (
                    <Box className="custom-news-wrap">
                      <Box className="custom-news-tabs">
                        {categoryList?.slice(0, 10)?.map((item) => (
                          <Box
                            key={item?.value}
                            className={`tab ${
                              newsActiveTab === item?.value ? "active" : ""
                            }`}
                            onClick={() => {
                              handleTabClick(item?.value);
                            }}
                          >
                            {item?.label}
                          </Box>
                        ))}
                        {categoryList?.length > 10 ? (
                          <Box
                            className="tab tab-more"
                            onClick={() => setMoreTabData(!moreTabData)}
                          >
                            more <SelectIndicator />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Collapse in={moreTabData} className="more-news-tab">
                        {categoryList?.slice(10)?.map((item) => {
                          return (
                            <>
                              <Box
                                key={item?.value}
                                className={`tab ${
                                  newsActiveTab === item?.value
                                    ? categoryList?.slice(10)?.length % 2 === 0
                                      ? "active last-active"
                                      : "active last-second"
                                    : ""
                                }`}
                                onClick={() => handleTabClick(item?.value)}
                              >
                                {item?.label}
                              </Box>
                            </>
                          );
                        })}
                      </Collapse>
                    </Box>
                  )}
                </ClickAwayListener>
              </Box>

              <Box className="news-layout-section">
                <Grid container className="news-section">
                  <>
                    <Grid
                      item
                      size={{ lg: 9 }}
                      className="left-section left-saved-article-section"
                    >
                      <Box className="left-saved-container">
                        <LeftSaveArticle
                          categoryList={categoryList}
                          savedArticleData={savedArticleData}
                          isArticleLoading={isArticleLoading}
                          categoryCount={categoryCount}
                          fetchSavedArticle={() =>
                            fetchSavedArticle(
                              newsActiveTab,
                              (Number(currentPage) - 1) * rowPerPage,
                              searchValue,
                            )
                          }
                          currentPage={currentPage}
                        />
                        {savedArticleData?.length > 0 && (
                          <Box className="news-Pagination">
                            <Pagination
                              disabled={
                                categoryCount / rowPerPage > 1 ? false : true
                              }
                              page={currentPage}
                              onChange={(e, value) =>
                                handlePaginationClick(e, value)
                              }
                              count={pageNumbers[pageNumbers?.length - 1]}
                              siblingCount={2}
                              boundaryCount={1}
                              // count={10}
                              size="small"
                            />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid item size={{ lg: 3 }} className="right-section">
                      <RightNewsSideBar />
                    </Grid>
                  </>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SaveArticle;
