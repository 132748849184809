import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export default function CommonTabs({
  tabs,
  teamTypeTabs,
  womenTeamTypeTabs,
  activeTab,
  setActiveTab,
}) {
  const [teamType, setTeamType] = React.useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab.id);
  };

  const handleTeamTypeTabClick = (tab) => {
    setTeamType(tab.id);
  };

  return (
    <Box className="tab-container">
      <Box className="tab-wrap tab-wrap-bottom">
        {tabs?.map((tab, index) => (
          <Box
            key={index}
            className={`tab-details ${activeTab === tab?.id ? "tab-actives" : ""}`}
            onClick={() => handleTabClick(tab)}
          >
            <p className="tab-text">{tab?.label}</p>
          </Box>
        ))}
      </Box>
      {/* <Box className="tab-wrap">
        {(activeTab === 1 ? teamTypeTabs : womenTeamTypeTabs)?.map(
          (tab, index) => (
            <Box
              key={index}
              className={`tab-details ${teamType === tab?.id ? "tab-active" : ""}`}
              onClick={() => handleTeamTypeTabClick(tab)}
            >
              <p className="tab-text">{tab?.label}</p>
            </Box>
          ),
        )}
      </Box> */}
    </Box>
  );
}

CommonTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultValue: PropTypes.number,
  teamTypeTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  womenTeamTypeTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
