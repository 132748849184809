import React, { lazy, Suspense, useEffect, useState } from "react";
import { Box } from "@mui/material";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import pageHeaderIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AdBannner from "../../AdBanner/AdBanner";
import AboveOurPartner from "../../../../assets/images/ad-placeholder/sports2.webp";
import BelowOurPartner from "../../../../assets/images/ad-placeholder/sports5.webp";
import BannerIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import ScriptLoader from "../../scriptLoader/ScriptLoader";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";
import { renderHelmet } from "../teamCommonComponent/newSportsUtils";
import "./fixtureAndResult.scss";

const OurPartner = lazy(() => import("../../ourPartners"));
const FixtureAndResultLayouts = lazy(
  () => import("./fixtureAndResultComponent/fixtureAndResult"),
);

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const FixtureAndResultLanding = () => {
  const params = useParams();
  let teamSportType = params?.sportsTypeName;
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    fetchAdsData();
  }, [teamSportType]);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = async () => {
    const pageIds = {
      cricket: 6,
      rugbyleague: 7,
      rugbyunion: 8,
      basketball: 12,
      americanfootball: 16,
      australianrules: 17,
      baseball: 18,
      boxing: 19,
      icehockey: 20,
      mma: 21,
      soccer: 22,
      tennis: 23,
    };

    try {
      const pageId = pageIds[teamSportType] || "";
      if (!pageId) return;

      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${pageId}&type=web&timeZone=${timezone}`,
      );
      if (status === 200) {
        setAdData(data?.result);
      }
    } catch (err) {
      console.error("Failed to fetch ads data", err);
    }
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;

  return (
    <>
      {renderHelmet(teamSportType)}
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release === "IN" ? pageHeaderIndia : banner,
          )}
        </Box>
        <Suspense
          fallback={
            <div className="allsport-loader-center homepage-loader">
              <Loader />
            </div>
          }
        >
          <FixtureAndResultLayouts
            teamSportType={teamSportType}
            fetchAds={fetchAds}
          />
        </Suspense>
        {fetchAds(
          2,
          "100%",
          Config.release === "IN" ? BannerIndia : AboveOurPartner,
        )}
        <Suspense
          fallback={
            <div className="allsport-loader-center homepage-loader">
              <Loader />
            </div>
          }
        >
          <OurPartner />
        </Suspense>
        {Config.release === "AU" && fetchAds(3, "102px", BelowOurPartner)}
      </Box>
    </>
  );
};

export default FixtureAndResultLanding;
