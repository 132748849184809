import React from "react";

const DoneArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.482"
      height="13"
      viewBox="0 0 13.482 13"
    >
      <g
        id="Group_113740"
        data-name="Group 113740"
        transform="translate(0.419 0.155)"
      >
        <rect
          id="Rectangle_6968"
          data-name="Rectangle 6968"
          width="13"
          height="13"
          transform="translate(0 -0.155)"
          fill="none"
        />
        <path
          id="Path_12120"
          data-name="Path 12120"
          d="M4,10l4,4L14.659,6"
          transform="translate(-3.005 -3.599)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default DoneArrowIcon;
