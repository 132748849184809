import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import pageHeaderIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import AdBannner from "../../AdBanner/AdBanner";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import { Config } from "src/helpers/context";
import SportsHeader from "../teamCommonComponent/sportsHeader";
import ViewExpertTips from "../teamCommonComponent/viewExpertTips";
import OurPartner from "../../ourPartners";
import TournamnetListSelect from "../tournamnetListSelect";
import SeasonListSelect from "../seasonListSelect";
import "./statsLanding.scss";
import {
  battingStatsData,
  bowlingStatsData,
  rugbyLeageStateData,
  SPORTS_TYPE,
  statsData,
  teamStatsData,
} from "../teamCommonComponent/newSportsUtils";
import StatsLandingTable from "./statsTable";
import StatsLegend from "../rankingsLanding/statsLegend";
import StatsDashboard from "./StatsDashboard";
import { usePlayerStats } from "src/helpers/context/PlayersStats/PlayerStatsContext";

const stats = [
  { label: "Avg", text: "Average", column: 1 },
  { label: "Inns", text: "Innings", column: 1 },
  { label: "M", text: "Matches Played", column: 1 },
  { label: "SR", text: "Strike rate", column: 1 },
  { label: "HS", text: "Highest Scores", column: 1 },
  { label: "Wkt", text: "Wickets", column: 2 },
  { label: "O", text: "Overs", column: 2 },
  { label: "BBI", text: "Best Bowling in Innings", column: 2 },
  { label: "Mdns", text: "Maidens", column: 2 },
  { label: "Ecn", text: "Economy", column: 2 },
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const StatsLanding = () => {
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const urlTouranamentId = Number(searchParamsMenu.get("touranamentId"));
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const expertTipsCount = useSelector(
    (state) => state?.reduxData?.viewExpertTipsCount,
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const [leagueOption, setLeagueOption] = useState([]);
  const [seasonOption, setSeasonOption] = useState([]);

  const [selectedStatsLabel, setSelectedStatsLabel] = useState("Batting Stats");
  const [statsFilterOption, setStatsFilterOption] = useState([]);

  const sportsId = Number(params?.sportsId);

  const {
    stateOptions,
    setStateOptions,
    selectedStats,
    setSelectedStats,
    selectdStatsFilter,
    setSelectdStatsFilter,
    selectedLeague,
    setSelectedLeague,
    selectedSeason,
    setSelectedSeason,
    setDefaultStatsOption,
  } = usePlayerStats();

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    if (urlTouranamentId !== 0) {
      setSelectedLeague(urlTouranamentId);
    }
    setSelectedSeason(null);
    setSeasonOption([]);
  }, [urlTouranamentId]);

  useEffect(() => {
    const initialStats =
      selectedStatsLabel === "Batting Stats"
        ? battingStatsData
        : selectedStatsLabel === "Bowling Stats"
          ? bowlingStatsData
          : teamStatsData;

    setStatsFilterOption(initialStats);
    setSelectdStatsFilter(initialStats?.[0]?.value || null);
  }, []); // Runs only on mount

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleleagueChange = (e) => {
    setSelectedLeague(e?.value);
    setDefaultStatsOption();
    setSelectedSeason(null);
    setSeasonOption([]);
  };

  const handleSeasonChange = (e) => {
    setDefaultStatsOption();
    setSelectedSeason(e?.value);
  };

  const handleSelectedStatsValueChange = (e) => {
    setSelectedStats(e?.value);
    setSelectedStatsLabel(e?.label);
    const seletedOptionStats =
      e?.label === "Batting Stats"
        ? battingStatsData
        : e?.label === "Bowling Stats"
          ? bowlingStatsData
          : teamStatsData;
    setStatsFilterOption(seletedOptionStats);
    setSelectdStatsFilter(seletedOptionStats?.[0]?.value);
  };

  const handleStatsFilterValueChange = (e) => {
    setSelectdStatsFilter(e?.value);
  };

  // Setting State Options
  useEffect(() => {
    if (sportsId === SPORTS_TYPE?.circket) {
      setStateOptions(statsData);
    }

    if (sportsId === SPORTS_TYPE.rugbyLeague) {
      setStateOptions(rugbyLeageStateData);
    }
  }, [sportsId]);

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release === "IN" ? pageHeaderIndia : banner,
          )}
        </Box>
        <Box>
          <SportsHeader
            localesData={localesData}
            params={params}
            expertTipsCount={expertTipsCount}
            screenWidth={screenWidth}
          />
        </Box>
        <Box className="homepage-landing-page-wrap ranking-landing-page-wrap">
          <Box className="homepage-landing-header ranking-landing-header">
            <Box className="ranking-team-select-wrap">
              {urlTouranamentId === 0 && (
                <Box className="table-select league-table-select">
                  <TournamnetListSelect
                    selectOption={leagueOption}
                    setSelectOption={setLeagueOption}
                    selectedValue={selectedLeague}
                    setSelectedValue={setSelectedLeague}
                    handleSelectedValueChange={handleleagueChange}
                  />
                </Box>
              )}
              <Box className="table-select season-table-select">
                <SeasonListSelect
                  selectOption={seasonOption}
                  setSelectOption={setSeasonOption}
                  selectedValue={selectedSeason}
                  setSelectedValue={setSelectedSeason}
                  handleSelectedValueChange={handleSeasonChange}
                  selectedLeague={selectedLeague}
                />
              </Box>
              <Box className="table-select league-table-select stats-select">
                <Select
                  className="React league-select"
                  value={stateOptions?.find((item) => {
                    return item?.value === selectedStats;
                  })}
                  onChange={(e) => {
                    handleSelectedStatsValueChange(e);
                  }}
                  options={stateOptions}
                  classNamePrefix="select"
                  placeholder="Stats"
                  components={{ DropdownIndicator }}
                />
              </Box>
            </Box>
            {screenWidth >= 1023 && expertTipsCount > 0 && (
              <Box>
                <ViewExpertTips />
              </Box>
            )}
          </Box>
          <Box className="stats-section-wrap">
            <Box className="stats-header-section">
              <Typography className="stats-name">
                {selectedStatsLabel}
              </Typography>

              <Box
                className="table-select stats-table-select"
                sx={{
                  display: sportsId === SPORTS_TYPE?.rugbyLeague && "flex",
                  alignItems: "center",
                }}
              >
                {sportsId === SPORTS_TYPE?.rugbyLeague && (
                  <Box>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        sx={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="total"
                          control={<Radio />}
                          label="Total"
                        />
                        <FormControlLabel
                          value="average"
                          control={<Radio />}
                          label="Average"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}
                <Select
                  className="React league-select"
                  value={statsFilterOption?.find((item) => {
                    return item?.value === selectdStatsFilter;
                  })}
                  onChange={(e) => {
                    handleStatsFilterValueChange(e);
                  }}
                  options={statsFilterOption}
                  classNamePrefix="select"
                  placeholder="Stats"
                  components={{ DropdownIndicator }}
                />

                {sportsId === SPORTS_TYPE?.rugbyLeague && <StatsDashboard />}
              </Box>
            </Box>
            <Box>
              <StatsLandingTable selectedStats={selectedStats} />
            </Box>
          </Box>
          <Box>
            <StatsLegend stats={stats} />
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default StatsLanding;
