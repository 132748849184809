import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import { setApiMessage } from "src/helpers/commonFunctions";
import DeleteIcon from "src/components/Common/Icons/DeleteIcon";

const validationSchema = Yup.object({
  accountName: Yup.string().required("Account name is required"),
  bsb: Yup.string()
    .matches(/^\d{6}$/, "BSB must be 6 digits")
    .required("BSB is required"),
  accountNumber: Yup.string()
    .matches(/^\d+$/, "Account number must be numeric")
    .min(9, "Account number must be 9 digits")
    .max(9, "Account number must be 9 digits")
    .required("Account number is required"),
  bankName: Yup.string().required("Bank name is required"),
});

const BankForm = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [bankDetails, setBankDetails] = useState();
  const [loadingState, setLoadingState] = useState({
    updateBank: false,
  });

  const getBank = async () => {
    try {
      const { data } = await axiosInstance.get(
        Config.fantasyAPI + "/withdraw/get-bank/",
      );
      setBankDetails(data?.result);
    } catch (error) {}
  };

  const mutateBank = async ({ id, payload, type }) => {
    try {
      setLoadingState({ updateBank: true });

      let response;

      if (type === "create") {
        // Create new bank (POST request)
        response = await axiosInstance.post(
          `${Config.fantasyAPI}/withdraw/create-bank`,
          payload,
        );
      } else {
        // Update existing bank (PUT request)
        response = await axiosInstance.put(
          `${Config.fantasyAPI}/withdraw/update-bank/${id}`,
          payload,
        );
      }

      if (response.status === 200) {
        setApiMessage("success", response.data?.message);
        getBank(); // Refresh the bank data
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState({ updateBank: false });
    }
  };

  const deleteBank = async ({ id }) => {
    try {
      setLoadingState({
        updateBank: true,
      });
      const { status, data } = await axiosInstance.delete(
        Config.fantasyAPI + `/withdraw/delete-bank/${id}`,
      );
      if (status === 200) {
        setApiMessage("success", data?.message);
        getBank();
        formik.resetForm();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState({
        updateBank: false,
      });
    }
  };
  useEffect(() => {
    getBank();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  const formik = useFormik({
    initialValues: {
      accountName: "",
      bsb: "",
      accountNumber: "",
      bankName: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        accountHolderName: values?.accountName,
        accountNumber: `${values?.accountNumber}`,
        bsb: `${values?.bsb}`,
        bankName: values?.bankName,
      };
      mutateBank({
        id: bankDetails?.id,
        payload,
        type: bankDetails ? "update" : "create",
      });
    },
  });

  useEffect(() => {
    if (bankDetails) {
      formik.setValues({
        accountName: bankDetails?.accountHolderName,
        bsb: Number(bankDetails?.bsb),
        accountNumber: Number(bankDetails?.accountNumber),
        bankName: bankDetails?.bankName,
      });
    }
  }, [bankDetails]);

  return (
    <Box className="user-bank-details">
      {screenWidth > 799 && (
        <Box className="useredit-details">
          <h4>Bank Details </h4>
        </Box>
      )}
      <Box className="form-bank-details">
        <form onSubmit={formik.handleSubmit}>
          <Box className="details">
            <TextField
              variant="outlined"
              className="details-textfield"
              type="text"
              label="Account name"
              name="accountName"
              value={formik?.values?.accountName}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.accountName &&
                Boolean(formik?.errors?.accountName)
              }
              helperText={
                formik?.touched?.accountName && formik?.errors?.accountName
              }
            />
          </Box>
          <Box className="details">
            <TextField
              variant="outlined"
              className="details-textfield"
              type="number"
              label="BSB"
              name="bsb"
              value={formik.values.bsb}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bsb && Boolean(formik.errors.bsb)}
              helperText={formik.touched.bsb && formik.errors.bsb}
            />
          </Box>
          <Box className="details">
            <TextField
              variant="outlined"
              className="details-textfield"
              type="number"
              label="Account number"
              name="accountNumber"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.accountNumber &&
                Boolean(formik.errors.accountNumber)
              }
              helperText={
                formik.touched.accountNumber && formik.errors.accountNumber
              }
            />
          </Box>
          <Box className="details">
            <TextField
              variant="outlined"
              className="details-textfield"
              type="text"
              label="Bank name"
              name="bankName"
              value={formik.values.bankName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bankName && Boolean(formik.errors.bankName)}
              helperText={formik.touched.bankName && formik.errors.bankName}
            />
          </Box>
          {bankDetails?.id && (
            <Box
              sx={{ cursor: "pointer", display: "inline-block", width: 40 }}
              onClick={() => deleteBank({ id: bankDetails?.id })}
            >
              <DeleteIcon />
            </Box>
          )}
          <Box className="profile-button">
            <Box className="profile-btn">
              <Button
                variant="contained"
                className="btn-save"
                type="submit"
                disabled={loadingState?.updateBank}
              >
                Save bank details
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default BankForm;
