import { Box, Typography } from "@mui/material";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import TeamLogo from "src/assets/images/defaultTeam.png";
import SortIcons from "src/components/Common/Icons/SortIcons";
import { Config } from "src/helpers/context";

import { ReactComponent as FirstRank } from "src/assets/images/icons/first_rank.svg";
import { ReactComponent as SecondRank } from "src/assets/images/icons/second_rank.svg";
import { ReactComponent as ThirdRank } from "src/assets/images/icons/third_rank.svg";

export const viwTipLadderStaticColumns = (headerCount, tippingType) => [
  {
    accessorKey: "rank",
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          # <SortIcons isSorted={column.getIsSorted()} />
        </Box>
      </Box>
    ),
    cell: (info) => {
      const rank = info.getValue();

      return rank === 1 ? (
        <FirstRank />
      ) : rank === 2 ? (
        <SecondRank />
      ) : rank === 3 ? (
        <ThirdRank />
      ) : (
        <Typography sx={{ textAlign: "center" }}>{rank}</Typography>
      );
    },
  },
  {
    accessorKey: "firstName",
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          Tipster <SortIcons isSorted={column.getIsSorted()} />
        </Box>
      </Box>
    ),
    cell: ({ row }) => {
      const item = row?.original;
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box className="team-wrap">
            <Box className="team-image-wrap">
              <img
                src={
                  item?.filePath
                    ? item?.filePath?.includes("uploads")
                      ? Config.mediaURL + item?.filePath
                      : item?.filePath
                    : DefaultTeam
                }
                alt="Team Logo"
                className="team-image"
                onError={(e) => (e.target.src = DefaultTeam)}
              />
            </Box>
            <Box className="team-name-wrap">
              <Typography className="team-name">
                {row.original.firstName + " " + row.original.lastName}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    },
  },
  ...Array.from({ length: headerCount }, (_, i) => ({
    accessorKey: `teamData`,
    header: `Game ${i + 1}`,
    cell: ({ row }) => {
      const item = row?.original?.teamData?.[i];
      if (tippingType === "winning") {
        return (
          <Box className="odds-team-section">
            <Box className="team-wrap">
              <img
                src={
                  item?.flag
                    ? item?.flag?.includes("uploads")
                      ? Config.mediaURL + item?.flag
                      : item?.flag
                    : DefaultTeam
                }
                alt="logo"
              />
            </Box>
          </Box>
        );
      }
      if (tippingType === "odds") {
        return (
          <Box className="odds-team-section">
            <Box className="team-wrap">
              <img
                src={
                  item?.flag
                    ? item?.flag?.includes("uploads")
                      ? Config.mediaURL + item?.flag
                      : item?.flag
                    : DefaultTeam
                }
                alt="logo"
              />
            </Box>

            <Box className="odds-text">
              <span>{item?.odd ? item?.odd : "-"}</span>
            </Box>
          </Box>
        );
      } else {
        return (
          <Box className="odds-team-section">
            <Box className="team-wrap">
              <img
                src={
                  item?.flag
                    ? item?.flag?.includes("uploads")
                      ? Config.mediaURL + item?.flag
                      : item?.flag
                    : DefaultTeam
                }
                alt="logo"
              />
            </Box>
            <Box className="spread-line">
              <span>{item?.point}</span>
            </Box>
            <Box className="odds-text">
              <span>{item?.odd ? item?.odd : "-"}</span>
            </Box>
          </Box>
        );
      }
    },
  })),
];
