import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { KeyboardArrowDown } from "@mui/icons-material";
import TeamLogo from "../../../assets/images/sydneyRoosters.png";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import { dummyData } from "../newTeamSportPage/teamCommonComponent/newSportsUtils";
import axiosInstance from "src/helpers/Axios/axiosInstance";

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    backgroundColor: "#ffffff",
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    backgroundColor: "#ffffff",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

const TipsterTable = ({ compsId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isSorted, setIsSorted] = useState(false);
  const [sortOder, setSortOrder] = useState("desc");
  const SortIcons = ({ isSorted }) => (
    <Box className="sort-icon-wrap">
      {/* Ascending Arrow */}
      <span>
        <SortingArrow className={isSorted === true ? "asc-sort" : ""} />
      </span>
      {/* Descending Arrow */}
      <span className="down-arrow">
        <SortingArrow className={isSorted === false ? "desc-sort" : ""} />
      </span>
    </Box>
  );

  const getTipsters = async ({ sortOder = "asc" }) => {
    try {
      setLoading(true);
      const { data, status } = await axiosInstance.get(
        `tipping/tipsters?competitionId=${compsId}&round=1&sort=totalPoints&orderBy=${sortOder}`,
      );
      if (status === 200) {
        setTableData(data?.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTipsters({ sortOder });
  }, [sortOder]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  return (
    <>
      <Box className="new-landing-table-container-details view-tips-comp-table tipsters-table">
        {loading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <TableContainer>
            <Table className="new-landing-landing-table">
              <TableHead>
                <TableRow>
                  <StickyTableCell
                    className={`${classes.head} rnk-cell`}
                  ></StickyTableCell>
                  <StickyTableCell
                    className={`${classes.head} team-cell`}
                    align="left"
                  >
                    <Box
                      className="sorting-cell"
                      // onClick={toggleSortOrder}
                    >
                      Tipster
                      {/* <SortIcons isSorted={null} /> */}
                    </Box>
                  </StickyTableCell>
                  <TableCell
                    className={`${classes.head} text-align-center`}
                    align="center"
                  >
                    <Box className="sorting-cell text-align-center">
                      Entry Fee
                    </Box>
                  </TableCell>
                  <TableCell
                    className={`${classes.head} text-align-center`}
                    align="center"
                  >
                    <Box className="sorting-cell text-align-center">Status</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <div className="allsport-loader-center ">
                        <Loader />
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {!loading && tableData?.length > 0
                  ? tableData?.map((item, index) => {
                      return (
                        <>
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#F7F7F8" : "#FFFFFF",
                            }}
                          >
                            <StickyTableCell
                              className={`${classes.cell} rnk-cell`}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F7F7F8" : "#FFFFFF",
                              }}
                            >
                              {item?.rank}
                            </StickyTableCell>
                            <StickyTableCell
                              className={`${classes.cell} team-cell`}
                              align="left"
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F7F7F8" : "#FFFFFF",
                              }}
                            >
                              <Box className="team-wrap">
                                <Box className="team-image-wrap">
                                  <img
                                    src={
                                      item?.filePath
                                        ? item?.filePath?.includes("uploads")
                                          ? Config.mediaURL + item?.filePath
                                          : item?.filePath
                                        : DefaultTeam
                                    }
                                    onError={(e) => {
                                      e.target.src = DefaultTeam;
                                    }}
                                    alt="Team 1"
                                    className="team-image"
                                  />
                                </Box>
                                <Box className="team-name-wrap">
                                  <Typography className="team-name">
                                    {item?.firstName + " " + item?.lastName}
                                  </Typography>
                                </Box>
                              </Box>
                            </StickyTableCell>
                            <TableCell className={classes.cell} align="center">
                              ${item?.amount}
                            </TableCell>
                            <TableCell
                              className={`${classes.cell}`}
                              align="center"
                            >
                              <Box className="paid-and-notPaid-wrap">
                                <Button
                                  variant="contained"
                                  className={`paid-notPaid-btn  ${item?.status === "Not Paid" ? "notPaid" : "paid"}`}
                                >
                                  {item?.status}
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })
                  : !loading && (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography className="allsport-loader-center ">
                            No Data Available
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                {/* <TableRow>
                  <TableCell
                    colSpan={10}
                    align="center"
                    className="show-more-cell"
                  >
                    <Typography className="show-more-text">
                      Show more <KeyboardArrowDown />
                    </Typography>
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default TipsterTable;
