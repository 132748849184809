import React, { useState, useEffect, useContext, useRef } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Collapse,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { IntlContext } from "src/App";
import { useDispatch, useSelector } from "react-redux";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchAdvtData } from "../../helpers/store/Actions/Ads";
import MainMenubar from "./MainMenubar";
import LeftSideBar from "../../views/component/leftsidebar/leftsidebar";
import RightSideBar from "../../views/component/righsidebar/rightsidebar";
import Footer from "./../../views/component/footer/footer";
import "src/layouts/layout.scss";
import AdvertisingScreenPage from "src/views/component/advertisingScreenPage";
import TopHeaderBanner from "../../assets/images/ad-placeholder/Topheader.webp";
import TopHeaderBannerIndia from "../../assets/images/ad-placeholder/IPL_Header_Banner_1350x292_1.jpg";
import SideBgBanner from "../../assets/images/ad-placeholder/side-bg-ad.webp";
import SideBgBannerIndia from "../../assets/images/ad-placeholder/IPL_Side_Banner_218x766_2.jpg";
import GeofencingPage from "src/views/pages/RestrictedPage/geofencingpage";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import DynamicScript from "src/views/component/DynamicScript/DynamicScript";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import DialogBox from "src/views/component/UI/dialogBox";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as ShareSlip } from "src/assets/images/icons/shareSlip.svg";
import Bet365Slip from "src/assets/images/bet365Slip.png";
import Select, { components } from "react-select";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import MaintenancePage from "src/views/pages/MaintenancePage";
import { fetchSubscriptionData } from "../../helpers/store/Actions/SubscriptionData";
import { release } from "src/helpers/context/release";
import OddsCompLayout from "src/views/component/RacingOddsComparison";
import BetSlipCollapse from "./BetslipCollapse";
import { fetchALLBookkeeperData } from "src/helpers/store/Actions/bookkeeperData";
import LoginPopUp from "src/views/component/UI/loginPopup";
import FooterV2 from "src/views/component/footer/FooterV2";
import { fetchLayoutData } from "src/helpers/store/Actions/LayoutData";
import NewSportHeader from "./newSportHeader";
import HomeLandingProvider from "src/helpers/context/HomeLanding/HomeLandingContext";
import NewMenuListMobile from "./newMenuListMobile";
// import RaceAlertSound from "./raceAlertSound";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const options = [
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" },
  // { value: "3", label: "Win Tote" },
  // { value: "4", label: "Place Tote" }
  // { value: "4", label: "Starting Price" },
];

const defaultHome = {
  racingStateId: [],
  racingCountryId: [],
  racingFeaturedNews: [],
  racingExpertTips: [],
  tippingcompetitions: [],
  featuredPodcasts: [],
  sportFeaturedNews: [],
  sportResult: [],
  sportFeaturedFixtures: [],
  sportUpcomingFixtures: [],
  sportExpertTips: [],
  pageOrder: [],
};

const DashboardLayout = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const redux_user_data = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );
  const redux_auth = useSelector((state) => state?.reduxData?.authData);
  const redux_channel = useSelector((state) => state?.reduxData?.channelData);
  const redux_userData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );
  const user_statics = redux_userData
    ? redux_userData
    : fetchFromStorage("userData");
  const auth = redux_auth ? redux_auth : fetchFromStorage("authData");
  const channelData = redux_channel
    ? redux_channel
    : fetchFromStorage("channelData");
  const redux_tooltipStep = useSelector(
    (state) => state?.reduxData?.tooltipData,
  );
  const redux_intervaldata = useSelector(
    (state) => state?.reduxData?.intervalData,
  );

  const intervalPage = useSelector((state) => state?.reduxData?.page);
  const tooltipStep = redux_tooltipStep
    ? redux_tooltipStep
    : fetchFromStorage("tooltipStep");
  const redux_organisationData = useSelector(
    (state) => state?.reduxData?.organisationData,
  );
  const organisationData = redux_organisationData
    ? redux_organisationData
    : fetchFromStorage("organisationData");
  const token = localStorage.getItem("auth_token");
  const verify_count = useSelector(
    (state) => state?.reduxData?.user_profile_count,
  );
  const BetSlipToggleData = useSelector(
    (state) => state?.reduxData?.BetSlipToggle,
  );
  const restrictedUser = useSelector(
    (state) => state?.reduxData?.RestrictedData,
  );
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const [SideBGAdData, setSideBGAdData] = useState([]);
  const [TopHeaderAdData, setTopHeaderAdData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const myDiv = document.getElementById("adv-wrap");
  const [open, setOpen] = useState(false);
  const [progress, setprogress] = useState(false);
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(fetchRestrictedRoute(false));
    setOpen(false);
    localStorage.removeItem("prev_authroute");
  };
  useEffect(() => {
    fetchBookKeeper();
  }, []);

  useEffect(() => {
    if (
      (redux_user_data?.address === null ||
        redux_user_data?.address?.country === null ||
        redux_user_data?.address?.state === null ||
        redux_user_data?.dob === "") &&
      !location.pathname.includes("profile-edit")
    ) {
      navigate("/profile");
    }
  }, [location.pathname, redux_user_data]);
  useEffect(() => {
    if (restrictedUser) {
      handleModal();
    }
  }, [restrictedUser]);

  useEffect(() => {
    if (myDiv) myDiv.scrollTop = 0;
  }, [myDiv, location]);

  useEffect(() => {
    if (document.getElementById("adv-wrap")) {
      document.getElementById("adv-wrap").scrollIntoView();
      document.getElementById("adv-wrap").scrollTop += 10;
    }
  }, [location]);
  useEffect(() => {
    if (
      ((location.pathname.includes("/racing") ||
        location.pathname.includes("/profile") ||
        location.pathname.includes("/statistics") ||
        location.pathname.includes("/teamsports") ||
        location.pathname.includes("/sportmatchups") ||
        location.pathname.includes("/news") ||
        location.pathname.includes("/combatsports") ||
        location.pathname.includes("/sportmarket") ||
        location?.pathname?.includes("/blackbook") ||
        location?.pathname?.includes("/smartbook") ||
        location?.pathname?.includes("/tips-marketplace/enter-sports-tips") ||
        location?.pathname?.includes("/tips-marketplace/sports-tips") ||
        location?.pathname?.includes("/tipper-profile") ||
        location?.pathname?.includes("/tips-marketplace/enter-racing-tips") ||
        location?.pathname?.includes("/purchased-tips") ||
        location?.pathname?.includes("/team-sports-data") ||
        location?.pathname?.includes("/race/event-calendar") ||
        location?.pathname?.includes("/sport/event-calendar") ||
        location?.pathname?.includes("/recommended-websites") ||
        location?.pathname?.includes("/create-competition") ||
        location?.pathname?.includes("/my-comps") ||
        location?.pathname?.includes("/rankings") ||
        location?.pathname?.includes("/our-people") ||
        location?.pathname?.includes("/tips") ||
        location?.pathname?.includes("/cart-payment") ||
        location?.pathname?.includes("/messages") ||
        location?.pathname?.includes("/podcasts") ||
        location?.pathname?.includes("/gettips") ||
        location?.pathname?.includes("/international-racing") ||
        location?.pathname?.includes("/soc-signup") ||
        location?.pathname?.includes("/install-apk") ||
        location?.pathname?.includes("/saved-article") ||
        location?.pathname?.includes("/subscription-payment") ||
        location?.pathname?.includes("/membership") ||
        location?.pathname?.includes("/all-sports")) &&
        !location?.pathname?.includes("/race/event-calendar/details") &&
        !location?.pathname?.includes("/sport/event-calendar/details")) ||
      location?.pathname === "/"
    ) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [location.pathname]);
  if (redux_intervaldata) {
    if (intervalPage === "teamsports") {
      if (window.location.href.includes("teamsports")) {
      } else {
        clearInterval(redux_intervaldata);
        clearTimeout(redux_intervaldata);
      }
    } else {
      if (
        window.location.href.includes("horse") ||
        window.location.href.includes("greyhounds") ||
        window.location.href.includes("harness")
      ) {
      } else {
        clearInterval(redux_intervaldata);
        clearTimeout(redux_intervaldata);
      }
    }
  }
  const advertisingScreen = location.pathname.includes("/advertisingscreen");
  const maintenanceScreen = location.pathname.includes("/issue-page");
  const restrictedScreen = location.pathname.includes("/restricted");
  const racingOddsComparison = location.pathname.includes("/odds-comparison/");
  useEffect(() => {
    if (!advertisingScreen) {
      fetchAdsData();
    }
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleAdsImression = async (id, position) => {
    try {
      const payload = {
        id: id,
        position_id: [position],
      };
      const { status, data } = await axiosInstance.post(
        `addImpression`,
        payload,
      );
      if (status === 200) {
      }
    } catch (err) {}
  };

  const fetchAdsData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=1,9,10,11,40&type=web&timeZone=${timezone}`,
      );
      if (status === 200) {
        dispatch(fetchAdvtData(data?.result));
        let sideBgAdsData = data?.result?.filter((item) => item?.page_id === 9);
        setSideBGAdData(sideBgAdsData);
        let topHeaderAdsData = data?.result?.filter(
          (item) => item?.page_id === 10,
        );
        setTopHeaderAdData(topHeaderAdsData);

        if (screenWidth > 1440) {
          sideBgAdsData?.length > 0 &&
            handleAdsImression(sideBgAdsData?.[0]?.id, 1);
        }
        topHeaderAdsData?.length > 0 &&
          handleAdsImression(topHeaderAdsData?.[0]?.id, 1);
      }
    } catch (err) {}
  };
  const handleClick = async (id) => {
    try {
      let payload = {
        id: id,
        position_id: 1,
      };
      const { status, data } = await axiosInstance.post(`addClick`, payload);
      if (status === 200) {
      }
    } catch (err) {}
  };
  const handleNavigate = () => {
    navigate("/advertisingenquiries", { state: location });
  };
  const fetchTopheaderAds = () => {
    if (TopHeaderAdData?.length > 0) {
      let addetails = TopHeaderAdData?.[0];
      if (addetails) {
        return addetails?.MediaDetail?.type === "image" &&
          addetails?.MediaDetail?.image !== "" ? (
          <a
            href={addetails?.Campaign?.url}
            className="top-header-banner-wrap"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={
                addetails?.MediaDetail?.image?.includes("uploads")
                  ? Config.countryMediaURL + addetails?.MediaDetail?.image
                  : addetails?.MediaDetail?.image
              }
              onClick={() => handleClick(addetails?.id)}
              alt="ad-banner"
              className="top-header-banner"
              // preload="true"
              // loading="lazy"
              effect="blur"
              width="100%"
            />
          </a>
        ) : addetails?.MediaDetail?.type === "script" &&
          addetails?.MediaDetail?.script !== "" ? (
          <a
            href={addetails?.Campaign?.url}
            className="top-header-banner-wrap"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DynamicScript
              dynamicscript={addetails?.MediaDetail?.script}
              addetails={addetails}
              handleClick={handleClick}
            />
          </a>
        ) : (
          <Box
            // href={addetails?.Campaign?.url}
            className="top-header-banner-wrap"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="top-header-banner"
              src={
                Config.release == "IN" ? TopHeaderBannerIndia : TopHeaderBanner
              }
              alt="banner"
              onClick={() => handleNavigate()}
              // preload="true"
              // loading="lazy"
              effect="blur"
            />
          </Box>
        );
      } else {
        return (
          <Box
            // href="mailto:info@smartb.com.au"
            className="top-header-banner-wrap"
          >
            <img
              className="top-header-banner"
              src={
                Config.release == "IN" ? TopHeaderBannerIndia : TopHeaderBanner
              }
              alt="banner"
              onClick={() => handleNavigate()}
              // preload="true"
              // loading="lazy"
              effect="blur"
            />
          </Box>
        );
      }
    } else {
      return (
        <Box
          // href="mailto:info@smartb.com.au"

          className="top-header-banner-wrap"
        >
          <img
            className="top-header-banner"
            src={
              Config.release == "IN" ? TopHeaderBannerIndia : TopHeaderBanner
            }
            alt="banner"
            onClick={() => handleNavigate()}
            // preload="true"
            // loading="lazy"
            effect="blur"
          />
        </Box>
      );
    }
  };

  const fetchSideBgAds = (location) => {
    if (SideBGAdData?.length > 0) {
      let addetails = SideBGAdData?.[0];
      if (addetails) {
        return addetails?.MediaDetail?.type === "image" &&
          addetails?.MediaDetail?.image !== "" ? (
          <a
            href={addetails?.Campaign?.url}
            className={`side-bg-banner ${location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={
                addetails?.MediaDetail?.image?.includes("uploads")
                  ? Config.countryMediaURL + addetails?.MediaDetail?.image
                  : addetails?.MediaDetail?.image
              }
              onClick={() => handleClick(addetails?.id)}
              alt="ad-banner"
              effect="blur"
              width="100%"
              // preload="true"
              // loading="lazy"
            />
          </a>
        ) : addetails?.MediaDetail?.type === "script" &&
          addetails?.MediaDetail?.script !== "" ? (
          <a
            href={addetails?.Campaign?.url}
            className={`side-bg-banner ${location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <DynamicScript
              dynamicscript={addetails?.MediaDetail?.script}
              addetails={addetails}
              handleClick={handleClick}
            />
          </a>
        ) : (
          <Box
            // href={addetails?.Campaign?.url}
            className={`side-bg-banner ${location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Config.release == "IN" ? SideBgBannerIndia : SideBgBanner}
              alt="banner"
              onClick={() => handleNavigate()}
              // preload="true"
              // loading="lazy"
              effect="blur"
            />
          </Box>
        );
      } else {
        return (
          <Box
            // href="mailto:info@smartb.com.au"
            className="top-header-banner-wrap"
          >
            <img
              src={Config.release == "IN" ? SideBgBannerIndia : SideBgBanner}
              alt="banner"
              onClick={() => handleNavigate()}
              // preload="true"
              // loading="lazy"
              effect="blur"
            />
          </Box>
        );
      }
    } else {
      return (
        <Box
          // href="mailto:info@smartb.com.au"
          className={`side-bg-banner ${location}`}
        >
          <img
            src={Config.release == "IN" ? SideBgBannerIndia : SideBgBanner}
            alt="banner"
            onClick={() => handleNavigate()}
            // preload="true"
            // loading="lazy"
            effect="blur"
          />
        </Box>
      );
    }
  };

  const collapseStyles = {
    transformOrigin: "top right", // Set the transformation origin to top right
    transform: `scaleX(${BetSlipToggleData ? 1 : 0})`, // Scale the element in X-axis to 1 (normal size) when open, 0 when closed
    transition: "transform 0.3s ease-in-out", // Add a transition for a smooth animation
  };

  const pageTopRef = useRef(null);

  // const scrollToTop = () => {
  //   if (pageTopRef.current) {
  //     pageTopRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY;
    const scrollThreshold = 200;
    if (scrollY > scrollThreshold) {
      setprogress(true);
    } else {
      setprogress(false);
    }
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const tokenAuth =
        redux_auth && redux_auth !== ""
          ? redux_auth
          : fetchFromStorage("auth_token");
      tokenAuth && fetchSubscriptionPaymentData();
    }, 2000);
  }, []);

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
        if (data?.isDefaultHomePage === true) {
          fetchLayout("homepage");
        } else if (data?.isDefaultRacingPage === true) {
          fetchLayout("racingpage");
        } else if (data?.isDefaultSportPage === true) {
          fetchLayout("sportpage");
        } else {
          fetchLayout("homepage");
        }
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };

  const fetchLayout = async (type) => {
    try {
      const { status, data } = await axiosInstance.get(
        `user/customise/UserPagesGet?type=${type}`,
      );
      if (status === 200) {
        dispatch(fetchLayoutData(data?.result));
      } else {
        dispatch(fetchLayoutData(null));
      }
    } catch (err) {
      dispatch(fetchLayoutData(null));
    }
  };

  useEffect(() => {
    let SportId =
      params?.type === "cricket"
        ? 4
        : params?.type === "rugbyleague"
          ? 12
          : params?.type === "rugbyunion"
            ? 13
            : params?.type === "basketball"
              ? 10
              : params?.type === "americanfootball"
                ? 15
                : params?.type === "australianrules"
                  ? 9
                  : params?.type === "golf"
                    ? 16
                    : params?.type === "tennis"
                      ? 7
                      : params?.type === "baseball"
                        ? 11
                        : params?.type === "icehockey"
                          ? 17
                          : params?.type === "boxing"
                            ? 6
                            : params?.type === "mma"
                              ? 5
                              : params?.type === "soccer"
                                ? 8
                                : "";
    if (Config.release == "IN" && (SportId || Number(params?.sportId))) {
      if (
        release[Config.release]?.sportId?.includes(SportId) ||
        release[Config.release]?.sportId?.includes(Number(params?.sportId))
      ) {
      } else {
        navigate("/");
      }
    }
  }, []);

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `apiProviders/bookKeepers`,
      );
      if (status === 200) {
        dispatch(fetchALLBookkeeperData(data?.result));
      } else {
        dispatch(fetchALLBookkeeperData([]));
      }
    } catch (err) {
      dispatch(fetchALLBookkeeperData([]));
    }
  };
  return (
    <>
      {racingOddsComparison ? (
        <OddsCompLayout />
      ) : advertisingScreen ? (
        <AdvertisingScreenPage />
      ) : restrictedScreen ? (
        <GeofencingPage />
      ) : maintenanceScreen ? (
        <MaintenancePage />
      ) : (
        <Box className="wrapper" ref={pageTopRef}>
          {/* <a
            href="mailto:info@smartb.com.au"
            className="top-header-banner-wrap"
          >
            <img
              className="top-header-banner"
              src={TopHeaderBanner}
              alt="banner"
            />
          </a> */}

          <Box className="Main-Wrap">
            <Box className="container">
              {/* <a
                href="mailto:info@smartb.com.au"
                className="side-bg-banner left-bg-banner"
              >
                <img src={SideBgBanner} alt="banner" />
              </a> */}
              {/* {fetchSideBgAds("left-bg-banner")} */}
              {/* {fetchSideBgAds("right-bg-banner")} */}
              <Box className="external-wrapper" id="external-wrapper">
                {/* {screenWidth >= 800 ? fetchTopheaderAds() : ""} */}
                <MainMenubar
                  auth={auth}
                  user_statics={user_statics}
                  organisationData={organisationData}
                  verify_count={verify_count}
                  onMobileNavOpen={() => setMobileNavOpen(true)}
                  tooltipStep={tooltipStep}
                />
                {location?.pathname?.includes("/all-sports") && (
                  <HomeLandingProvider>
                    <NewSportHeader />
                  </HomeLandingProvider>
                )}
                {screenWidth < 799 &&
                  (location?.pathname?.includes("/tipscompetition") ||
                    location?.pathname?.includes("/smartinfo")) && (
                    <NewMenuListMobile />
                  )}
                <Box
                  // className={
                  //   !isHome || BetSlipToggleData
                  //     ? "layout-wrapper"
                  //     : "full-layout layout-wrapper"
                  // }
                  className={
                    !isHome
                      ? "layout-wrapper"
                      : location.pathname?.includes("soc-signup") ||
                          location.pathname?.includes("install-apk")
                        ? "soc-signup-layout full-layout layout-wrapper install-apk-layout"
                        : "full-layout layout-wrapper"
                  }
                >
                  {/* <Box id="left-sidebar">
                    <div className="left-sidebarcontent">
                      <LeftSideBar />
                    </div>
                  </Box> */}
                  <Box className="content">
                    <Box className="inner-content" id="inner-content">
                      <Outlet />
                    </Box>
                    <Box className="Footer">
                      {/* <Footer /> */}
                      <FooterV2 />
                    </Box>
                  </Box>

                  {!isHome ? (
                    <Box id="right-sidebar">
                      <Box className="right-sidebarcontent">
                        <RightSideBar />
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* <Collapse
                    in={BetSlipToggleData}
                    className="betslip-collapse"
                    style={screenWidth < 799 ? collapseStyles : ""}
                  >
                    <BetSlipCollapse />
                  </Collapse> */}
                </Box>
              </Box>
            </Box>
            <LoginPopUp
              open={open}
              onClose={handleClose}
              handleClose={handleClose}
              title={false}
              content={<></>}
              closeIcon={true}
            />
          </Box>
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Box style={{ display: progress ? "block" : "none" }}>
            <Box className="arrow-up" onClick={() => scrollToTop()}>
              <ArrowUpwardIcon />
            </Box>
          </Box>
        </Box>
      )}
      {/* <RaceAlertSound /> */}
    </>
  );
};

export default DashboardLayout;
