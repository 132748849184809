import React from "react";
import { Navigate } from "react-router-dom";

// LAYOUT
import AuthLayout from "src/layouts/AuthLayout";
import MainLayout from "src/layouts/MainLayout";
// import AdminLayout from "src/layouts/AdminLayout";

// USER
import Home from "./views/pages/Home/home";
import ComingSoon from "./views/pages/404/comingsoon";
import Racing from "./views/pages/allsports/racing";
import RunnersTable from "./views/pages/allsports/racing/runnerTable";
// import SignInPage from "./views/component/auth/SignIn/signin";
import SignInPage from "./views/component/auth/SignInV2/SignInV2";
// import SignUpPage from "./views/component/auth/SignUp/signup";
import SignUpPage from "./views/component/auth/SignUpV2/signupV2.jsx";
// import ForgotPassword from "./views/component/auth/ForgotPassword/forgotpassword";
import ForgotPassword from "./views/component/auth/ForgotPasswordV2/forgotpasswordV2.jsx";
// import EnterOtp from "./views/component/auth/EnterOtp/enterOtp";
import EnterOtp from "./views/component/auth/EnterOtpV2/enterOtpV2";
import TermsAndConditions from "./views/pages/policies/termsAndConditions";
import PrivacyPolicy from "./views/pages/policies/privacyPolicy";
import ResponsibleGambling from "./views/pages/policies/responsibleGambling";
import Profile from "./views/pages/profile/profile";
import ProfileEdit from "./views/component/Profile/profileEdit/index";
// import { fetchFromStorage } from "src/helpers/context";
import Trackprofiles from "./views/pages/Statistics/Trackprofiles";
import TrackprofilesDetailPage from "./views/component/Statistics/trackprofile/trackprofiledetail/trackprofiledetailpage";
import PlayerInfoPage from "./views/component/Statistics/trackprofile/trackprofiledetail/playerInfo";
import HorsesInfo from "./views/component/Statistics/trackprofile/trackprofiledetail/playerInfo/horses";
import FuturesTablePageView from "./views/pages/allsports/racing/futuresTable";
import TeamSportsPage from "./views/pages/allsports/teamSports";
import AdvertisingScreen from "./views/pages/advertisingScreen";
import TeamSports from "./views/component/allsports/teamSpoorts/teamSports";
import GeofencingPage from "./views/pages/RestrictedPage/geofencingpage";
import AdvertisingEnquiries from "./views/pages/advertisingEnquiries";
import SportMatchups from "./views/pages/sportMatchups";
import News from "./views/pages/news";
import CombatSportsPage from "./views/pages/allsports/combatSports";
import AllCombatSportMarketPage from "./views/component/allsports/combatSports/allCombatSports/AllCombatSportMarket";
import Blackbook from "./views/pages/blackbook";
import Podcasts from "./views/pages/podcasts";
import TeamSportsData from "./views/pages/teamSportsData";
import LeagueDataPage from "./views/component/teamSportData/leagueData";
import TeamDataPage from "./views/component/teamSportData/leagueData/teamData";
import StadiumDataPage from "./views/component/teamSportData/leagueData/teamData/stadiumData";
import PlayerDataPage from "./views/component/teamSportData/leagueData/teamData/playerData";
import CoachDataPage from "./views/component/teamSportData/leagueData/teamData/playerData/coachData";
import BookMakers from "./views/pages/bookmakers";
import BookMakersInfoPage from "./views/component/bookmakers/bookmakersInfo";
import EventDetails from "./views/component/EventsCalendar/EventDetails";
import SubscriptionAndPayment from "./views/pages/subscriptionAndPayment";
import Membership from "./views/pages/membership";
import SocialShare from "./views/pages/socialShare";
import MaintenancePage from "./views/pages/MaintenancePage";
import Smartbook from "./views/pages/smartbook";
import RaceingTips from "./views/pages/tipsMarketplace/racingTips";
import EnterSportsTips from "./views/pages/tipsMarketplace/enterSportsTips";
import SportsTips from "./views/pages/tipsMarketplace/sportsTips";
import TipperProfile from "./views/pages/tipperProfile";
import GetTipsPage from "./views/pages/getTips/GetTips";
import EnterRacingTips from "./views/pages/tipsMarketplace/enterRacingTips";
import PurchasedTips from "./views/pages/tipsMarketplace/purchasedTips";
import RecommendedWebsites from "./views/pages/recommenedWebsites";
import CreateComp from "./views/pages/createComp";
import MyComp from "./views/pages/myComp";
import OurPeople from "./views/pages/outTeam";
import CricketSportDataPage from "./views/component/teamSportData/cricketSportData";
import IndividualCompetition from "./views/pages/individualCompetition";
import Rankings from "./views/pages/rankings";
import CompTips from "./views/pages/compTips";
import CartAndPayment from "./views/pages/cartAndPayment";
import InternationalRacing from "./views/pages/internationalRacing";
import InRacingDetails from "./views/component/internationalRacingPage/InRacingDetails";
import UserMessages from "./views/pages/userMessages";
import UserIndividualMessage from "./views/pages/userIndividualMessage";
import Checkout from "./views/pages/checkout";
import SoccerSportDataPage from "./views/component/teamSportData/soccerSportData";
import FixtureInfoSport from "./views/component/allsports/teamSpoorts/fixtureInfoSport";
import SoccerFixtureInfoSport from "./views/component/allsports/teamSpoorts/SoccerfixtureInfoSport";
import TippingCompsList from "./views/pages/tippingComps/tips";
import TippingCompsRanking from "./views/pages/tippingComps/rankings";
import SoccerTeamDataPage from "./views/component/teamSportData/soccerSportData/soccerTeamData";
import SportExpertTips from "./views/pages/SportExpertTips";
import SportExpertDetailsTips from "./views/pages/SportExpertTipsDetails";
import SportDataPage from "./views/component/teamSportData/sportDataPage";
import AllFixtureInfoSport from "./views/component/allsports/teamSpoorts/allFixtureInfoSport";
import { Config } from "./helpers/context";
import IndividualCompetitionPublic from "./views/pages/IndividualCompetitionPublic";
import EventsCalendar from "./views/pages/eventCalendar/EventCalendar";
import RacingOddsComparison from "./views/pages/racingOddsComparison";
import MySubscriptionPlan from "./views/component/subscriptionAndPayment/mySubscriptionPlan";
import ManageEmailSubscriptions from "./views/pages/manageEmailSubscriptions";
import FuturesTable from "./views/component/allsports/racing/futuresTable/futuresTablePage";
import SubscriptionTermsAndConditions from "./views/pages/policies/subscriptionTermsAndConditions";
import SOCSignup from "./views/pages/socSignup";
import SaveArticle from "./views/component/newsPage/savedArticle/saveArticle";
import TippingFAQs from "./views/pages/tippingFAQs";
import TippingPrize from "./views/pages/tippingPrize";
import NewTeamSportsPage from "./views/pages/newTeamSport";
import PlayerStatsProvider from "./helpers/context/PlayersStats/PlayerStatsContext";
import LadderStatsProvider from "./helpers/context/Ladder/LadderContext";
import StatsProvider from "./helpers/context/Providers/StatsProvider";
import MyCompArchive from "./views/pages/myCompArchive";
import Payment from "./views/pages/payment";
import InstallApk from "./views/pages/installApk";

// ADMIN
// import AdminHome from "./views/pages/Admin/home";
export const authRoutes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "/sign-up", element: <SignUpPage /> },
      { path: "/sign-in", element: <SignInPage /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "/forgot-password-otp", element: <EnterOtp /> },
      { path: "/verify-user", element: <EnterOtp /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
];
export const authRoutesPubilc = {
  path: "/",
  element: <AuthLayout />,
  children: [
    { path: "/sign-up", element: <SignUpPage /> },
    { path: "/sign-in", element: <SignInPage /> },
    { path: "/verify-user", element: <EnterOtp /> },
  ],
};
const AUPublicData = [
  { path: "/", element: <Home /> },
  { path: "/comingsoon", element: <ComingSoon /> },
  { path: "/racing", element: <Racing /> },
  {
    path: "/racing/:race/:sportId/:raceId/:trackId/:eventId",
    element: <RunnersTable />,
  },
  // { path: "/racing/futures", element: <FuturesTablePageView /> },
  {
    path: "/racing-futures/:race/:sportId/:raceId/:trackId/:eventId",
    element: <FuturesTable />,
  },
  { path: "/terms-and-conditions", element: <TermsAndConditions /> },
  {
    path: "/subscription-terms-and-conditions",
    element: <SubscriptionTermsAndConditions />,
  },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/responsible-gambling", element: <ResponsibleGambling /> },

  {
    path: "/teamsports/:type/:id/:seeall",
    element: <TeamSportsPage />,
  },
  {
    path: "/teamsports/:type/:oddstype/:id/:seeall",
    element: <TeamSports />,
  },

  {
    path: "/advertisingscreen",
    element: <AdvertisingScreen />,
  },

  {
    path: "/advertisingenquiries",
    element: <AdvertisingEnquiries />,
  },
  {
    path: "/sportmatchups",
    element: <SportMatchups />,
  },
  {
    path: "smartinfo/news/:categoryId",
    element: <News />,
  },
  {
    path: "smartinfo/news/:categoryId/:id",
    element: <News />,
  },
  {
    path: "/combatsports/:type/:id/:seeall",
    element: <CombatSportsPage />,
  },
  {
    path: "/sportmarket",
    element: <AllCombatSportMarketPage />,
  },
  {
    path: "/podcast",
    element: <Podcasts />,
  },
  {
    path: "/subscription-payment",
    element: <SubscriptionAndPayment />,
  },
  {
    path: "/membership",
    element: <Membership />,
  },
  {
    path: "/mySubscription",
    element: <MySubscriptionPlan />,
  },
  {
    path: "/social-betslipshare",
    element: <SocialShare />,
  },
  {
    path: "/smartinfo/bookmaker",
    element: <BookMakers />,
  },
  {
    path: "/smartinfo/bookmaker/bookmakers-info/:id",
    element: <BookMakersInfoPage />,
  },
  {
    path: "/issue-page",
    element: <MaintenancePage />,
  },
  {
    path: "/smartinfo/recommended-websites",
    element: <RecommendedWebsites />,
  },
  {
    path: "/manage-email-subscription",
    element: <ManageEmailSubscriptions />,
  },
  {
    path: "/tipscompetition/private-comps",
    element: <MyComp />,
  },
  {
    path: "/smartinfo/our-people",
    element: <OurPeople />,
  },
  {
    path: "/international-racing",
    element: <InternationalRacing />,
  },
  {
    path: "/international-racing/:race/:id",
    element: <InRacingDetails />,
  },
  {
    path: "/tipscompetition/public/tips",
    element: <TippingCompsList />,
  },
  {
    path: "/tipscompetition/public/rankings",
    element: <Rankings />,
  },
  {
    path: "/tipscompetition/share-tips",
    element: <IndividualCompetitionPublic />,
  },
  {
    path: "/soc-signup",
    element: <SOCSignup />,
  },
  {
    path: "/install-apk",
    element: <InstallApk />,
  },
  {
    path: "/all-sports/:sportsTypeName/:sportsId/:oddstype/:id/:seeall",
    element: (
      <StatsProvider>
        <NewTeamSportsPage />
      </StatsProvider>
    ),
  },
  { path: "*", element: <Navigate to="/" /> },
];

const INPublicData = [
  { path: "/", element: <Home /> },
  { path: "/comingsoon", element: <ComingSoon /> },
  { path: "/terms-and-conditions", element: <TermsAndConditions /> },
  {
    path: "/subscription-terms-and-conditions",
    element: <SubscriptionTermsAndConditions />,
  },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/responsible-gambling", element: <ResponsibleGambling /> },
  {
    path: "/teamsports/:type/:id/:seeall",
    element: <TeamSportsPage />,
  },
  {
    path: "/teamsports/:type/:oddstype/:id/:seeall",
    element: <TeamSports />,
  },
  {
    path: "/advertisingenquiries",
    element: <AdvertisingEnquiries />,
  },
  {
    path: "smartinfo/news/:categoryId",
    element: <News />,
  },
  {
    path: "smartinfo/news/:categoryId/:id",
    element: <News />,
  },
  {
    path: "/podcast",
    element: <Podcasts />,
  },
  {
    path: "/issue-page",
    element: <MaintenancePage />,
  },
  {
    path: "/tipscompetition/private-comps",
    element: <MyComp />,
  },
  {
    path: "/tipscompetition/public/tips",
    element: <TippingCompsList />,
  },
  {
    path: "/tipscompetition/public/rankings",
    element: <Rankings />,
  },
  {
    path: "/tipscompetition/share-tips",
    element: <IndividualCompetitionPublic />,
  },
  { path: "*", element: <Navigate to="/" /> },
];

const AUUserRoutes = [
  { path: "/profile", element: <Profile />, isPrivate: true },
  {
    path: "/profile-edit",
    element: <ProfileEdit />,
    isPrivate: true,
  },
  {
    path: "/statistics/:profileType",
    element: <Trackprofiles />,
    isPrivate: true,
  },
  {
    path: "/statistics/:profileType/:trackId",
    element: <TrackprofilesDetailPage />,
    isPrivate: true,
  },
  {
    path: "/statistics/:profileType/:trackId/:personType/:personId",
    element: <PlayerInfoPage />,
    isPrivate: true,
  },
  {
    path: "/statistics/:profileType/:trackId/:personType/:personId/:horseId",
    element: <HorsesInfo />,
    isPrivate: true,
  },
  {
    path: "/blackbook",
    element: <Blackbook />,
    isPrivate: true,
  },
  {
    path: "/smartbook",
    element: <Smartbook />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data",
    element: <LeagueDataPage />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data/:teamId/team-data",
    element: <TeamDataPage />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data/:teamId/team-data/:playerId/player-data",
    element: <PlayerDataPage />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data/:coachId/coach-data",
    element: <CoachDataPage />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data/:teamId/team-data/:coachId/coach-data",
    element: <CoachDataPage />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data/:teamId/team-data/:playerId/player-data/:coachId/coach-data",
    element: <CoachDataPage />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data/:stadiumId/stadium-data",
    element: <StadiumDataPage />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data/:teamId/team-data/:stadiumId/stadium-data",
    element: <StadiumDataPage />,
    isPrivate: true,
  },
  {
    path: "/sport/statistics/:sportId/:tournamentId/league-data/:teamId/team-data/:playerId/player-data/:stadiumId/stadium-data",
    element: <StadiumDataPage />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/statistics/:tournament/info/:sportId/:tournamentId",
    element: <SportDataPage />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/:tournament/:event/info/:SportId/:tournamentId/:id",
    element: <AllFixtureInfoSport />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/statistics/team/:teamName/:sportId/:tournamentId/:teamId",
    element: <SoccerTeamDataPage />,
    isPrivate: true,
  },
  {
    path: ":sportType/event-calendar",
    element: <EventsCalendar />,
    isPrivate: true,
  },
  {
    path: ":sportType/event-calendar/details/:id/:SportId/:tournamentId",
    element: <EventDetails />,
    isPrivate: true,
  },
  {
    path: "/tips-marketplace/racing-tips",
    element: <RaceingTips />,
    isPrivate: true,
  },
  {
    path: "/tips-marketplace/sports-tips",
    element: <SportsTips />,
    isPrivate: true,
  },
  {
    path: "/tips-marketplace/enter-sports-tips",
    element: <EnterSportsTips />,
    isPrivate: true,
  },
  {
    path: "/tipper-profile/:type/:id",
    element: <TipperProfile />,
    isPrivate: true,
  },
  {
    path: "/tips-marketplace/enter-racing-tips",
    element: <EnterRacingTips />,
    isPrivate: true,
  },
  {
    path: "/purchased-tips/:page",
    element: <PurchasedTips />,
    isPrivate: true,
  },
  {
    path: "/:page/cart-payment",
    element: <CartAndPayment />,
    isPrivate: true,
  },
  {
    path: "/:name/checkout",
    element: <Checkout />,
    isPrivate: true,
  },
  {
    path: "/messages",
    element: <UserMessages />,
    isPrivate: true,
  },
  {
    path: "/user-messages/:user",
    element: <UserIndividualMessage />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/create-competition",
    element: <CreateComp />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/my-comps",
    element: <MyComp />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/payment",
    element: <Payment />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/my-comps/archive",
    element: <MyCompArchive />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/my-comps/:sports/:tournament/:SportId/:tournamentId/:compsId",
    element: <IndividualCompetition />,
    isPrivate: true,
  },
  // {
  //   path:
  //     "/tipscompetition/public/rankings/:sports/:tournament/:SportId/:tournamentId/:compsId",
  //   element: <Rankings />,
  //   isPrivate: true
  // },
  {
    path: "/tipscompetition/public/tips/:sports/:tournament/:SportId/:tournamentId/:compsId",
    element: <CompTips />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/public/tips",
    element: <TippingCompsList />,
    isPrivate: true,
  },
  // {
  //   path: "/tipscompetition/public/rankings",
  //   element: <Rankings />,
  //   isPrivate: true
  // },
  {
    path: "/tipscompetition/faqs",
    element: <TippingFAQs />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/prize",
    element: <TippingPrize />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/sport-expert-tips",
    element: <SportExpertTips />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/sport-expert-tips/:name/:round/:id/:tournamentId",
    element: <SportExpertDetailsTips />,
    isPrivate: true,
  },
  // {
  //   path: "/odds-comparison",
  //   element: <RacingOddsComparison />,
  //   isPrivate: true
  // },
  {
    path: "/odds-comparison/:type/:sportId/:trackId/:raceId",
    element: <RacingOddsComparison />,
    isPrivate: true,
  },
  {
    path: "/manage-email-subscription",
    element: <ManageEmailSubscriptions />,
    // isPrivate: true
  },
  {
    path: "/smartinfo/saved-article",
    element: <SaveArticle />,
    isPrivate: true,
  },
  {
    path: "/gettips/:sportId/:id",
    element: <GetTipsPage />,
    isPrivate: true,
  },
  ...AUPublicData,
];

const INUserRoutes = [
  { path: "/profile", element: <Profile />, isPrivate: true },
  {
    path: "/profile-edit",
    element: <ProfileEdit />,
    isPrivate: true,
  },
  {
    path: "/smartbook",
    element: <Smartbook />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/statistics/:tournament/info/:sportId/:tournamentId",
    element: <SportDataPage />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/:tournament/:event/info/:SportId/:tournamentId/:id",
    element: <AllFixtureInfoSport />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/statistics/team/:teamName/:sportId/:tournamentId/:teamId",
    element: <SoccerTeamDataPage />,
    isPrivate: true,
  },
  {
    path: ":sportType/event-calendar",
    element: <EventsCalendar />,
    isPrivate: true,
  },
  {
    path: ":sportType/event-calendar/details/:id/:SportId/:tournamentId",
    element: <EventDetails />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/create-competition",
    element: <CreateComp />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/my-comps",
    element: <MyComp />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/payment",
    element: <Payment />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/my-comps/:sports/:tournament/:SportId/:tournamentId/:compsId",
    element: <IndividualCompetition />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/public/tips/:sports/:tournament/:SportId/:tournamentId/:compsId",
    element: <CompTips />,
    isPrivate: true,
  },
  {
    path: "/tipscompetition/public/tips",
    element: <TippingCompsList />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/sport-expert-tips",
    element: <SportExpertTips />,
    isPrivate: true,
  },
  {
    path: "/teamsports/:type/sport-expert-tips/:name/:round/:id/:tournamentId",
    element: <SportExpertDetailsTips />,
    isPrivate: true,
  },
  {
    path: "/smartinfo/saved-article",
    element: <SaveArticle />,
    isPrivate: true,
  },
  ...INPublicData,
];

// USER ROUTES
export const userRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: Config.release === "AU" ? [...AUUserRoutes] : [...INUserRoutes],
  },
  authRoutesPubilc,
];

// PUBLIC ROUTES
export const publicRoute = [
  {
    path: "/",
    element: <MainLayout />,
    children: Config.release === "AU" ? [...AUPublicData] : [...INPublicData],
  },
];

//RESTRICTED ROUTES
export const RestrictedRoute = [
  {
    path: "/",
    element: <GeofencingPage />,
    children: [{ path: "*", element: <Navigate to="/" /> }],
  },
];

// ADMIN ROUTES
// export const adminRoutes = [
//   {
//     path: "/admin",
//     element: <AdminLayout />,
//     children: [
//       { path: "/", element: <Navigate to="/admin" /> },
//       { path: "/admin", element: <AdminHome /> },
//       { path: "*", element: <Navigate to="/admin/dashboard" /> }
//     ]
//   }
// ];
