import React from "react";
import "./installApk.scss";
import { Box, Typography, Button, Card, CardContent } from "@mui/material";
import { ReactComponent as Important } from "src/assets/images/important.svg";
import { ReactComponent as Step } from "src/assets/images/step.svg";
import { ReactComponent as AndroidIcon } from "src/assets/images/AndroidIcon.svg";

import AppleStore from "../../../assets/images/new_app_store.png";
import SmartOddsComp from "../../../assets/images/newHeaderIcon/smartOdds2x.png";
import SmartPlayLogoPng from "src/assets/images/newHeaderIcon/smartPlay2x.png";
import logo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";

const apkLinks = [
  {
    name: "SmartB",
    logo: logo,
    desc: "",
    linkText: "Click here to download SmartB app",
    apkUrl:
      "https://drive.google.com/file/d/1bR4OyaFme1JGMK-v23mwWFw4Jkr2bviQ/view",
    appleURL: "https://apps.apple.com/au/app/smartb-app/id1672189237",
  },
  {
    name: "SmartPlay",
    logo: SmartPlayLogoPng,
    desc: "DAILY FANTASY SPORTS APP!",
    linkText: "Click here to download SmartPlay app",
    apkUrl:
      "https://drive.google.com/file/d/1CHWedIPrDgy3mS_VoeWbMBtisrr9zErY/view?usp=sharing",
    appleURL: "https://apps.apple.com/us/app/smart-play/id6737409400",
  },
  // {
  //   name: "SmartOdds",
  //   logo: SmartOddsComp,
  //   desc: "AUSTRALIA'S BEST RACING AND SPORTS COMPARISON APP!",
  //   linkText: "Click here to download SmartOdds app",
  //   apkUrl: "#",
  // },
];

const InstallAPK = () => {
  return (
    <Box className="install-wrapper">
      <Box className="install-apk-container">
        <Box className="apk-container">
          <Box className="install-info-box">
            <Important />
            <Typography className="info-text">
              <strong>SmartB</strong> is a Australia's fastest growing Sports
              and Racing aggregator platform and as such not a betting or
              gambling platform. While we continue to get this point across to
              Google Store policies and get our dedicated apps live on Google
              Store –
              <strong>
                {" "}
                for android users please click on the links below for our
                SmartPlay , SmartOdds and SmartB apps
              </strong>{" "}
              to continue to enjoy our latest apps for your sporting and racing
              needs.
            </Typography>
          </Box>

          <Typography variant="h5" className="install-heading">
            To <span className="highlight">install</span> the APK on your{" "}
            <span className="highlight">Android device</span>,
          </Typography>
          <Typography variant="h6" className="follow-steps">
            FOLLOW THESE SIMPLE STEPS:
          </Typography>
          <Box className="steps-box-wrap">
            <Box className="steps-container">
              {[1, 2, 3]?.map((step) => (
                <Box key={step} className="step-box">
                  <Step />
                  <Typography className="step-text">
                    <span className="step-number">STEP {step}</span>
                    <br />
                    <span className="step-desc">
                      {step === 1 &&
                        "First, download the APK file from the link provided on our website."}
                      {step === 2 &&
                        "Next, go to your phone’s Settings, navigate to Security (or Privacy on some devices), and enable the option to install apps from Unknown Sources."}
                      {step === 3 &&
                        "Then, open your file manager, locate the downloaded APK file (usually in the Downloads folder), tap on it, and follow the prompts to install."}
                    </span>
                  </Typography>
                </Box>
              ))}
              <Typography className="step-desc">
                Once installed, you can launch the app as usual. Enjoy!
              </Typography>
            </Box>

            <Card className="apps-box">
              <CardContent>
                {apkLinks?.map((app) => (
                  <Box key={app?.name} className="app-link-box">
                    <Box className="app-logo-wrap">
                      <img src={app?.logo} alt={app?.name} />
                    </Box>
                    {app?.desc && (
                      <Typography className="app-desc">{app?.desc}</Typography>
                    )}
                    <Box className="app-link-wrap">
                      <Box className="apk-link">
                        <AndroidIcon />
                        <Link to={app?.apkUrl} target="_blank">
                          {" "}
                          {app?.linkText}
                        </Link>
                      </Box>

                      <Box>
                        <a
                          href={app?.appleURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={AppleStore} alt="AppleStore" />
                        </a>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InstallAPK;
