export function calculateTotalWithCardFee(amount) {
  const percentage = 1.75 / 100; // 1.75% fee
  const fixedFee = 0.3; // Fixed fee of $0.30

  // Calculate the fee
  const cardFee = percentage * amount + fixedFee;

  // Calculate total
  const totalAmount = amount + cardFee;

  return {
    fee: parseFloat(cardFee.toFixed(2)),
    total: parseFloat(totalAmount.toFixed(2)),
  };
}

export const formatExpiryDate = (e, handleChange) => {
  const input = e.target;
  let value = input.value.replace(/\D/g, "");

  if (value.length <= 2) {
    input.value = value;
  } else {
    input.value = `${value.slice(0, 2)}/${value.slice(2, 6)}`;
  }

  // Trigger formik's handleChange
  handleChange(e);
};

export function formatExpiryDatePayload(expiry) {
  const [month, year] = expiry.split("/"); // Split into MM and YYYY
  if (!month || !year || year.length !== 4) return null; // Validate format
  return `${year}-${month.padStart(2, "0")}`; // Ensure MM is 2 digits
}
