import { useEffect, useState } from "react";
import { Box, ClickAwayListener, Collapse, Typography } from "@mui/material";
import MobileMenuSmartInfo from "../../assets/images/newHeaderIcon/mobileMenuSmartInfo.png";
import MobileMenuSmartTipping from "../../assets/images/newHeaderIcon/mobileMenuSmartTipping.png";

import "./newSportHeader.scss";
import { Config, fetchFromStorage } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import { useLocation, useNavigate } from "react-router";
import MobileMenuWhite from "src/components/Common/Icons/MobileMenuWhite";

const NewMenuListMobile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const mobileMenuOpen = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };
  const mobileMenuData = location?.pathname?.includes("tipscompetition")
    ? release[Config.release]?.tippingSubMenuItems
    : release[Config.release]?.smartInfoSubMenuItems;

  const menuImg = location?.pathname?.includes("tipscompetition")
    ? MobileMenuSmartTipping
    : MobileMenuSmartInfo;
  return (
    <>
      <Box
        className={
          handleRestrictedUser()
            ? "new-sport-Header-wrapper new-mobile-menu"
            : "without-sigup-header new-sport-Header-wrapper new-mobile-menu"
        }
      >
        <Box className="header-left-section">
          <ClickAwayListener onClickAway={() => setMobileMenu(false)}>
            <Box className="mobile-menu-new">
              <Box
                className="mobile-menu-new-mobile"
                onClick={() => mobileMenuOpen()}
              >
                <MobileMenuWhite />
              </Box>
              <Box>
                <img src={menuImg} alt="logo" />
              </Box>
              <Collapse
                in={mobileMenu}
                timeout="auto"
                unmountOnExit
                className="all-mobile-menu-collapse"
              >
                <Box className="mobile-menu">
                  {mobileMenuData?.map((item, index) => {
                    return (
                      <>
                        <Box
                          key={index}
                          className={
                            location?.pathname?.includes(item?.routeName)
                              ? "menu-item-wrap active"
                              : "menu-item-wrap"
                          }
                          onClick={() => {
                            setMobileMenu(false);
                            navigate(item?.url);
                          }}
                        >
                          <Typography className="menu-name">
                            {item?.name}
                          </Typography>
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </Collapse>
            </Box>
          </ClickAwayListener>
        </Box>
      </Box>
    </>
  );
};

export default NewMenuListMobile;
