import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

import NrlPlayerStatsData from "src/assets/static/NRLPlayerStats.json";
import NrlTeamStatsData from "src/assets/static/NRLTeamStats.json";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TableContainer,
  Typography,
} from "@mui/material";
import Loader from "src/components/Loader";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import useCloumn from "src/components/Common/Hooks/useColumn";
import { KeyboardArrowDown } from "@mui/icons-material";
const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    backgroundColor: "#ffffff",
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    backgroundColor: "#ffffff",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

const NRLStatsTable = ({ loading, selectedStats }) => {
  const { NRLTeamStatsColumns, NRLPlayerStatsColumns } = useCloumn();

  const showPlayerStats = selectedStats === 0;
  const showTeamStats = selectedStats === 1;
  let tableData, tableColumn;

  if (showTeamStats) {
    tableData = NrlTeamStatsData;
    tableColumn = NRLTeamStatsColumns;
  }

  if (showPlayerStats) {
    tableData = NrlPlayerStatsData;
    tableColumn = NRLPlayerStatsColumns;
  }

  const table = useReactTable({
    data: tableData || [],
    columns: tableColumn,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
  });

  if (!tableData) {
    return <Loader />;
  }

  return (
    <Box className="new-landing-table-container-details">
      <TableContainer className="no-border-radius">
        <Table className="new-landing-landing-table">
          <TableHead>
            {table?.getHeaderGroups()?.map((headerGroup) => (
              <TableRow key={headerGroup?.id}>
                {headerGroup?.headers?.map((header, index) => {
                  if (index <= 1) {
                    return (
                      <StickyTableCell
                        key={header.id}
                        style={{
                          position: "sticky",
                          left: index === 0 ? 0 : "40px", // Adjust left offset for multiple sticky columns
                          zIndex: 9,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </StickyTableCell>
                    );
                  }

                  return (
                    <TableCell
                      key={header.id}
                      style={{
                        backgroundColor: header?.id === "pts" && "#4455c7",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={21} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows.length > 0 ? (
              <>
                {table.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? "rgb(255, 255, 255)"
                          : "rgb(247, 247, 248)",
                    }}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      if (index <= 1) {
                        return (
                          <TableCell
                            key={cell.id}
                            style={{
                              position: "sticky",
                              left: index === 0 ? 0 : "40px", // Adjust left offset for multiple sticky columns
                              zIndex: 9,
                              backgroundColor:
                                index % 2 === 0
                                  ? "rgb(255, 255, 255)"
                                  : "rgb(247, 247, 248)",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={cell.id}
                          style={{
                            backgroundColor:
                              cell.column.id === "pts" &&
                              "rgba(0, 55, 100, 0.1)",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    colSpan={21}
                    align="center"
                    className="show-more-cell"
                  >
                    <Typography className="show-more-text">
                      Show more <KeyboardArrowDown />
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={21} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default NRLStatsTable;
