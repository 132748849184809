import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./homeLandingRight.scss";
import LandingICCRankingDetails from "./landingICCRankingDetails";
import LandingTableDetails from "./landingTableDetails";
import LandingFixturesResultDetails from "./landingFixturesResultsDetails";
import { useLocation, useNavigate, useParams } from "react-router";
import { SPORTS_TYPE } from "../../teamCommonComponent/newSportsUtils";

const HomeLandingRight = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const urlTouranamentId = Number(searchParamsMenu.get("touranamentId"));
  const urlTouranamentName = searchParamsMenu.get("touranamentName");
  const [lastUpdated, setLastUpdated] = useState("");
  const sportsId = Number(params?.sportsId);
  let rightTableLabelText = "";

  if (sportsId === SPORTS_TYPE.rugbyLeague) {
    rightTableLabelText = "NRL Ladder";
  } else {
    rightTableLabelText = "Table";
  }

  const handleNavigateTable = () => {
    navigate(
      `/all-sports/${params?.sportsTypeName}/${params?.sportsId}/odds/0/false?menu=fixtures_results&touranamentId=${urlTouranamentId}&touranamentName=${urlTouranamentName}`,
    );
  };

  const handleNavigateFixtures = () => {
    navigate(
      `/all-sports/${params?.sportsTypeName}/${params?.sportsId}/odds/0/false?menu=fixtures_results&touranamentId=${urlTouranamentId}&touranamentName=${urlTouranamentName}`,
    );
  };
  return (
    <>
      <Box className="landing-home-right-wrap">
        {sportsId === SPORTS_TYPE.circket && (
          <Box className="right-side-wrap">
            <Box className="right-side-title-wrap">
              <Typography className="right-side-title">ICC Rankings</Typography>
              <Typography className="right-updated-text">
                Last updated: {lastUpdated ? lastUpdated : null}
              </Typography>
            </Box>
            <Box>
              <LandingICCRankingDetails setLastUpdated={setLastUpdated} />
            </Box>
          </Box>
        )}
        {sportsId === 12 ? (
          urlTouranamentId === 0 || urlTouranamentId === 1 ? (
            <Box className="right-side-wrap">
              <Box className="right-side-title-wrap">
                <Typography className="right-side-title">
                  {rightTableLabelText}
                </Typography>
                <Typography
                  className="view-all-text"
                  onClick={() => {
                    handleNavigateTable();
                  }}
                >
                  <span className="text-underline">View all</span>
                  <ChevronRightIcon />
                </Typography>
              </Box>
              <Box>
                <LandingTableDetails />
              </Box>
            </Box>
          ) : null
        ) : (
          <Box className="right-side-wrap">
            <Box className="right-side-title-wrap">
              <Typography className="right-side-title">
                {rightTableLabelText}
              </Typography>
              <Typography
                className="view-all-text"
                onClick={() => {
                  handleNavigateTable();
                }}
              >
                <span className="text-underline">View all</span>
                <ChevronRightIcon />
              </Typography>
            </Box>
            <Box>
              <LandingTableDetails />
            </Box>
          </Box>
        )}

        <Box className="right-side-wrap">
          <Box className="right-side-title-wrap">
            <Typography className="right-side-title">
              Fixtures & Results{" "}
            </Typography>
            <Typography
              className="view-all-text"
              onClick={() => {
                handleNavigateFixtures();
              }}
            >
              <span className="text-underline">View all</span>
              <ChevronRightIcon />
            </Typography>
          </Box>
          <Box>
            <LandingFixturesResultDetails />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomeLandingRight;
