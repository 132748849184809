import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Loader from "src/components/Loader";
import { useParams } from "react-router";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { viwTipLadderStaticColumns } from "src/components/Common/Columns/Tipping/Ladder";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ReactComponent as FirstRank } from "../../../assets/images/icons/first_rank.svg";
import { ReactComponent as SecondRank } from "../../../assets/images/icons/second_rank.svg";
import { ReactComponent as ThirdRank } from "../../../assets/images/icons/third_rank.svg";

const ViewTipsTable = ({ stepperCount, eventByIdData }) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [ladderList, setLadderList] = useState([]);
  const [ladderOffset, setLadderOffset] = useState(0);
  const [pageCount, setPageCount] = useState(null);
  const [headerCount, setHeaderCount] = useState(0);
  const tippingType = eventByIdData?.tippingType;

  // ✅ Fetch ladder data
  const getLadderList = async (offset, stepperCount, type, order) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/ladderBoard?competitionId=${Number(
          params?.compsId,
        )}&limit=10&offset=${offset}&round=${stepperCount}&sort=${
          type || ""
        }&orderBy=${order ? "asc" : "desc"}&viewTips=true`,
      );

      if (status === 200) {
        setPageCount(data?.count);
        setHeaderCount(data?.headerCount);

        // Handle the ladder list with current user at top
        if (offset === 0) {
          // If this is the first page load, set current user at top
          // followed by the complete result list (including current user)
          if (data?.currentUser) {
            // Add current user at the top and keep all results intact
            setLadderList([data.currentUser, ...data?.result]);
          } else {
            setLadderList(data?.result || []);
          }
        } else {
          // For pagination, just append the new results
          setLadderList((prev) => [...prev, ...data?.result]);
        }

        setLadderOffset(offset + 10); // Increase offset for next request
      }
    } catch (err) {
      console.error("Error fetching ladder data", err);
    } finally {
      setLoading(false);
    }
  };

  // ✅ Initial fetch when `stepperCount` or `params.compsId` changes
  useEffect(() => {
    setLadderList([]); // Reset on new fetch
    setLadderOffset(0); // Reset pagination
    getLadderList(0, stepperCount, "totalPoints", false);
  }, [stepperCount, params]);

  const ladderColumns = useMemo(
    () => viwTipLadderStaticColumns(headerCount, tippingType),
    [headerCount, tippingType],
  );

  const table = useReactTable({
    data: ladderList,
    columns: ladderColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    state: {},
    manualPagination: true,
    pageCount: pageCount,
  });

  return (
    <Box className="new-landing-table-container-details view-tips-comp-table">
      <TableContainer>
        <Table className="new-landing-landing-table">
          <TableHead>
            <TableRow sx={{ textAlign: "center" }}>
              {table.getHeaderGroups().map((headerGroup) =>
                headerGroup.headers.map((header, index) => (
                  <TableCell key={header.id} sx={{ textAlign: "center" }}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableCell>
                )),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && ladderList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={headerCount + 2} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows.length > 0 ? (
              <>
                {table.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? "rgb(255, 255, 255)"
                          : "rgb(247, 247, 248)",

                      borderBottom: index === 0 ? "2px solid #4455c7" : "none",
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                {ladderList.length < pageCount && !loading && (
                  <TableRow sx={{ position: "sticky", bottom: -1, zIndex: 9 }}>
                    <TableCell
                      colSpan={headerCount + 2}
                      align="center"
                      className="show-more-cell"
                    >
                      <Typography
                        className="show-more-text"
                        onClick={() =>
                          getLadderList(
                            ladderOffset,
                            stepperCount,
                            "totalPoints",
                            false,
                          )
                        }
                        sx={{ cursor: "pointer" }}
                      >
                        Show more <KeyboardArrowDown />
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={headerCount + 2} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ViewTipsTable;
