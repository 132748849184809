import axios from "axios";
import { Config } from "./config";
import { fetchFromStorage } from "./Storage";
import SmartInfo from "../../assets/images/newHeaderIcon/smartInfo2x.png";
import SmartOddsComp from "../../assets/images/newHeaderIcon/smartOdds2x.png";
import SmartTipping from "../../assets/images/newHeaderIcon/smartTipping2x.png";
import SmartInfoWhite from "../../assets/images/newHeaderIcon/mobileMenuSmartInfo.png";
import SmartOddsCompWhite from "../../assets/images/newHeaderIcon/mobileMenuSmartodds.png";
import SmartTippingWhite from "../../assets/images/newHeaderIcon/mobileMenuSmartTipping.png";
import SmartPlayWhite from "../../assets/images/newHeaderIcon/smartPlayWhiteMenu.png";

const fetchSportData = async () => {
  try {
    const token = fetchFromStorage("auth_token");
    const { status, data } = await axios.get(
      Config.baseURL + `sports/sport?sportTypeId=${2}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );
    if (status === 200) {
      const newSportData = data?.result?.map((item) => {
        return {
          ...item,
          name: item?.sportName,
          url:
            item?.sportName === "American Football"
              ? "/teamsports/americanfootball/odds/0/false"
              : item?.sportName === "Australian Rules"
                ? "/all-sports/australianrules/9/odds/0/false"
                : item?.sportName === "Baseball"
                  ? "/teamsports/baseball/odds/0/false"
                  : item?.sportName === "Basketball"
                    ? "/teamsports/basketball/odds/0/false"
                    : item?.sportName === "Boxing"
                      ? "/teamsports/boxing/odds/0/false"
                      : item?.sportName === "Cricket"
                        ? "/all-sports/cricket/4/odds/0/false"
                        : item?.sportName === "Golf"
                          ? "/teamsports/golf/odds/0/false"
                          : item?.sportName === "Ice Hockey"
                            ? "/teamsports/icehockey/odds/0/false"
                            : item?.sportName === "Mixed Martial Arts"
                              ? "/teamsports/mma/odds/0/false"
                              : item?.sportName === "Rugby League"
                                ? "/all-sports/rugbyleague/12/odds/0/false"
                                : item?.sportName === "Rugby Union"
                                  ? "/teamsports/rugbyunion/odds/0/false"
                                  : item?.sportName === "Soccer"
                                    ? "/teamsports/soccer/odds/0/false"
                                    : item?.sportName === "Tennis"
                                      ? "/teamsports/tennis/odds/0/false"
                                      : "",
          routeName:
            item?.sportName === "American Football"
              ? "teamsports/americanfootball"
              : item?.sportName === "Australian Rules"
                ? "teamsports/australianrules"
                : item?.sportName === "Baseball"
                  ? "teamsports/baseball"
                  : item?.sportName === "Basketball"
                    ? "teamsports/basketball"
                    : item?.sportName === "Boxing"
                      ? "teamsports/boxing"
                      : item?.sportName === "Cricket"
                        ? "/all-sports/cricket"
                        : item?.sportName === "Golf"
                          ? "teamsports/golf"
                          : item?.sportName === "Ice Hockey"
                            ? "teamsports/icehockey"
                            : item?.sportName === "Mixed Martial Arts"
                              ? "teamsports/mma"
                              : item?.sportName === "Rugby League"
                                ? "teamsports/rugbyleague"
                                : item?.sportName === "Rugby Union"
                                  ? "teamsports/rugbyunion"
                                  : item?.sportName === "Soccer"
                                    ? "teamsports/soccer"
                                    : item?.sportName === "Tennis"
                                      ? "teamsports/tennis"
                                      : "",
        };
      });

      const isFilterData = newSportData
        ?.filter((item) => {
          return item?.isFeatured === true;
        })
        ?.sort((a, b) => a.sortOrder - b.sortOrder);

      const isFeatured = isFilterData?.map((obj, index) => {
        return {
          id: index + 1,
          name: obj?.name,
          url: obj?.url,
          routeName: obj?.routeName,
          parentId: 3,
        };
      });
      const eventCalendar = [
        {
          id: 99,
          name: "Events Calendar",
          // url: `${Config?.baseURL?.replace("/api/", "/")}events-calendar/`,
          url: "/sport/event-calendar",
          parentId: 3,
          // routeName: "sports"
        },
      ];
      const showAllSport = [
        {
          id: isFeatured?.length + 1,
          name: "Show All Sports",
          url: "",
          secondSubMenu: newSportData
            ?.filter((item) => {
              return item?.isFeatured === false;
            })
            ?.sort((a, b) => a.sortOrder - b.sortOrder)
            ?.map((obj, index) => {
              return {
                id: index + 1,
                name: obj?.name,
                url: obj?.url,
                routeName: obj?.routeName,
                sportParentId: isFeatured?.length + 1,
              };
            }),
        },
      ];
      const finalData = [...isFeatured, ...showAllSport, ...eventCalendar];

      var sportsdata = data?.result?.map((s) => {
        return s?.id;
      });
      const menuData = {
        sportsdata,
        finalData,
      };
      return menuData;
    }
  } catch (err) {
    console.log(err);
  }
};

export const release = {
  IN: {
    menu: [
      { id: 1, name: "Home", url: "/", submenu: [] },
      {
        id: 3,
        name: "Sports",
        url: "",
        submenu: [
          {
            id: 6,
            name: "Cricket",
            url: "/teamsports/cricket/odds/0/false",
            routeName: "teamsports/cricket",
            parentId: 3,
          },
          {
            id: 99,
            name: "Events Calendar",
            // url: `${Config?.baseURL?.replace("/api/", "/")}events-calendar/`,
            url: "/sport/event-calendar",
            parentId: 3,
            // routeName: "sports"
          },
        ],
      },
      {
        id: 10,
        name: "Information",
        url: "",
        submenu: [
          {
            id: 1,
            name: "News",
            url: "smartinfo/news/0",
            parentId: 10,
            routeName: "news",
          },
          {
            id: 7,
            name: "Podcasts",
            url: "/podcast",
            parentId: 10,
            routeName: "podcast",
          },
          // {
          //   id: 6,
          //   name: "Events Calendar",
          //   url: "/event-calendar",
          //   parentId: 10,
          //   routeName: "event-calendar"
          // }
        ],
      },
      // {
      //   id: 11,
      //   name: "Smartbook",
      //   url: "",
      //   submenu: [
      //     {
      //       id: 2,
      //       name: "Sports Smartbook",
      //       url: "/smartbook",
      //       parentId: 11
      //     }
      //   ]
      // },
      {
        id: 12,
        name: "Tipping Competition",
        url: "",
        submenu: [
          {
            id: 2,
            name: "My Comps",
            url: "/tipscompetition/my-comps",
            parentId: 12,
          },
          {
            id: 3,
            name: "Public Comps",
            url: "/tipscompetition/public/tips",
            parentId: 12,
            routeName: "/tipscompetition/public/tips",
          },
          {
            id: 1,
            name: "Create Comps",
            url: "/tipscompetition/create-competition",
            parentId: 12,
          },
          {
            id: 4,
            name: "Rankings",
            url: "/tipscompetition/public/rankings",
            parentId: 12,
            routeName: "/tipscompetition/public/rankings",
          },
        ],
      },
      // {
      //   id: 8,
      //   name: "Policies",
      //   url: "",
      //   submenu: [
      //     {
      //       id: 1,
      //       name: "Responsible Gambling",
      //       url: "/responsible-gambling",
      //       parentId: 8
      //     },
      //     {
      //       id: 2,
      //       name: "Privacy Policy",
      //       url: "/privacy-policy",
      //       parentId: 8
      //     },
      //     {
      //       id: 3,
      //       name: "Terms & Conditions",
      //       url: "/terms-and-conditions",
      //       parentId: 8
      //     }
      //   ]
      // }
    ],
    sportId: [4],
    raceSportId: [],
    countryFilter: [],
    betSlip: false,
    featuredBookmakers: false,
    homePageTab: ["NEWS", "SPORTS"],
    rightSideBar: false,
    podcasts: ["Sports Update", "SEN", "Videos"],
    oddsCheck: false,
    Outrights: false,
    FluctuationGraph: false,
    signStep: ["step1", "step2", "step3", "step4"],
    NoDataComp: [
      {
        name: "Cricket",
        url: "/all-sports/cricket/4/odds/0/false",
      },
      {
        name: "News",
        url: "smartinfo/news/0",
      },
    ],
  },

  AU: {
    menu: [
      { id: 1, name: "Home", url: "/", submenu: [], seq: 5 },
      {
        id: "smart-play",
        name: "SmartPlay",
        url: Config.fantasySiteBaseURL,
        darkLogo: SmartPlayWhite,
        submenu: [],
        seq: 3,
      },
      {
        id: 2,
        name: "Racing",
        url: "/racing",
        seq: 6,
        submenu: [
          {
            id: 1,
            name: "Horse Racing",
            url: `/racing?type=${1}`,
          },
          {
            id: 3,
            name: "Greyhound Racing",
            url: `/racing?type=${3}`,
          },
          {
            id: 2,
            name: "Harness Racing",
            url: `/racing?type=${2}`,
          },
        ],
      },
      {
        id: 3,
        name: "Sports",
        url: "",
        seq: 7,
        submenu: [],
      },
      {
        id: 7,
        name: "Podcasts",
        url: "/podcast",
        parentId: 10,
        seq: 8,
        routeName: "podcast",
      },

      {
        id: 10,
        name: "Smart Info",
        logo: SmartInfo,
        darkLogo: SmartInfoWhite,
        url: "/smartinfo/news/0",
        submenu: [],
        seq: 4,
      },
      {
        id: 100,
        name: "Smart Odds Comparison",
        logo: SmartOddsComp,
        darkLogo: SmartOddsCompWhite,
        url: `/odds-comparison/`,
        raceParentId: 2,
        seq: 1,
      },
      {
        id: 12,
        name: "Tipping Competitions",
        logo: SmartTipping,
        darkLogo: SmartTippingWhite,
        url: "/tipscompetition/my-comps",
        submenu: [],
        seq: 2,
      },
    ],
    tippingSubMenuItems: [
      {
        id: 2,
        name: "My Comps",
        url: "/tipscompetition/my-comps",
        parentId: 12,
        routeName: "/tipscompetition/my-comps",
      },
      {
        id: 3,
        name: "Join Comps",
        url: "/tipscompetition/public/tips",
        parentId: 12,
        routeName: "/tipscompetition/public/tips",
      },
      {
        id: 1,
        name: "Create Comps",
        url: "/tipscompetition/create-competition",
        parentId: 12,
        routeName: "/tipscompetition/create-competition",
      },
      {
        id: 4,
        name: "Rankings",
        url: "/tipscompetition/public/rankings",
        parentId: 12,
        routeName: "/tipscompetition/public/rankings",
      },
      {
        id: 6,
        name: "Prizes",
        url: "/tipscompetition/prize",
        parentId: 12,
        routeName: "/tipscompetition/prize",
      },
      {
        id: 5,
        name: "Tipping FAQs",
        url: "/tipscompetition/faqs",
        parentId: 12,
        routeName: "/tipscompetition/faqs",
      },
    ],
    smartInfoSubMenuItems: [
      {
        id: 1,
        name: "News",
        url: "/smartinfo/news/0",
        parentId: 10,
        routeName: "news",
      },
      {
        id: 13,
        name: "Saved Articles",
        url: "/smartinfo/saved-article",
        parentId: 10,
        routeName: "saved-article",
      },

      {
        id: 4,
        name: "Bookmakers",
        url: "/smartinfo/bookmaker",
        parentId: 10,
        routeName: "bookmaker",
      },

      {
        id: 9,
        name: "Our People",
        url: "/smartinfo/our-people",
        parentId: 10,
        routeName: "our-people",
      },
      {
        id: 8,
        name: "Recommended Websites",
        url: "/smartinfo/recommended-websites",
        parentId: 10,
        routeName: "recommended-websites",
      },
    ],
    smartOddsMenuItems: [
      {
        id: 2,
        name: "Home",
        // url: "/tipscompetition/my-comps",
        // parentId: 12,
        // routeName: "/tipscompetition/my-comps",
      },
      {
        id: 3,
        name: "My BlackBook",
        // url: "/tipscompetition/public/tips",
        // parentId: 12,
        // routeName: "/tipscompetition/public/tips",
      },
      {
        id: 1,
        name: "Odds Status",
        // url: "/tipscompetition/create-competition",
        // parentId: 12,
        // routeName: "/tipscompetition/create-competition",
      },
      {
        id: 1,
        name: "Contact Us",
        // url: "/tipscompetition/create-competition",
        // parentId: 12,
        // routeName: "/tipscompetition/create-competition",
      },
    ],
    sportId: [],
    raceSportId: [1, 2, 3],
    countryFilter: ["Aus/NZ", "Intl"],
    betSlip: true,
    featuredBookmakers: true,
    homePageTab: ["NEWS", "RACING", "SPORTS"],
    rightSideBar: true,
    podcasts: ["Sports Update", "Racing Update", "SEN", "Videos"],
    oddsCheck: true,
    Outrights: true,
    FluctuationGraph: true,
    signStep: ["step1", "step2", "step3", "step4", "step5"],
    NoDataComp: [
      {
        name: "American Football",
        url: "/teamsports/americanfootball/odds/0/false",
      },
      {
        name: "Baseball",
        url: "/teamsports/baseball/odds/0/false",
      },
      {
        name: "Horse Racing",
        url: "/racing?type=1",
      },
      {
        name: "Cricket",
        url: "/all-sports/cricket/4/odds/0/false",
      },
      {
        name: "News",
        url: "smartinfo/news/0",
      },
    ],
  },
};
async function initializeRelease() {
  const { sportsdata, finalData } = await fetchSportData();
  release.AU.sportId = sportsdata;
  release.AU.menu[3].submenu = finalData;
}

initializeRelease();
