import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  ClickAwayListener,
  FormControl,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { ReactComponent as Uncheck } from "src/assets/images/icons/Checkbox.svg";
import { ReactComponent as Check } from "src/assets/images/icons/check.svg";
import { ReactComponent as Notcheck } from "src/assets/images/icons/notcheck.svg";
import { Config } from "src/helpers/context";
import "./tippingCompCard.scss";
import SportsOdds from "../../sportsOdds/SportsOdds";
import { release } from "src/helpers/context/release";
import TippingSportsOdds from "./TippingSportsOdds";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const TippingCompCard = ({
  eventsData,
  eventByIdData,
  eventLength,
  lastDate,
  BookkeeperData,
  handleOutsideClick,
  handleOpenTooltip,
  handleCheckboxClick,
  handleIncrement,
  handleDecrement,
  MyPLusNutton,
  MyMinusNutton,
  setCounts,
  counts,
}) => {
  const params = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const winPercentage = (homePercentage, awayPercentage) => {
    const isHomeGreater = homePercentage > awayPercentage;

    const gradient = !isHomeGreater
      ? `conic-gradient(#003764 ${awayPercentage}%, 0, #B4BBE9 ${homePercentage}%)`
      : `conic-gradient(#B4BBE9 ${awayPercentage}%, 0, #003764 ${homePercentage}%)`;
    const winBackGround = isHomeGreater
      ? "linear-gradient(90deg, rgb(222, 229, 235) 50%, rgb(242, 242, 242) 50%)"
      : homePercentage == awayPercentage
        ? "#f2f2f2"
        : "linear-gradient(90deg, rgb(242, 242, 242) 50%, rgb(222, 229, 235) 50%)";
    return (
      <>
        <Box
          id="progress"
          style={{
            display: "grid",
            background: gradient,
          }}
        >
          <Typography
            id="progress-value"
            className="stats"
            style={{
              background: winBackGround,
            }}
          >
            Stats
          </Typography>
        </Box>
      </>
    );
  };
  return (
    <>
      {eventsData?.map((tip, index) => {
        const dateTocheck =
          eventByIdData?.tipDeadline == "game"
            ? moment(tip?.startTime)
            : lastDate;
        const IsScore =
          eventLength === 0
            ? tip?.awayScore && tip?.homeScore
              ? true
              : false
            : Number(params?.SportId) != 4
              ? Boolean(tip?.homeScore ?? false)
              : tip?.ScoreBoard
                ? Object.keys(tip?.ScoreBoard)?.length > 0 &&
                  Boolean(tip?.ScoreBoard?.Epr == 2)
                : false;
        const totalTip = tip?.homeTeam?.TipCount + tip?.awayTeam?.TipCount;
        const homePercentageCount = (
          (tip?.homeTeam?.TipCount / totalTip) *
          100
        ).toFixed(0);
        const awayPercentageCount = (
          (tip?.awayTeam?.TipCount / totalTip) *
          100
        ).toFixed(0);
        return (
          <>
            <Box className="tipping-comp-card-wrap" key={index}>
              <Box className="tipping-comp-top-header">
                <Typography variant="h6" className="comp-header-text">
                  <span className="dark-color">
                    {tip?.startTime ? fetchDayName(tip?.startTime) : ""}{" "}
                    {tip?.startTime
                      ? moment.utc(tip?.startTime).local().format("DD/MM/YYYY")
                      : ""}{" "}
                  </span>
                  |{" "}
                  <span className="dark-color">
                    {tip?.startTime
                      ? moment.utc(tip?.startTime).local().format("hh:mm A")
                      : ""}{" "}
                  </span>
                </Typography>
                <Typography variant="h6" className="comp-header-text">
                  <span className="dark-color">
                    {eventByIdData?.tournament?.name ?? ""}
                  </span>
                </Typography>
              </Box>
              <Box className="win-indication-relative">
                <Box
                  className={
                    eventLength === 0
                      ? `comp-odds-tips-sec`
                      : tip?.homeScore ||
                          (tip?.ScoreBoard &&
                            Object.keys(tip?.ScoreBoard)?.length > 0 &&
                            tip?.ScoreBoard?.Epr == 2)
                        ? `comp-odds-tips-sec `
                        : tip?.winnerCode == 3 ||
                            (tip?.winnerCode === null &&
                              tip?.status === null &&
                              moment.utc(tip?.startTime).tz(timezone) <
                                moment().tz(timezone))
                          ? `comp-odds-tips-sec indefinite-result no-result`
                          : `comp-odds-tips-sec no-result`
                  }
                >
                  <Box className="left-sec ">
                    <Box className="check-box-sec">
                      <FormControl>
                        <label>
                          <Checkbox
                            icon={
                              tip?.winnerCode == 3 ||
                              (((eventByIdData?.tippingType === "odds" &&
                                eventLength !== 0) ||
                                (eventByIdData?.tippingType === "spread" &&
                                  eventLength !== 0)) &&
                                tip?.homeTeam?.isTip !== 1 &&
                                tip?.awayTeam?.isTip !== 1) ? (
                                <Notcheck />
                              ) : dateTocheck < moment() &&
                                tip?.homeTeam?.isTip !== 1 &&
                                tip?.awayTeam?.isTip !== 1 ? (
                                <Notcheck />
                              ) : (
                                <Uncheck />
                              )
                            }
                            checkedIcon={
                              tip?.winnerCode ? (
                                tip?.winnerCode == 1 ? (
                                  <Check />
                                ) : (
                                  <Notcheck />
                                )
                              ) : (
                                <Check />
                              )
                            }
                            disabled={
                              dateTocheck < moment() ||
                              Boolean(tip?.winnerCode) ||
                              (eventByIdData?.tippingType === "odds" &&
                                eventLength !== 0) ||
                              (eventByIdData?.tippingType === "spread" &&
                                eventLength !== 0)
                            }
                            value="homeTeamId"
                            onChange={(event) => {
                              handleCheckboxClick(event, tip, "hometeam");
                            }}
                            checked={tip?.homeTeam?.isTip == 1}
                            className="tip-comp-checkbox"
                          />
                        </label>
                      </FormControl>
                    </Box>
                    <Box
                      className={
                        homePercentageCount > awayPercentageCount
                          ? "dark team-wrap-details left-padding"
                          : "light team-wrap-details left-padding"
                      }
                    >
                      <Box className="team-wrap">
                        <Box
                          className={`${params?.SportId === "9" && params?.compsId === "57" ? "img-blur" : ""} team-img-wrap marign-right-mr`}
                        >
                          <img
                            className="team-img"
                            src={
                              tip?.homeTeam?.flag?.includes("uploads")
                                ? Config.mediaURL + tip?.homeTeam?.flag
                                : Melbourne
                            }
                            alt="team"
                          />
                        </Box>
                        <Box className="comp-name-tip-wrap flex-rr">
                          <Typography className="team-name">
                            {tip?.homeTeam?.name}
                          </Typography>
                          <ClickAwayListener
                            onClickAway={() =>
                              handleOutsideClick(
                                tip,
                                tip?.homeTeamId,
                                index,
                                null,
                              )
                            }
                          >
                            <Box className="blackbook-icon">
                              {handleOpenTooltip(
                                tip,
                                tip?.homeTeamId,
                                index,
                                "hometeam",
                                null,
                              )}
                            </Box>
                          </ClickAwayListener>
                        </Box>
                      </Box>
                      {release[Config.release]?.oddsCheck ? (
                        <>
                          <TippingSportsOdds
                            data={tip}
                            type={"odds"}
                            team={"hometeam"}
                            teamId={tip?.homeTeamId}
                            isScore={IsScore}
                            eventLength={eventLength}
                            eventByIdData={eventByIdData}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* {screenWidth >= 1000 ? ( */}
                      {eventLength !== 0 && (
                        <>
                          {Number(params?.SportId) != 4 ? (
                            tip?.homeScore ? (
                              <Box className="blue-score">
                                <Typography className="score">
                                  {tip?.homeScore?.current ?? 0}
                                </Typography>
                              </Box>
                            ) : (
                              <></>
                            )
                          ) : tip?.ScoreBoard &&
                            Object.keys(tip?.ScoreBoard)?.length > 0 &&
                            tip?.ScoreBoard?.Epr == 2 ? (
                            <Box className="blue-score">
                              {tip?.ScoreBoard?.Exd > "1" ? (
                                <Typography className="score">
                                  {tip?.ScoreBoard?.Tr1C1 ?? "-"}
                                  {tip?.ScoreBoard?.Tr1CW1 &&
                                  tip?.ScoreBoard?.Tr1CW1 === 10
                                    ? ""
                                    : (tip?.ScoreBoard?.Tr1CW1 ||
                                        tip?.ScoreBoard?.Tr1CW1 === 0) &&
                                      `/${tip?.ScoreBoard?.Tr1CW1}${
                                        tip?.ScoreBoard?.Tr1CD1 &&
                                        tip?.ScoreBoard?.Tr1CD1 === 1
                                          ? "d"
                                          : ""
                                      }`}{" "}
                                  {(tip?.ScoreBoard?.Tr1C2 ||
                                    tip?.ScoreBoard?.Tr1C2 === 0) &&
                                    `& ${tip?.ScoreBoard?.Tr1C2}`}
                                  {tip?.ScoreBoard?.Tr1CW2 &&
                                  tip?.ScoreBoard?.Tr1CW2 === 10
                                    ? ""
                                    : (tip?.ScoreBoard?.Tr1CW2 ||
                                        tip?.ScoreBoard?.Tr1CW2 === 0) &&
                                      `/${tip?.ScoreBoard?.Tr1CW2}${
                                        tip?.ScoreBoard?.Tr1CD2 &&
                                        tip?.ScoreBoard?.Tr1CD2 === 1
                                          ? "d"
                                          : ""
                                      }`}{" "}
                                </Typography>
                              ) : (
                                <Typography className="score">
                                  {tip?.ScoreBoard?.Tr1C1 ?? "-"}
                                  {tip?.ScoreBoard?.Tr1CW1 &&
                                  tip?.ScoreBoard?.Tr1CW1 === 10
                                    ? ""
                                    : (tip?.ScoreBoard?.Tr1CW1 ||
                                        tip?.ScoreBoard?.Tr1CW1 === 0) &&
                                      `/${tip?.ScoreBoard?.Tr1CW1}`}{" "}
                                </Typography>
                              )}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {/* ) : (
                    <></>
                  )} */}
                    </Box>
                  </Box>
                  <Box className="left-sec ">
                    <Box
                      className={
                        homePercentageCount < awayPercentageCount
                          ? "dark team-wrap-details right-padding left-sec-end"
                          : "light team-wrap-details right-padding left-sec-end"
                      }
                    >
                      {/* {screenWidth >= 1000 ? ( */}
                      {eventLength !== 0 && (
                        <>
                          {Number(params?.SportId) != 4 ? (
                            tip?.awayScore ? (
                              <Box className="blue-score">
                                <Typography className="score">
                                  {tip?.awayScore?.current ?? 0}
                                </Typography>
                              </Box>
                            ) : (
                              <></>
                            )
                          ) : tip?.ScoreBoard &&
                            Object.keys(tip?.ScoreBoard)?.length > 0 &&
                            tip?.ScoreBoard?.Epr == 2 ? (
                            <Box className="blue-score">
                              {tip?.ScoreBoard?.Exd > "1" ? (
                                <Typography className="score">
                                  {tip?.ScoreBoard?.Tr2C1 ?? "-"}
                                  {tip?.ScoreBoard?.Tr2CW1 &&
                                  tip?.ScoreBoard?.Tr2CW1 === 10
                                    ? ""
                                    : (tip?.ScoreBoard?.Tr2CW1 ||
                                        tip?.ScoreBoard?.Tr2CW1 === 0) &&
                                      `/${tip?.ScoreBoard?.Tr2CW1}${
                                        tip?.ScoreBoard?.Tr2CD1 &&
                                        tip?.ScoreBoard?.Tr2CD1 === 1
                                          ? "d"
                                          : ""
                                      }`}{" "}
                                  {(tip?.ScoreBoard?.Tr2C2 ||
                                    tip?.ScoreBoard?.Tr2C2 === 0) &&
                                    `& ${tip?.ScoreBoard?.Tr2C2}`}
                                  {tip?.ScoreBoard?.Tr2CW2 &&
                                  tip?.ScoreBoard?.Tr2CW2 === 10
                                    ? ""
                                    : (tip?.ScoreBoard?.Tr2CW2 ||
                                        tip?.ScoreBoard?.Tr2CW2 === 0) &&
                                      `/${tip?.ScoreBoard?.Tr2CW2}${
                                        tip?.ScoreBoard?.Tr2CD2 &&
                                        tip?.ScoreBoard?.Tr2CD2 === 1
                                          ? "d"
                                          : ""
                                      }`}{" "}
                                </Typography>
                              ) : (
                                <Typography className="score">
                                  {tip?.ScoreBoard?.Tr2C1 ?? "-"}
                                  {tip?.ScoreBoard?.Tr2CW1 &&
                                  tip?.ScoreBoard?.Tr2CW1 === 10
                                    ? ""
                                    : (tip?.ScoreBoard?.Tr2CW1 ||
                                        tip?.ScoreBoard?.Tr2CW1 === 0) &&
                                      `/ ${tip?.ScoreBoard?.Tr2CW1}`}{" "}
                                </Typography>
                              )}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {/* ) : (
                    <></>
                  )} */}
                      {release[Config.release]?.oddsCheck ? (
                        <TippingSportsOdds
                          data={tip}
                          type={"odds"}
                          team={"awayteam"}
                          teamId={tip?.awayTeamId}
                          isScore={IsScore}
                          eventLength={eventLength}
                          eventByIdData={eventByIdData}
                        />
                      ) : (
                        <></>
                      )}
                      <Box className="team-wrap">
                        <Box
                          className={`${params?.SportId === "9" && params?.compsId === "57" ? "img-blur" : ""} team-img-wrap margin-left-ml`}
                        >
                          <img
                            className="team-img"
                            src={
                              tip?.awayTeam?.flag?.includes("uploads")
                                ? Config.mediaURL + tip?.awayTeam?.flag
                                : Brisbane
                            }
                            alt="team"
                          />
                        </Box>
                        <Box className="comp-name-tip-wrap">
                          <Typography className="team-name">
                            {tip?.awayTeam?.name}
                          </Typography>
                          <ClickAwayListener
                            onClickAway={() =>
                              handleOutsideClick(
                                tip,
                                tip?.awayTeamId,
                                index,
                                null,
                              )
                            }
                          >
                            <Box className="blackbook-icon">
                              {handleOpenTooltip(
                                tip,
                                tip?.awayTeamId,
                                index,
                                "awayteam",
                                null,
                              )}
                            </Box>
                          </ClickAwayListener>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="check-box-sec">
                      <FormControl>
                        <label>
                          <Checkbox
                            icon={
                              tip?.winnerCode == 3 ||
                              (((eventByIdData?.tippingType === "odds" &&
                                eventLength !== 0) ||
                                (eventByIdData?.tippingType === "spread" &&
                                  eventLength !== 0)) &&
                                tip?.homeTeam?.isTip !== 1 &&
                                tip?.awayTeam?.isTip !== 1) ? (
                                <Notcheck />
                              ) : dateTocheck < moment() &&
                                tip?.awayTeam?.isTip !== 1 &&
                                tip?.homeTeam?.isTip !== 1 ? (
                                <Notcheck />
                              ) : (
                                <Uncheck />
                              )
                            }
                            checkedIcon={
                              tip?.winnerCode ? (
                                tip?.winnerCode == 2 ? (
                                  <Check />
                                ) : (
                                  <Notcheck />
                                )
                              ) : (
                                <Check />
                              )
                            }
                            disabled={
                              dateTocheck < moment() ||
                              Boolean(tip?.winnerCode) ||
                              (eventByIdData?.tippingType === "odds" &&
                                eventLength !== 0) ||
                              (eventByIdData?.tippingType === "spread" &&
                                eventLength !== 0)
                            }
                            value="awayTeamId"
                            onChange={(event) => {
                              handleCheckboxClick(event, tip, "awayteam");
                            }}
                            checked={tip?.awayTeam?.isTip == 1}
                            className="tip-comp-checkbox"
                          />
                        </label>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                {!IsScore && totalTip > 0 && (
                  <Box className="win-indication-wrap">
                    <span>{homePercentageCount}%</span>
                    {winPercentage(homePercentageCount, awayPercentageCount)}
                    <span>{awayPercentageCount}%</span>
                  </Box>
                )}
              </Box>
              {tip?.margin && eventByIdData?.marginRound == 1 && (
                <>
                  <Box className="margin-sec-wrap">
                    <Box className="margin-sec">
                      <Typography className="margin-txt">
                        {params?.sports == "cricket"
                          ? "Number of 6s"
                          : "Margin"}
                      </Typography>
                    </Box>
                    <Box className="counter-score-sec">
                      <Box className="counter-input">
                        <Button
                          disableFocusRipple
                          disableRipple
                          className="counter-input__button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDecrement(tip?.id, index);
                          }}
                          disabled={counts == 0 || dateTocheck < moment()}
                        >
                          <MyMinusNutton />
                        </Button>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          className="margin-textfield"
                          name="compName"
                          onChange={(e) => {
                            const input = e?.target?.value;
                            const numericInput = input?.replace(/[^0-9]/g, "");
                            const removeLeadingZeros = (numericInput) => {
                              if (numericInput.length >= 2) {
                                const result = parseInt(
                                  numericInput,
                                  10,
                                ).toString();
                                return result;
                              }
                              return numericInput;
                            };
                            const finalMargin =
                              removeLeadingZeros(numericInput);

                            setCounts(finalMargin ? Number(finalMargin) : 0);
                          }}
                          onFocus={(e) => e.target.select()}
                          value={counts}
                          disabled={dateTocheck < moment()}
                        />

                        <Button
                          disableFocusRipple
                          disableRipple
                          className="counter-input__button plus-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            handleIncrement(tip?.id, index);
                          }}
                          disabled={dateTocheck < moment()}
                        >
                          <MyPLusNutton />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </>
        );
      })}
    </>
  );
};

export default TippingCompCard;
