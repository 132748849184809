import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import { fetchFromStorage } from "src/helpers/context";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import AdvertisingBannner from "../advertisingBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import DialogBox from "../UI/dialogBox";
import { Pagination, Rating } from "@mui/material";
import OurPartner from "../ourPartners";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../AdBanner/AdBanner";
import "./bookmakers.scss";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Search } from "src/assets/images/icons/search.svg";
import Select, { components } from "react-select";
import { ReactComponent as Star1 } from "src/assets/images/icons/star1.svg";
import { ReactComponent as Star2 } from "src/assets/images/icons/star2.svg";
import { ReactComponent as Star3 } from "src/assets/images/icons/star3.svg";
import { ReactComponent as Star4 } from "src/assets/images/icons/star4.svg";
import { ReactComponent as Star5 } from "src/assets/images/icons/star5.svg";
import DefaultImg from "src/assets/images/smartb_default.png";
import { debounce } from "lodash";

const ratingOption = [
  { label: "5", value: 5 },
  { label: "4", value: 4 },
  { label: "3", value: 3 },
  { label: "2", value: 2 },
  { label: "1", value: 1 },
];

const BookMakersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);

  const [bookkeeperData, setBookkeeperData] = useState([]);
  const [BookkeeperID, setBookkeeperID] = useState(1);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState("");
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [selectedPage, setSelectedPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");

  const options = [
    { value: "10", label: "10" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
  ];

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const debouncedGetBookmakersList = useCallback(
    debounce((page, selectedPage, searchVal) => {
      getBookmakersList(page, selectedPage, searchVal);
    }, 500),
    [],
  );

  useEffect(() => {
    // getBookmakersList(1, selectedPage, searchVal);
    debouncedGetBookmakersList(1, selectedPage, searchVal);
  }, [selectedPage, searchVal, debouncedGetBookmakersList]);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = (ReduxAdsData) => {
    // try {
    //   const { status, data } = await axiosInstance.get(
    //     `getAllAds?page_id=1,11`
    //   );
    //   if (status === 200) {
    let AdvtData = ReduxAdsData?.filter((item) => item?.page_id === 1);
    setAdData(AdvtData);
    // dispatch(fetchAdvtData(AdvtData?.[0]));
    let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var payload = {
      bookKeeperId: BookkeeperID,
      review: review,
      rating: rating,
    };

    if (rating === 0) {
      setRatingError("Please select a rating.");
    } else {
      setRatingError("");
      if (rating !== null || rating > 0) {
        try {
          const { status, data } = await axiosInstance.post(
            `/reviews`,
            payload,
          );
          if (status === 201) {
            setOpen(false);
            setReview("");
            setRating(0);
          }
        } catch (err) {
          console.log("err", err);
        }
      }
    }
  };
  const oncloseDialog = () => {
    setOpen(false);
    setReview("");
    setRating(0);
  };

  const getBookmakersList = async (offset, perPage, searchVal) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/getAllbookKeeper?page=${offset}&perPage=${perPage}&search=${searchVal}`,
      );
      if (status === 200) {
        setLoader(false);
        setBookkeeperData(data?.result);
        setTotalCount(data?.count);
        setcurrentPage(offset);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("error", err);
    }
  };

  const handleNavigate = (data) => {
    navigate(`/smartinfo/bookmaker/bookmakers-info/${data?.id}`, {
      state: { data: data },
    });
  };

  const showLogo = (item) => {
    var logo = item?.bookMakerList_logo
      ? Config.mediaURL + item?.bookMakerList_logo
      : "";
    return logo;
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleRatingChange = (newValue) => {
    setRating(newValue);
  };

  const handleReviewSection = (data) => {
    if (handleRestrictedUser()) {
      setOpen(true);
      setBookkeeperID(data?.id);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  const handlePaginationClick = (event, page) => {
    setcurrentPage(Number(page));
    getBookmakersList(Number(page), selectedPage, searchVal);
    const targetSection = document.getElementById("bookmaker-list-scroll");

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const pageNumbers = [];

  if (totalCount > 0) {
    for (let i = 1; i <= Math.ceil(totalCount / selectedPage); i++) {
      pageNumbers.push(i);
    }
  }

  const reviewEmoji = (value) => {
    if (value <= 1) {
      return <Star1 />;
    }
    if (value <= 2) {
      return <Star2 />;
    }
    if (value <= 3) {
      return <Star3 />;
    }
    if (value <= 4) {
      return <Star4 />;
    }
    if (value <= 5) {
      return <Star5 />;
    }
  };

  const joinbtnView = (item) => (
    <Box className="review-box join-btn-wrap pr-33">
      <Box>
        <Button
          className="bookmakers-join-btn"
          disableElevation
          disableFocusRipple
          disableRipple
          onClick={() => window.open(item?.affiliate_link, "_blank")}
        >
          Join
        </Button>

        <br />
        <Button
          className="review-txt"
          disableElevation
          disableFocusRipple
          disableRipple
          onClick={() => {
            handleNavigate(item);
          }}
        >
          Visit {item?.name}
        </Button>
      </Box>
    </Box>
  );

  const startResult = (currentPage - 1) * selectedPage + 1;
  const endResult = Math.min(currentPage * selectedPage, totalCount);

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {/* <AdvertisingBannner src={banner} className="banner" /> */}
          {fetchPageHeaderAds("218px", banner)}
        </Box>

        <Box className="bookmakers-data" id="bookmaker-list-scroll">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                HOME
              </Link>

              <Typography>Bookmakers</Typography>
            </Breadcrumbs>
          </Box>

          <Typography className="page-title">Bookmakers</Typography>

          <Typography className="bookmaker-sub-desc">
            {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et */}
          </Typography>
          <Typography>
            {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
          et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
          Lorem ipsum dolor sit amet. */}
          </Typography>

          <Box className="bookmakers-list-box">
            <Box className="d-flex text-align content-space-betwen bookmaker-header-content">
              <Box className="d-flex text-align">
                <Select
                  className="React pagination-select"
                  value={options?.find((item) => {
                    return item?.value === selectedPage;
                  })}
                  onChange={(e) => setSelectedPage(e?.value)}
                  options={options}
                  classNamePrefix="select"
                  placeholder="Pagination: 10"
                  isSearchable={false}
                  components={{ DropdownIndicator }}
                />
                <Typography className="pagination-result-show">
                  {!loader && bookkeeperData?.length > 0
                    ? `Showing ${startResult} - ${endResult} of ${totalCount} results`
                    : ""}
                </Typography>
              </Box>
              <Box className="field-container">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="comp-textfield"
                  placeholder="Search Bookmaker"
                  name="searchVal"
                  onChange={(e) => setSearchVal(e?.target?.value)}
                  value={searchVal}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Box className="divider"></Box>
            {loader ? (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            ) : bookkeeperData?.length > 0 ? (
              bookkeeperData?.map((item, i) => {
                return (
                  <Box className="bookmakers-list" key={i}>
                    {item?.isFeatured && (
                      <Typography className="bookmakers-featured">
                        Featured
                      </Typography>
                    )}
                    <Box className="image-title-box">
                      <Box
                        className="img-box"
                        onClick={() => handleNavigate(item)}
                      >
                        <img
                          className="img"
                          src={showLogo(item) ? showLogo(item) : DefaultImg}
                          alt={item?.key}
                          onError={(e) => {
                            e.target.src = DefaultImg;
                          }}
                        />
                      </Box>
                      <Box
                        className="bookmakers-list-details"
                        onClick={() => handleNavigate(item)}
                      >
                        <Typography className="title">{item?.name}</Typography>
                        <Typography className="bookmakers-list-desc">
                          {item?.sort_descriptions}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="review-box">
                      <Box>
                        <Typography className="bookmakers-review-count">
                          {item?.averageRating?.toFixed(1)}
                        </Typography>
                        <Button
                          className="review-txt"
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          onClick={() => {
                            handleReviewSection(item);
                          }}
                        >
                          Add review
                        </Button>
                      </Box>
                      <Box className="show-1180">{joinbtnView(item)}</Box>
                    </Box>
                    <Box className="hide-1180">{joinbtnView(item)}</Box>
                  </Box>
                );
              })
            ) : (
              <Box className="no-data-wrap-bookmakers">
                <Typography className="text-align no-data">
                  No Data Available
                </Typography>
              </Box>
            )}
            {!loader && bookkeeperData?.length > 0 && (
              <Box className="bookmaker-Pagination">
                <Pagination
                  disabled={totalCount / selectedPage > 1 ? false : true}
                  page={currentPage}
                  onChange={(e, value) => handlePaginationClick(e, value)}
                  count={pageNumbers[pageNumbers?.length - 1]}
                  siblingCount={2}
                  boundaryCount={1}
                  // count={10}
                  size="small"
                />
              </Box>
            )}
          </Box>
          <OurPartner />
        </Box>

        <Dialog
          onClose={() => oncloseDialog()}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="odds-Comparison-modal filter-modal"
        >
          <DialogTitle className="modal-title">
            <Typography variant="h6" className="title">
              Write a review
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => oncloseDialog()}
              className="close-icon"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="review-modal-details">
            <Box className="smartb-dialog">
              <Box className="d-flex text-align modal-review-star">
                {ratingOption?.map((item, i) => {
                  return (
                    <Box
                      key={i}
                      className="bookmaker-total-review-emoji mr-18"
                      onClick={() => handleRatingChange(item?.value)}
                    >
                      {reviewEmoji(parseInt(item?.value))}
                      <Rating
                        name="customized-10"
                        defaultValue={item?.value}
                        // precision={0.5}
                        className={`rating ${
                          rating === item?.value ? "select-rating" : ""
                        }`}
                        readOnly
                      />
                    </Box>
                  );
                })}
              </Box>
              {ratingError && !rating && (
                <div className="text-danger ml-4">{ratingError}</div>
              )}
              <TextField
                id="review"
                variant="outlined"
                className="txt-fld"
                placeholder="Write a review"
                multiline
                rows={8}
                fullWidth
                value={review}
                onChange={handleReviewChange}
              />
              <Box className="submit-btn-box">
                <Button className="submit-btn" onClick={(e) => handleSubmit(e)}>
                  Submit
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        {/* <DialogBox
        open={open}
        onClose={oncloseDialog}
        handleClose={oncloseDialog}
        title={false}
        className="dialog-box"
        content={
          <Box className="smartb-dialog">
            <Typography className="title">Write a review</Typography>
            <Rating
              value={rating}
              name="half-rating"
              className="rating"
              onChange={handleRatingChange}
            />
            {ratingError && !rating && (
              <div className="text-danger ml-4">{ratingError}</div>
            )}
            <TextField
              id="review"
              variant="outlined"
              className="txt-fld"
              placeholder="Write a review"
              multiline
              rows={8}
              fullWidth
              value={review}
              onChange={handleReviewChange}
            />

            <Box className="submit-btn-box">
              <Button className="submit-btn" onClick={e => handleSubmit(e)}>
                Submit
              </Button>
            </Box>
          </Box>
        }
        closeIcon={true}
      /> */}
      </Box>
    </>
  );
};

export default BookMakersPage;
