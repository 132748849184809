import { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import MasterCard from "src/assets/images/mastercard.png";
import AmericanExpress from "src/assets/images/americanExpress.png";
import Visa from "src/assets/images/visa.png";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { fetchFromStorage } from "src/helpers/context";
import { Formik, Form } from "formik";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment";
import PaymentAddEditModal from "src/views/component/UI/paymentAddEditModal";
import "./payment.scss";
import CommonTabs from "src/components/Tabs";
import Summary from "./Summary";
import PaymentForm from "./PaymentForm";
import { setApiMessage } from "src/helpers/commonFunctions";
import { formatExpiryDatePayload } from "src/helpers/utils";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [cardList, setCardList] = useState([]);
  const [cardLoader, setCardLoader] = useState(false);
  const [cardAddEditModalOpen, setCardAddEditModalOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [selectedCard, setSelectedCard] = useState("");
  const [cardsave, setCardSave] = useState(false);
  const formikRef = useRef(null);
  const [isPayementLoading, setIsPaymentLoading] = useState(false);
  const [cardFormValues, setCardFormValues] = useState(null);
  const [cardFormError, setCardFormError] = useState(null);
  const [cardValidate, setCardValidate] = useState(false);
  const [cardFeeInfo, setCardFeeInfo] = useState({});

  const [activeTab, setActiveTab] = useState(1);
  const location = useLocation();

  const eventData = location.state;

  const navigateBack = () => {
    navigate(-1);
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const isLogin = handleRestrictedUser();

  useEffect(() => {
    // For new card entry
    const { cardname, cardNumber, expirationDate, cvv } = cardFormValues ?? {};
    let formattedDate = "";

    if (expirationDate) {
      const parts = expirationDate.split("/");
      if (parts?.length === 2) {
        const month = parts[0].padStart(2, "0");
        const year = parts[1];
        formattedDate = `${year}-${month}`;
      }
    }

    // Only validate if all fields are filled and no errors
    if (
      cardNumber?.length >= 16 &&
      expirationDate?.length === 7 &&
      cvv?.length === 3 &&
      !cardFormError?.cardname &&
      !cardFormError?.cardNumber &&
      !cardFormError?.expirationDate &&
      !cardFormError?.cvv
    ) {
      validateUserCard({
        cardname,
        cardNumber: cardNumber.replace(/\s/g, ""),
        cardExp: formattedDate,
        cvv,
        amount: eventData?.entryFee ?? 0,
      });
    }
  }, [cardFormValues, cardFormError]);

  const validateUserCard = async (cardData) => {
    setCardValidate(true);
    try {
      const payload = cardData.cardId
        ? {
            cardId: cardData.cardId,
            amount: cardData.amount,
          }
        : {
            card: {
              card_holder_name: cardData.cardname,
              card_exp: cardData.cardExp,
              card_number: cardData.cardNumber,
              cvv: cardData.cvv,
            },
            amount: cardData.amount,
          };

      const { status, data } = await axiosInstance.post(
        `subscription/card/validations`,
        payload,
      );

      if (status === 200) {
        setCardFeeInfo(data?.data);
        setCardValidate(false);
      } else {
        setCardValidate(false);
        setApiMessage("error", data?.message);
      }
    } catch (err) {
      setCardValidate(false);
      setApiMessage(
        "error",
        err?.response?.data?.message || "Card validation failed",
      );
    }
  };

  const makePayment = async ({ payload, tippingId }) => {
    try {
      setIsPaymentLoading(true);
      const { status, data } = await axiosInstance.post(
        `/tipping/payment/tippingEntryFee/${tippingId}`,
        payload,
      );

      if (status === 200) {
        setApiMessage("success", data?.message);
        fetchGetCardDetails();
        setIsPaymentLoading(false);
        if (formikRef.current) {
          formikRef.current.resetForm(); // Reset the form
        }
        navigateBack();
      }
    } catch (error) {
      console.error(error);
      setApiMessage("error", error?.response?.data?.message);
      setIsPaymentLoading(false);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const handleSubmit = ({ values }) => {};

  const handelPayment = () => {
    if (selectedCard?.id) {
      const alreadyCardPayload = {
        cardId: selectedCard.id,
        isFeatured: true,
        paymentBase: "tippingCompetitionEntryFee",
        tippingId: eventData?.id,
      };

      makePayment({ payload: alreadyCardPayload, tippingId: eventData?.id });
      return;
    } else {
      if (formikRef.current) {
        formikRef.current.submitForm();
        const formData = formikRef?.current?.values;
        const card = {
          card_holder_name: formData?.cardname,
          card_exp: formatExpiryDatePayload(formData?.expirationDate),
          card_number: formData?.cardNumber?.replace(/\s/g, ""),
          cvv: formData?.cvv,
        };
        if (
          formData?.cardname &&
          formData?.expirationDate &&
          formData?.cardNumber &&
          formData?.cvv
        ) {
          makePayment({
            payload: {
              card: card,
              isFeatured: formData?.isFeatured,
              paymentBase: "tippingCompetitionEntryFee",
            },
            tippingId: eventData?.id,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      fetchGetCardDetails();
    }
  }, []);

  useEffect(() => {
    const defaultCard = cardList?.find((card) => card?.is_default === 1);

    setSelectedCard(defaultCard);
    setPaymentMode(defaultCard?.id);
  }, [cardList]);

  const handleCardAddEditModal = () => {
    setCardAddEditModalOpen(true);
  };

  const handleCardAddEditModalClose = () => {
    setCardAddEditModalOpen(false);
  };

  const fetchGetCardDetails = async () => {
    setCardLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/get-card`,
      );
      if (status === 200) {
        setCardLoader(false);
        const cardDetails = data?.card;
        setCardList(cardDetails);
      }
    } catch (error) {
      setCardLoader(false);
    }
  };

  const handleCardSave = (event) => {
    setCardSave(event?.target?.checked);
  };

  const handlePaymentModeChange = (e, data) => {
    if (e?.target?.checked) {
      setSelectedCard(data);
      setPaymentMode(e?.target?.value);
      setCardFeeInfo({});
      validateUserCard({
        cardId: e?.target?.value,
        amount: eventData?.entryFee ?? 0,
      });
    } else {
      setSelectedCard("");
      setPaymentMode("");
      setCardFeeInfo({});
    }
  };

  const handlecardRemove = async (id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `/subscription/delete-card/${id}`,
        {
          body: {
            type: "web",
          },
        },
      );
      if (status === 200) {
        fetchGetCardDetails();
        setSelectedCard("");
        setPaymentMode("");
      } else {
        setSelectedCard("");
        setPaymentMode("");
      }
    } catch (error) {
      setCardLoader(false);
      setSelectedCard("");
      setPaymentMode("");
    }
  };

  const tabs = [
    { id: 1, label: "Credit/Debit Card" },
    { id: 2, label: "Bank Deposit" },
  ];

  const handleBack = () => {
    navigate(-1);
  };

  // Effect for clearing form

  useEffect(() => {
    if (formikRef.current) {
      if (paymentMode !== "new") {
        formikRef.current.resetForm();
      }
    }
  }, [formikRef, paymentMode]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", sm: "2fr 1fr" }, // 1 column on xs, 2 columns on sm+
        gap: 2,
      }}
    >
      <Box className="edit-comp-details" sx={{ mb: 10 }}>
        <Accordion defaultExpanded className="create-comp-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              onClick={handleBack}
            >
              <ArrowBackIosIcon color="white" sx={{ color: "white" }} />
              <Typography className="accordion-header-text">Payment</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <CommonTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {/* Credit Card */}
            {activeTab === 1 && (
              <Box sx={{ padding: 2 }}>
                <Box className="tipping-payment-details">
                  <Typography className="payment-details">
                    Save cards
                  </Typography>
                  <Box className="credit-card-details"></Box>
                  <Box className="payment-select">
                    <RadioGroup
                      aria-label="payment"
                      name="payment"
                      className="payment-radioGroup"
                      value={Number(paymentMode)}
                    >
                      {cardLoader && (
                        <div className="allsport-loader-center">
                          <Loader />
                        </div>
                      )}
                      {!cardLoader &&
                        (cardList?.length > 0 ? (
                          cardList?.map((item) => {
                            return (
                              <>
                                <Box className="payment-card-flex">
                                  <FormControlLabel
                                    key={item.id}
                                    className="save-card"
                                    value={Number(item.id)}
                                    onChange={(e) =>
                                      handlePaymentModeChange(e, item)
                                    }
                                    control={
                                      <Checkbox
                                        size="small"
                                        icon={
                                          <UnChecked className="radio-icon" />
                                        }
                                        checkedIcon={
                                          <Checked className="radio-icon" />
                                        }
                                        disableRipple
                                        checked={item?.id == paymentMode}
                                      />
                                    }
                                    label={
                                      <Box className="save-payment-card-label">
                                        <Box>
                                          <Typography className="bold">
                                            <span>****{item?.last4}</span>
                                          </Typography>
                                          <Typography className="expiry-date">
                                            Expiry - {item?.cardExp}{" "}
                                            {/* <Box>
                                              <span
                                                className={
                                                  item.is_default === 1
                                                    ? "disabled delete-underline"
                                                    : "cursor-pointer delete-underline"
                                                }
                                                onClick={() => {
                                                  if (
                                                    // item &&
                                                    // item.is_default !== 1
                                                    true
                                                  ) {
                                                    handlecardRemove(item.id);
                                                  }
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Box> */}
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <img
                                            src={
                                              item?.brand === "Visa"
                                                ? Visa
                                                : item?.brand === "Master"
                                                  ? MasterCard
                                                  : AmericanExpress
                                            }
                                            alt="card"
                                          />
                                        </Box>
                                      </Box>
                                    }
                                  />
                                </Box>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        ))}

                      <PaymentForm
                        selectedCard={selectedCard}
                        paymentMode={paymentMode}
                        cardsave={cardsave}
                        handleCardSave={handleCardSave}
                        onSubmit={handleSubmit}
                        formRef={formikRef}
                        hideSubmitButton={true}
                        setPaymentMode={setPaymentMode}
                        setSelectedCard={setSelectedCard}
                        setCardFormValues={setCardFormValues}
                        setCardFormError={setCardFormError}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
                <Dialog
                  onClose={handleCardAddEditModalClose}
                  aria-labelledby="customized-dialog-title"
                  open={cardAddEditModalOpen}
                  className="payment-add-edit-modal"
                >
                  <DialogTitle className="modal-title">
                    <Typography variant="h6" className="title">
                      Add Payment Method
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={() => handleCardAddEditModalClose()}
                      className="close-icon"
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent className="payment-details">
                    <PaymentAddEditModal
                      handleCardAddEditModalClose={handleCardAddEditModalClose}
                    />
                  </DialogContent>
                </Dialog>
              </Box>
            )}
            {/* Deposit */}

            {activeTab === 2 && (
              <Box sx={{ padding: 2 }}>
                <Typography>
                  To pay your entry fee with no additional charges, you can
                  deposit directly into our bank account.
                </Typography>
                <Box
                  sx={{
                    background: "#F7F7F8",
                    mt: 2,
                    borderRadius: 2,
                    padding: 2,
                  }}
                >
                  <Typography>
                    <span className="font-bold">Account Name:</span> Better
                    Business Register Pty Ltd
                  </Typography>
                  <Typography>
                    <span className="font-bold">BSB:</span> 082057
                  </Typography>
                  <Typography>
                    <span className="font-bold">Account Number:</span> 749720405
                  </Typography>
                  <Typography>
                    <span className="font-bold">Reference Number:</span> #
                    {eventData?.id}
                  </Typography>
                </Box>
                <Typography>
                  <span className="font-bold">Note:</span>
                  You must add the competition number as your reference,
                  otherwise we may be unable to track your deposit
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Summary
        isBnakDeposit={activeTab === 2}
        selectedCard={selectedCard}
        handlePaymentSubmit={handelPayment}
        formikRef={formikRef}
        isPayementLoading={isPayementLoading}
        cardFeeInfo={cardFeeInfo}
        cardValidate={cardValidate}
      />
    </Box>
  );
};

export default Payment;
