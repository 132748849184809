import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableContainer,
  FormControl,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  ClickAwayListener,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import banner from "src/assets/images/banner/smart-b-banner-primary.png";
import bannerIndia from "src/assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import OurPartner from "../ourPartners";
import { useParams } from "react-router-dom";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import playerAvtar from "src/assets/images/player-avatar.png";
import AdBannner from "../AdBanner/AdBanner";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import DummyImage from "src/assets/images/featuredNews1.jpg";
import Bet365 from "src/assets/images/bet365.png";
import moment from "moment-timezone";
import { ReactComponent as Uncheck } from "src/assets/images/icons/Checkbox.svg";
import { ReactComponent as Check } from "src/assets/images/icons/check.svg";
import { ReactComponent as BlueCheck } from "src/assets/images/icons/blue-checkbox.svg";
import { ReactComponent as Minus } from "src/assets/images/icons/counter-minus.svg";
import { ReactComponent as Plus } from "src/assets/images/icons/counter-plus.svg";
import { ReactComponent as TooltipArrow } from "src/assets/images/icons/tooltip_arrow.svg";
import Facebook from "src/assets/images/social/fb-link.svg";
import Linkedin from "src/assets/images/social/li-link.svg";
import Twitter_X from "src/assets/images/social/x-link.svg";
import Snapchat from "src/assets/images/social/sc-link.svg";
import Copy from "src/assets/images/social/copy-link.svg";
import Messenger from "src/assets/images/social/mess-link.svg";
import Wp from "src/assets/images/social/wp-link.svg";
import Message from "src/assets/images/social/messages.svg";
import { ReactComponent as Share } from "src/assets/images/icons/share.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import NoDataComp from "../UI/NoData";
import { useDispatch, useSelector } from "react-redux";
import "./individualCompetitionPublic.scss";
import { Config, fetchFromStorage } from "src/helpers/context";
import Countdown from "react-countdown";
import { release } from "src/helpers/context/release";
import DefaultImg from "src/assets/images/smartb_default.png";
import SmartBookModal from "../UI/smartbookModal";
import DialogBox from "../UI/dialogBox";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const IndividualCompetitionPublicPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );
  const navigate = useNavigate();
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [stepperCount, setStepperCount] = useState(0);
  const [rounds, setRounds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const limit = 20;
  const [teamApiCount, setTeamApiCount] = useState(0);
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState(null);
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [OrgAll, setOrgAll] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [countOrg, setcountOrg] = useState(0);
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [eventLength, setEventLength] = useState(0);
  const [childIndex, setChildIndex] = useState(0);
  const [eventByIdData, setEventByIdData] = useState({});
  const [sharedUser, setSharedUser] = useState({});
  const [allEventData, setAllEventData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [eventsData, setEventsData] = useState(allEventData);
  const [groupedEventData, setGroupedData] = useState({});
  const [counts, setCounts] = useState(0);
  const [isJoker, setIsJoker] = useState(false);
  const [jokerRound, setJockerRound] = useState(null);
  const [completedRound, setCompletedRound] = useState([]);
  const [cutoffDate, setcutoffDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [recallAPI, setRecallAPI] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredParentIndex, setHoveredParentIndex] = useState(null);
  const [clickToolTipTeamId, setClickToolTipTeamId] = useState(null);
  const [SBopen, setSBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [joinOpen, setJoinOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const [link, setLink] = useState(window.location.href);
  const redux_userData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );

  const round = searchParams?.get("round");
  const compId = searchParams?.get("compId");
  const referral_type = searchParams?.get("referral_type");
  const referral = searchParams?.get("referral");
  const sId = searchParams?.get("sportId");
  const tId = searchParams?.get("tournamentId");

  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  const url =
    Config.baseURL.replace("/api", "") +
    `tipscompetition/share-tips?round=${round}&compId=${compId}&referral_type=tippingComp&referral=${referral}&sportId=${sId}&tournamentId=${tId}`;

  const convetToGroupData = (eventdata) => {
    const groupedData = _.groupBy(eventdata, (event) =>
      moment(event?.startTime).format("YYYY-MM-DD"),
    );
    setGroupedData(groupedData);
  };

  useEffect(() => {
    setCurrentPage(0);
    fetchSportData();
    fetchBookKeeper();
    // getEventByID("");
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    round && getEventByID(round);
  }, []);
  useEffect(() => {
    if (cutoffDate && eventByIdData?.cutOffTime) {
      const time = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss",
        )
        .tz(timezone)
        .format("HH:mm:ss");
      const dateTime = moment(`${cutoffDate} ${time}`, "YYYY-MM-DD HH:mm:ss");
      setLastDate(dateTime);
    }
  }, [cutoffDate, eventByIdData?.cutOffTime]);

  //   useEffect(() => {
  //     if (lastDate && lastDate > moment()) {
  //       const updateCurrentTime = () => {
  //         const currentTimePassed = lastDate < moment();
  //         if (currentTimePassed) {
  //           setRecallAPI(true);
  //           clearInterval(intervalId);
  //         }
  //       };

  //       const intervalId = setInterval(updateCurrentTime, 1000);

  //       return () => clearInterval(intervalId);
  //     }
  //   }, [lastDate]);

  //   useEffect(() => {
  //     if (recallAPI) {
  //       getEventByID(stepperCount);
  //       // setRecallAPI(false);
  //     }
  //   }, [recallAPI]);
  useEffect(() => {
    if (allEventData?.length > 0) {
      const modifiedEventData = allEventData?.map((item, index) =>
        index === 0 ? { ...item, margin: true } : item,
      );

      convetToGroupData(modifiedEventData);
      setEventsData(modifiedEventData);
      const margin = allEventData?.[0]?.roundData?.margin;

      // setCounts(margin ? margin : counts);
    }
  }, [allEventData]);

  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const getEventByID = async (round) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/get/${compId}?timezone=${timezone}&round=${round}&referral=${referral}`,
      );
      if (status === 200) {
        setEventByIdData(data?.result);
        setSharedUser(data?.sharedUser);
        setEventLength(data?.events?.length);
        setIsJoker(round == data?.result?.joker);
        setJockerRound(data?.result?.joker);
        if (data?.events?.length === 0) {
          getAllEvents(round);
        } else {
          setLoading(false);
          setAllEventData(data?.events);
          setCounts(
            data?.events?.[0]?.roundData?.margin
              ? data?.events?.[0]?.roundData?.margin
              : counts,
          );
          setcutoffDate(data?.result?.date);
          convetToGroupData(data?.events);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getAllEvents = async (round) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/events?timezone=${timezone}&tournamentId=${tId}&SportId=${sId}&startDate=${moment()
          .tz(timezone)
          .format("YYYY-MM-DD")}&round=${round}&competitionId=${compId}`,
      );
      if (status === 200) {
        setLoading(false);
        setAllEventData(data?.result);
        setCounts(0);
        convetToGroupData(data?.result);
        setcutoffDate(data?.competition?.date);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const MyMinusNutton = () => {
    return (
      <Button>
        <Minus />
      </Button>
    );
  };
  const MyPLusNutton = () => {
    return (
      <Button>
        <Plus />
      </Button>
    );
  };

  const getFields = (list, field) => {
    return list.reduce(function (carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`,
      );
      if (status === 200) {
        var sportsdata = data?.result?.map((s) => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        var filterSports = getFields(sportsdata, "id");
        setSelectedSports(filterSports);
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var mergedArray = _.concat(
          [{ label: "ALL", value: filterSports, isAll: true }],
          sdata,
        );
        setSports(mergedArray);
      }
    } catch (err) {}
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`,
      );
      if (status === 200) {
        setOrgApiCount(OrgApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.name,
            value: item?.id,
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        let allteamdatas = filterData?.unshift({
          label: "All Tournaments",
          value: 0,
        });
        setOrgAll(
          _.uniqBy(filterData, function (e) {
            return e.value;
          }),
        );
      }
    } catch (err) {}
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`,
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.name,
              value: item?.id,
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function (e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };

  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };
  const handleBookkeeperCounter = async (BookKeeperId, type, item) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(item?.SportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item, fullData) => {
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let iconData = icon?.[0]?.BookKeeper;

    return (
      <a
        href={iconData?.affiliate_link}
        target="_blank"
        rel="noopener noreferrer"
        className="odds-icon-anchor"
      >
        <img
          className="bookmaker-thumb odd-img"
          src={
            iconData?.small_logo?.includes("uploads")
              ? Config.mediaURL + iconData?.small_logo
              : iconData?.small_logo
          }
          alt="Odds Icon"
          onClick={() => handleBookkeeperCounter(BookKeeperId, type, fullData)}
        />
      </a>
    );
  };

  const shareOnMessenger = (link) => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link, // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };
  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore,
  ) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let iconData = icon?.[0]?.BookKeeper;
    const oddsInfo = BookKeeperData?.[0];
    // let isAdded = BetslipData?.some(el => {
    //   if (
    //     el?.BookKeeperId == BookKeeperId &&
    //     el?.betOfferId == oddsInfo?.id &&
    //     el?.eventId == fulldata?.id
    //   )
    //     return true;
    //   else return false;
    // });
    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      // {/* <span
      //   className="odds-link"
      //   onClick={() => handleAddToBetSlip(BookKeeperId, type, item, fulldata)}
      //   //  onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
      // >
      //   {Number(odds).toFixed(2)}
      // </span> */}
      // </a>
      <>
        {odds ? (
          <span
            className="odds-btn cursor-pointer"
            onClick={(e) => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              // IsBetslip === "nobetslip"
              //   ? handleBookkeeperCounter(BookKeeperId, type, e)
              //   : isAdded
              //   ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
              //   : handleAddToBetSlip(
              //       BookKeeperId,
              //       type,
              //       item,
              //       fulldata,
              //       Isscore,
              //       e
              //     );
            }}
          >
            <span
              className={
                "odds-link odds"
                // isAdded && IsBetslip === "betslip"
                //   ? "betslip-added odds-link"
                //   : "odds-link"
              }
            >
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : (
          <span className="odds-btn">NOA</span>
        )}
      </>
    );
    // }
  };
  const fetchCurrentBestOdds = (data, type, team, teamid, Isscore) => {
    let allTeamOdds =
      data?.SportId === 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.SportId === 10
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : data?.SportId === 15
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : data?.SportId === 9
              ? data?.ARBetOffers?.[0]?.AROdds
              : data?.SportId === 16
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : data?.SportId === 7
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : data?.SportId === 11
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : data?.SportId === 17
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : data?.SportId === 6
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : data?.SportId === 5
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : data?.SportId === 8
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.SportId === 10
          ? item?.NBATeamId == data?.homeTeamId
          : data?.SportId === 15
            ? item?.AFLTeamId == data?.homeTeamId
            : data?.SportId === 9
              ? item?.ARTeamId == data?.homeTeamId
              : data?.SportId === 16
                ? item?.GolfTeamId == data?.homeTeamId
                : data?.SportId === 7
                  ? item?.TennisTeamId == data?.homeTeamId
                  : data?.SportId === 11
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : data?.SportId === 17
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : data?.SportId === 6
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : data?.SportId === 5
                          ? item?.MMATeamId == data?.homeTeamId
                          : data?.SportId === 8
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.SportId === 10
          ? item?.NBATeamId == data?.awayTeamId
          : data?.SportId === 15
            ? item?.AFLTeamId == data?.awayTeamId
            : data?.SportId === 9
              ? item?.ARTeamId == data?.awayTeamId
              : data?.SportId === 16
                ? item?.GolfTeamId == data?.awayTeamId
                : data?.SportId === 7
                  ? item?.TennisTeamId == data?.awayTeamId
                  : data?.SportId === 11
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : data?.SportId === 17
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : data?.SportId === 6
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : data?.SportId === 5
                          ? item?.MMATeamId == data?.awayTeamId
                          : data?.SportId === 8
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);

    if (maxno !== -1) {
      if (maxno !== 0) {
        let bookkeeperid = teamData
          ?.map((obj) => {
            if (obj?.odd === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);
        return fetchClickableOdds(
          maxno,
          bookkeeperid?.[0],
          "header",
          teamData,
          data,
          "betslip",
          Isscore,
        );
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };
  const fetchCurrentBestsOddsIcon = (data, type, team, teamid) => {
    let allTeamOdds =
      data?.SportId === 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.SportId === 10
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : data?.SportId === 15
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : data?.SportId === 9
              ? data?.ARBetOffers?.[0]?.AROdds
              : data?.SportId === 16
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : data?.SportId === 7
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : data?.SportId === 11
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : data?.SportId === 17
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : data?.SportId === 6
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : data?.SportId === 5
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : data?.SportId === 8
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.SportId === 10
          ? item?.NBATeamId == data?.homeTeamId
          : data?.SportId === 15
            ? item?.AFLTeamId == data?.homeTeamId
            : data?.SportId === 9
              ? item?.ARTeamId == data?.homeTeamId
              : data?.SportId === 16
                ? item?.GolfTeamId == data?.homeTeamId
                : data?.SportId === 7
                  ? item?.TennisTeamId == data?.homeTeamId
                  : data?.SportId === 11
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : data?.SportId === 17
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : data?.SportId === 6
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : data?.SportId === 5
                          ? item?.MMATeamId == data?.homeTeamId
                          : data?.SportId === 8
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.SportId === 10
          ? item?.NBATeamId == data?.awayTeamId
          : data?.SportId === 15
            ? item?.AFLTeamId == data?.awayTeamId
            : data?.SportId === 9
              ? item?.ARTeamId == data?.awayTeamId
              : data?.SportId === 16
                ? item?.GolfTeamId == data?.awayTeamId
                : data?.SportId === 7
                  ? item?.TennisTeamId == data?.awayTeamId
                  : data?.SportId === 11
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : data?.SportId === 17
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : data?.SportId === 6
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : data?.SportId === 5
                          ? item?.MMATeamId == data?.awayTeamId
                          : data?.SportId === 8
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;

    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let providerid = teamData
          ?.map((obj) => {
            let oddsType = obj?.odd;
            if (oddsType === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);

        return oddsicon(providerid?.[0], "header", teamData, data);
      } else {
        return "";
        // <span className="odds"> - </span>
      }
    } else {
      return "";
      // <span className="odds"> - </span>
    }
  };

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`,
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const handleModal = () => {
    setSBOpen(true);
  };

  const Renderer = ({ days, hours, minutes, seconds }) => {
    return null;
  };

  const ifNoTipData = (data) => {
    if (data !== null && data === "ZERO_SCORE_ROUND") {
      return "Zero Score for Round";
    } else if (data !== null && data === "LOWEST_SCORE_ROUND") {
      return "Lowest Score for Round";
    } else if (data !== null && data === "AWAY_TEAM_PICKED") {
      return "All Away Teams auto picked";
    } else if (data !== null && data === "HOME_TEAM_PICKED") {
      return "All Home Teams auto picked";
    } else {
      return "-";
    }
  };

  const handleTooltipContentClick = (e) => {
    e.stopPropagation();
    setTooltipOpen(!tooltipOpen);
  };

  const handleOutsideClick = () => {
    if (tooltipOpen) {
      setTooltipOpen(false);
    }
  };

  const handleOpenTooltip = () => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/share-tips?round=${round}&compId=${compId}&referral_type=tippingComp&referral=${referral}&sportId=${sId}&tournamentId=${tId}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={(e) => handleTooltipContentClick(e)}
      >
        {/* <Box> */}
        {eventByIdData?.tipButton === "updateTip" && (
          <>
            {screenWidth < 560 ? (
              <Box className="share-icon-box">
                <Share className="share-icon" />
              </Box>
            ) : (
              <Button className="share-btn" endIcon={<Share />}>
                Share
              </Button>
            )}
          </>
        )}

        {/* </Box> */}
        {tooltipOpen && (
          <Box className="tooltip">
            <Box style={{ position: "relative" }}>
              <TooltipArrow className="tooltip-arrow" />
            </Box>
            <Box className="share-option">
              <Box className="social-option-box">
                <Box className="option">
                  <CopyToClipboard text={link} onCopy={handleCopy}>
                    <Box className="cursor-pointer">
                      <img src={Copy} alt="Copy" />
                      <Typography className="option-name">Copy Link</Typography>
                    </Box>
                  </CopyToClipboard>
                </Box>
                <Box className="option">
                  <WhatsappShareButton url={link}>
                    <img src={Wp} alt="Whatsapp" />
                  </WhatsappShareButton>
                  <Typography className="option-name">Whatsapp</Typography>
                </Box>
                <Box className="option">
                  <FacebookShareButton url={link}>
                    <img src={Facebook} alt="Facebook" />
                  </FacebookShareButton>
                  <Typography className="option-name">Facebook</Typography>
                </Box>
                <Box className="option cursor-pointer">
                  <img
                    src={Messenger}
                    alt="Messenger"
                    className="social-icon"
                    onClick={() => shareOnMessenger(link)}
                  />
                  <Typography className="option-name">Messenger</Typography>
                </Box>
                <Box className="option">
                  <TwitterShareButton url={link}>
                    <img src={Twitter_X} alt="Twitter_X" />
                  </TwitterShareButton>
                  <Typography className="option-name">Twitter</Typography>
                </Box>
                <Box className="option">
                  <LinkedinShareButton url={link}>
                    <img src={Linkedin} alt="Linkedin" />
                  </LinkedinShareButton>
                  <Typography className="option-name">Linkedin</Typography>
                </Box>
                {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleJoin = async (e, item, id) => {
    if (handleRestrictedUser()) {
      setSelectedItem(item);
      handleJoinModal();
      setSelectedId(id);
    } else {
      // /tipscompetition/share-tips
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        window.location.pathname +
          `?round=${round}&compId=${compId}&referral_type=tippingComp&referral=${referral}&sportId=${sId}&tournamentId=${tId}`,
      );
    }
  };

  const handleJoinModal = () => {
    setJoinOpen(true);
  };

  const handleJoinClose = () => {
    setJoinOpen(false);
    setSelectedItem("");
    setSelectedId("");
  };

  const handleAddComps = async (e, item) => {
    e.stopPropagation();
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const tournamentSlug = item?.Tournament?.name
      ? item?.Tournament?.name.trim().toLowerCase().replaceAll(" ", "-")
      : "tournament";
    const sportSlug = item?.Sport?.sportName
      ? item?.Sport?.sportName.trim().toLowerCase().replaceAll(" ", "-")
      : "sports";
    if (isLogin) {
      try {
        const { status, data } = await axiosInstance.put(
          `/tipping/addLadder/${item?.code}`,
        );
        if (status === 200) {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
          setTimeout(() => {
            navigate(
              `/tipscompetition/my-comps/${sportSlug}/${tournamentSlug}/${item?.SportId}/${item?.tournamentId}/${item?.id}?round=${round}?userId=${item?.UserId}`,
            );
          }, 2000);
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        window.location.pathname + window.location.search,
      );
    }
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerIndia : banner,
          )}
        </Box>

        <Box className="public-comp-tips-wrap">
          {/* <Box className="comp-detail-header">
            <Box className="comp-image">
              <img
                src={
                  eventByIdData?.Media?.filePath?.includes("uploads")
                    ? Config.mediaURL + eventByIdData?.Media?.filePath
                    : DefaultImg
                }
                alt="comp-image"
              />
            </Box>
            <Box className="comp-info-left">
              <Typography className="title">
                {eventByIdData?.competitionName}
              </Typography>
              <Typography className="title">Round {round}</Typography>
              <Typography className="about-comp">
                {eventByIdData?.about ? eventByIdData?.about : ""}
              </Typography>
            </Box>
            <Box className="comp-info-right">
              <Box className="info-left">
                <Typography className="info-text fw-600">Sport:</Typography>
                <Typography className="info-text fw-600">League:</Typography>
                <Typography className="info-text fw-600">
                  Comp Code#:
                </Typography>
                <Typography className="info-text fw-600">
                  Starting Round:
                </Typography>
                <Typography className="info-text fw-600">Privacy:</Typography>
                <Typography className="info-text fw-600">
                  Tip Deadline:
                </Typography>
                <Typography className="info-text fw-600">
                  If no tip is entered:
                </Typography>
                <Typography className="info-text fw-600">
                  Perfect round Bonus:
                </Typography>
                <Typography className="info-text fw-600">
                  Joker Round:
                </Typography>
                <Typography className="info-text fw-600">
                  Margin System:
                </Typography>
                <Typography className="info-text fw-600">
                  Number of Tipsters:
                </Typography>
              </Box>
              <Box className="info-right">
                <Typography className="info-text">
                  {eventByIdData?.Sport?.sportName
                    ? eventByIdData?.Sport?.sportName
                    : "-"}
                </Typography>
                <Typography className="info-text">
                  {eventByIdData?.tournament?.name
                    ? eventByIdData?.tournament?.name
                    : "-"}
                </Typography>
                <Typography className="info-text">
                  {eventByIdData?.code ? eventByIdData?.code : "-"}
                </Typography>
                <Typography className="info-text">
                  Round {eventByIdData?.startingRound}
                </Typography>
                <Typography className="info-text">
                  {eventByIdData?.privacy === "public"
                    ? "Public"
                    : eventByIdData?.privacy === "private"
                    ? "Private"
                    : "-"}
                </Typography>
                <Typography className="info-text">
                  {eventByIdData?.tipDeadline === "round"
                    ? "Round Based"
                    : eventByIdData?.tipDeadline === "game"
                    ? "Game Based"
                    : "-"}
                </Typography>
                <Typography className="info-text">
                  {ifNoTipData(eventByIdData?.noTip)}
                </Typography>
                <Typography className="info-text">
                  {eventByIdData?.perfectRound === 1
                    ? "Yes"
                    : eventByIdData?.perfectRound === 0
                    ? "No"
                    : "-"}
                </Typography>
                <Typography className="info-text">
                  {eventByIdData?.jokerRound === 1
                    ? "Yes"
                    : eventByIdData?.jokerRound === 0
                    ? "No"
                    : "-"}
                </Typography>

                <Typography className="info-text">
                  {eventByIdData?.marginRound === 1
                    ? "Yes"
                    : eventByIdData?.marginRound === 0
                    ? "No"
                    : "-"}
                </Typography>
                <Typography className="info-text">
                  {" "}
                  {eventByIdData?.numberOfTippers === 1
                    ? "Yes"
                    : eventByIdData?.numberOfTippers === 0
                    ? "No"
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Box> */}
          <Box className="tip-share-option">
            <Box>
              <Box className="share-header-box">
                <Typography
                  className="share-header"
                  style={{ marginRight: "5px" }}
                >
                  {sharedUser?.firstName ? (
                    <span className="share-header text-blue">
                      {sharedUser?.firstName}'s Tips
                    </span>
                  ) : (
                    <span className="share-header text-blue">Tips</span>
                  )}{" "}
                  for {eventByIdData?.competitionName}{" "}
                  {eventByIdData?.SportId == 4 ? "Day" : "Round"} - {round}
                </Typography>
              </Box>
              <Box className="share-header-box">
                <Typography className="time-header">
                  {" "}
                  Saved on {fetchDayName(eventByIdData?.createdAt)},{" "}
                  {moment
                    .utc(eventByIdData?.createdAt)
                    .local()
                    .format("DD MMMM YYYY")}
                  ,{" "}
                  {moment
                    .utc(eventByIdData?.createdAt)
                    .local()
                    .format("hh:mm A")}
                </Typography>
                {/* {eventByIdData && eventByIdData?.tipDeadline == "round" && (
                  <Typography className="round-info">
                    Round {round} closes at <span> {fetchDayandTime()}</span>
                  </Typography>
                )} */}
              </Box>
            </Box>
            <Box className="comp-share-btn-wrap">
              <ClickAwayListener onClickAway={() => handleOutsideClick()}>
                <Box className="share-tooltip-icon">{handleOpenTooltip()}</Box>
              </ClickAwayListener>
            </Box>
          </Box>
          <Box className="page-deatils-wrap padding-container">
            {isLoading ? (
              <div className="allsport-loader-center app-loader">
                <Loader />
              </div>
            ) : (
              <>
                {allEventData && allEventData?.length > 0 ? (
                  <>
                    {Object.entries(groupedEventData)?.map(
                      ([date, tips], parentIndex) => {
                        return (
                          <Box className="tips-sec" key={date}>
                            <Box className="title-date-sec">
                              <Typography className="title-date">
                                {date ? fetchDayName(date) : ""}{" "}
                                {date
                                  ? moment
                                      .utc(date)
                                      .local()
                                      .format("DD/MM/YYYY")
                                  : ""}{" "}
                                {/* <span>
                                  {" "}
                                  {date
                                    ? "|" +
                                      " " +
                                      moment
                                        .utc(date)
                                        .local()
                                        .format("hh:mm A")
                                    : ""}
                                </span> */}
                                {parentIndex == 0 &&
                                  // childIndex == 0 &&
                                  eventByIdData?.marginRound == 1 && (
                                    <span>
                                      {" "}
                                      {counts ? (
                                        <Box className="market-box">
                                          <span>
                                            {"|" +
                                              " " +
                                              `${
                                                eventByIdData?.SportId == 4
                                                  ? "Number of 6s"
                                                  : "Margin"
                                              }`}
                                          </span>
                                          <Box className="market">{counts}</Box>
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                  )}
                              </Typography>
                            </Box>
                            {tips?.map((tip, index) => {
                              const dateTocheck =
                                eventByIdData?.tipDeadline == "game"
                                  ? moment(tip?.startTime)
                                  : lastDate;

                              // setChildIndex(index);
                              return (
                                <>
                                  <Box className="comp-tip-data-sec">
                                    <Typography className="time-venue-txt">
                                      {tip?.startTime
                                        ? moment
                                            .utc(tip?.startTime)
                                            .local()
                                            .format("hh:mm A")
                                        : ""}{" "}
                                      <span>
                                        {tip?.venue ? "|" + tip?.venue : ""}
                                      </span>
                                    </Typography>
                                    <Box
                                      className={
                                        eventLength === 0
                                          ? `comp-odds-tips-sec`
                                          : tip?.homeScore ||
                                              (tip?.ScoreBoard &&
                                                Object.keys(tip?.ScoreBoard)
                                                  ?.length > 0 &&
                                                tip?.ScoreBoard?.Epr == 2)
                                            ? `comp-odds-tips-sec `
                                            : `comp-odds-tips-sec no-result`
                                      }
                                    >
                                      <Box className="left-sec flex-rr jc-start">
                                        <Box className="team-wrap ">
                                          <Box
                                            className={`${sId === "9" && compId === "57" ? "img-blur" : ""} team-img-wrap`}
                                          >
                                            <img
                                              className="team-img"
                                              src={
                                                tip?.homeTeam?.flag?.includes(
                                                  "uploads",
                                                )
                                                  ? Config.mediaURL +
                                                    tip?.homeTeam?.flag
                                                  : Melbourne
                                              }
                                              alt="team"
                                            />
                                          </Box>
                                          <Box className="comp-name-tip-wrap">
                                            <Typography className="team-name">
                                              {tip?.homeTeam?.name}
                                            </Typography>
                                            {/* <ClickAwayListener
                                              onClickAway={() =>
                                                handleOutsideClick(
                                                  tip,
                                                  tip?.homeTeamId,
                                                  index,
                                                  parentIndex
                                                )
                                              }
                                            >
                                              <Box className="blackbook-icon">
                                                {handleOpenTooltip(
                                                  tip,
                                                  tip?.homeTeamId,
                                                  index,
                                                  "hometeam",
                                                  parentIndex
                                                )}
                                              </Box>
                                            </ClickAwayListener> */}
                                          </Box>
                                        </Box>
                                        {/* {Config.release === "AU" &&
                                        !Boolean(tip?.winnerCode) ? (
                                          release[Config.release]?.oddsCheck ? (
                                            <Box className="odds-wrap">
                                              {fetchCurrentBestOdds(
                                                tip,
                                                null,
                                                "hometeam",
                                                tip?.homeTeamId,
                                                true
                                              )}
                                              {fetchCurrentBestsOddsIcon(
                                                tip,
                                                null,
                                                "hometeam",
                                                tip?.homeTeamId
                                              )}
                                            </Box>
                                          ) : (
                                            <></>
                                          )
                                        ) : (
                                          <>
                                            {Number(sId) != 4 ? (
                                              tip?.homeScore ? (
                                                <Box className="blue-score">
                                                  <Typography className="score">
                                                    {tip?.homeScore?.current ??
                                                      0}
                                                  </Typography>
                                                </Box>
                                              ) : (
                                                <></>
                                              )
                                            ) : tip?.ScoreBoard &&
                                              Object.keys(tip?.ScoreBoard)
                                                ?.length > 0 &&
                                              tip?.ScoreBoard?.Epr == 2 ? (
                                              <Box className="blue-score">
                                                {tip?.ScoreBoard?.Exd > "1" ? (
                                                  <Typography className="score">
                                                    {tip?.ScoreBoard?.Tr1C1 ??
                                                      "-"}
                                                    {tip?.ScoreBoard?.Tr1CW1 &&
                                                    tip?.ScoreBoard?.Tr1CW1 ===
                                                      10
                                                      ? ""
                                                      : (tip?.ScoreBoard
                                                          ?.Tr1CW1 ||
                                                          tip?.ScoreBoard
                                                            ?.Tr1CW1 === 0) &&
                                                        `/${
                                                          tip?.ScoreBoard
                                                            ?.Tr1CW1
                                                        }${
                                                          tip?.ScoreBoard
                                                            ?.Tr1CD1 &&
                                                          tip?.ScoreBoard
                                                            ?.Tr1CD1 === 1
                                                            ? "d"
                                                            : ""
                                                        }`}{" "}
                                                    {(tip?.ScoreBoard?.Tr1C2 ||
                                                      tip?.ScoreBoard?.Tr1C2 ===
                                                        0) &&
                                                      `& ${tip?.ScoreBoard?.Tr1C2}`}
                                                    {tip?.ScoreBoard?.Tr1CW2 &&
                                                    tip?.ScoreBoard?.Tr1CW2 ===
                                                      10
                                                      ? ""
                                                      : (tip?.ScoreBoard
                                                          ?.Tr1CW2 ||
                                                          tip?.ScoreBoard
                                                            ?.Tr1CW2 === 0) &&
                                                        `/${
                                                          tip?.ScoreBoard
                                                            ?.Tr1CW2
                                                        }${
                                                          tip?.ScoreBoard
                                                            ?.Tr1CD2 &&
                                                          tip?.ScoreBoard
                                                            ?.Tr1CD2 === 1
                                                            ? "d"
                                                            : ""
                                                        }`}{" "}
                                                  </Typography>
                                                ) : (
                                                  <Typography className="score">
                                                    {tip?.ScoreBoard?.Tr1C1 ??
                                                      "-"}
                                                    {tip?.ScoreBoard?.Tr1CW1 &&
                                                    tip?.ScoreBoard?.Tr1CW1 ===
                                                      10
                                                      ? ""
                                                      : (tip?.ScoreBoard
                                                          ?.Tr1CW1 ||
                                                          tip?.ScoreBoard
                                                            ?.Tr1CW1 === 0) &&
                                                        `/${tip?.ScoreBoard?.Tr1CW1}`}{" "}
                                                  </Typography>
                                                )}
                                              </Box>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )} */}
                                        <Box className="check-box-sec">
                                          <FormControl>
                                            <label>
                                              <Checkbox
                                                icon={
                                                  tip?.winnerCode == 3 ? (
                                                    <Uncheck />
                                                  ) : dateTocheck < moment() &&
                                                    tip?.homeTeam?.isTip !==
                                                      1 &&
                                                    tip?.awayTeam?.isTip !==
                                                      1 ? (
                                                    <Uncheck />
                                                  ) : (
                                                    <Uncheck />
                                                  )
                                                }
                                                checkedIcon={
                                                  tip?.homeTeam?.isTip == 1 ? (
                                                    <BlueCheck />
                                                  ) : (
                                                    <Uncheck />
                                                  )
                                                }
                                                disabled={true}
                                                // name="filter"
                                                value="homeTeamId"
                                                // onChange={event => {
                                                //   // setSelectedIds({
                                                //   //   ...selectedIds,
                                                //   //   [tip?.id]:
                                                //   //     selectedIds[tip?.id] ===
                                                //   //     tip?.homeTeamId
                                                //   //       ? null
                                                //   //       : tip?.homeTeamId
                                                //   // });
                                                //   handleCheckboxClick(
                                                //     event,
                                                //     tip,
                                                //     "hometeam"
                                                //   );
                                                // }}
                                                checked={
                                                  tip?.homeTeam?.isTip == 1
                                                }
                                              />
                                              {/* {filter?.icon} */}
                                            </label>
                                          </FormControl>
                                        </Box>
                                      </Box>
                                      <Typography className="vs-txt">
                                        VS
                                      </Typography>
                                      <Box className="left-sec jc-end">
                                        <Box className="team-wrap flex-rr">
                                          <Box
                                            className={`${sId === "9" && compId === "57" ? "img-blur" : ""} team-img-wrap`}
                                          >
                                            <img
                                              className="team-img"
                                              src={
                                                tip?.awayTeam?.flag?.includes(
                                                  "uploads",
                                                )
                                                  ? Config.mediaURL +
                                                    tip?.awayTeam?.flag
                                                  : Brisbane
                                              }
                                              alt="team"
                                            />
                                          </Box>
                                          <Box className="comp-name-tip-wrap">
                                            <Typography className="team-name">
                                              {tip?.awayTeam?.name}
                                            </Typography>
                                            {/* <ClickAwayListener
                                              onClickAway={() =>
                                                handleOutsideClick(
                                                  tip,
                                                  tip?.awayTeamId,
                                                  index,
                                                  parentIndex
                                                )
                                              }
                                            >
                                              <Box className="blackbook-icon">
                                                {handleOpenTooltip(
                                                  tip,
                                                  tip?.awayTeamId,
                                                  index,
                                                  "awayteam",
                                                  parentIndex
                                                )}
                                              </Box>
                                            </ClickAwayListener> */}
                                          </Box>
                                        </Box>
                                        {/* {Config.release === "AU" &&
                                        !Boolean(tip?.winnerCode) ? (
                                          release[Config.release]?.oddsCheck ? (
                                            <Box className="odds-wrap odd-rr">
                                              {fetchCurrentBestOdds(
                                                tip,
                                                null,
                                                "awayteam",
                                                tip?.awayTeamId,
                                                true
                                              )}
                                              {fetchCurrentBestsOddsIcon(
                                                tip,
                                                null,
                                                "awayteam",
                                                tip?.awayTeamId
                                              )}
                                            </Box>
                                          ) : (
                                            <></>
                                          )
                                        ) : (
                                          <>
                                            {Number(sId) != 4 ? (
                                              tip?.awayScore ? (
                                                <Box className="blue-score">
                                                  <Typography className="score">
                                                    {tip?.awayScore?.current ??
                                                      0}
                                                  </Typography>
                                                </Box>
                                              ) : (
                                                <></>
                                              )
                                            ) : tip?.ScoreBoard &&
                                              Object.keys(tip?.ScoreBoard)
                                                ?.length > 0 &&
                                              tip?.ScoreBoard?.Epr == 2 ? (
                                              <Box className="blue-score">
                                                {tip?.ScoreBoard?.Exd > "1" ? (
                                                  <Typography className="score">
                                                    {tip?.ScoreBoard?.Tr2C1 ??
                                                      "-"}
                                                    {tip?.ScoreBoard?.Tr2CW1 &&
                                                    tip?.ScoreBoard?.Tr2CW1 ===
                                                      10
                                                      ? ""
                                                      : (tip?.ScoreBoard
                                                          ?.Tr2CW1 ||
                                                          tip?.ScoreBoard
                                                            ?.Tr2CW1 === 0) &&
                                                        `/${
                                                          tip?.ScoreBoard
                                                            ?.Tr2CW1
                                                        }${
                                                          tip?.ScoreBoard
                                                            ?.Tr2CD1 &&
                                                          tip?.ScoreBoard
                                                            ?.Tr2CD1 === 1
                                                            ? "d"
                                                            : ""
                                                        }`}{" "}
                                                    {(tip?.ScoreBoard?.Tr2C2 ||
                                                      tip?.ScoreBoard?.Tr2C2 ===
                                                        0) &&
                                                      `& ${tip?.ScoreBoard?.Tr2C2}`}
                                                    {tip?.ScoreBoard?.Tr2CW2 &&
                                                    tip?.ScoreBoard?.Tr2CW2 ===
                                                      10
                                                      ? ""
                                                      : (tip?.ScoreBoard
                                                          ?.Tr2CW2 ||
                                                          tip?.ScoreBoard
                                                            ?.Tr2CW2 === 0) &&
                                                        `/${
                                                          tip?.ScoreBoard
                                                            ?.Tr2CW2
                                                        }${
                                                          tip?.ScoreBoard
                                                            ?.Tr2CD2 &&
                                                          tip?.ScoreBoard
                                                            ?.Tr2CD2 === 1
                                                            ? "d"
                                                            : ""
                                                        }`}{" "}
                                                  </Typography>
                                                ) : (
                                                  <Typography className="score">
                                                    {tip?.ScoreBoard?.Tr2C1 ??
                                                      "-"}
                                                    {tip?.ScoreBoard?.Tr2CW1 &&
                                                    tip?.ScoreBoard?.Tr2CW1 ===
                                                      10
                                                      ? ""
                                                      : (tip?.ScoreBoard
                                                          ?.Tr2CW1 ||
                                                          tip?.ScoreBoard
                                                            ?.Tr2CW1 === 0) &&
                                                        `/ ${tip?.ScoreBoard?.Tr2CW1}`}{" "}
                                                  </Typography>
                                                )}
                                              </Box>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )} */}
                                        <Box className="check-box-sec">
                                          <FormControl>
                                            <label>
                                              <Checkbox
                                                icon={
                                                  tip?.winnerCode == 3 ? (
                                                    <Uncheck />
                                                  ) : dateTocheck < moment() &&
                                                    tip?.awayTeam?.isTip !==
                                                      1 &&
                                                    tip?.homeTeam?.isTip !==
                                                      1 ? (
                                                    <Uncheck />
                                                  ) : (
                                                    <Uncheck />
                                                  )
                                                }
                                                checkedIcon={
                                                  tip?.awayTeam?.isTip == 1 ? (
                                                    <BlueCheck />
                                                  ) : (
                                                    <Uncheck />
                                                  )
                                                }
                                                disabled={true}
                                                value="awayTeamId"
                                                // onChange={event => {
                                                //   handleCheckboxClick(
                                                //     event,
                                                //     tip,
                                                //     "awayteam"
                                                //   );
                                                // }}
                                                checked={
                                                  tip?.awayTeam?.isTip == 1
                                                }
                                              />
                                              {/* {filter?.icon} */}
                                            </label>
                                          </FormControl>
                                        </Box>
                                      </Box>
                                    </Box>
                                    {/* {parentIndex == 0 &&
                                      index == 0 &&
                                      eventByIdData?.marginRound == 1 && (
                                        <>
                                          <Box className="margin-sec">
                                            <Typography className="margin-txt">
                                              {sId == 4
                                                ? "Number of 6s"
                                                : "Margin"}
                                            </Typography>
                                          </Box>
                                          <Box className="counter-score-sec">
                                            <Box className="counter-input">
                                              <Button
                                                disableFocusRipple
                                                disableRipple
                                                className="counter-input__button"
                                                onClick={e => {
                                                  e.preventDefault();
                                                  handleDecrement(
                                                    tip?.id,
                                                    index
                                                  );
                                                }}
                                                disabled
                                              >
                                                <MyMinusNutton />
                                              </Button>
                                              <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="margin-textfield"
                                                name="compName"
                                                onChange={e => {
                                                  const input =
                                                    e?.target?.value;
                                                  const numericInput = input?.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  );
                                                  const removeLeadingZeros = numericInput => {
                                                    if (
                                                      numericInput.length >= 2
                                                    ) {
                                                      const result = parseInt(
                                                        numericInput,
                                                        10
                                                      ).toString();
                                                      return result;
                                                    }
                                                    return numericInput;
                                                  };
                                                  const finalMargin = removeLeadingZeros(
                                                    numericInput
                                                  );

                                                  setCounts(
                                                    finalMargin
                                                      ? Number(finalMargin)
                                                      : 0
                                                  );
                                                }}
                                                onFocus={e => e.target.select()}
                                                value={counts}
                                                disabled
                                              />
                                              <Button
                                                disableFocusRipple
                                                disableRipple
                                                className="counter-input__button plus-btn"
                                                onClick={e => {
                                                  e.preventDefault();
                                                  handleIncrement(
                                                    tip?.id,
                                                    index
                                                  );
                                                }}
                                                disabled
                                              >
                                                <MyPLusNutton />
                                              </Button>
                                            </Box>
                                          </Box>
                                        </>
                                      )} */}
                                  </Box>
                                  {eventByIdData?.tipDeadline == "game" && (
                                    <Countdown
                                      date={moment.utc(tip?.startTime).toDate()}
                                      renderer={Renderer}
                                      onComplete={() =>
                                        setTimeout(
                                          () => getEventByID(stepperCount),
                                          1000,
                                        )
                                      }
                                      key={moment.utc(tip?.startTime).format()}
                                    />
                                  )}
                                </>
                              );
                            })}
                          </Box>
                        );
                      },
                    )}
                    {eventByIdData?.tipButton === "enterTip" && (
                      <Box className="update-tips-btn-sec">
                        <Button
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          fullWidth
                          className="submit-tips-btn"
                          onClick={() => {
                            const tournamentSlug = eventByIdData?.tournament
                              ?.name
                              ? eventByIdData?.tournament?.name
                                  .trim()
                                  .toLowerCase()
                                  .replaceAll(" ", "-")
                              : "tournament";
                            const sportSlug = eventByIdData?.Sport?.sportName
                              ? eventByIdData?.Sport?.sportName
                                  .trim()
                                  .toLowerCase()
                                  .replaceAll(" ", "-")
                              : "sports";
                            navigate(
                              `/tipscompetition/my-comps/${sportSlug}/${tournamentSlug}/${Number(
                                eventByIdData?.SportId,
                              )}/${Number(
                                eventByIdData?.tournamentId,
                              )}/${Number(eventByIdData?.id)}?round=${round}?userId=${eventByIdData?.userId}`,
                            );
                          }}
                        >
                          Enter your tips
                        </Button>
                      </Box>
                    )}
                    {eventByIdData?.tipButton === "updateTip" && (
                      <Box className="update-tips-btn-sec">
                        <Button
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          fullWidth
                          className="submit-tips-btn"
                          onClick={() => {
                            const tournamentSlug = eventByIdData?.tournament
                              ?.name
                              ? eventByIdData?.tournament?.name
                                  .trim()
                                  .toLowerCase()
                                  .replaceAll(" ", "-")
                              : "tournament";
                            const sportSlug = eventByIdData?.Sport?.sportName
                              ? eventByIdData?.Sport?.sportName
                                  .trim()
                                  .toLowerCase()
                                  .replaceAll(" ", "-")
                              : "sports";
                            navigate(
                              `/tipscompetition/my-comps/${sportSlug}/${tournamentSlug}/${Number(
                                eventByIdData?.SportId,
                              )}/${Number(
                                eventByIdData?.tournamentId,
                              )}/${Number(eventByIdData?.id)}?round=${round}?userId=${eventByIdData?.userId}`,
                            );
                          }}
                        >
                          Update tips
                        </Button>
                      </Box>
                    )}
                    {eventByIdData?.tipButton === "isJoin" && (
                      <Box className="update-tips-btn-sec">
                        <Button
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          fullWidth
                          className="submit-tips-btn"
                          onClick={(e) => {
                            handleJoin(e, eventByIdData, eventByIdData?.id);
                            // handleAddComps(e, eventByIdData);
                          }}
                        >
                          Join comp!
                        </Button>
                      </Box>
                    )}
                    {eventByIdData?.tipButton === "login" && (
                      <Box className="update-tips-btn-sec">
                        <Button
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          fullWidth
                          className="submit-tips-btn"
                          onClick={(e) => {
                            dispatch(fetchRestrictedRoute(true));
                            localStorage.setItem(
                              "prev_authroute",
                              window.location.pathname + window.location.search,
                            );
                          }}
                        >
                          Join comp!
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <NoDataComp />
                  </Box>
                )}
              </>
            )}
          </Box>

          <OurPartner />
        </Box>
        <DialogBox
          open={joinOpen}
          onClose={handleJoinClose}
          handleClose={handleJoinClose}
          title={false}
          content={
            <>
              <Box className="delete-dialogbox">
                <Typography className="delete-title">
                  Are you sure you want to Join?
                </Typography>
                <Box className="delete-btn">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="btn-cancel blackbook-delete"
                    onClick={(e) => {
                      handleAddComps(e, selectedItem);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="btn-save"
                    onClick={() => handleJoinClose()}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </>
          }
          closeIcon={false}
        />
      </Box>
    </>
  );
};

export default IndividualCompetitionPublicPage;
