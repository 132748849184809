import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import DefaultImg from "../../../../assets/images/smartb_default.png";
import Loader from "src/components/Loader";
import moment from "moment-timezone";
import NoDataComp from "src/views/component/UI/NoData";
import FeaturedNews from "src/views/component/Home/featuredNews";
import he from "he";
import "./racesNews.scss";
import NewsComp from "../../NewsComponent/NewsComp";

const RacesNews = ({ teamSportType, newsType, searchParamsType }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentPage, setcurrentPage] = useState(1);
  const [categoryCount, setcategoryCount] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const rowPerPage = 10;

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });

    fetchNewsCategoryData(0);
  }, [searchParamsType]);

  const fetchNewsCategoryData = async (page) => {
    const SportId =
      teamSportType === "cricket"
        ? 4
        : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "rugbyunion"
            ? 13
            : teamSportType === "basketball"
              ? 10
              : teamSportType === "americanfootball"
                ? 15
                : teamSportType === "australianrules"
                  ? 9
                  : teamSportType === "baseball"
                    ? 11
                    : teamSportType === "boxing"
                      ? 6
                      : teamSportType === "icehockey"
                        ? 17
                        : teamSportType === "mma"
                          ? 5
                          : teamSportType === "soccer"
                            ? 8
                            : teamSportType === "tennis"
                              ? 7
                              : teamSportType === "golf"
                                ? 16
                                : teamSportType === "racing"
                                  ? searchParamsType
                                    ? searchParamsType
                                    : [1, 2, 3]
                                  : "";
    try {
      setIsCategoryLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/articles?SportId=${SportId}&limit=${rowPerPage}&offset=${page}`
      );
      if (status === 200) {
        setCategoryData(data?.result);
        setIsCategoryLoading(false);
        setcategoryCount(data?.result?.count);
        // setSearchValue("");
      } else {
        setIsCategoryLoading(false);
      }
    } catch (err) {
      setIsCategoryLoading(false);
    }
  };
  const fetchNewsTime = (newsdate) => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours == 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes == 1 ? "minute" : "minutes"
      } ago`;
    }
  };
  const scrollToTopSection = () => {
    const targetSection = document.getElementById("news-section");

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const handlePaginationClick = (event, page) => {
    setcurrentPage(Number(page));
    fetchNewsCategoryData((Number(page) - 1) * rowPerPage);
    scrollToTopSection();
  };

  const pageNumbers = [];

  if (categoryCount > 0) {
    for (let i = 1; i <= Math.ceil(categoryCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const fetchNewsSubtitle = (data) => {
    const parseHTMLContent = (body) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = (content) => {
      return he.decode(content);
    };
    const removeHtmlTags = (htmlString) => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = (content) => {
      return (
        <Typography className="sub-details">
          {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)}
          <span className="read-more">...read more</span>
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        (item) =>
          item?.type === "editor_block" && item?.data?.type === "paragraph"
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };

  return (
    <>
      <Box className="featurenews-wrap">
        <FeaturedNews
          newsType={newsType}
          tag=""
          teamSportType={teamSportType}
        />
      </Box>

      <Box className="news-section">
        <Box className="all-news category-news" id="news-section">
          {isCategoryLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : categoryData?.raw?.length > 0 ? (
            <>
              <Typography variant="h4">
                {/* {headerData
                  ? headerData?.tournament?.name
                  : teamSportType === "cricket"
                  ? "Cricket"
                  : teamSportType === "rugbyleague"
                  ? "Rugby League"
                  : teamSportType === "rugbyunion"
                  ? "Rugby Union"
                  : teamSportType === "basketball"
                  ? "Basketball "
                  : teamSportType === "americanfootball"
                  ? "American Football"
                  : teamSportType === "australianrules"
                  ? "Australian Rules "
                  : teamSportType === "golf"
                  ? "Golf"
                  : teamSportType === "tennis"
                  ? "Tennis"
                  : teamSportType === "baseball"
                  ? "Baseball"
                  : teamSportType === "icehockey"
                  ? "Ice Hockey"
                  : teamSportType === "boxing"
                  ? "Boxing"
                  : teamSportType === "mma"
                  ? "mma"
                  : teamSportType === "soccer"
                  ? "soccer"
                  : ""}{" "} */}
                RACING NEWS
              </Typography>
              {/* <Grid container className="individual-news-cont" >
                  {categoryData?.raw?.map((item) => (
                    <Grid
                      item
                      xs={12}
                      className="individual-single-news"
                      key={item?.id}

                    >
                      <NavLink to={`/smartinfo/news/${item?.NewsCategoryId}/${item?.id}`}>
                        <Box className="news-box">
                          <Box className="news-tag-relative">
                            <Box className="news-img">
                              <img
                                src={
                                  item?.mainMedia?.gallery
                                    ? item?.mainMedia?.gallery?.url &&
                                      item?.mainMedia?.gallery?.url !== ""
                                      ? item?.mainMedia?.gallery?.url
                                      : DefaultImg
                                    : item?.mainMedia?.[0]?.gallery
                                      ? item?.mainMedia?.[0]?.gallery?.url &&
                                        item?.mainMedia?.[0]?.gallery?.url !== ""
                                        ? item?.mainMedia?.[0]?.gallery?.url
                                        : DefaultImg
                                      : DefaultImg
                                }
                                alt="placeholder"
                              />
                            </Box>
                            <span className="news-tag">
                              {item?.NewsCategory?.initialTitle}
                            </span>
                          </Box>
                          <Box className="individual-news-details">
                            <Typography
                              className="details"
                              dangerouslySetInnerHTML={{
                                __html: item?.title
                                  ? item?.title
                                  : item?.subTitle,
                              }}
                            ></Typography>

                            <Box className="sub-details-wrap">
                              {fetchNewsSubtitle(item)}
                            </Box>

                            <Box className="tag-time-details">
                              <Typography className="stories-time">
                                <span className="watch">
                                  <Watch />
                                </span>
                                {fetchNewsTime(item?.rapidCreatedAt)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </NavLink>
                    </Grid>
                  ))}
                </Grid> */}
              <NewsComp newsData={categoryData?.raw} />
              <Box className="news-Pagination">
                <Pagination
                  disabled={categoryCount / rowPerPage > 1 ? false : true}
                  page={currentPage}
                  onChange={(e, value) => handlePaginationClick(e, value)}
                  count={pageNumbers[pageNumbers?.length - 1]}
                  siblingCount={2}
                  boundaryCount={1}
                  size="small"
                />
              </Box>
            </>
          ) : (
            <>
              <Box style={{ textAlign: "center" }}>
                <NoDataComp />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RacesNews;
