import { useEffect } from "react";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  Collapse,
  FormControl,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as CricketIcon } from "../../assets/images/sportHeaderIcon/headerCricketIcon.svg";
import { ReactComponent as ARIcon } from "../../assets/images/sportHeaderIcon/headerARIcon.svg";
import { ReactComponent as RubyIcon } from "../../assets/images/sportHeaderIcon/headerRubyIcon.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import "./newSportHeader.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/components/Loader";
import { fetchFromStorage } from "src/helpers/context";
import {
  headerCricketMenuData,
  headerRugbyLeagueMenu,
  australianrulesMenu,
  SPORTS_TYPE,
  getSportsTitle,
} from "src/views/component/newTeamSportPage/teamCommonComponent/newSportsUtils";
import { useHomeLandingContext } from "src/helpers/context/HomeLanding/HomeLandingContext";

const NewSportHeader = () => {
  const {
    searchParams,
    setSearchParams,
    params,
    location,
    menuValue,
    urlTouranamentId,
    urlTouranamentName,
    sportsId,
    navigate,
    sportTouranamentMenu,
    setSportTouranamentMenu,
    asDefault,
    setAsDefault,
    sportTouranamentMenuList,
    setSportTouranamentMenuList,
    touranamentApiCount,
    setTouranamentApiCount,
    offset,
    setOffset,
    touranamentRowPerPage,
    selectedTouranamentId,
    setSelectedTouranamentId,
    screenWidth,
    setScreenWidth,
    mobileMenu,
    setMobileMenu,
    sportHeaderMenuData,
    setSportHeaderMenuData,
    setDefaultSport,
  } = useHomeLandingContext() || {};
  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const sportLabelName = sportsId === 4 ? "All Cricket" : "All Leagues";

  // Effect for setting the state data for sports header
  useEffect(() => {
    if (sportsId === SPORTS_TYPE.rugbyLeague) {
      setSportHeaderMenuData(headerRugbyLeagueMenu);
    }
    if (sportsId === SPORTS_TYPE.circket) {
      setSportHeaderMenuData(headerCricketMenuData);
    }

    if (sportsId === SPORTS_TYPE.australianrules) {
      setSportHeaderMenuData(australianrulesMenu);
    }
  }, [sportsId]);

  useEffect(() => {
    if (!menuValue) {
      setSearchParams({ menu: "home" });
      setSearchParams({
        menu: "home",
        touranamentId: 0,
        touranamentName: sportLabelName,
      });
    }
    fetchTouranament(0, sportsId);
  }, [menuValue, sportsId]);

  const handleSportTournamentMenu = () => {
    setSportTouranamentMenu(!sportTouranamentMenu);
  };

  const handleAsDefault = (event) => {
    setAsDefault(event.target.checked);
    if (event.target.checked) {
      const sportsData = [
        {
          sportId: sportsId,
          tournamentId: selectedTouranamentId,
        },
      ];

      const selectedTouranamentName = sportTouranamentMenuList?.find(
        (item) => item?.value === selectedTouranamentId,
      )?.label;

      setDefaultSport({ sportsData });
      setSearchParams({
        menu: searchParams.get("menu"),
        touranamentId: selectedTouranamentId,
        touranamentName: selectedTouranamentName,
      });
    }
  };

  // Touranament API call

  const fetchTouranament = async (page, sportId) => {
    // Use this url for pagination list
    // const paginatedUrl = `/public/tournament?SportId=${sportId}&limit=${touranamentRowPerPage}&offset=${page}`;
    try {
      const { status, data } = await axiosInstance.get(
        `/public/tournament?SportId=${sportId}`,
      );
      if (status === 200) {
        let count = data?.result?.count / 20;

        // Map the data into the desired format
        let newdata = data?.result?.rows?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        // Sort alphabetically by label
        newdata?.sort((a, b) => a?.label?.localeCompare(b?.label));
        const firstOptionLabel = sportLabelName;
        // Add "All Leagues" to the beginning of the array
        newdata?.unshift({
          label: firstOptionLabel,
          value: 0,
        });
        setSportTouranamentMenuList(newdata);
        setTouranamentApiCount(count);
      }
    } catch (err) {}
  };

  const handleOnScrollBottomTournament = async (offset, sportId) => {
    try {
      // Use this url for pagination list
      // const paginatedUrl = `/public/tournament?SportId=${sportId}&limit=${touranamentRowPerPage}&offset=${page}`;
      const { status, data } = await axiosInstance.get(
        `/public/tournament?SportId=${sportId}`,
      );
      if (status === 200) {
        setOffset(offset);
        let newdata = data?.result?.rows?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        // Sort alphabetically by label

        // Sort alphabetically by label
        newdata?.sort((a, b) => a?.label?.localeCompare(b?.label));
        let tournamentdata = [...sportTouranamentMenuList, ...newdata];

        setSportTouranamentMenuList(tournamentdata);
      } else {
      }
    } catch (err) {}
  };

  const mobileMenuOpen = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handelSportRedirection = (item) => {
    setSearchParams({
      menu: item?.urlValue,
      touranamentId: urlTouranamentId ? urlTouranamentId : 0,
      touranamentName: urlTouranamentName ? urlTouranamentName : sportLabelName,
    });

    // if (sportsId === SPORTS_TYPE.circket) {
    //   setSearchParams({
    //     menu: item?.urlValue,
    //     touranamentId: urlTouranamentId ? urlTouranamentId : 0,
    //     touranamentName: urlTouranamentName
    //       ? urlTouranamentName
    //       : "All Leagues ",
    //   });
    // } else if (sportsId === SPORTS_TYPE.rugbyLeague && item?.menu !== "Home") {
    //   navigate("/teamsports/rugbyleague/odds/0/false");
    // } else if (
    //   sportsId === SPORTS_TYPE.australianrules &&
    //   item?.menu !== "Home"
    // ) {
    //   navigate("/teamsports/australianrules/odds/0/false");
    // }
  };

  return (
    <>
      <Box
        className={
          handleRestrictedUser()
            ? "new-sport-Header-wrapper"
            : "without-sigup-header new-sport-Header-wrapper"
        }
      >
        <Box className="header-left-section">
          {screenWidth <= 824 ? (
            <ClickAwayListener onClickAway={() => setMobileMenu(false)}>
              <Box>
                <Box
                  className="mobile-down-arrow"
                  onClick={() => mobileMenuOpen()}
                >
                  <KeyboardArrowDownIcon />
                </Box>
                <Collapse
                  in={mobileMenu}
                  timeout="auto"
                  unmountOnExit
                  className="all-mobile-menu-collapse"
                >
                  <Box className="mobile-menu">
                    {sportHeaderMenuData?.map((item, index) => {
                      return (
                        <>
                          <Box
                            key={index}
                            className={
                              menuValue === item?.urlValue
                                ? "menu-item-wrap active"
                                : "menu-item-wrap"
                            }
                            onClick={() => {
                              setSearchParams({
                                menu: item?.urlValue,
                                touranamentId: urlTouranamentId
                                  ? urlTouranamentId
                                  : 0,
                                touranamentName: urlTouranamentName
                                  ? urlTouranamentName
                                  : sportLabelName,
                              });
                              setMobileMenu(false);
                            }}
                          >
                            <Typography className="menu-name">
                              {item?.menu}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Collapse>
              </Box>
            </ClickAwayListener>
          ) : (
            <></>
          )}
          <Box>
            {params?.sportsId === "4" ? (
              <CricketIcon />
            ) : params?.sportsId === "9" ? (
              <ARIcon />
            ) : params?.sportsId === "12" ? (
              <RubyIcon />
            ) : null}
          </Box>
          <Box className="sport-name-box">
            <Typography className="sport-name">
              {getSportsTitle(params?.sportsTypeName)}
            </Typography>
          </Box>
        </Box>
        <Box className="header-right-section">
          <ClickAwayListener onClickAway={() => setSportTouranamentMenu(false)}>
            <Box className="all-menu-collapse-wrap">
              <Box
                className="all-menu-wrap"
                onClick={() => handleSportTournamentMenu()}
              >
                <Typography className="all-menu-name">
                  <span className="all-touranament-name">
                    {urlTouranamentName}
                  </span>{" "}
                  <KeyboardArrowDownIcon />
                </Typography>
              </Box>
              <Collapse
                in={sportTouranamentMenu}
                timeout="auto"
                unmountOnExit
                className="all-menu-collapse"
              >
                <Box className="tournament-menu-wrap">
                  <Box className="set-default-wrap">
                    <FormControl>
                      <label>
                        <Checkbox
                          icon={<CheckboxUnChecked className="radio-icon" />}
                          checkedIcon={
                            <CheckBoxChecked className="radio-icon" />
                          }
                          name="filter"
                          value={asDefault}
                          onChange={(event) => {
                            handleAsDefault(event);
                            // setSportTouranamentMenu(false);
                          }}
                          checked={asDefault}
                          disableRipple={true}
                        />
                        Set as default
                      </label>
                    </FormControl>
                  </Box>
                  <InfiniteScroll
                    dataLength={sportTouranamentMenuList?.length}
                    // Disable because get all data withou pagination
                    // next={() =>
                    //   handleOnScrollBottomTournament(
                    //     offset + touranamentRowPerPage,
                    //     sportsId,
                    //   )
                    // }
                    // hasMore={
                    //   touranamentApiCount !== 0 &&
                    //   touranamentApiCount !==
                    //     Math.ceil(offset / touranamentRowPerPage + 1)
                    // }
                    // loader={
                    //   <div className="allsport-loader-center ">
                    //     <Loader />
                    //   </div>
                    // }
                    height={255}
                  >
                    <Box className="tournament-menu-list-wrap">
                      {sportTouranamentMenuList?.map((item, index) => {
                        return (
                          <>
                            <Box
                              key={index}
                              className={
                                "tournament-menu-list-item " +
                                (item?.value === Number(selectedTouranamentId)
                                  ? "active"
                                  : "")
                              }
                              onClick={() => {
                                setSearchParams({
                                  menu: searchParams.get("menu"),
                                  touranamentId: item?.value,
                                  touranamentName: encodeURIComponent(
                                    item?.label,
                                  ),
                                });
                                setSelectedTouranamentId(item?.value);
                                setAsDefault(false);
                                setSportTouranamentMenu(false);
                              }}
                            >
                              {item?.label}
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </InfiniteScroll>
                </Box>
              </Collapse>
            </Box>
          </ClickAwayListener>
          {screenWidth > 824 ? (
            sportHeaderMenuData?.map((item, index) => {
              return (
                <>
                  <Box
                    key={index}
                    className={
                      menuValue === item?.urlValue
                        ? "menu-item-wrap active"
                        : "menu-item-wrap"
                    }
                    onClick={() => handelSportRedirection(item)}
                  >
                    <Typography className="menu-name">{item?.menu}</Typography>
                  </Box>
                </>
              );
            })
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default NewSportHeader;
