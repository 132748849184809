import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IntlContext } from "src/App";
import {
  AppBar,
  Button,
  Box,
  IconButton,
  Typography,
  Collapse,
  SwipeableDrawer,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIconMUi from "@mui/icons-material/Close";

import Grid from "@mui/material/Grid2";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as FaBars } from "../../../../assets/images/oddsComparison/web-menu-side.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/images/oddsComparison/web-menu-close.svg";
import { ReactComponent as MobileMenu } from "../../../../assets/images/icons/menu.svg";
import { ReactComponent as ContactUsIconPopUp } from "../../../../assets/images/icons/contact-us-icon-big.svg";
import MobileMenuSmartOdds from "../../../../assets/images/newHeaderIcon/mobileMenuSmartodds.png";
import Close from "@mui/icons-material/Close";
import logo from "../../../../assets/images/logo.svg";
import SmartOddsLogo from "../../../../assets/images/newHeaderIcon/smartOdds2x.png";
import { useDispatch, useSelector } from "react-redux";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Config, fetchFromStorage } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import "./header.scss";
import LeftSideBar from "../../leftsidebar/leftsidebar";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchBetSlipToggle } from "src/helpers/store/Actions/BetSlipToggle";
import BetSlipCollapse from "src/layouts/MainLayout/BetslipCollapse";
import BlackbookPage from "../rightRaceBar/blackBookModal";
import ContactUs from "../rightRaceBar/contactUsModal";
import OddsStatusModal from "../rightRaceBar/oddsStatusModal";
import MobileMenuWhite from "src/components/Common/Icons/MobileMenuWhite";

const OddsComparisonHeader = () => {
  let dispatch = useDispatch();
  const location = useLocation();
  const BetSlipToggleData = useSelector(
    (state) => state?.reduxData?.BetSlipToggle,
  );
  const BetSlipCount = useSelector((state) => state?.reduxData?.BetSlipCount);
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  let token = localStorage.getItem("auth_token");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [betCount, setBetCount] = useState(0);
  const [MenuOpen, setMenuOpen] = useState(false);
  const [showMenu, setshowMenu] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);
  const [oddsStatusModal, setOddsStatusModal] = useState(false);
  const [blackBookModal, setBlackbookModal] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (BetSlipCount) {
      setBetCount(BetSlipCount);
    } else if (BetSlipCount === 0) {
      setBetCount(BetSlipCount);
    }
  }, [BetSlipCount]);

  const handleToggleMenu = () => {
    setMenuOpen(!MenuOpen);
  };

  const toggleMenu = () => {
    setshowMenu(!showMenu);
  };

  useEffect(() => {
    setshowMenu(false);
  }, [location?.pathname, location?.search]);

  const handleCloseMenu = () => {
    setshowMenu(false);
  };

  const handleClickAway = () => {
    setMenuOpen(false);
  };

  const handleBetSlipToggle = () => {
    if (!token || token == "null") {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    } else {
      dispatch(
        fetchBetSlipToggle(BetSlipToggleData ? !BetSlipToggleData : true),
      );
      if (!BetSlipToggleData) {
        document.body.classList.add("no-scroll");
        if (screenWidth < 799) {
          window.scrollTo(0, 0);
        }
      } else {
        document.body.classList.remove("no-scroll");
      }
    }
  };

  const collapseStyles = {
    transformOrigin: "top right", // Set the transformation origin to top right
    transform: `scaleX(${BetSlipToggleData ? 1 : 0})`, // Scale the element in X-axis to 1 (normal size) when open, 0 when closed
    transition: "transform 0.3s ease-in-out", // Add a transition for a smooth animation
  };

  const handleBlackbookModal = () => {
    setBlackbookModal(true);
  };

  const handleBlackbookModalClose = () => {
    setBlackbookModal(false);
  };

  const handleContactUsModal = () => {
    setContactUsModal(true);
  };

  const handleContactUsModalClose = () => {
    setContactUsModal(false);
  };

  const handleOddsStatusModal = () => {
    setOddsStatusModal(true);
  };

  const handleOddsStatusModalClose = () => {
    setOddsStatusModal(false);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const mobileMenuOpen = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };
  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        className="main-menu-wrap"
        style={{ backgroundColor: "#FFF" }}
      >
        <div className="mobileMenu">
          <SwipeableDrawer
            anchor={"left"}
            open={showMenu}
            onClose={toggleMenu}
            onOpen={toggleMenu}
            className="mobileMenuDrawer"
            swipeAreaWidth={0}
          >
            <div className="mobileMenuDrawerClose">
              <Close onClick={toggleMenu} />
            </div>
            <LeftSideBar handleCloseMenu={handleCloseMenu} />
          </SwipeableDrawer>
        </div>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Grid item className="text-left logo-container">
            <Box className="header-left-menu-wrap">
              <Box>
                <ClickAwayListener onClickAway={() => handleClickAway()}>
                  {screenWidth > 799 ? (
                    <>
                      {/* {MenuOpen ? (
                      <CloseIcon
                        className="MenuTopBar-button"
                        onClick={() => handleToggleMenu()}
                      />
                    ) : (
                      <FaBars
                        className="MenuTopBar-button"
                        onClick={() => handleToggleMenu()}
                      />
                    )} */}
                    </>
                  ) : (
                    <MobileMenu
                      className="MenuTopBar-button"
                      onClick={() => toggleMenu()}
                    />
                  )}
                </ClickAwayListener>
              </Box>
              <Box>
                <NavLink to="/">
                  <img
                    src={logo}
                    style={{ maxWidth: "240.56px" }}
                    className="App-logo-main"
                    alt="logo"
                  />
                </NavLink>
              </Box>
            </Box>
          </Grid>
          {screenWidth > 799 && (
            <Grid item>
              <Box className="v3-header-menu-wrapper">
                <Box className="v3-header-menu-list">
                  <Box className="v3-header-menu-btn">
                    <img src={SmartOddsLogo} alt="logo" />
                  </Box>
                  {release[Config.release]?.smartOddsMenuItems?.map(
                    (item, index) => (
                      <Box
                        key={index}
                        className={`v3-header-menu-btn ${
                          location?.pathname === item?.url
                            ? "active"
                            : "inactive"
                        }`}
                        onClick={() => {
                          if (item?.name === "My BlackBook") {
                            handleBlackbookModal();
                          } else if (item?.name === "Contact Us") {
                            handleContactUsModal();
                          } else if (item?.name === "Odds Status") {
                            handleOddsStatusModal();
                          } else {
                            navigate(item.url);
                          }
                        }}
                      >
                        {item?.name}
                      </Box>
                    ),
                  )}
                </Box>
              </Box>
            </Grid>
          )}

          <Grid item className="MuiGrid-spacing-xs-0 login-buttons">
            {!token || token == "null" ? (
              <Box className="signup-login-wrap">
                <Link to="/sign-up">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signup"
                  >
                    {localesData?.auth_btn?.signup}
                  </Button>
                </Link>
                <Link to="/sign-in">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signin"
                  >
                    {localesData?.auth_btn?.signin}
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box
                className="profile-logout-wrap"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                {reduxSubscriptionData?.Media?.filePath ? (
                  <Box className="profile-img-wrap">
                    <img
                      src={
                        Config?.countryMediaURL +
                        reduxSubscriptionData?.Media?.filePath
                      }
                      alt="profile"
                    />
                  </Box>
                ) : (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    className="profile-icon"
                    onClick={() => {
                      navigate("/profile");
                    }}
                    size="large"
                  >
                    {/* <AccountCircle /> */}
                    <PersonIcon />
                  </IconButton>
                )}
                <Typography className="user-name">
                  {reduxSubscriptionData?.firstName
                    ? reduxSubscriptionData?.firstName
                    : ""}
                </Typography>
              </Box>
            )}
            {release[Config.release]?.betSlip && (
              <Box
                className="bet-slip-button"
                onClick={() => handleBetSlipToggle()}
              >
                <Typography className="bet-slip">Bet Slip</Typography>
                <Box className="bet-slip-count">{betCount}</Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </AppBar>
      {screenWidth < 799 && (
        <Box
          className={
            handleRestrictedUser()
              ? "new-sport-Header-wrapper new-mobile-menu"
              : "without-sigup-header new-sport-Header-wrapper new-mobile-menu"
          }
        >
          <Box className="header-left-section">
            <ClickAwayListener onClickAway={() => setMobileMenu(false)}>
              <Box className="mobile-menu-new">
                <Box
                  className="mobile-menu-new-mobile"
                  onClick={() => mobileMenuOpen()}
                >
                  <MobileMenuWhite />
                </Box>
                <Box>
                  <img src={MobileMenuSmartOdds} alt="logo" />
                </Box>
                <Collapse
                  in={mobileMenu}
                  timeout="auto"
                  unmountOnExit
                  className="all-mobile-menu-collapse"
                >
                  <Box className="mobile-menu">
                    {release[Config.release]?.smartOddsMenuItems?.map(
                      (item, index) => {
                        return (
                          <>
                            <Box
                              key={index}
                              className={
                                location?.pathname?.includes(item?.routeName)
                                  ? "menu-item-wrap active"
                                  : "menu-item-wrap"
                              }
                              onClick={() => {
                                setMobileMenu(false);
                                if (item?.name === "My BlackBook") {
                                  handleBlackbookModal();
                                } else if (item?.name === "Contact Us") {
                                  handleContactUsModal();
                                } else if (item?.name === "Odds Status") {
                                  handleOddsStatusModal();
                                } else {
                                  navigate(item.url);
                                }
                              }}
                            >
                              <Typography className="menu-name">
                                {item?.name}
                              </Typography>
                            </Box>
                          </>
                        );
                      },
                    )}
                  </Box>
                </Collapse>
              </Box>
            </ClickAwayListener>
          </Box>
        </Box>
      )}
      <Collapse in={MenuOpen} className="Menu-collapse">
        <Box className="left-sidebarcontent">
          <LeftSideBar />
        </Box>
      </Collapse>
      <Collapse
        in={BetSlipToggleData}
        className="betslip-collapse"
        style={screenWidth < 799 ? collapseStyles : ""}
      >
        <BetSlipCollapse />
      </Collapse>
      {/*My  Blackbook Listing Modal  */}
      <Dialog
        onClose={handleBlackbookModalClose}
        aria-labelledby="customized-dialog-title"
        open={blackBookModal}
        className="odds-Comparison-modal blackbook-listing-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            My Blackbook
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleBlackbookModalClose()}
            className="close-icon"
            size="large"
          >
            <CloseIconMUi />
          </IconButton>
        </DialogTitle>
        <DialogContent className="blackbook-listing-modal-details">
          <BlackbookPage />
        </DialogContent>
      </Dialog>

      {/* Contact Us */}
      <Dialog
        onClose={handleContactUsModalClose}
        aria-labelledby="customized-dialog-title"
        open={contactUsModal}
        className="odds-Comparison-modal contact-us-modal"
      >
        <DialogTitle className="modal-title contact-us-title">
          <Typography variant="h6" className="title">
            <ContactUsIconPopUp />
            Contact us
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleContactUsModalClose()}
            className="close-icon"
            size="large"
          >
            <CloseIconMUi />
          </IconButton>
        </DialogTitle>
        <DialogContent className="contact-us-details">
          <ContactUs handleContactUsModalClose={handleContactUsModalClose} />
        </DialogContent>
      </Dialog>

      {/* odds status Modal */}

      <Dialog
        onClose={handleOddsStatusModalClose}
        aria-labelledby="customized-dialog-title"
        open={oddsStatusModal}
        className="odds-Comparison-modal odds-status-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            Odds Status
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleOddsStatusModalClose()}
            className="close-icon"
            size="large"
          >
            <CloseIconMUi />
          </IconButton>
        </DialogTitle>
        <DialogContent className="odds-Comparison-modal-details odds-status-modal-details">
          <OddsStatusModal
            handleOddsStatusModalClose={handleOddsStatusModalClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OddsComparisonHeader;
