import React, { useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Container,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Checkbox,
  StepConnector,
  IconButton,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as RadioChecked } from "src/assets/images/icons/smartb-check.svg";
import { ReactComponent as RadioUnChecked } from "src/assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Indeterminent } from "src/assets/images/icons/indeterminent-icon.svg";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { ReactComponent as Football } from "src/assets/images/Sport/football.svg";
import { ReactComponent as Baseball } from "src/assets/images/Sport/baseballLight.svg";
import { ReactComponent as Basketball } from "src/assets/images/Sport/basketballLight.svg";
import { ReactComponent as Boxing } from "src/assets/images/Sport/boxingLight.svg";
import { ReactComponent as Cricket } from "src/assets/images/Sport/cricketLight.svg";
import { ReactComponent as Golf } from "src/assets/images/Sport/golfLight.svg";
import { ReactComponent as IceHockey } from "src/assets/images/Sport/iceHockey-new.svg";
import { ReactComponent as MMA } from "src/assets/images/Sport/mmaIcon-new.svg";
import { ReactComponent as Rugby } from "src/assets/images/Sport/rugby.svg";
import { ReactComponent as Soccer } from "src/assets/images/Sport/soccerIcon-new.svg";
import { ReactComponent as Tennis } from "src/assets/images/Sport/Tennis.svg";
import { ReactComponent as AR } from "src/assets/images/Sport/arIcon-new.svg";
import { ReactComponent as RU } from "src/assets/images/Sport/rugbyUnionIcon-new.svg";
import { ReactComponent as RaceHorses } from "src/assets/images/Sport/horseRacingIcon.svg";
import { ReactComponent as AuthClose } from "src/assets/images/signup-img/auth-close.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { NavLink, Link, useNavigate } from "react-router-dom";
import AuthTitleLogo from "src/assets/images/signup-img/auth-title-logo.webp";
import AuthTextLogo from "src/assets/images/signup-img/auth-text-logo.webp";
import finish_logo from "src/assets/images/finish-logo.png";
import leftimage from "src/assets/images/signup-img/auth-left-v2.png";
import leftimage1 from "src/assets/images/signup-img/left-image-1.webp";
import leftimage2 from "src/assets/images/signup-img/left-image-2.webp";
import leftimage3 from "src/assets/images/signup-img/left-image-3.webp";
import leftimage4 from "src/assets/images/signup-img/left-image-4.webp";
import leftimage5 from "src/assets/images/signup-img/left-image-5.webp";
import leftimage6 from "src/assets/images/signup-img/left-image-6.webp";
import DefaultImg from "src/assets/images/smartb_default.png";
// import "../auth.scss";
import "../authV2.scss";
import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { Helmet } from "react-helmet";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment/moment";
import _ from "lodash";
import { loginUser } from "src/helpers/store/Actions/auth";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "src/components/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScriptLoader from "../../scriptLoader/ScriptLoader";
import { Config, fetchFromStorage } from "src/helpers/context";
import { release } from "src/helpers/context/release";
import flag from "src/helpers/constants/flag";
import Register from "./Register";
import VerifyOtp from "./VerifyOtp";
import CompleteProfile from "./CompleteProfile";
import ProfileDetails from "./ProfileDetails";
import ProfileSports from "./ProfileSports";
import ProfileNotification from "./ProfileNotification";
import ProfileBookmaker from "./ProfileBookmaker";
import HomePageCustomisation from "./HomePageCustomisation";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";
import bottomads from "src/assets/images/signup-img/bottom_ads.png";
import mobileAuthV2 from "src/assets/images/signup-img/mobileAuthV2.png";

const titleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Ms", label: "Ms" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" },
];
const bookmakersList = [
  { id: 1, name: "Bet365" },
  { id: 2, name: "Betgold" },
  { id: 3, name: "PlayUp" },
  { id: 4, name: "Elitebet" },
  { id: 5, name: "UniBet" },
  { id: 6, name: "Gallopbet" },
  { id: 7, name: "TopSport" },
  { id: 8, name: "VICBet" },
  { id: 9, name: "BlueBet" },
  { id: 10, name: "Unchecked" },
  { id: 11, name: "BoomBet" },
  { id: 12, name: "TAB" },
  { id: 13, name: "Southern Cross Bet" },
  { id: 14, name: "SportsBet" },
  { id: 15, name: "Palmerbet" },
  { id: 16, name: "Betfair" },
  { id: 17, name: "Bbet" },
  { id: 18, name: "Winbet" },
  { id: 19, name: "Pointsbet Australia" },
  { id: 20, name: "Other - Please specify" },
];

const hearAboutList = [
  { id: 1, name: "Google" },
  { id: 2, name: "Radio" },
  { id: 3, name: "Social Media" },
  { id: 4, name: "Friend" },
  { id: 5, name: "Subversion" },
  { id: 6, name: "Other" },
];
const sportsList = [
  { id: 1, name: "Horses" },
  { id: 2, name: "Cycling" },
  { id: 3, name: "Netball" },
  { id: 4, name: "Greyhounds" },
  { id: 5, name: "Darts" },
  { id: 6, name: "Novelties and Entertainment" },
  { id: 7, name: "Harness" },
  { id: 8, name: "e - Sports" },
  { id: 9, name: "Politics" },
  { id: 10, name: "American Football" },
  { id: 11, name: "GAA Matches" },
  { id: 12, name: "Rugby League" },
  { id: 13, name: "Australian Rules" },
  { id: 14, name: "Golf" },
  { id: 15, name: "Rugby Union" },
  { id: 16, name: "Baseball" },
  { id: 17, name: "Handball" },
  { id: 18, name: "Snooker" },
  { id: 19, name: "Basketball - Aus/Other" },
  { id: 20, name: "Ice Hockey - Other" },
  { id: 21, name: "Soccer" },
  { id: 22, name: "Basketball - US" },
  { id: 23, name: "Ice Hockey - US" },
  { id: 24, name: "Sports Novelties" },
  { id: 25, name: "Boxing" },
  { id: 26, name: "Lacrosse" },
  { id: 27, name: "Table Tennis" },
  { id: 28, name: "Chess" },
  { id: 29, name: "Megabet" },
  { id: 30, name: "Tennis" },
  { id: 31, name: "Cricket" },
  { id: 32, name: "Motor Racing" },
  { id: 33, name: "UFC - MMA" },
  { id: 34, name: "Other - Please specify" },
];
const otherOffering = [
  { id: 1, name: "Race tips" },
  { id: 2, name: "Sports tips" },
  { id: 3, name: "Benefits and discounts" },
  { id: 4, name: "Customer forums" },
  { id: 5, name: "Racing Stats" },
  { id: 6, name: "Sport Stats" },
  { id: 7, name: "Form Guide" },
  { id: 8, name: "Other - Please specify" },
];
const notificationData = [
  {
    id: 1,
    name: "American Football",
    icon: <Football />,
    SportId: 15,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 2,
    name: "Australian Rules",
    icon: <AR />,
    SportId: 9,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 3,
    name: "Baseball",
    icon: <Baseball />,
    SportId: 11,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 4,
    name: "Basketball",
    icon: <Basketball />,
    SportId: 10,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 5,
    name: "Boxing",
    icon: <Boxing />,
    SportId: 6,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 6,
    name: "Cricket",
    icon: <Cricket />,
    SportId: 4,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 7,
    name: "Golf",
    icon: <Golf />,
    SportId: 16,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 8,
    name: "Ice Hockey",
    icon: <IceHockey />,
    SportId: 17,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 9,
    name: "Mixed Martial Arts",
    icon: <MMA />,
    SportId: 5,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 10,
    name: "Rugby League",
    icon: <Rugby />,
    SportId: 12,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 11,
    name: "Rugby Union",
    icon: <RU />,
    SportId: 13,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 12,
    name: "Soccer",
    icon: <Soccer />,
    SportId: 8,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 13,
    name: "Tennis",
    icon: <Tennis />,
    SportId: 7,
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
  {
    id: 14,
    name: "Racing",
    icon: <RaceHorses />,
    SportId: [1, 2, 3],
    notificationData: [
      { id: 1, subName: "Tips" },
      { id: 2, subName: "News" },
      { id: 3, subName: "Fixtures & Results" },
    ],
  },
];
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4455c7",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4455c7",
    },
  },
  line: {
    borderColor: "#D4D6D8",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);
export default function SignUp({ handleModal }) {
  let dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const { apiInfo, setApiInfo } = useAutoSaveStore();
  const [showPassword, setshowPassword] = useState(false);
  const [showRepeatPassword, setshowRepeatPassword] = useState(false);
  let [step, setStep] = useState(1);
  // const [title, setTitle] = useState("");
  const [value, setValue] = useState("no");
  const [checkboxValue, setcheckboxValue] = useState([]);
  const [hearAboutData, setHearAboutData] = useState([]);
  const [selectedSports, setselectedSports] = useState([]);
  const [selectedOffering, setselectedOffering] = useState([]);
  const [isFinish, setIsFinish] = useState(false);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [state, setState] = useState([]);
  const [formvalues, setformvalues] = useState({});
  const [emailmsg, setEmailmsg] = useState("");
  const [count, setcount] = useState(0);
  const [pageCountry, setpageCountry] = useState(0);
  const [statecount, setstatecount] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [isOtp, setOtp] = useState(false);
  const [isOTPVerify, setIsOTPVerify] = useState(false);
  const [isOtpError, setOtpError] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [searchCountryCount, setsearchCountryCount] = useState("");
  const [searchCountry, setsearchCountry] = useState([]);
  const [isCountrySearch, setisCountrySearch] = useState("");
  const [SearchCountrypage, setSearchCountrypage] = useState(0);
  const [searchStateCount, setsearchStateCount] = useState("");
  const [searchState, setsearchState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStatePage, setsearchStatePage] = useState(0);
  const [isCountrySelectOpen, setisCountrySelectOpen] = useState(false);
  const [countryApiCount, setCountryApiCount] = useState(0);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [stateApiCount, setStateApiCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [errorBookmakerOther, seterrorBookmakerOther] = useState("");
  const [errorSportOther, seterrorSportOther] = useState("");
  const [errorBookmakersOption, setErrorBookmakersOption] = useState("");
  const [errorOfferingOther, seterrorOfferingOther] = useState("");
  const [sports, setSports] = useState([]);
  const [priorityCountry, setPriorityCountry] = useState([]);
  const [registerError, setRegisterError] = useState("");
  const [isCompleteProfile, setIsCompleteProfile] = useState(false);
  const [countryPhone, setCountryPhone] = useState([]);
  const [countCountryPhone, setCountCountryPhone] = useState(0);
  const [pageCountryPhone, setpageCountryPhone] = useState(0);
  const [searchCountryPhoneCount, setsearchCountryPhoneCount] = useState("");
  const [searchCountryPhone, setsearchCountryPhone] = useState([]);
  const [isCountryPhoneSearch, setisCountryPhoneSearch] = useState("");
  const [SearchCountryPhonepage, setSearchCountryPhonepage] = useState(0);
  const [isCountryPhoneSelectOpen, setisCountryPhoneSelectOpen] =
    useState(false);
  const [countryPhoneApiCount, setCountryPhoneApiCount] = useState(0);

  const getSteps = () => {
    return ["step1", "step2", "step3", "step4", "step5"];
  };
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const handleClose = () => {
    let prev_auth_route = localStorage.getItem("prev_authroute");
    navigate(prev_auth_route ? prev_auth_route : "/");
    localStorage.removeItem("prev_authroute");
    dispatch(fetchRestrictedRoute(false));
  };
  const prevStep = () => {
    if (step > 1) {
      step--;
    }
    setStep(step);
  };
  const nextStep = () => {
    if (step < 5) {
      step++;
    }
    setStep(step);
  };
  // const submit = async () => {
  //   setisLoading(true);
  //   const updatedHearAbout = formvalues?.hearAbout?.map((item) => {
  //     if (item === "Other") {
  //       return `other:${formvalues?.hearAboutOther ? formvalues?.hearAboutOther : ""
  //         }`;
  //     }
  //     return item;
  //   });
  //   const notificationData =
  //     (await sports?.length) > 0 &&
  //     sports?.map((item) => {
  //       if (item?.id != 1) {
  //         return {
  //           SportId: item?.id,
  //           tips: item?.notificationData?.[0]?.isChecked
  //             ? item?.notificationData?.[0]?.isChecked
  //             : false,
  //           news: item?.notificationData?.[1]?.isChecked
  //             ? item?.notificationData?.[1]?.isChecked
  //             : false,
  //           fixtures: item?.notificationData?.[2]?.isChecked
  //             ? item?.notificationData?.[2]?.isChecked
  //             : false,
  //         };
  //       } else {
  //         return {
  //           SportId: item?.id,
  //           tips: item?.notificationData?.[0]?.isChecked
  //             ? item?.notificationData?.[0]?.isChecked
  //             : false,

  //           news: item?.notificationData?.[1]?.isChecked
  //             ? item?.notificationData?.[1]?.isChecked
  //             : false,
  //           dailyBestBet: item?.notificationData?.[2]?.isChecked
  //             ? item?.notificationData?.[2]?.isChecked
  //             : false,
  //           fixtures: item?.notificationData?.[3]?.isChecked
  //             ? item?.notificationData?.[3]?.isChecked
  //             : false,
  //           weeklyNewsLetter: item?.notificationData?.[4]?.isChecked
  //             ? item?.notificationData?.[4]?.isChecked
  //             : false,
  //           smartBNewsLetter: item?.notificationData?.[5]?.isChecked
  //             ? item?.notificationData?.[5]?.isChecked
  //             : false,
  //         };
  //       }
  //     });
  //   let sportId1 =
  //     (await notificationData?.length) > 0 &&
  //     notificationData?.find((item) => item.SportId === 1);
  //   if (sportId1) {
  //     var allSportsNotificationData = [
  //       ...notificationData,
  //       { ...sportId1, SportId: 2 },
  //       { ...sportId1, SportId: 3 },
  //     ];
  //   }
  //   let payload = {
  //     firstName: formvalues?.firstname,
  //     lastName: formvalues?.lastname,
  //     dob: moment(formvalues?.dob).format("YYYY-MM-DD"),
  //     bookMakerAccount: formvalues?.bookMakerAccount
  //       ? formvalues?.bookMakerAccount
  //       : "",
  //     phone: formvalues?.phonenumber,
  //     titlePrefix: formvalues?.title,
  //     address: {
  //       country: formvalues?.country,
  //       state: formvalues?.state == "" ? null : formvalues?.state,
  //     },
  //     username: formvalues?.email,
  //     password: formvalues?.password,
  //     bookMaker: formvalues?.bookMakers ? formvalues?.bookMakers : [],
  //     sportOrEvent: formvalues?.sportOrEvent ? formvalues?.sportOrEvent : [],
  //     NotificationPreference:
  //       release[Config.release]?.raceSportId?.length > 0
  //         ? allSportsNotificationData
  //         : notificationData,
  //     // offerings: formvalues?.offerings ? formvalues?.offerings : [],
  //     // offeringsOther: formvalues?.offerings?.includes("Other - Please specify")
  //     //   ? formvalues?.offeringsOther
  //     //     ? formvalues?.offeringsOther
  //     //     : []
  //     //   : [],
  //     sportOrEventOther: formvalues?.sportOrEvent?.includes(
  //       "Other - Please specify"
  //     )
  //       ? formvalues?.sportOrEventOther
  //         ? formvalues?.sportOrEventOther
  //         : []
  //       : [],
  //     bookMakersOther: formvalues?.bookMakers?.includes(
  //       "Other - Please specify"
  //     )
  //       ? formvalues?.bookMakersOther
  //         ? formvalues?.bookMakersOther
  //         : []
  //       : [],
  //     hearedAbout: updatedHearAbout,
  //     platFormType: "web",
  //     // wpUserName: formvalues?.wpUserName ? formvalues?.wpUserName : null,
  //     // referralLink: referral ? referral: null
  //   };
  //   const referralCode = localStorage.getItem("referralCode");
  //   const referralType = localStorage.getItem("referralType");
  //   try {
  //     const { status, data } = await axiosInstance.post(
  //       `user/register?type=${referralType ? referralType : ""}&referralCode=${referralCode ? referralCode : ""
  //       }`,
  //       payload
  //     );
  //     if (status === 200) {
  //       setOtp(true);
  //       toast.success(localesData?.register?.OTP_SENT, {
  //         position: "bottom-center",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         theme: "colored",
  //       });
  //       setisLoading(false);
  //     } else {
  //       toast.error("An Error Occurred", {
  //         position: "bottom-center",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         theme: "colored",
  //       });
  //     }
  //   } catch (err) {
  //     setisLoading(false);
  //     toast.error(err?.response?.data?.message, {
  //       position: "bottom-center",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "colored",
  //     });
  //   }
  // };
  const registerV2 = async () => {
    setisLoading(true);
    let payload = {
      firstName: formvalues?.firstname,
      lastName: formvalues?.lastname,
      dob: moment(formvalues?.dob).format("YYYY-MM-DD"),
      phone: formvalues?.phonenumber,
      phoneCountryId: formvalues?.countryPhone,
      username: formvalues?.email,
      password: formvalues?.password,
      platFormType: "web",
      address: {
        country: formvalues?.country,
        state: formvalues?.state == "" ? null : formvalues?.state,
      },
    };
    const referralCode = localStorage.getItem("referralCode");
    const referralType = localStorage.getItem("referralType");
    try {
      const { status, data } = await axiosInstance.post(
        `/user/v2/register?type=${referralType ? referralType : ""
        }&referralCode=${referralCode ? referralCode : ""}`,
        payload
      );
      if (status === 200) {
        setOtp(true);
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        setisLoading(false);
        setRegisterError("");
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      setisLoading(false);
      // toast.error(err?.response?.data?.message, {
      //     position: "bottom-center",
      //     autoClose: 3000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     theme: "colored",
      // });
      setRegisterError(err?.response?.data?.message);
    }
  };

  const fetchEditProfile = async (isFinished) => {
    const isSkipnotificationData =
      sports?.length > 0 &&
      sports?.map((item) => {
        if (item?.id != 1) {
          return {
            SportId: item?.id,
            tips: true,
            news: true,
            fixtures: true,
          };
        } else {
          return {
            SportId: item?.id,
            news: true,
            tips: true,
            dailyBestBet: true,
            fixtures: true,
            weeklyNewsLetter: true,
            smartBNewsLetter: true,
          };
        }
      });
    let isSkipSportId1 =
      isSkipnotificationData?.length > 0 &&
      isSkipnotificationData?.find((item) => item.SportId === 1);
    if (isSkipSportId1) {
      var isAllSportsNotificationData = [
        ...isSkipnotificationData,
        { ...isSkipSportId1, SportId: 2 },
        { ...isSkipSportId1, SportId: 3 },
      ];
    }

    const notificationData =
      (await sports?.length) > 0 &&
      sports?.map((item) => {
        if (item?.id != 1) {
          return {
            SportId: item?.id,
            tips: item?.notificationData?.[0]?.isChecked
              ? item?.notificationData?.[0]?.isChecked
              : false,
            news: item?.notificationData?.[1]?.isChecked
              ? item?.notificationData?.[1]?.isChecked
              : false,
            fixtures: item?.notificationData?.[2]?.isChecked
              ? item?.notificationData?.[2]?.isChecked
              : false,
          };
        } else {
          return {
            SportId: item?.id,
            tips: item?.notificationData?.[0]?.isChecked
              ? item?.notificationData?.[0]?.isChecked
              : false,
            news: item?.notificationData?.[1]?.isChecked
              ? item?.notificationData?.[1]?.isChecked
              : false,
            dailyBestBet: item?.notificationData?.[2]?.isChecked
              ? item?.notificationData?.[2]?.isChecked
              : false,
            fixtures: item?.notificationData?.[3]?.isChecked
              ? item?.notificationData?.[3]?.isChecked
              : false,
            weeklyNewsLetter: item?.notificationData?.[4]?.isChecked
              ? item?.notificationData?.[4]?.isChecked
              : false,
            smartBNewsLetter: item?.notificationData?.[5]?.isChecked
              ? item?.notificationData?.[5]?.isChecked
              : false,
          };
        }
      });
    let sportId1 =
      (await notificationData?.length) > 0 &&
      notificationData?.find((item) => item.SportId === 1);
    if (sportId1) {
      var allSportsNotificationData = [
        ...notificationData,
        { ...sportId1, SportId: 2 },
        { ...sportId1, SportId: 3 },
      ];
    }
    let payload;
    payload = {
      ...(formvalues?.mediaId && { MediaId: formvalues?.mediaId }),
      ...(formvalues?.nickName && { nickName: formvalues?.nickName }),
      ...(formvalues?.gender && { gender: formvalues?.gender }),
      // ...(formvalues?.country &&
      //   formvalues?.state && {
      //     address: {
      //       country: formvalues?.country,
      //       state: formvalues?.state,
      //     },
      //   }),
      ...(formvalues?.sportOrEvent?.length > 0 && {
        sportOrEvent: formvalues?.sportOrEvent,
      }),
      ...(formvalues?.sportOrEventOther?.length > 0 && {
        sportOrEventOther: selectedSports?.includes("Other")
          ? formvalues?.sportOrEventOther
          : [],
      }),
      ...(isFinished === "skip" &&
        (step === 1 || step === 2 || step === 3) && {
        NotificationPreference:
          release[Config.release]?.raceSportId?.length > 0
            ? isAllSportsNotificationData
            : isSkipnotificationData,
      }),
      ...(isFinished !== "skip" &&
        step >= 3 && {
        NotificationPreference:
          release[Config.release]?.raceSportId?.length > 0
            ? allSportsNotificationData
            : notificationData,
      }),
      ...(formvalues?.bookMakers?.length > 0 && {
        bookMaker: formvalues?.bookMakers,
      }),
      ...((formvalues?.isDefaultHomePage !== undefined ||
        formvalues?.isDefaultHomePage !== null) && {
        isDefaultHomePage: formvalues?.isDefaultHomePage,
      }),
      ...((formvalues?.isDefaultSportPage !== undefined ||
        formvalues?.isDefaultSportPage !== null) && {
        isDefaultSportPage: formvalues?.isDefaultSportPage,
      }),
      ...((formvalues?.isDefaultRacingPage !== undefined ||
        formvalues?.isDefaultRacingPage !== null) && {
        isDefaultRacingPage: formvalues?.isDefaultRacingPage,
      }),
    };
    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        if (isFinished == "finished") {
          setIsFinish(true);
        } else {
          if (Object?.keys(apiInfo)?.length > 0) {
            callAutoSaveApi(apiInfo);
          } else {
            let fantasyURL = JSON.parse(localStorage.getItem("redirect"));
            if (fantasyURL?.url) {
              // navigate(fantasyURL?.url);
              window.location.href = fantasyURL?.url;
              localStorage.removeItem("redirect");
            }
            navigate("/");
          }
        }
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        if (Object?.keys(apiInfo)?.length > 0) {
          callAutoSaveApi(apiInfo);
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      if (Object?.keys(apiInfo)?.length > 0) {
        callAutoSaveApi(apiInfo);
      } else {
        navigate("/");
      }
    }
  };
  //step1 function

  //step3 function
  const handleRadioChange = (e) => {
    setValue(e?.target?.value);
    e?.target?.value === "no" && setErrorBookmakersOption("");
    setformvalues({
      ...formvalues,
      bookMakerAccount: e?.target?.value === "yes" ? true : false,
    });
  };
  const handleOnChange = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...checkboxValue, value];
      setcheckboxValue(checkboxdata);
      setformvalues({ ...formvalues, bookMakers: checkboxdata });
    } else {
      let checkboxdata = checkboxValue.filter((element) => element !== value);
      setcheckboxValue(checkboxdata);
      setformvalues({ ...formvalues, bookMakers: checkboxdata });
    }
  };
  const handleOnChangeHearAbout = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...hearAboutData, value];
      setHearAboutData(checkboxdata);
      setformvalues({ ...formvalues, hearAbout: checkboxdata });
    } else {
      let checkboxdata = hearAboutData.filter((element) => element !== value);
      setHearAboutData(checkboxdata);
      setformvalues({ ...formvalues, hearAbout: checkboxdata });
    }
  };
  const handleSelectedSports = (sport) => {
    if (!Boolean(selectedSports?.includes(sport?.sportName))) {
      let checkboxdata = [...selectedSports, sport?.sportName];
      setselectedSports(checkboxdata);
      setformvalues({ ...formvalues, sportOrEvent: checkboxdata });
    } else {
      let checkboxdata = selectedSports?.filter(
        (element) => element !== sport?.sportName
      );
      setselectedSports(checkboxdata);
      setformvalues({ ...formvalues, sportOrEvent: checkboxdata });
    }
  };
  const handleOtherOffering = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...selectedOffering, value];
      setselectedOffering(checkboxdata);
      setformvalues({ ...formvalues, offerings: checkboxdata });
    } else {
      let checkboxdata = selectedOffering.filter(
        (element) => element !== value
      );
      setselectedOffering(checkboxdata);
      setformvalues({ ...formvalues, offerings: checkboxdata });
    }
  };
  const ValidateBookmakerOther = () => {
    let flag = true;
    if (
      checkboxValue?.includes("Other - Please specify") &&
      (formvalues?.bookMakersOther
        ? formvalues?.bookMakersOther?.[0] === ""
        : true)
    ) {
      flag = false;
      seterrorBookmakerOther("Please Add Bookmaker");
    } else if (checkboxValue && checkboxValue?.length === 0 && value == "yes") {
      flag = false;
      setErrorBookmakersOption("Please select atleast one bookmaker");
    } else {
      setErrorBookmakersOption("");
      seterrorBookmakerOther("");
    }
    return flag;
  };
  const ValidateSportOther = () => {
    let flag = true;
    if (
      selectedSports?.includes("Other - Please specify") &&
      (formvalues?.sportOrEventOther
        ? formvalues?.sportOrEventOther?.[0] === ""
        : true)
    ) {
      flag = false;
      seterrorSportOther("Please Add Sport or Event");
    } else {
      seterrorSportOther("");
    }
    return flag;
  };
  const ValidateOfferingOther = () => {
    let flag = true;
    if (
      selectedOffering?.includes("Other - Please specify") &&
      (formvalues?.offeringsOther
        ? formvalues?.offeringsOther?.[0] === ""
        : true)
    ) {
      flag = false;
      seterrorOfferingOther("Please Add Offering");
    } else {
      seterrorOfferingOther("");
    }
    return flag;
  };

  const formatOptionValue = ({ label, flag, phoneCode }) => (
    <div>
      <img
        src={flag}
        onError={(e) => (e.target.src = DefaultImg)}
        style={{ width: "24px", marginRight: "10px" }}
      />
      <span style={{ marginLeft: "10px", color: "#888" }}>+{phoneCode}</span>
    </div>
  );
  const fetchProrityCountry = async (page) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}&countryId=13,230,231,101`
      );
      if (status === 200) {
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
            phoneCode: item?.phoneCode,
            flag: item?.country_flag?.includes("uploads")
              ? Config?.mediaURL + item?.country_flag
              : item?.country_flag,
          });
        });
        setPriorityCountry(newdata);
        fetchCountryPhone(0, newdata);
        fetchCountry(0, newdata);
      }
    } catch (err) { }
  };
  const fetchCountryPhone = async (page, priorityCountryData) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setCountCountryPhone(Math.ceil(data?.result?.count / 20));
        let newdata = [];

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          (country) => !priorityCountryData?.includes(country?.value)
        );

        let track = nonPrioritizedCountries?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
            phoneCode: item?.phoneCode,
            flag: item?.country_flag?.includes("uploads")
              ? Config?.mediaURL + item?.country_flag
              : item?.country_flag,
          });
        });
        const orderedPriorityCountries = priorityCountryData
          ? _.concat(priorityCountryData, newdata)
          : [];
        const orderedCountries = newdata;
        setCountryPhone(
          _.unionBy(
            countryPhone,
            page == 0 ? orderedPriorityCountries : orderedCountries
          )
        );
        let filterData = _.unionBy(
          countryPhone,
          page == 0 ? orderedPriorityCountries : orderedCountries
        )?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        const finalArray = filterData?.unshift(...priorityCountryData);

        setCountryPhone(
          _.uniqBy(filterData, function (e) {
            return e.value;
          })
        );

        //Old Code

        // let track = data?.result?.rows?.map((item) => {
        //   newdata.push({
        //     label: item?.country,
        //     value: item?.id,
        //   });
        // });

        // setCountry(_.unionBy(country, newdata));
      }
    } catch (err) { }
  };
  const handleOnScrollBottomCountryPhone = () => {
    if (
      isCountryPhoneSearch !== "" &&
      searchCountryPhoneCount !== Math.ceil(SearchCountryPhonepage / 20)
    ) {
      handleCountryPhoneInputChange(
        SearchCountryPhonepage + 20,
        isCountryPhoneSearch
      );

      setSearchCountryPhonepage(SearchCountryPhonepage + 20);
    } else {
      if (countCountryPhone !== Math.ceil(pageCountryPhone / 20)) {
        fetchCountryPhone(pageCountryPhone + 20, priorityCountry);
        setpageCountryPhone(pageCountryPhone + 20);
      }
    }
  };
  const handleCountryPhoneInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryPhoneCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.country,
              value: item?.id,
              phoneCode: item?.phoneCode,
              flag: item?.country_flag?.includes("uploads")
                ? Config?.mediaURL + item?.country_flag
                : item?.country_flag,
            });
          });
          let filterData = _.unionBy(searchCountryPhone, newdata);
          setsearchCountryPhone(
            _.uniqBy(filterData, function (e) {
              return e.value;
            })
          );
          setisCountryPhoneSearch(value);
        }
      });
  };
  const fetchCountry = async (page, priorityCountryData) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          (country) => !priorityCountryData?.includes(country?.value)
        );

        let track = nonPrioritizedCountries?.map((item) => {
          newdata.push({
            label: item?.country,
            value: item?.id,
          });
        });
        const orderedPriorityCountries = priorityCountryData
          ? _.concat(priorityCountryData, newdata)
          : [];
        const orderedCountries = newdata;
        setCountry(
          _.unionBy(
            country,
            page == 0 ? orderedPriorityCountries : orderedCountries
          )
        );
        let filterData = _.unionBy(
          country,
          page == 0 ? orderedPriorityCountries : orderedCountries
        )?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        const finalArray = filterData?.unshift(...priorityCountryData);

        setCountry(
          _.uniqBy(filterData, function (e) {
            return e.value;
          })
        );
      }
    } catch (err) { }
  };
  const handleOnScrollBottomCountry = () => {
    if (
      isCountrySearch !== "" &&
      searchCountryCount !== Math.ceil(SearchCountrypage / 20)
    ) {
      handleCountryInputChange(SearchCountrypage + 20, isCountrySearch);

      setSearchCountrypage(SearchCountrypage + 20);
    } else {
      if (count !== Math.ceil(pageCountry / 20)) {
        fetchCountry(pageCountry + 20, priorityCountry);
        setpageCountry(pageCountry + 20);
      }
    }
  };
  const handleCountryInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.country,
              value: item?.id,
            });
          });
          let filterData = _.unionBy(searchCountry, newdata);
          setsearchCountry(
            _.uniqBy(filterData, function (e) {
              return e.value;
            })
          );
          setisCountrySearch(value);
        }
      });
  };
  const fetchState = async (id, page, type, statecount) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${id}?limit=20&offset=${page}`
      );
      if (status === 200) {
        setpageState(page);
        setStateApiCount(statecount + 1);
        setstatecount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.state,
            value: item?.id,
          });
        });
        // setState(_.unionBy(state, newdata))
        if (type) {
          // setState(_.unionBy(newdata));
          let filterData = _.unionBy(newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setState(
            _.uniqBy(sortData, function (e) {
              return e.value;
            })
          );
        } else {
          // setState(_.unionBy(state, newdata));
          let filterData = _.unionBy(state, newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setState(
            _.uniqBy(sortData, function (e) {
              return e.value;
            })
          );
        }
      }
    } catch (err) { }
  };
  const handleOnScrollBottomState = () => {
    // if (statecount !== Math.ceil(pageState / 20)) {
    //   fetchState(countryId, pageState + 20, false);
    //   setpageState(pageState + 20);
    // }
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(searchStatePage / 20)
    ) {
      handleStateInputChange(searchStatePage + 20, isStateSearch);

      setsearchStatePage(searchStatePage + 20);
    } else {
      if (statecount !== Math.ceil(pageState / 20)) {
        fetchState(countryId, pageState + 20, false, stateApiCount);
        setpageState(pageState + 20);
      }
    }
  };

  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${countryId}?limit=20&offset=${page}&search=${value}`
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchStateCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.state,
              value: item?.id,
            });
          });
          const finalStateData = newdata.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          let filterData = _.unionBy(searchState, finalStateData);
          setsearchState(
            _.uniqBy(filterData, function (e) {
              return e.value;
            })
          );
          setisStateSearch(value);
        }
      });
  };
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountrySelectOpen) {
          if (isCountrySearch === "" && countryApiCount !== count) {
            fetchCountry(pageCountry + 20, priorityCountry);
            setpageCountry(pageCountry + 20);
          }
        }
      }, 2000);
    }
  }, [isCountrySelectOpen, countryApiCount, isCountrySearch]);
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountryPhoneSelectOpen) {
          if (
            isCountryPhoneSearch === "" &&
            countryPhoneApiCount !== countCountryPhone
          ) {
            fetchCountryPhone(pageCountryPhone + 20, priorityCountry);
            setpageCountryPhone(pageCountryPhone + 20);
          }
        }
      }, 2000);
    }
  }, [isCountryPhoneSelectOpen, countryPhoneApiCount, isCountryPhoneSearch]);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && stateApiCount !== statecount) {
            fetchState(countryId, pageState + 20, false, stateApiCount);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);
  // const checkEmail = async (email) => {
  //   setisLoading(true);
  //   try {
  //     const { status, data } = await axiosInstance.get(
  //       `user/checkEmail/${email}`
  //     );
  //     if (status === 200) {
  //       setisLoading(false);
  //       if (data?.success === true) {
  //         if (step < 5) {
  //           step++;
  //         }
  //         setStep(step);
  //         setEmailmsg("");
  //       }
  //     }
  //   } catch (err) {
  //     setEmailmsg(err?.response?.data?.message);
  //     setisLoading(false);
  //   }
  // };
  const handleVerifyOtp = async (value) => {
    setisLoading(true);

    try {
      const { status, data } = await axiosInstance.get(
        // `user/verifyOtp/${formvalues?.email}/${value?.otp}`
        `user/verifyOtp/${formvalues?.email}/${value?.otp}?isRegister=1`
      );
      if (status === 200) {
        setisLoading(false);
        let { access_token } = data;
        setIsToken(access_token);
        // setIsFinish(true);
        setIsOTPVerify(true);
        // setIsCompleteProfile(true);
        setOtp(false);
        setOtpError("");
        handleFinishRegistration(access_token);
      }
    } catch (err) {
      console.log("err", err?.response);
      setOtpError(err?.response?.data?.message);
      setisLoading(false);
    }
  };
  const handleResendOtp = async () => {
    setisLoading(true);
    setOtpError("");
    try {
      const { status, data } = await axiosInstance.get(
        `user/resendOtp/${formvalues?.email}`
      );
      if (status === 200) {
        setisLoading(false);
        toast.success(localesData?.register?.OTP_SENT, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (err) {
      console.log("err", err?.response);
      setisLoading(false);
    }
  };
  const handleHearAboutOther = (e) => {
    setformvalues({ ...formvalues, hearAboutOther: e?.target?.value });
  };
  const handleBookmakerOther = (e) => {
    setformvalues({ ...formvalues, bookMakersOther: [e?.target?.value] });
  };

  const handleOfferingsOther = (e) => {
    setformvalues({ ...formvalues, offeringsOther: [e?.target?.value] });
  };

  const handleSportChange = (sportId) => {
    const updatedSports = sports?.map((sport) => {
      if (Array.isArray(sport?.id) && sport?.id.includes(sportId)) {
        const updatedSubscriptions = sport?.notificationData?.map((sub) => ({
          ...sub,
          isChecked: !sub?.isChecked,
        }));
        return {
          ...sport,
          notificationData: updatedSubscriptions,
        };
      } else if (sport?.id === sportId) {
        return {
          ...sport,
          isChecked: !sport?.isChecked,
          isIndeterminate: false,
          notificationData: sport?.notificationData?.map((sub) => ({
            ...sub,
            isChecked: !sport?.isChecked,
          })),
        };
      }
      return sport;
    });

    setSports(updatedSports);
  };
  const handleSubscriptionChange = (sportId, subId) => {
    const updatedSports = sports?.map((sport) => {
      if (sport?.id === sportId) {
        const updatedSubscriptions = sport?.notificationData?.map((sub) => {
          if (sub?.id === subId) {
            return {
              ...sub,
              isChecked: !sub?.isChecked,
            };
          }
          return sub;
        });

        const allChecked = updatedSubscriptions?.every((sub) => sub?.isChecked);
        const someChecked = updatedSubscriptions?.some((sub) => sub?.isChecked);
        const isChecked = allChecked || (!allChecked && someChecked);

        return {
          ...sport,
          notificationData: updatedSubscriptions,
          isChecked,
          isIndeterminate: !allChecked && someChecked,
        };
      }
      return sport;
    });
    setSports(updatedSports);
  };

  const handleFinishRegistration = (access_token) => {
    let Type = localStorage.getItem("type");
    let Url = localStorage.getItem("url");
    // let fantasyURL = JSON.parse(localStorage.getItem("redirect"));
    // if (fantasyURL?.url) {
    //   // navigate(fantasyURL?.url);
    //   window.location.href = fantasyURL?.url;
    //   localStorage.removeItem("redirect");
    // }
    if (Type && Url) {
      window.location.href = Url;
      localStorage.removeItem("type");
      localStorage.removeItem("url");
      localStorage.setItem("smartb_user", formvalues?.email);
      localStorage.setItem("auth_token", JSON.stringify(access_token));
      dispatch(loginUser(access_token));
      dispatch(fetchRestrictedRoute(false));
    } else {
      let prev_auth_route = localStorage.getItem("prev_authroute");
      localStorage.setItem("auth_token", JSON.stringify(access_token));
      dispatch(loginUser(access_token));
      setTimeout(() => {
        dispatch(fetchRestrictedRoute(false));
        // navigate(prev_auth_route ? prev_auth_route : "/");
        // setIsOTPVerify(true)
        // localStorage.removeItem("prev_authroute");
      }, 1000);
    }
  };
  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        const releaseFilterSportData = data?.result?.filter((item) =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        const SportsData = await releaseFilterSportData?.map((item) => {
          return {
            ...item,
            notificationData: [
              { id: 1, subName: "Tips" },
              { id: 2, subName: "News" },
              { id: 3, subName: "Fixtures & Results" },
            ],
          };
        });
        const sortedData = SportsData?.sort((a, b) => {
          return a?.sportName.localeCompare(b?.sportName);
        });
        const mergeData =
          release[Config.release]?.raceSportId?.length > 0
            ? [
              ...sortedData,
              {
                id: 1,
                sportName: "Racing",
                SportId: 1,
                isChecked: false,
                isIndeterminate: true,
                notificationData: [
                  { id: 1, subName: "Tips of the Day" },
                  { id: 2, subName: "News" },
                  { id: 4, subName: "Daily Best Bet" },
                  { id: 3, subName: "Fixtures & Results" },
                  { id: 5, subName: "Weekly Newsletter" },
                  { id: 6, subName: "SmartB Newsletter", isChecked: true },
                ],
              },
            ]
            : [...sortedData];

        setSports(sports?.length > 0 ? sports : mergeData);
      }
    } catch (err) { }
  };
  const fetchSportIcon = (sportId) => {
    if (sportId === 1) {
      return <RaceHorses />;
    } else if (sportId === 15) {
      return <Football />;
    } else if (sportId === 9) {
      return <AR />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MMA />;
    } else if (sportId === 12) {
      return <Rugby />;
    } else if (sportId === 13) {
      return <RU />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };

  const skipToUpdateNotification = async () => {
    const notificationData =
      sports?.length > 0 &&
      sports?.map((item) => {
        if (item?.id != 1) {
          return {
            SportId: item?.id,
            tips: true,
            news: true,
            fixtures: true,
          };
        } else {
          return {
            SportId: item?.id,
            news: true,
            tips: true,
            dailyBestBet: true,
            fixtures: true,
            weeklyNewsLetter: true,
            smartBNewsLetter: true,
          };
        }
      });
    let sportId1 =
      notificationData?.length > 0 &&
      notificationData?.find((item) => item.SportId === 1);
    if (sportId1) {
      var allSportsNotificationData = [
        ...notificationData,
        { ...sportId1, SportId: 2 },
        { ...sportId1, SportId: 3 },
      ];
    }
    let payload;
    payload = {
      NotificationPreference:
        release[Config.release]?.raceSportId?.length > 0
          ? allSportsNotificationData
          : notificationData,
    };
    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload
      );
      if (status === 200) {
        navigate("/profile");
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (error) { }
  };

  const callAutoSaveApi = async (apiInfo) => {
    let prev_auth_route = localStorage.getItem("prev_authroute");
    try {
      const { status, data } = await axiosInstance[apiInfo?.method](
        apiInfo?.api,
        apiInfo?.payload
      );
      if (status === 200) {
        let fantasyURL = JSON.parse(localStorage.getItem("redirect"));
        if (fantasyURL?.url) {
          // navigate(fantasyURL?.url);
          window.location.href = fantasyURL?.url;
          localStorage.removeItem("redirect");
        }
        setTimeout(() => {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        }, 1000);

        prev_auth_route
          ? prev_auth_route === "/odds-comparison/"
            ? navigate(prev_auth_route, { state: true })
            : navigate(prev_auth_route)
          : navigate("/");
        localStorage.removeItem("popup_intervalId");
        localStorage.removeItem("prev_authroute");
        setApiInfo({});
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        prev_auth_route
          ? prev_auth_route === "/odds-comparison/"
            ? navigate(prev_auth_route, { state: true })
            : navigate(prev_auth_route)
          : navigate("/");
        localStorage.removeItem("popup_intervalId");
        localStorage.removeItem("prev_authroute");
        setApiInfo({});
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      prev_auth_route
        ? prev_auth_route === "/odds-comparison/"
          ? navigate(prev_auth_route, { state: true })
          : navigate(prev_auth_route)
        : navigate("/");
      localStorage.removeItem("popup_intervalId");
      localStorage.removeItem("prev_authroute");
      setApiInfo({});
    }
  };

  useEffect(() => {
    fetchProrityCountry(0);
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    // fetchSportData()
  }, []);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const signupstep1 = () => {
    return (
      <ProfileDetails
        formvalues={formvalues}
        setformvalues={setformvalues}
        registerV2={registerV2}
        isLoading={isLoading}
        handleOnScrollBottomCountry={handleOnScrollBottomCountry}
        handleCountryInputChange={handleCountryInputChange}
        isCountrySearch={isCountrySearch}
        searchCountry={searchCountry}
        country={country}
        setCountryId={setCountryId}
        isCountrySelectOpen={isCountrySelectOpen}
        setisCountrySelectOpen={setisCountrySelectOpen}
        handleOnScrollBottomState={handleOnScrollBottomState}
        handleStateInputChange={handleStateInputChange}
        isStateSearch={isStateSearch}
        searchState={searchState}
        state={state}
        setisStateSelectOpen={setisStateSelectOpen}
        setStateApiCount={setStateApiCount}
        fetchState={fetchState}
        setState={setState}
        setpageState={setpageState}
        isStateSelectOpen={isStateSelectOpen}
        nextStep={nextStep}
        fetchEditProfile={fetchEditProfile}
        fetchSportData={fetchSportData}
      />
    );
  };
  const signupstepComplete = () => {
    return (
      <CompleteProfile
        nextStep={nextStep}
        fetchSportData={fetchSportData}
        fetchEditProfile={fetchEditProfile}
      />
    );
  };
  const homepageCustomization = () => {
    return (
      <HomePageCustomisation
        handleSelectedSports={handleSelectedSports}
        selectedSports={selectedSports}
        formvalues={formvalues}
        nextStep={nextStep}
        fetchEditProfile={fetchEditProfile}
        setformvalues={setformvalues}
        notificationFetchSportData={fetchSportData}
      />
    );
  };
  const signupstep2 = () => {
    return (
      <ProfileSports
        handleSelectedSports={handleSelectedSports}
        selectedSports={selectedSports}
        formvalues={formvalues}
        nextStep={nextStep}
        fetchEditProfile={fetchEditProfile}
        setformvalues={setformvalues}
        notificationFetchSportData={fetchSportData}
      />
    );
  };

  const signupstep3 = () => {
    return (
      <ProfileNotification
        formvalues={formvalues}
        setformvalues={setformvalues}
        fetchSportData={fetchSportData}
        sports={sports}
        handleSportChange={handleSportChange}
        handleSubscriptionChange={handleSubscriptionChange}
        nextStep={nextStep}
        fetchEditProfile={fetchEditProfile}
      />
    );
  };

  const signupstep4 = () => {
    return (
      <>
        <ProfileBookmaker
          handleRadioChange={handleRadioChange}
          handleOnChange={handleOnChange}
          checkboxValue={checkboxValue}
          formvalues={formvalues}
          value={value}
          fetchEditProfile={fetchEditProfile}
        />
      </>
    );
  };

  const signupstep5 = () => {
    return <></>;
  };

  const renderStep = () => {
    let content = null;
    switch (step) {
      case 1:
        content = signupstep1();
        break;
      case 2:
        content = homepageCustomization();
        break;
      case 3:
        content = signupstep2();
        break;
      case 4:
        content = signupstep3();
        break;
      case 5:
        content = signupstep4();
        break;
      default:
        content = <div>Error</div>;
        break;
    }
    return content;
  };

  const INRenderStep = () => {
    let content = null;
    switch (step) {
      case 1:
        content = signupstep1();
        break;
      case 2:
        content = homepageCustomization();
        break;
      case 3:
        content = signupstep2();
        break;
      case 4:
        content = signupstep3();
        break;
      default:
        content = <div>Error</div>;
        break;
    }
    return content;
  };

  const steps = release[Config.release]?.signStep;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
  };
  const scriptCode = `
                rdt('track', 'SignUp', {
                  "currency": "USD",
                "transactionId": "12345678",
                "value": 100,
                "products": [
                {
                  "id": "product id 1",
                "name": "product name 1",
                "category": "product category 1"
        },
                // additional products can be added here
                ]
});
                `;
  return (
    <>
      <Helmet>
        <title>
          SmartB | Sign UP Today To Get The Latest News and Odds Data
        </title>
        <meta
          name="description"
          content="Sign up Today. SmartB is the smartest Odds Comparison Platform on the Web. Learn more about Australian sports trends and news.
"
        />
      </Helmet>
      <ScriptLoader scriptCode={scriptCode} />
      {isCompleteProfile == true && (
        <Box
          className="auth-close-wrap"
          onClick={() => {
            handleClose();
            step == 5 ? fetchEditProfile("finished") : fetchEditProfile("skip");
          }}
        >
          <AuthClose />
        </Box>
      )}
      {!isCompleteProfile && (
        <Box
          className="auth-close-wrap"
          onClick={() => {
            handleClose();
          }}
        >
          <AuthClose />
        </Box>
      )}
      <Box className="auth-sec">
        <Box item md={3} lg={4} className="left-sec hide-mobilemenu">
          <img
            src={leftimage}
            alt="left-banner"
            className="image-sec"
            width="100%"
          />
        </Box>
        <Box item xs={12} sm={12} md={9} lg={8} className="right-sec">
          {screenWidth > 599 ? (
            <>
              <NavLink to="/" className="hide-mobilemenu">
                <img
                  src={AuthTitleLogo}
                  style={{ maxWidth: " 240.56px" }}
                  className="App-logo-main"
                  alt="logo"
                />
              </NavLink>
            </>
          ) : (
            <></>
          )}
          {!isOtp && !isOTPVerify && !isFinish ? (
            <>
              <Typography className="login-title">
                {!isCompleteProfile
                  ? localesData?.auth_btn?.signup
                  : localesData?.register?.COMPLETE_PROFILE}
              </Typography>
              {!isCompleteProfile && (
                <Box className="sign-in-account-sec">
                  <Typography className="account-text">
                    {localesData?.register?.already_account}
                  </Typography>
                  <Link
                    className="redirect-link"
                    // onClick={() => {handleModal("signin");}}
                    to="/sign-in"
                  >
                    {localesData?.auth_btn?.signin}
                  </Link>
                </Box>
              )}
              {!isCompleteProfile ? (
                <Register
                  formvalues={formvalues}
                  setformvalues={setformvalues}
                  registerV2={registerV2}
                  isLoading={isLoading}
                  handleOnScrollBottomCountryPhone={
                    handleOnScrollBottomCountryPhone
                  }
                  handleCountryPhoneInputChange={handleCountryPhoneInputChange}
                  isCountryPhoneSearch={isCountryPhoneSearch}
                  searchCountryPhone={searchCountryPhone}
                  countryPhone={countryPhone}
                  setisCountryPhoneSelectOpen={setisCountryPhoneSelectOpen}
                  registerError={registerError}
                  handleOnScrollBottomCountry={handleOnScrollBottomCountry}
                  handleCountryInputChange={handleCountryInputChange}
                  isCountrySearch={isCountrySearch}
                  searchCountry={searchCountry}
                  country={country}
                  setCountryId={setCountryId}
                  isCountrySelectOpen={isCountrySelectOpen}
                  setisCountrySelectOpen={setisCountrySelectOpen}
                  handleOnScrollBottomState={handleOnScrollBottomState}
                  handleStateInputChange={handleStateInputChange}
                  isStateSearch={isStateSearch}
                  searchState={searchState}
                  state={state}
                  setisStateSelectOpen={setisStateSelectOpen}
                  setStateApiCount={setStateApiCount}
                  fetchState={fetchState}
                  setState={setState}
                  setpageState={setpageState}
                  isStateSelectOpen={isStateSelectOpen}
                />
              ) : (
                <>
                  <Stepper
                    activeStep={step - 1}
                    alternativeLabel
                    className="stepper-label-wrap"
                    connector={<QontoConnector />}
                  >
                    {steps.map((label) => (
                      <Step key={label} className="stepper-label">
                        <StepLabel></StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  <Container className="stepper-main-wrap">
                    {Config.release == "AU" ? renderStep() : INRenderStep()}
                  </Container>
                </>
              )}
            </>
          ) : (
            <>
              {isOtp && !isFinish ? (
                <VerifyOtp
                  handleVerifyOtp={handleVerifyOtp}
                  isLoading={isLoading}
                  formvalues={formvalues}
                  isOtpError={isOtpError}
                  handleResendOtp={handleResendOtp}
                  setOtpError={setOtpError}
                />
              ) : isOTPVerify ? (
                <CompleteProfile
                  setIsCompleteProfile={setIsCompleteProfile}
                  setIsOTPVerify={setIsOTPVerify}
                  fetchSportData={fetchSportData}
                  skipToUpdateNotification={skipToUpdateNotification}
                  fetchEditProfile={fetchEditProfile}
                />
              ) : (
                <Box>
                  <Box className="finish-msg-wrap">
                    <img src={finish_logo} alt="finish-logo" />
                    <Typography className="finish-done">
                      {localesData?.register?.ALL_DONE}
                    </Typography>
                    <Typography className="finish-msg">
                      {localesData?.register?.FINISH_MSG}
                    </Typography>

                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      // type="submit"
                      className="submit-btn"
                      onClick={() => {
                        if (Object?.keys(apiInfo)?.length > 0) {
                          callAutoSaveApi(apiInfo);
                        } else {
                          let fantasyURL = JSON.parse(
                            localStorage.getItem("redirect")
                          );
                          if (fantasyURL?.url) {
                            // navigate(fantasyURL?.url);
                            window.location.href = fantasyURL?.url;
                            localStorage.removeItem("redirect");
                          }
                          navigate("/");
                        }
                      }}
                    >
                      {localesData?.register?.START_EXPERIENCE}
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Box sx={{ marginTop: "10px" }}>
        <img src={mobileAuthV2} alt="bottom-ads" className="mobile-auth-v2" />
      </Box>
    </>
  );
}
