import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useMemo, createContext, useContext, useState, useEffect } from "react";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { getTeamSportId } from "src/views/component/newTeamSportPage/teamCommonComponent/newSportsUtils";

const homeLandingContext = createContext();

const HomeLandingProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const menuValue = searchParamsMenu.get("menu"); // Extracts the value of "menu"
  const urlTouranamentId = Number(searchParamsMenu.get("touranamentId"));
  const urlTouranamentName = decodeURIComponent(
    searchParamsMenu.get("touranamentName"),
  );
  const sportsId = Number(params?.sportsId);
  const navigate = useNavigate();
  const [sportTouranamentMenu, setSportTouranamentMenu] = useState(false);
  const [asDefault, setAsDefault] = useState(false);
  const [sportTouranamentMenuList, setSportTouranamentMenuList] = useState([]);
  const [touranamentApiCount, setTouranamentApiCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const touranamentRowPerPage = 20;
  const [selectedTouranamentId, setSelectedTouranamentId] = useState(
    urlTouranamentId ? urlTouranamentId : 0,
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [sportHeaderMenuData, setSportHeaderMenuData] = useState([]);
  const [sportsData, setSportsData] = useState([]);

  const getUserSportsData = async () => {
    try {
      const { data } = await axiosInstance.get("/user/get-user");
      setSportsData(data?.sportsData);
    } catch (error) {
      console.log(error);
    }
  };

  const setDefaultSport = async ({ sportsData }) => {
    try {
      const { status } = await axiosInstance.put("/user", {
        sportsData,
      });

      if (status === 200) {
        getUserSportsData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   Getting Config for default selected sports

  useEffect(() => {
    getUserSportsData();
  }, [sportsId]);

  //   Setting Config for default selected sports
  useEffect(() => {
    const defalultSport = sportsData?.find(
      (item) => item?.sportId === getTeamSportId(params?.sportsTypeName),
    );

    if (defalultSport) {
      setSelectedTouranamentId(defalultSport?.tournamentId);
      const touranamentName = sportTouranamentMenuList?.find(
        (item) => item?.value === defalultSport?.tournamentId,
      )?.label;
      if (touranamentName) {
        setSearchParams({
          menu: searchParams.get("menu"),
          touranamentId: defalultSport?.tournamentId,
          touranamentName: touranamentName,
        });
      }
    }
  }, [sportsData, sportTouranamentMenuList]);

  const contextValue = useMemo(() => {
    return {
      searchParams,
      setSearchParams,
      params,
      location,
      menuValue,
      urlTouranamentId,
      urlTouranamentName,
      sportsId,
      navigate,
      sportTouranamentMenu,
      setSportTouranamentMenu,
      asDefault,
      setAsDefault,
      sportTouranamentMenuList,
      setSportTouranamentMenuList,
      touranamentApiCount,
      setTouranamentApiCount,
      offset,
      setOffset,
      touranamentRowPerPage,
      selectedTouranamentId,
      setSelectedTouranamentId,
      screenWidth,
      setScreenWidth,
      mobileMenu,
      setMobileMenu,
      sportHeaderMenuData,
      setSportHeaderMenuData,
      setDefaultSport,
      sportsData,
    };
  }, [
    searchParams,
    setSearchParams,
    params,
    location,
    menuValue,
    urlTouranamentId,
    urlTouranamentName,
    sportsId,
    navigate,
    sportTouranamentMenu,
    setSportTouranamentMenu,
    asDefault,
    setAsDefault,
    sportTouranamentMenuList,
    setSportTouranamentMenuList,
    touranamentApiCount,
    setTouranamentApiCount,
    offset,
    setOffset,
    selectedTouranamentId,
    setSelectedTouranamentId,
    screenWidth,
    setScreenWidth,
    mobileMenu,
    setMobileMenu,
    sportHeaderMenuData,
    setSportHeaderMenuData,
    setDefaultSport,
    sportsData,
  ]);

  return (
    <homeLandingContext.Provider value={contextValue}>
      {children}
    </homeLandingContext.Provider>
  );
};

export const useHomeLandingContext = () => useContext(homeLandingContext);

export default HomeLandingProvider;
