import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
// import Placeholder from "../../../../../assets/images/placeOrder.png";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import BelowSlider from "../../../../../assets/images/ad-placeholder/sports2.webp";
import bannerIndia from "../../../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import BelowTopStory from "../../../../../assets/images/ad-placeholder/sports1.webp";
import BelowFirstCategory from "../../../../../assets/images/ad-placeholder/sports3.webp";
// import NewaSlider1 from "../../../../../assets/images/newsSlider1.png";
// import NewaSlider2 from "../../../../../assets/images/newsSlider2.png";
import AboveOurPartner from "../../../../../assets/images/ad-placeholder/sports5.webp";
import BelowOurPartner from "../../../../../assets/images/ad-placeholder/homebelow-ourpartner.webp";
import OurPartner from "src/views/component/ourPartners";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import Loader from "src/components/Loader";
import moment from "moment-timezone";
import DefaultImg from "../../../../../assets/images/smartb_default.png";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfiniteScroll from "react-infinite-scroll-component";
import he from "he";
import { Config, fetchFromStorage } from "src/helpers/context";
import NewsArticleComp from "src/views/component/NewsComponent/NewsArticleComp";
import NewsComp from "src/views/component/NewsComponent/NewsComp";
import { ReactComponent as SeeAllRightArrow } from "../../../../../assets/images/icons/rightArrow.svg";
import { GettyEmbed, setApiMessage } from "src/helpers/commonFunctions";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import { ReactComponent as BookmarkIcon } from "src/assets/images/icons/bookmark-icon.svg";
import { ReactComponent as ChatIcon } from "src/assets/images/icons/chat-icon.svg";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AllNews = ({ categoryList }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const silderRef = useRef(null);
  const navigate = useNavigate();
  const { setApiInfo } = useAutoSaveStore();
  const URL = window?.location?.origin;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [AdData, setAdData] = useState([]);
  const [SliderData, setSliderData] = useState([]);
  const [isSliderLoading, setIsSliderLoading] = useState(false);
  const [categoryListData, setCategoryListData] = useState([]);
  const [TopStoryData, setTopStoryData] = useState([]);
  const [isTopStoryLoading, setIsTopStoryLoading] = useState(false);
  const [HomeArticleData, setHomeArticleData] = useState([]);
  const [isHomeArticleLoading, setIsHomeArticleLoading] = useState(false);
  const [visibleAds, setVisibleAds] = useState([]);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [newsCategoryIdArray, setNewsCategoryIdArray] = useState([]);
  const [CategoryOffset, setCategoryOffset] = useState(0);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
    // fetchAllSliderData();
  }, []);

  useEffect(() => {
    if (categoryList?.length > 0) {
      // fetchCategoryName(categoryList);
      setCategoryListData(categoryList);
      const newCategoryList = categoryList?.filter((item) => item?.value !== 0);
      setNewsCategoryIdArray(newCategoryList);
      fetchNewsCategoryData(0, newCategoryList);
    }
  }, [categoryList, params?.categoryId]);

  const fetchCategoryName = (id) => {
    let categoryName = categoryListData?.filter((item) => item?.value == id);
    return categoryName?.[0]?.label;
  };

  useEffect(() => {
    fetchAllSliderData();
    fetchTopStoryData();
    // fetchHomeArticleData();
    // if (newsCategoryIdArray?.length > 0) {
    // if (newsCategoryIdArray?.length > 0) {
    //   fetchNewsCategoryData(0);
    // }

    // }
  }, [params?.catagoryId]);

  const fetchAllSliderData = async () => {
    try {
      setIsSliderLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/feature/articles`,
      );
      if (status === 200) {
        setSliderData(data?.result);
        setIsSliderLoading(false);
      } else {
        setIsSliderLoading(false);
      }
    } catch (err) {
      setIsSliderLoading(false);
    }
  };

  const fetchTopStoryData = async () => {
    try {
      setIsTopStoryLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/topstory/articles?limit=6&offset=0`,
      );
      if (status === 200) {
        if (data?.result?.length > 0) {
          setTopStoryData(data?.result);
          setIsTopStoryLoading(false);
        }
      } else {
        setIsTopStoryLoading(false);
      }
    } catch (err) {
      setIsTopStoryLoading(false);
    }
  };

  // const fetchHomeArticleData = async () => {
  //   try {
  //     setIsHomeArticleLoading(true);
  //     const { status, data } = await axiosInstance.get(
  //       `v2/news/homepage/articles`
  //     );
  //     if (status === 200) {
  //       setHomeArticleData(data?.result);
  //       setIsHomeArticleLoading(false);
  //     } else {
  //       setIsHomeArticleLoading(false);
  //     }
  //   } catch (err) {
  //     setIsHomeArticleLoading(false);
  //   }
  // };

  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) {}
  // };

  const fetchAdsData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=13&type=web&timeZone=${timezone}`,
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };

  const fetchNewsTime = (newsdate) => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours == 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes == 1 ? "minute" : "minutes"
      } ago`;
    }
  };

  const settings = {
    infinite: SliderData?.length > 1,
    speed: 500,
    slidesToShow: SliderData?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    arrows: SliderData?.length > 1,
    prevArrow: (
      <Box className="arrow arrow-prev">
        <SeeAllRightArrow />
      </Box>
    ),
    nextArrow: (
      <Box className="arrow arrow-next">
        <SeeAllRightArrow />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 610,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const fetchNewsCategoryData = async (offset, newsArray) => {
    setIsCategoryLoading(true);

    try {
      const { status, data } = await axiosInstance.get(
        `v2/news/articles?NewsCategoryId=${
          newsArray?.[offset]?.value ?? 1
        }&limit=5&offset=${0}&timeZone=${timezone}&news=today`,
      );
      if (status === 200) {
        let newsCategoryData = [
          {
            newsId: newsArray?.[offset]?.value ?? 1,
            name: newsArray?.[offset]?.label ?? "football",
            news: data?.result?.raw,
          },
        ];
        setCategoryData(newsCategoryData);
        setCategoryOffset(offset);
        setIsCategoryLoading(false);
        // setSearchValue("");
      } else {
        setIsCategoryLoading(false);
      }
    } catch (err) {
      setIsCategoryLoading(false);
    }
  };

  const fetchScrollNewsCategoryData = async (offset) => {
    try {
      const { status, data } = await axiosInstance.get(
        `v2/news/articles?NewsCategoryId=${
          newsCategoryIdArray?.[offset]?.value ?? 1
        }&limit=5&offset=${0}&timeZone=${timezone}&news=today`,
      );
      if (status === 200) {
        let newsCategoryData = [
          {
            newsId: newsCategoryIdArray?.[offset]?.value ?? 1,
            name: newsCategoryIdArray?.[offset]?.label ?? "football",
            news: data?.result?.raw,
          },
        ];
        setCategoryData((prevData) => [...categoryData, ...newsCategoryData]);
        setCategoryOffset(offset);
        // setSearchValue("");
      } else {
      }
    } catch (err) {}
  };

  const fetchNewsSubtitle = (data, Newstype) => {
    const parseHTMLContent = (body) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = (content) => {
      return he.decode(content);
    };
    const removeHtmlTags = (htmlString) => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = (content) => {
      return Newstype === "top-article" ? (
        <Typography className="sub-details">
          <span
            dangerouslySetInnerHTML={{
              __html: content?.slice(0, 75),
            }}
          ></span>
          {/* {content?.slice(0, 75)} */}
          <span className="read-more">...read more</span>
        </Typography>
      ) : (
        <Typography className="sub-details">
          {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)}
          <span className="read-more">...read more</span>
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        (item) =>
          item?.type === "editor_block" && item?.data?.type === "paragraph",
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };

  const handleNewsSave = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    if (handleRestrictedUser()) {
      let payload = {
        status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted",
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/saved/${item?.NewsArticleId}`,
          payload,
        );
        if (status === 200) {
          setApiMessage(
            "success",
            item?.NewsArticle?.isSaved === 0
              ? "News successfully saved!"
              : "News removed from saved!",
          );

          const updateNews = SliderData?.map((article) =>
            article.NewsArticleId === item?.NewsArticleId
              ? {
                  ...article,
                  NewsArticle: {
                    ...article.NewsArticle,
                    isSaved: item?.NewsArticle?.isSaved === 0 ? 1 : 0,
                  },
                }
              : article,
          );
          setSliderData(updateNews);
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      setApiInfo({
        api: `/v2/news/saved/${item?.NewsArticleId}`,
        payload: {
          status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted",
        },
        method: "post",
      });
    }
  };
  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };
  return (
    <>
      <Box className="all-news">
        <Box className="news-slider news-feature-slider">
          {isSliderLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : SliderData?.length > 0 ? (
            <>
              <Typography variant="h4">Featured News</Typography>
              <Slider
                {...settings}
                ref={silderRef}
                className="news-slider-comp"
              >
                {SliderData?.map((item) => (
                  <Grid
                    container
                    key={item?.id}
                    className="slide-cont"
                    // onClick={() =>
                    //   // navigate(
                    //   //   `/smartinfo/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`
                    //   // )
                    // }
                  >
                    <NavLink
                      to={`/smartinfo/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`}
                    >
                      <Grid
                        item
                        size={{ lg: 6 }}
                        key={item?.NewsArticle?.id}
                        className="slide-single-box"
                      >
                        <Box
                          className={
                            SliderData?.length === 1
                              ? "single-silder single-silder-object"
                              : "single-silder"
                          }
                          // style={{
                          //   backgroundImage: `url(${item?.NewsArticle?.mainMedia?.gallery?.url})`
                          // }}
                        >
                          <Box
                            className="background-overlay"
                            style={{
                              content: "''",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url('${
                                item?.NewsArticle?.mainMedia?.gallery
                                  ? item?.NewsArticle?.mainMedia?.gallery
                                      ?.url &&
                                    item?.NewsArticle?.mainMedia?.gallery
                                      ?.url !== ""
                                    ? item?.NewsArticle?.mainMedia?.gallery?.url
                                    : DefaultImg
                                  : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url &&
                                      item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url !== ""
                                      ? item?.NewsArticle?.mainMedia?.[0]
                                          ?.gallery?.url
                                      : DefaultImg
                                    : DefaultImg
                              }')`,
                              backgroundSize: "cover",
                              backgroundPosition: "center top",
                              filter: "blur(10px)",
                              zIndex: -1,
                              borderRadius: "8px",
                            }}
                          ></Box>
                          <Box className="slider-img">
                            {item?.NewsArticle?.embeddedImageStatus ? (
                              <GettyEmbed
                                embedCode={item?.NewsArticle?.embeddedImage}
                                className={"single-news-flex-center"}
                              />
                            ) : (
                              <img
                                src={
                                  item?.NewsArticle?.mainMedia?.gallery
                                    ? item?.NewsArticle?.mainMedia?.gallery
                                        ?.url &&
                                      item?.NewsArticle?.mainMedia?.gallery
                                        ?.url !== ""
                                      ? item?.NewsArticle?.mainMedia?.gallery
                                          ?.url
                                      : DefaultImg
                                    : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ? item?.NewsArticle?.mainMedia?.[0]
                                          ?.gallery?.url &&
                                        item?.NewsArticle?.mainMedia?.[0]
                                          ?.gallery?.url !== ""
                                        ? item?.NewsArticle?.mainMedia?.[0]
                                            ?.gallery?.url
                                        : DefaultImg
                                      : DefaultImg
                                }
                                alt={
                                  item?.NewsArticle?.mainMedia?.gallery
                                    ? item?.NewsArticle?.mainMedia?.gallery?.alt
                                    : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ? item?.NewsArticle?.mainMedia?.[0]
                                          ?.gallery.alt
                                      : ""
                                }
                              />
                            )}
                          </Box>

                          <Box className="details-back">
                            <Box className="slider-news-deatils">
                              <span className="news-tag">
                                {" "}
                                {/* {fetchCategoryName(item?.NewsArticle?.NewsCategoryId)} */}
                                {item?.NewsArticle?.NewsCategory?.initialTitle}
                              </span>
                              <Link
                                to="#"
                                dangerouslySetInnerHTML={{
                                  __html: item?.NewsArticle?.title,
                                }}
                              ></Link>
                              <Box className="news-time-comment-wrapper">
                                <Typography className="news-time">
                                  {fetchNewsTime(
                                    item?.NewsArticle?.rapidCreatedAt,
                                  )}
                                </Typography>
                                <Box className="bookmark-chat-wrapper">
                                  <Box onClick={(e) => handleNewsSave(e, item)}>
                                    <Tooltip
                                      placement="bottom"
                                      arrow
                                      title='Save to "Saved articles"'
                                      classes={{
                                        tooltip: "news-tooltip",
                                      }}
                                    >
                                      <BookmarkIcon
                                        className={
                                          item?.NewsArticle?.isSaved === 0
                                            ? "unsaved-bookmark-icon"
                                            : "saved-bookmark-icon"
                                        }
                                      />
                                    </Tooltip>
                                  </Box>
                                  <Tooltip
                                    placement="bottom"
                                    arrow
                                    title="Comments"
                                    classes={{
                                      tooltip: "news-tooltip",
                                    }}
                                  >
                                    <Box className="chat-details">
                                      <ChatIcon />
                                      <Typography className="news-time">
                                        {item?.NewsArticle?.totalComments}
                                      </Typography>
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </NavLink>
                  </Grid>
                ))}
              </Slider>
            </>
          ) : (
            <></>
          )}
        </Box>
        {fetchAds(
          1,
          "100%",
          Config?.release === "IN" ? bannerIndia : BelowSlider,
        )}
        <Typography variant="h4">Top Stories</Typography>

        {isTopStoryLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : (
          <>
            <Grid container className="top-stories-cont">
              <NewsArticleComp newsData={TopStoryData?.slice(0, 6)} />
            </Grid>
          </>
        )}
        {fetchAds(
          2,
          "100%",
          Config?.release === "IN" ? bannerIndia : BelowTopStory,
        )}

        {isCategoryLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : (
          <>
            <InfiniteScroll
              dataLength={categoryData?.length}
              next={() =>
                newsCategoryIdArray?.length > 0 &&
                fetchScrollNewsCategoryData(CategoryOffset + 1)
              }
              hasMore={newsCategoryIdArray?.length !== CategoryOffset + 1}
              loader={
                newsCategoryIdArray?.length > 0 && (
                  <div className="allsport-loader-center ">
                    <Loader />
                  </div>
                )
              }
            >
              {categoryData?.map((obj, index) => {
                return (
                  <>
                    <Typography
                      variant="h4"
                      // onClick={() =>
                      //   // navigate(`/smartinfo/news/${obj?.newsId}`)
                      // }
                      className="article-heading article-heading-a "
                    >
                      <NavLink to={`/smartinfo/news/${obj?.newsId}`}>
                        {obj?.name}
                      </NavLink>
                    </Typography>
                    <NewsComp newsData={obj?.news} />
                    {/* <Grid container className="individual-news-cont">
                      {obj?.news?.map(item => (
                        <Grid
                          item
                          key={item?.id}
                          xs={12}
                          className="individual-single-news"
                        >
                          <NavLink to={`/smartinfo/news/${obj?.newsId}/${item?.id}`}>
                            <Box className="news-box">
                              <Box className="news-img">
                                <img
                                  src={
                                    item?.mainMedia?.gallery
                                      ? item?.mainMedia?.gallery?.url &&
                                        item?.mainMedia?.gallery?.url !== ""
                                        ? item?.mainMedia?.gallery?.url
                                        : DefaultImg
                                      : item?.mainMedia?.[0]?.gallery
                                      ? item?.mainMedia?.[0]?.gallery?.url &&
                                        item?.mainMedia?.[0]?.gallery?.url !==
                                          ""
                                        ? item?.mainMedia?.[0]?.gallery?.url
                                        : DefaultImg
                                      : DefaultImg
                                  }
                                  alt={
                                    item?.mainMedia?.gallery
                                      ? item?.mainMedia?.gallery?.alt
                                      : item?.mainMedia?.[0]?.gallery
                                      ? item?.mainMedia?.[0]?.gallery.alt
                                      : ""
                                  }
                                />
                              </Box>
                              <Box className="individual-news-details">
                                <Typography
                                  className="details"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.title
                                      ? item?.title
                                      : item?.subTitle
                                  }}
                                ></Typography>

                                <Box className="sub-details-wrap">
                                  {fetchNewsSubtitle(item, "article")}
                                </Box>

                                <Box className="tag-time-details">
                                  <Typography className="stories-time">
                                    <span className="watch">
                                      <Watch />
                                    </span>
                                    {fetchNewsTime(item?.rapidCreatedAt)}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </NavLink>
                        </Grid>
                      ))}
                    </Grid> */}
                    {index !== categoryData?.length - 1 ? (
                      fetchAds(
                        index + 101,
                        "100%",
                        Config?.release === "IN"
                          ? bannerIndia
                          : BelowFirstCategory,
                      )
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </InfiniteScroll>
          </>
        )}
      </Box>
      {fetchAds(
        3,
        "100%",
        Config?.release === "IN" ? bannerIndia : AboveOurPartner,
      )}
      <OurPartner />
      {Config?.release == "AU" && fetchAds(4, "102px", BelowOurPartner)}
    </>
  );
};

export default AllNews;
