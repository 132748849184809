import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/uncheck_checkbox_large.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/check_checkbox_large.svg";

const CompFilter = ({ clearAll, handleToggle, filters }) => {
  return (
    <>
      <Box className="filter-list-wrap">
        <Box className="filter-header">
          <Typography className="filter-title">Filters</Typography>
          <Typography className="clear-all" onClick={clearAll}>
            <CloseIcon /> Clear all
          </Typography>
        </Box>

        <List className="filter-list">
          {filters?.map((item) => (
            <ListItem
              key={item?.value}
              onClick={() => handleToggle(item?.value)}
              button
            >
              <ListItemIcon>
                <Checkbox
                  checked={item?.active}
                  icon={<Unchecked />}
                  checkedIcon={<Checked />}
                />
              </ListItemIcon>
              <ListItemText primary={item?.label} />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default CompFilter;
