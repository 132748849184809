import React, { useContext, useState } from "react";
import "./contactUs.scss";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import Loader from "src/components/Loader";
import Grid from "@mui/material/Grid2";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import { setApiMessage } from "src/helpers/commonFunctions";

const ContactUs = ({ handleContactUsModalClose }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const nameRegex = /^[a-zA-Z\s'-]+$/;

  const [formvalues, setformvalues] = useState({});

  const handleSubmitForm = async () => {
    try {
      const { status, data } = await axiosInstance.post("/contact", formvalues);
      if (status === 200) {
        setApiMessage("success", data?.message);
        handleContactUsModalClose();
        setformvalues({});
      } else {
        setApiMessage("error", "An Error Occurred");
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
    }
  };

  return (
    <Box className="contact-us-modal-detail-container">
      <Typography>
        We’re here to help. Drop us a message using the form below and we’ll be
        in touch.
      </Typography>
      <Box className="form-container">
        <Formik
          enableReinitialize
          initialValues={{
            firstname: formvalues?.firstname || "",
            lastname:
              formvalues?.lastname && formvalues?.lastname !== ""
                ? formvalues?.lastname
                : "",

            // email:
            //   formvalues?.email && formvalues?.email !== ""
            //     ? formvalues?.email
            //     : "",
            subject: formvalues?.subject || "",
            message:
              formvalues?.message && formvalues?.message !== ""
                ? formvalues?.message
                : "",
          }}
          validationSchema={Yup.object().shape({
            firstname: Yup.string()
              .trim()
              .required(localesData?.validation?.required_message),
            lastname: Yup.string()
              .trim()
              .required(localesData?.validation?.required_message),
            // email: Yup.string()
            //   .matches(
            //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            //     localesData?.validation?.valid_email,
            //   )
            //   .max(255)
            //   .required(localesData?.validation?.required_message),
            subject: Yup.string()
              .trim()
              .required(localesData?.validation?.required_message),
            message: Yup.string()
              .trim()
              .required(localesData?.validation?.required_message),
          })}
          // validate={(values) => {
          //     if (!values.hearAboutInfo.length) {
          //         return {
          //             hearAboutInfo: "Please Choose Atleast One",
          //         };
          //     }
          //     return {};
          // }}

          onSubmit={async (values, { setSubmitting }) => {
            setformvalues({ ...formvalues, ...values });

            handleSubmitForm();
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="stepper-main-wrap">
              <Box className="step1-container register-container">
                <>
                  <Grid container columnSpacing={3}>
                    <Grid item size={{ xs: 12, sm: 6 }}>
                      <Box className="textfield-sec">
                        <TextField
                          placeholder={""}
                          variant="outlined"
                          label="First name*"
                          type="text"
                          name="firstname"
                          className="text-field"
                          error={Boolean(
                            touched?.firstname && errors?.firstname,
                          )}
                          helperText={
                            touched?.firstname ? errors?.firstname : ""
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setformvalues((prevFormValues) => ({
                              ...prevFormValues,
                              [e?.target?.name]: e?.target?.value,
                            }));
                          }}
                          value={values?.firstname}
                          onBlur={handleBlur}

                          // value={formvalues?.firstname && formvalues?.firstname !== "" ? formvalues?.firstname : values?.firstname}
                        />
                      </Box>
                    </Grid>
                    <Grid item size={{ xs: 12, sm: 6 }}>
                      <Box className="textfield-sec">
                        <TextField
                          placeholder={""}
                          variant="outlined"
                          type="text"
                          name="lastname"
                          label="Last name*"
                          className="text-field"
                          error={Boolean(touched?.lastname && errors?.lastname)}
                          helperText={touched?.lastname ? errors?.lastname : ""}
                          onChange={(e) => {
                            handleChange(e);
                            setformvalues((prevFormValues) => ({
                              ...prevFormValues,
                              [e?.target?.name]: e?.target?.value,
                            }));
                          }}
                          // values={formvalues?.lastname && formvalues?.lastname !== "" ? formvalues?.lastname : values?.lastname}
                          value={values?.lastname}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>

                    {/* <Grid item size={{ xs: 12, sm: 12 }}>
                      <Box className="textfield-sec">
                        <TextField
                          placeholder={""}
                          variant="outlined"
                          type="email"
                          name="email"
                          label="Email*"
                          className="text-field email-text-field"
                          error={Boolean(touched?.email && errors?.email)}
                          helperText={touched?.email ? errors?.email : ""}
                          onChange={(e) => {
                            handleChange(e);

                            setformvalues((prevFormValues) => ({
                              ...prevFormValues,
                              [e?.target?.name]: e?.target?.value,
                            })); // Clear the error message when the email input changes
                          }}
                          inputProps={{
                            autoComplete: "new-password",
                          }}
                          onBlur={handleBlur}
                          value={values?.email}
                          // value={formvalues?.email && formvalues?.email !== "" ? formvalues?.email : values?.email}
                        />
                      </Box>
                    </Grid> */}
                    <Grid item size={{ xs: 12, sm: 12 }}>
                      <Box className="textfield-sec">
                        <TextField
                          placeholder={""}
                          variant="outlined"
                          label="Subject*"
                          type="text"
                          name="subject"
                          className="text-field"
                          error={Boolean(touched?.subject && errors?.subject)}
                          helperText={touched?.subject ? errors?.subject : ""}
                          onChange={(e) => {
                            handleChange(e);
                            setformvalues((prevFormValues) => ({
                              ...prevFormValues,
                              [e?.target?.name]: e?.target?.value,
                            }));
                          }}
                          value={values?.subject}
                          onBlur={handleBlur}
                          // value={formvalues?.firstname && formvalues?.firstname !== "" ? formvalues?.firstname : values?.firstname}
                        />
                      </Box>
                    </Grid>
                    <Grid item size={{ xs: 12, sm: 12 }}>
                      <Box className="textfield-sec">
                        <TextField
                          placeholder={""}
                          variant="outlined"
                          type="text"
                          name="message"
                          label="Message*"
                          className="text-field"
                          multiline
                          maxRows={4}
                          minRows={3}
                          error={Boolean(touched?.message && errors?.message)}
                          helperText={touched?.message ? errors?.message : ""}
                          onChange={(e) => {
                            handleChange(e);
                            setformvalues((prevFormValues) => ({
                              ...prevFormValues,
                              [e?.target?.name]: e?.target?.value,
                            }));
                          }}
                          // values={formvalues?.message && formvalues?.message !== "" ? formvalues?.message : values?.message}
                          value={values?.message}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    type="submit"
                    className="submit-btn step1-btn"
                    // onClick={() => {
                    //   step < 5 ? nextStep() : submit();
                    // }}
                  >
                    Send
                  </Button>
                </>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ContactUs;
