import React from "react";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../helpers/context/config";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as EditIcon } from "src/assets/images/icons/editIcon.svg";
import moment from "moment";
import OurPartner from "../ourPartners";
import Loader from "src/components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import DialogBox from "../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import SmartBookModal from "../UI/smartbookModal";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NoDataComp from "../UI/NoData";
import { ReactComponent as AmericanFootball } from "../../../assets/images/SportIcon/outlineSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../../assets/images/SportIcon/outlineSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../../assets/images/SportIcon/outlineSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../../assets/images/SportIcon/outlineSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../../assets/images/SportIcon/outlineSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../../assets/images/SportIcon/outlineSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../../assets/images/SportIcon/outlineSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../../assets/images/SportIcon/outlineSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../../assets/images/SportIcon/outlineSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../../assets/images/SportIcon/outlineSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../../assets/images/SportIcon/outlineSportIcon/Tennis.svg";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { release } from "src/helpers/context/release";
import { useDispatch, useSelector } from "react-redux";
import { BETSLIP_DATA } from "src/helpers/store/constants";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchFromStorage } from "src/helpers/context";

const UpcomingRunnerV2 = ({
  upcomingData,
  isUpcomingLoading,
  fetchUpcomingBlackBook,
  selectedOrg,
  selectedTeams,
  handlePaginationClick,
  upcomingCount,
  upcomingPage,
  pageNumbers,
  isToggle,
}) => {
  const reduxBookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData,
  );
  const BetslipData = useSelector((state) => state?.reduxData?.BetSlipData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [dataId, setDataId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [editComment, setEditComment] = useState("");
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const fetchBestOpenValue = (data, OddsFluc) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })

    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno == 0) {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          return fetchClickableOdds(maxno, providerid?.[0], "header", data);
        } else {
          return "-";
        }
      } else {
        let providerid = data
          ?.map((obj) => {
            if (
              obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue === maxno
            ) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      }
      // return maxno;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
          style={{ margin: "0px auto" }}
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchBestOpenIcon = (data, OddsFluc) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (
              obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue === maxno
            ) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (
                obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === newmaxno
              ) {
                return obj?.BookKeeperId;
              }
            })
            .filter((x) => x !== undefined);
          return oddsicon(providerid?.[0], "header", data);
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };
  const fetchCurrentBestValue = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  const fetchCurrentBestIcon = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const fetchClickableOdds = (odds, BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let iconData = icon?.[0]?.BookKeeper;

    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          {" "}
          {odds === 0 ? "SP" : Number(odds).toFixed(2)}{" "}
        </a>
      ) : (
        <></>
      );
    } else {
      return (
        <a
          href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <span onClick={() => handleBookkeeperCounter(BookKeeperId, type)}>
            {odds === 0 ? "SP" : Number(odds).toFixed(2)}
          </span>
        </a>
      );
    }
  };
  const handleBookkeeperCounter = async (BookKeeperId, type, item) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(item?.SportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleAddToBetSlip = async (item, BookKeeperId, type, fulldata, e) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, type, e);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      let payload = {
        sportId: fulldata?.SportId,
        eventId: fulldata?.eventId,
        teamId: item?.TeamId,
        bookKeeperId: item?.BookKeeperId,
        betOfferId: item?.id,
      };
      try {
        const { status, data } = await axiosInstance.post(
          `betSlipCard/BetSlipCard`,
          payload,
        );
        if (status === 200) {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });

          const addedData = [...BetslipData, data?.result];
          dispatch(fetchBetSlipData(addedData));
          dispatch(fetchBetSlipCount(addedData?.length));
        } else {
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  const handleDeleteBetslip = async (BookKeeperId, oddsInfoID, fulldata, e) => {
    e.stopPropagation();
    let delslip = BetslipData?.filter((el) => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfoID &&
        el?.eventId == fulldata?.eventId
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`,
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        let chageData = BetslipData?.filter((obj) => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };

  const oddsicon = (BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let iconData = icon?.[0]?.BookKeeper;

    return (
      <Box className="current-best-odds-icon">
        <a
          href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="currentbest-bookmaker-thumb"
            src={
              iconData?.currentBest_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.currentBest_logo
                : iconData?.currentBest_logo
            }
            alt="Odds Icon"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type, item)}
          />
        </a>
      </Box>
    );
  };

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`,
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const handleDelete = (type, id) => {
    setSelectedType(type);
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedType("");
    setSelectedId("");
  };
  const handleuserDelete = async (type, id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `blackbook/favoriteData/${id}?type=${type}`,
      );
      if (status === 200) {
        toast.success("Deleted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });

        handleDeleteClose();

        fetchUpcomingBlackBook(selectedOrg, selectedTeams, upcomingPage);
      } else {
      }
    } catch (error) {}
  };
  const handleEdit = async (type, id, item) => {
    setSelectedType(type);
    setTypeId(item?.SportId);
    setSelectedId(id);
    setDataId(item?.dataId);
    const comment = item?.comment;
    setEditComment(comment);
    const title =
      type === "Tournament"
        ? item?.tournamentName
        : item?.dataId === item?.homeTeamId
          ? item?.homeTeamName
          : item?.awayTeamName;

    setSelectedTitle(title);
    handleModal();
  };
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedType("");
    setSelectedId("");
  };

  useEffect(() => {
    fetchBookKeeper();
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const sportIcon = (sportId) => {
    if (sportId === 15) {
      return <AmericanFootball />;
    } else if (sportId === 9) {
      return <AustralianRules />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MixedMartialArts />;
    } else if (sportId === 12) {
      return <RugbyLeague />;
    } else if (sportId === 13) {
      return <RugbyUnion />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };

  const sportName = (sportId) => {
    if (sportId === 15) {
      return "American Football";
    } else if (sportId === 9) {
      return "Australian Rules";
    } else if (sportId === 11) {
      return "Baseball";
    } else if (sportId === 10) {
      return "Basketball";
    } else if (sportId === 6) {
      return "Boxing";
    } else if (sportId === 4) {
      return "Cricket";
    } else if (sportId === 16) {
      return "Golf";
    } else if (sportId === 17) {
      return "Ice Hockey";
    } else if (sportId === 5) {
      return "Mixed Martial Arts";
    } else if (sportId === 12) {
      return "Rugby League";
    } else if (sportId === 13) {
      return "Rugby Union";
    } else if (sportId === 8) {
      return "Soccer";
    } else if (sportId === 7) {
      return "Tennis";
    }
  };

  const handleNavigate = (item) => {
    const sportId = item?.SportId;
    let sportType =
      sportId === 4
        ? "cricket"
        : sportId === 12
          ? "rugbyleague"
          : sportId === 13
            ? "rugbyunion"
            : sportId === 10
              ? "basketball"
              : sportId === 15
                ? "americanfootball"
                : sportId === 9
                  ? "australianrules"
                  : sportId === 16
                    ? "golf"
                    : sportId === 7
                      ? "tennis"
                      : sportId === 11
                        ? "baseball"
                        : sportId === 17
                          ? "icehockey"
                          : sportId === 6
                            ? "boxing"
                            : sportId === 5
                              ? "mma"
                              : sportId === 8
                                ? "soccer"
                                : "cricket";
    sportId === 16
      ? navigate(`/teamsports/${sportType}/outrights/${item?.eventId}/true`)
      : sportId === 4
        ? navigate(
            `/all-sports/${sportType}${sportId}/odds/${item?.eventId}/true?menu=fixtures_results&touranamentId=0&touranamentName=${sportId === 4 ? "All+Cricket" : "All+Leagues"}`,
          )
        : navigate(`/teamsports/${sportType}/odds/${item?.eventId}/true`);
  };

  const handleFixtureNavigate = (item) => {
    const eventSlug = item?.eventName
      ? item.eventName.trim().toLowerCase().replaceAll(" ", "-")
      : "event";
    const TournamentName = item?.tournamentName;
    const tournamentSlug = TournamentName
      ? TournamentName.trim().toLowerCase().replaceAll(" ", "-")
      : "tournament";
    const sportName =
      item?.SportId === 4
        ? "cricket"
        : item?.SportId === 8
          ? "soccer"
          : item?.SportId === 12
            ? "rugbyleague"
            : "";

    const route = `/teamsports/${sportName}/${tournamentSlug}/${eventSlug}/info/${item?.SportId}/${item?.tournamentId}/${item?.eventId}`;
    navigate(route);
  };

  const fetchTeamScore = (score, sportId) => {
    const parsedScore = score
      ? typeof score === "string"
        ? JSON.parse(score)
        : score
      : {};
    // const cricketScore =
    //   (parsedScore?.innings?.inning1
    //     ? parsedScore?.innings?.inning1?.score +
    //       "/" +
    //       parsedScore?.innings?.inning1?.wickets
    //     : "-") +
    //   " " +
    //   (parsedScore?.innings?.inning1?.overs
    //     ? "(" + parsedScore?.innings?.inning1?.overs + ")"
    //     : "") +
    //   (parsedScore?.innings?.inning2
    //     ? ` & ${parsedScore?.innings?.inning2?.score}/${
    //         parsedScore?.innings?.inning2?.wickets
    //       } ${
    //         parsedScore?.innings?.inning2?.overs
    //           ? "(" + parsedScore?.innings?.inning2?.overs + ")"
    //           : ""
    //       })`
    //     : "");
    const scoreData = parsedScore?.current;
    return scoreData;
  };

  const fetchTennisScore = (score) => {
    const parsedScore = score
      ? typeof score === "string"
        ? JSON.parse(score)
        : score
      : {};

    return (
      <>
        <TableCell className="sport-score border-right border-bottom-cell">
          {parsedScore?.current ? parsedScore?.current : "-"}
        </TableCell>
        <TableCell className="sport-score border-right border-bottom-cell">
          {parsedScore?.normaltime ? parsedScore?.normaltime : "-"}
        </TableCell>
        <TableCell className="sport-score border-right border-bottom-cell">
          {parsedScore?.point ? parsedScore?.point : "-"}
        </TableCell>
      </>
    );
  };

  const fetchMobileTennisScore = (score) => {
    const parsedScore = score
      ? typeof score === "string"
        ? JSON.parse(score)
        : score
      : {};

    return (
      <>
        <TableCell>
          {parsedScore?.current ? parsedScore?.current : "-"}
        </TableCell>
        <TableCell>
          {parsedScore?.normaltime ? parsedScore?.normaltime : "-"}
        </TableCell>
        <TableCell>{parsedScore?.point ? parsedScore?.point : "-"}</TableCell>
      </>
    );
  };
  const fetchCricketScore = (teamScore, teamType) => {
    return teamType === "hometeam" ? (
      teamScore?.Exd > "1" ? (
        <Typography className="team-score">
          {teamScore?.Tr1C1 ?? "-"}
          {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
            ? ""
            : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
              `/${teamScore?.Tr1CW1}${
                teamScore?.Tr1CD1 && teamScore?.Tr1CD1 === 1 ? "d" : ""
              }`}{" "}
          {(teamScore?.Tr1C2 || teamScore?.Tr1C2 === 0) &&
            `& ${teamScore?.Tr1C2}`}
          {teamScore?.Tr1CW2 && teamScore?.Tr1CW2 === 10
            ? ""
            : (teamScore?.Tr1CW2 || teamScore?.Tr1CW2 === 0) &&
              `/${teamScore?.Tr1CW2}${
                teamScore?.Tr1CD2 && teamScore?.Tr1CD2 === 1 ? "d" : ""
              }`}{" "}
        </Typography>
      ) : (
        <Typography className="team-score">
          {teamScore?.Tr1C1 ?? "-"}
          {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
            ? ""
            : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
              `/ ${teamScore?.Tr1CW1}`}{" "}
          <span className="f-white">
            {" "}
            {teamScore?.Tr1CO1 ? `(${teamScore?.Tr1CO1})` : ""}
          </span>{" "}
        </Typography>
      )
    ) : teamScore?.Exd > "1" ? (
      <Typography className="team-score">
        {teamScore?.Tr2C1 ?? "-"}
        {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
          ? ""
          : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
            `/${teamScore?.Tr2CW1}${
              teamScore?.Tr2CD1 && teamScore?.Tr2CD1 === 1 ? "d" : ""
            }`}{" "}
        {(teamScore?.Tr2C2 || teamScore?.Tr2C2 === 0) &&
          `& ${teamScore?.Tr2C2}`}
        {teamScore?.Tr2CW2 && teamScore?.Tr2CW2 === 10
          ? ""
          : (teamScore?.Tr2CW2 || teamScore?.Tr2CW2 === 0) &&
            `/${teamScore?.Tr2CW2}${
              teamScore?.Tr2CD2 && teamScore?.Tr2CD2 === 1 ? "d" : ""
            }`}{" "}
      </Typography>
    ) : (
      <Typography className="team-score">
        {teamScore?.Tr2C1 ?? "-"}
        {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
          ? ""
          : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
            `/ ${teamScore?.Tr2CW1}`}{" "}
        <span className="f-white">
          {teamScore?.Tr2CO1 ? `(${teamScore?.Tr2CO1})` : ""}
        </span>
      </Typography>
    );
  };

  const fetchSoccerScore = (teamScore, teamType) => {
    return teamType === "hometeam" ? (
      <Typography className="team-score"> {teamScore?.Tr1 ?? "-"}</Typography>
    ) : (
      <Typography className="team-score">{teamScore?.Tr2 ?? "-"}</Typography>
    );
  };

  const IsFeaturedData = (item) => {
    return reduxBookkeeperData
      ?.filter((item) => item?.isFeatured === true)
      ?.sort((a, b) => a.featured_order - b.featured_order)
      ?.slice(0, 6)
      ?.map((bookkeper, index) => {
        return fetchFeaturedOdds(item?.homeOdds, bookkeper?.id, item);
      });
  };

  const fetchFeaturedOdds = (data, ProviderId, fullData) => {
    const odds = data?.filter((item) => item?.BookKeeperId === ProviderId);
    if (odds?.length > 0 && odds?.[0]?.odd != 0) {
      const oddsInfo = odds?.[0]?.id;
      let isAdded = BetslipData?.some((el) => {
        if (
          el?.BookKeeperId == odds?.[0]?.BookKeeperId &&
          el?.betOfferId == odds?.[0]?.id &&
          el?.eventId == fullData?.eventId
        )
          return true;
        else return false;
      });
      return (
        <Box className="odds-box-style">
          <Box
            className={`odds-style  ${isAdded ? "active-odd-style" : ""}`}
            onClick={(e) => {
              isAdded
                ? handleDeleteBetslip(
                    odds?.[0]?.BookKeeperId,
                    oddsInfo,
                    fullData,
                    e,
                  )
                : handleAddToBetSlip(
                    odds?.[0],
                    odds?.[0]?.BookKeeperId,
                    "header",
                    fullData,
                    e,
                  );
            }}
          >
            <Tooltip title="Bet" className="bet-tooltip" placement="top">
              <Typography className="odds bet-now">
                {" "}
                {odds?.[0]?.odd === 0
                  ? "SP"
                  : Number(odds?.[0]?.odd).toFixed(2)}
              </Typography>
            </Tooltip>
          </Box>
          <Box className="current-best-odds-icon">
            <Box className="odd-img">
              <img
                src={odds?.[0]?.BookKeeperLogo}
                // onClick={e => {
                //   handleBookkeeperCounter(BookKeeperId, type, e);
                //   handleAnchorTagClick(e, urlLink);
                // }}
                alt="Odds Icon"
              />
            </Box>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  const currentBestOdds = (odds, teamType, item) => {
    let isAdded = BetslipData?.some((el) => {
      if (
        el?.BookKeeperId ==
          (teamType === "homeTeam"
            ? item?.homeCurrentBookKeeperId
            : item?.awayCurrentBookKeeperId) &&
        el?.betOfferId ==
          (teamType === "homeTeam"
            ? item?.homeCurrentOddId
            : item?.awayCurrentOddId) &&
        el?.eventId == item?.eventId
      )
        return true;
      else return false;
    });

    const bookKeeperId =
      teamType === "homeTeam"
        ? item?.homeCurrentBookKeeperId
        : item?.awayCurrentBookKeeperId;
    const betOfferId =
      teamType === "homeTeam" ? item?.homeCurrentOddId : item?.awayCurrentOddId;

    return (
      <span
        className={`odds-btn cursor-pointer current-best-odds-value  ${
          isAdded ? "betslip-added" : ""
        } `}
        onClick={(e) => {
          if (
            item?.Epr == 0 ||
            (item?.Epr == null && (item?.SportId == 4 || item?.SportId == 8)) ||
            (item?.status && item?.status !== "inprogress") ||
            ((item?.homeScore === null || item?.awayScore === null) &&
              (item?.SportId != 4 || item?.SportId != 8))
          ) {
            if (isAdded) {
              handleDeleteBetslip(bookKeeperId, betOfferId, item, e);
            } else {
              handleAddToCurrentBetSlip(
                bookKeeperId,
                teamType,
                betOfferId,
                item,
                e,
              );
            }
          }
        }}
      >
        <span className="odds-link">
          <Tooltip title="Bet" className="bet-tooltip" placement="top">
            <span className="bet-now">
              {" "}
              {odds == 0 ? "SP" : Number(odds).toFixed(2)}{" "}
            </span>
          </Tooltip>
        </span>
      </span>
    );
  };

  const handleAddToCurrentBetSlip = async (
    BookKeeperId,
    teamType,
    betOfferId,
    fulldata,
    e,
  ) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, "header", e);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      let payload = {
        sportId: fulldata?.SportId,
        eventId: fulldata?.eventId,
        teamId:
          teamType === "homeTeam" ? fulldata?.homeTeamId : fulldata?.awayTeamId,
        bookKeeperId: BookKeeperId,
        betOfferId: betOfferId,
      };
      try {
        const { status, data } = await axiosInstance.post(
          `betSlipCard/BetSlipCard`,
          payload,
        );
        if (status === 200) {
          toast.success(data?.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });

          const addedData = [...BetslipData, data?.result];
          dispatch(fetchBetSlipData(addedData));
          dispatch(fetchBetSlipCount(addedData?.length));
        } else {
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  const handleTeamStateNavigate = (data, teamType) => {
    const teamSlug =
      teamType === "homeTeam"
        ? data?.homeTeam
          ? data?.homeTeam?.name
              .trim()
              .toLowerCase()
              .replaceAll(" ", "-")
              .replaceAll("/", "-")
          : "team"
        : data?.awayTeam
          ? data?.awayTeam?.name
              .trim()
              .toLowerCase()
              .replaceAll(" ", "-")
              .replaceAll("/", "-")
          : "team";

    const NavigateRoute =
      data?.SportId == 12
        ? `/sport/statistics/${data?.SportId}/${
            data?.tournamentId
          }/league-data/${
            teamType === "homeTeam" ? data?.homeTeamId : data?.awayTeamId
          }/team-data?seasonId=`
        : data?.SportId == 13
          ? `/sport/statistics/${data?.SportId}/${
              data?.tournamentId
            }/league-data/${
              teamType === "homeTeam" ? data?.homeTeamId : data?.awayTeamId
            }/team-data?seasonId=`
          : `/teamsports/soccer/statistics/team/${teamSlug}/${data?.SportId}/${
              data?.tournamentId
            }/${teamType === "homeTeam" ? data?.homeTeamId : data?.awayTeamId}`;
    navigate(NavigateRoute);
  };
  return (
    <>
      <Box className="page-content-wrap">
        {isUpcomingLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : upcomingData && upcomingData?.length > 0 ? (
          upcomingData?.map((item, index) => {
            return (
              <>
                <Box className="blackbook-runner-info-details">
                  <Box className="smart-top-header-details">
                    <Box className="header-details">
                      <Typography variant="h6" className="day-time-details">
                        {!(
                          item?.status === "inprogress" || item?.Epr === 1
                        ) && (
                          <>
                            <span className="dark-color">
                              {item?.startTime
                                ? fetchDayName(item?.startTime)
                                : ""}{" "}
                              {item?.startTime
                                ? moment
                                    .utc(item?.startTime)
                                    .local()
                                    .format("DD/MM/YYYY")
                                : ""}{" "}
                            </span>
                            |{" "}
                            <span className="dark-color">
                              {item?.startTime
                                ? moment
                                    .utc(item?.startTime)
                                    .local()
                                    .format("hh:mm A")
                                : ""}{" "}
                            </span>
                            |{" "}
                          </>
                        )}
                        <span className="dark-color">
                          {item?.marketLength
                            ? item?.marketLength
                            : item?.marketLength === 0
                              ? item?.marketLength
                              : ""}{" "}
                          {item?.marketLength
                            ? item?.marketLength === 1
                              ? "Market"
                              : "Markets"
                            : item?.marketLength === 0
                              ? "Market"
                              : ""}{" "}
                        </span>
                      </Typography>
                    </Box>

                    <Box className="see-all-box">
                      {release[Config.release]?.oddsCheck && (
                        <Typography
                          className="btn-txt"
                          onClick={() => handleNavigate(item)}
                        >
                          See all odds
                        </Typography>
                      )}
                      {screenWidth >= 1023 &&
                        (item?.SportId === 4 ||
                          item?.SportId === 8 ||
                          item?.SportId === 12) && (
                          <Typography
                            className="btn-txt"
                            onClick={() => handleFixtureNavigate(item)}
                          >
                            Fixture info
                          </Typography>
                        )}
                    </Box>
                  </Box>
                </Box>
                {screenWidth >= 1023 ? (
                  <Box
                    className="team-sport-table-wrap blackbook-runner-details"
                    key={index}
                  >
                    <Box className="smart-top-header-details bottom-border">
                      <Box className="header-details title-bottom-border">
                        <Typography
                          variant="h6"
                          className="day-time-details flex"
                        >
                          <span className="dark-color">
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span className="sport-icon">
                                {sportIcon(item?.SportId)}
                              </span>
                              <p className="m-0">
                                <span className="title-txt">
                                  {sportName(item?.SportId)}
                                </span>
                              </p>
                            </Box>
                          </span>
                        </Typography>

                        <Typography>
                          <span className="dark-color chip chip-txt">
                            {item?.tournamentName}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="Updated-date">
                        <Typography className="date">
                          Updated:{" "}
                          {item?.updatedAt
                            ? moment
                                .utc(item?.updatedAt)
                                .local()
                                .format("DD/MM/YYYY")
                            : ""}
                        </Typography>
                        <Box
                          className="delete-box cursor-pointer"
                          onClick={() =>
                            handleDelete(item?.type, item?.tableId)
                          }
                        >
                          <Delete />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="runner-deatils-v2">
                      <Table className="runner-smartbook-table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {(item?.status === "inprogress" ||
                                item?.Epr === 1) && (
                                <span className="live-indicator">LIVE</span>
                              )}
                            </TableCell>
                            {(item?.homeScore !== null &&
                              item?.awayScore !== null &&
                              item?.status !== null) ||
                            item?.Epr === 1 ||
                            item?.Epr === 2 ? (
                              item?.SportId === 7 ? (
                                <>
                                  <TableCell className="sport-score w-89 border-bottom border-right">
                                    Sets
                                  </TableCell>
                                  <TableCell className="sport-score w-89 border-bottom border-right">
                                    Games
                                  </TableCell>
                                  <TableCell className="sport-score w-89 border-bottom border-right">
                                    Point
                                  </TableCell>
                                </>
                              ) : (
                                <TableCell className="sport-score w-89 border-bottom border-right">
                                  {item?.SportId === 4
                                    ? item?.Epr === 1
                                      ? "SCORES"
                                      : "RESULTS"
                                    : item?.status == "inprogress"
                                      ? "SCORES"
                                      : "RESULTS"}
                                </TableCell>
                              )
                            ) : (
                              <></>
                            )}
                            {release[Config.release]?.oddsCheck &&
                              item?.homeCurrentBestOdd && (
                                <TableCell className="current-best border-bottom">
                                  CURRENT BEST
                                </TableCell>
                              )}
                            {((item?.status && item?.status !== "inprogress") ||
                              item?.Epr == 0 ||
                              (item?.Epr == null &&
                                (item?.SportId == 4 || item?.SportId == 8))) &&
                              (item?.homeOdds?.length > 0 ||
                                item?.awayOdds?.length > 0) &&
                              !IsFeaturedData(item)?.every(
                                (ele) => ele == null,
                              ) && (
                                <TableCell className="featured-odds">
                                  Featured bookmaker Odds
                                </TableCell>
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell className="runner-tc border-bottom-cell">
                              <Box className="team-img-wrap">
                                <img
                                  className="team-img"
                                  src={
                                    item?.homeFlag
                                      ? Config.mediaURL + item?.homeFlag
                                      : Brisbane
                                  }
                                  alt="team"
                                />
                              </Box>
                              <Typography
                                className={
                                  item?.SportId === 8 ||
                                  item?.SportId === 12 ||
                                  item?.SportId === 13
                                    ? "text-underline title-txt"
                                    : "title-txt"
                                }
                                onClick={() =>
                                  (item?.SportId === 8 ||
                                    item?.SportId === 12 ||
                                    item?.SportId === 13) &&
                                  handleTeamStateNavigate(item, "homeTeam")
                                }
                              >
                                {item?.homeTeamName}
                              </Typography>
                            </TableCell>
                            {item?.homeScore !== null ||
                            item?.Epr === 1 ||
                            item?.Epr === 2 ? (
                              item?.SportId === 7 ? (
                                <>
                                  {fetchTennisScore(
                                    item?.homeScore,
                                    "hometeam",
                                  )}
                                </>
                              ) : (
                                <TableCell className="sport-score border-right border-bottom-cell">
                                  {item?.homeScore ||
                                  item?.Epr === 1 ||
                                  item?.Epr === 2 ? (
                                    <Box className="odds-wrap">
                                      <span className="f-white ft-txt">
                                        {item?.SportId === 4
                                          ? fetchCricketScore(item, "hometeam")
                                          : item?.SportId === 8
                                            ? fetchSoccerScore(item, "hometeam")
                                            : fetchTeamScore(
                                                item?.homeScore,
                                                item?.SportId,
                                              )}
                                      </span>
                                    </Box>
                                  ) : (
                                    <Box>-</Box>
                                  )}
                                </TableCell>
                              )
                            ) : (
                              <></>
                            )}
                            {release[Config.release]?.oddsCheck &&
                              item?.homeCurrentBestOdd && (
                                <TableCell className="current-best-cell border-bottom-cell">
                                  {" "}
                                  {item?.homeCurrentBestOdd ? (
                                    <Box className="odds-wrap ">
                                      {currentBestOdds(
                                        item?.homeCurrentBestOdd,
                                        "homeTeam",
                                        item,
                                      )}
                                      {/* <span className="current-best-odds-value">
                                      {Number(item?.homeCurrentBestOdd).toFixed(
                                        2
                                      )}
                                    </span> */}
                                      {item?.homeCurrentBookKeeperId
                                        ? oddsicon(
                                            item?.homeCurrentBookKeeperId,
                                            "header",
                                            item,
                                          )
                                        : "-"}
                                    </Box>
                                  ) : (
                                    <Tooltip
                                      title="No odds available"
                                      className="odds-tooltip"
                                      placement="top"
                                    >
                                      <span className="odds-btn">
                                        <span className="no-odds">NOA</span>
                                      </span>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              )}
                            {((item?.status && item?.status !== "inprogress") ||
                              item?.Epr == 0 ||
                              (item?.Epr == null &&
                                (item?.SportId == 4 || item?.SportId == 8))) &&
                              item?.homeOdds?.length > 0 &&
                              !IsFeaturedData(item)?.every(
                                (ele) => ele == null,
                              ) && (
                                <TableCell className="border-bottom-cell hide-scroll max-w">
                                  {reduxBookkeeperData?.length > 0 && (
                                    <Box className="hide-scroll max-w">
                                      <Box className="odds-flex">
                                        {reduxBookkeeperData
                                          ?.filter(
                                            (item) => item?.isFeatured === true,
                                          )
                                          ?.sort(
                                            (a, b) =>
                                              a.featured_order -
                                              b.featured_order,
                                          )
                                          ?.slice(0, 6)
                                          ?.map((bookkeper, index) => {
                                            return fetchFeaturedOdds(
                                              item?.homeOdds,
                                              bookkeper?.id,
                                              item,
                                            );
                                          })}
                                      </Box>
                                    </Box>
                                  )}
                                </TableCell>
                              )}
                          </TableRow>

                          <TableRow>
                            <TableCell className="runner-tc">
                              <Box className="team-img-wrap">
                                <img
                                  className="team-img"
                                  src={
                                    item?.awayFlag
                                      ? Config.mediaURL + item?.awayFlag
                                      : Melbourne
                                  }
                                  alt="team"
                                />
                              </Box>
                              <Typography
                                className={
                                  item?.SportId === 8 ||
                                  item?.SportId === 12 ||
                                  item?.SportId === 13
                                    ? "text-underline title-txt"
                                    : "title-txt"
                                }
                                onClick={() =>
                                  (item?.SportId === 8 ||
                                    item?.SportId === 12 ||
                                    item?.SportId === 13) &&
                                  handleTeamStateNavigate(item, "awayTeam")
                                }
                              >
                                {item?.awayTeamName}
                              </Typography>
                            </TableCell>

                            {item?.awayScore !== null ||
                            item?.Epr === 1 ||
                            item?.Epr === 2 ? (
                              item?.SportId === 7 ? (
                                <>
                                  {fetchTennisScore(
                                    item?.awayScore,
                                    "awayTeam",
                                  )}
                                </>
                              ) : (
                                <TableCell className="sport-score border-right">
                                  {item?.awayScore ||
                                  item?.Epr === 1 ||
                                  item?.Epr === 2 ? (
                                    <Box className="odds-wrap">
                                      <span className="f-white ft-txt">
                                        {item?.SportId === 4
                                          ? fetchCricketScore(item, "awayteam")
                                          : item?.SportId === 8
                                            ? fetchSoccerScore(item, "awayteam")
                                            : fetchTeamScore(
                                                item?.awayScore,
                                                item?.SportId,
                                              )}
                                      </span>
                                    </Box>
                                  ) : (
                                    <Box>-</Box>
                                  )}
                                </TableCell>
                              )
                            ) : (
                              <></>
                            )}
                            {release[Config.release]?.oddsCheck &&
                              item?.awayCurrentBestOdd && (
                                <TableCell className="current-best-cell">
                                  {" "}
                                  {item?.awayCurrentBestOdd ? (
                                    <Box className="odds-wrap">
                                      {currentBestOdds(
                                        item?.awayCurrentBestOdd,
                                        "awayTeam",
                                        item,
                                      )}
                                      {/* <span className="current-best-odds-value">
                                      {Number(item?.awayCurrentBestOdd).toFixed(
                                        2
                                      )}
                                    </span> */}
                                      {item?.awayCurrentBookKeeperId
                                        ? oddsicon(
                                            item?.awayCurrentBookKeeperId,
                                            "header",
                                            item,
                                          )
                                        : "-"}
                                    </Box>
                                  ) : (
                                    <Tooltip
                                      title="No odds available"
                                      className="odds-tooltip"
                                      placement="top"
                                    >
                                      <span className="odds-btn">
                                        <span className="no-odds">NOA</span>
                                      </span>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              )}

                            {((item?.status && item?.status !== "inprogress") ||
                              item?.Epr == 0 ||
                              (item?.Epr == null &&
                                (item?.SportId == 4 || item?.SportId == 8))) &&
                              item?.awayOdds?.length > 0 &&
                              !IsFeaturedData(item)?.every(
                                (ele) => ele == null,
                              ) && (
                                <TableCell>
                                  {reduxBookkeeperData?.length > 0 && (
                                    <Box className="hide-scroll max-w">
                                      <Box className="odds-flex">
                                        {reduxBookkeeperData
                                          ?.filter(
                                            (item) => item?.isFeatured === true,
                                          )
                                          ?.sort(
                                            (a, b) =>
                                              a.featured_order -
                                              b.featured_order,
                                          )
                                          ?.slice(0, 6)
                                          ?.map((bookkeper, index) => {
                                            return fetchFeaturedOdds(
                                              item?.awayOdds,
                                              bookkeper?.id,
                                              item,
                                            );
                                          })}
                                      </Box>
                                    </Box>
                                  )}
                                </TableCell>
                              )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                    {isToggle ? (
                      <Box className="comment-section">
                        <Box className="runner-comment width-86">
                          <Typography className="runner">
                            {" "}
                            {item?.type}:
                          </Typography>
                          <Typography className="runner comment">
                            {item?.comment}
                          </Typography>
                        </Box>
                        <Box className="width-14">
                          <Typography
                            className="edit-comment cursor-pointer"
                            onClick={() =>
                              handleEdit(item?.type, item?.tableId, item)
                            }
                          >
                            <EditIcon />
                            Edit comment
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                ) : (
                  <Box className="mobile-smartbook-upcoming">
                    <Box className="bottom-border">
                      <Box className="smart-top-header-details">
                        <Box className="header-details title-bottom-border">
                          <Typography
                            variant="h6"
                            className="day-time-details flex"
                          >
                            <span className="dark-color">
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span className="sport-icon">
                                  {sportIcon(item?.SportId)}
                                </span>
                                <p className="m-0">
                                  <span className="title-txt">
                                    {sportName(item?.SportId)}
                                  </span>
                                </p>
                              </Box>
                            </span>
                          </Typography>
                        </Box>
                        <Box className="Updated-date">
                          <Typography className="date">
                            Updated:{" "}
                            {item?.updatedAt
                              ? moment
                                  .utc(item?.updatedAt)
                                  .local()
                                  .format("DD/MM/YYYY")
                              : ""}
                          </Typography>
                          <Box
                            className="delete-box cursor-pointer"
                            onClick={() =>
                              handleDelete(item?.type, item?.tableId)
                            }
                          >
                            <Delete />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="smart-bottom-header-details">
                        <Typography>
                          <span className="dark-color chip chip-txt">
                            {item?.tournamentName}
                          </span>
                        </Typography>
                        {(item?.SportId === 4 ||
                          item?.SportId === 8 ||
                          item?.SportId === 12) && (
                          <Typography
                            className="btn-txt"
                            onClick={() => handleFixtureNavigate(item)}
                          >
                            Fixture info
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box className="teams-details">
                      <Box className="teams-details-wrap">
                        <Box className="home-team-row">
                          <Box className="team-wrap">
                            <Box className="team-d-flex team-align-center team-logo-name">
                              <span className="team-img-wrap">
                                <img
                                  className="team-img"
                                  src={
                                    item?.homeFlag
                                      ? Config.mediaURL + item?.homeFlag
                                      : Brisbane
                                  }
                                  alt="logo"
                                />
                              </span>
                              <span
                                className={
                                  item?.SportId === 8 ||
                                  item?.SportId === 12 ||
                                  item?.SportId === 13
                                    ? "text-underline team-name"
                                    : "team-name"
                                }
                                onClick={() =>
                                  (item?.SportId === 8 ||
                                    item?.SportId === 12 ||
                                    item?.SportId === 13) &&
                                  handleTeamStateNavigate(item, "homeTeam")
                                }
                              >
                                {item?.homeTeamName ? item?.homeTeamName : ""}
                              </span>
                            </Box>
                          </Box>
                          {item?.homeCurrentBestOdd ? (
                            <Box className="odds-wrap ">
                              {currentBestOdds(
                                item?.homeCurrentBestOdd,
                                "homeTeam",
                                item,
                              )}
                              {/* <span className="current-best-odds-value">
                                {Number(item?.homeCurrentBestOdd).toFixed(2)}
                              </span> */}
                              {item?.homeCurrentBookKeeperId
                                ? oddsicon(
                                    item?.homeCurrentBookKeeperId,
                                    "header",
                                    item,
                                  )
                                : "-"}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Box>
                        {((item?.status && item?.status !== "inprogress") ||
                          item?.Epr == 0 ||
                          (item?.Epr == null &&
                            (item?.SportId == 4 || item?.SportId == 8))) &&
                          item?.awayOdds?.length > 0 &&
                          !IsFeaturedData(item)?.every(
                            (ele) => ele == null,
                          ) && (
                            <Box className="mobile-table-container">
                              <Table className="odds-table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="featured-odds">
                                      Featured bookmaker Odds
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      {reduxBookkeeperData?.length > 0 && (
                                        <Box className="hide-scroll max-w">
                                          <Box className="odds-flex">
                                            {reduxBookkeeperData
                                              ?.filter(
                                                (item) =>
                                                  item?.isFeatured === true,
                                              )
                                              ?.sort(
                                                (a, b) =>
                                                  a.featured_order -
                                                  b.featured_order,
                                              )
                                              ?.slice(0, 6)
                                              ?.map((bookkeper, index) => {
                                                return fetchFeaturedOdds(
                                                  item?.homeOdds,
                                                  bookkeper?.id,
                                                  item,
                                                );
                                              })}
                                          </Box>
                                        </Box>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          )}
                        <Box className="away-team-row">
                          <Box className="team-wrap">
                            <Box className="team-d-flex team-align-center team-logo-name">
                              <span className="team-img-wrap">
                                <img
                                  className="team-img"
                                  src={
                                    item?.awayFlag
                                      ? Config.mediaURL + item?.awayFlag
                                      : Brisbane
                                  }
                                  alt="logo"
                                />
                              </span>
                              <span
                                className={
                                  item?.SportId === 8 ||
                                  item?.SportId === 12 ||
                                  item?.SportId === 13
                                    ? "text-underline team-name"
                                    : "team-name"
                                }
                                onClick={() =>
                                  (item?.SportId === 8 ||
                                    item?.SportId === 12 ||
                                    item?.SportId === 13) &&
                                  handleTeamStateNavigate(item, "awayTeam")
                                }
                              >
                                {item?.awayTeamName ? item?.awayTeamName : ""}
                              </span>
                            </Box>
                          </Box>
                          {item?.awayCurrentBestOdd ? (
                            <Box className="odds-wrap ">
                              {currentBestOdds(
                                item?.awayCurrentBestOdd,
                                "awayTeam",
                                item,
                              )}
                              {/* <span className="current-best-odds-value">
                                {Number(item?.awayCurrentBestOdd).toFixed(2)}
                              </span> */}
                              {item?.awayCurrentBookKeeperId
                                ? oddsicon(
                                    item?.awayCurrentBookKeeperId,
                                    "header",
                                    item,
                                  )
                                : "-"}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Box>
                        {((item?.status && item?.status !== "inprogress") ||
                          item?.Epr == 0 ||
                          (item?.Epr == null &&
                            (item?.SportId == 4 || item?.SportId == 8))) &&
                          item?.awayOdds?.length > 0 &&
                          !IsFeaturedData(item)?.every(
                            (ele) => ele == null,
                          ) && (
                            <Box className="mobile-table-container">
                              <Table className="odds-table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="featured-odds">
                                      Featured bookmaker Odds
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      {reduxBookkeeperData?.length > 0 && (
                                        <Box className="hide-scroll max-w">
                                          <Box className="odds-flex">
                                            {reduxBookkeeperData
                                              ?.filter(
                                                (item) =>
                                                  item?.isFeatured === true,
                                              )
                                              ?.sort(
                                                (a, b) =>
                                                  a.featured_order -
                                                  b.featured_order,
                                              )
                                              ?.slice(0, 6)
                                              ?.map((bookkeper, index) => {
                                                return fetchFeaturedOdds(
                                                  item?.awayOdds,
                                                  bookkeper?.id,
                                                  item,
                                                );
                                              })}
                                          </Box>
                                        </Box>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          )}
                      </Box>
                    </Box>
                    {(item?.status === "inprogress" &&
                      item?.homeScore &&
                      item?.awayScore) ||
                    item?.Epr === 1 ||
                    item?.Epr === 2 ? (
                      <Box className="mobileview-smartbook-table-wrap">
                        <Box className="team-match-wrap">
                          <Box
                            className={
                              item?.SportId === 7
                                ? "score score-padding-mobile mobile-tennis-table"
                                : "odds-detail score score-padding-mobile"
                            }
                          >
                            <Box className="odds-body">
                              {item?.SportId === 7 ? (
                                item?.homeScore ||
                                item?.Epr === 1 ||
                                item?.Epr === 2 ? (
                                  <>
                                    <Box className="w-50">
                                      <Table className="tennis-table border-r">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Sets</TableCell>
                                            <TableCell>Games</TableCell>
                                            <TableCell>Points</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            {fetchMobileTennisScore(
                                              item?.homeScore,
                                            )}
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                      <Box className="team-name border-r">
                                        {item?.homeTeamName}
                                      </Box>
                                    </Box>
                                  </>
                                ) : (
                                  <Box>-</Box>
                                )
                              ) : (
                                <Box>
                                  {item?.homeScore ||
                                  item?.Epr === 1 ||
                                  item?.Epr === 2 ? (
                                    <Box className="odds-wrap">
                                      <span>
                                        <Box className="f-white points ft-txt">
                                          {item?.SportId === 4
                                            ? fetchCricketScore(
                                                item,
                                                "hometeam",
                                              )
                                            : item?.SportId === 8
                                              ? fetchSoccerScore(
                                                  item,
                                                  "hometeam",
                                                )
                                              : fetchTeamScore(
                                                  item?.homeScore,
                                                  item?.SportId,
                                                )}
                                        </Box>
                                        <Box className="f-white points ft-txt">
                                          {item?.homeTeamName}
                                        </Box>
                                      </span>
                                    </Box>
                                  ) : (
                                    <Box>-</Box>
                                  )}
                                </Box>
                              )}
                              {item?.SportId === 7 ? (
                                item?.homeScore ||
                                item?.Epr === 1 ||
                                item?.Epr === 2 ? (
                                  <>
                                    <Box className="w-50">
                                      <Table className="tennis-table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Sets</TableCell>
                                            <TableCell>Games</TableCell>
                                            <TableCell>Points</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            {fetchMobileTennisScore(
                                              item?.awayScore,
                                            )}
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                      <Box className="team-name">
                                        {item?.awayTeamName}
                                      </Box>
                                    </Box>
                                  </>
                                ) : (
                                  <Box>-</Box>
                                )
                              ) : (
                                <Box>
                                  {item?.awayScore ||
                                  item?.Epr === 1 ||
                                  item?.Epr === 2 ? (
                                    <Box className="odds-wrap">
                                      <span>
                                        <Box className="f-white points ft-txt">
                                          {item?.SportId === 4
                                            ? fetchCricketScore(
                                                item,
                                                "awayteam",
                                              )
                                            : item?.SportId === 8
                                              ? fetchSoccerScore(
                                                  item,
                                                  "awayteam",
                                                )
                                              : fetchTeamScore(
                                                  item?.awayScore,
                                                  item?.SportId,
                                                )}
                                        </Box>
                                        <Box className="f-white points ft-txt">
                                          {item?.awayTeamName}
                                        </Box>
                                      </span>
                                    </Box>
                                  ) : (
                                    <Box>-</Box>
                                  )}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {isToggle ? (
                      <Box className="comment-section">
                        <Box className="runner-comment width-86">
                          <Typography className="runner">
                            {" "}
                            {item?.type}:
                          </Typography>
                          <Typography className="runner comment">
                            {item?.comment}
                          </Typography>
                        </Box>
                        <Box className="width-14">
                          <Typography
                            className="edit-comment cursor-pointer"
                            onClick={() =>
                              handleEdit(item?.type, item?.tableId, item)
                            }
                          >
                            <EditIcon />
                            Edit comment
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
              </>
            );
          })
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              <Box
                style={{
                  textAlign: "center",
                  border: "transparent",
                  fontFamily: "sans-serif",
                }}
              >
                {/* No Data Available */}
                <NoDataComp />
              </Box>
            </Box>
          </>
        )}
        {!isUpcomingLoading && upcomingData && upcomingData?.length > 0 ? (
          <Box className="pagination-section">
            <Pagination
              disabled={upcomingCount / 10 > 1 ? false : true}
              page={upcomingPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              size="small"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <OurPartner />
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handleuserDelete(selectedType, selectedId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <SmartBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        tableId={selectedId}
        dataId={dataId}
        sportId={typeId}
        editComment={editComment}
        isEdit={true}
        tabType="Upcoming"
        listingFunction={() =>
          fetchUpcomingBlackBook(selectedOrg, selectedTeams, upcomingPage)
        }
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
    </>
  );
};

export default UpcomingRunnerV2;
