import React from "react";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { getSportsTitle } from "./newSportsUtils";
import ViewExpertTips from "./viewExpertTips";
import { useLocation } from "react-router-dom";

const SportsHeader = ({
  localesData,
  params,
  expertTipsCount,
  screenWidth,
}) => {
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const urlTouranamentId = Number(searchParamsMenu.get("touranamentId"));
  const urlTouranamentName = decodeURIComponent(
    searchParamsMenu.get("touranamentName"),
  );
  return (
    <>
      <Box className="bredcrumn-wrap">
        <Breadcrumbs
          separator="/"
          aria-label="breadcrumb"
          className="breadcrumb"
        >
          <Link underline="hover" color="inherit" to="/">
            {localesData?.MENU?.HOME}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            to=""
            className="cursor-default"
          >
            {localesData?.MENU?.SPORTS}
          </Link>
          <Typography>{params?.sportsTypeName}</Typography>
        </Breadcrumbs>
      </Box>
      <Box className="title-btn-box">
        <Typography variant="h1">
          {urlTouranamentId === 0
            ? getSportsTitle(params?.sportsTypeName)
            : urlTouranamentName}
        </Typography>
        {screenWidth < 1023 && expertTipsCount > 0 && (
          <Box>
            <ViewExpertTips />
          </Box>
        )}
      </Box>
    </>
  );
};

export default SportsHeader;
