import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import InvertedComma from "../../../assets/images/socSignupPage/invertedComma.png";
import playerAvtar from "../../../assets/images/player-avatar.png";
import { ReactComponent as NextSlide } from "../../../assets/images/icons/slide-next.svg";
import { ReactComponent as PrvSlide } from "../../../assets/images/icons/slide-prv.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";
import SubractIcon from "./subractIcon";

const SOCTestimonials = () => {
  const carouselRef = useRef(null);
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialLoading, setTestimonialsLoading] = useState(false);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    setTestimonialsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `testimonials?limit=&offset=`,
      );
      if (status === 200) {
        setTestimonials(data?.result);
        setTestimonialsLoading(false);
      } else {
        setTestimonialsLoading(false);
      }
    } catch (err) {
      setTestimonialsLoading(false);
    }
  };

  const setting = {
    infinite: testimonials?.length > 1,
    speed: 500,
    slidesToShow:
      testimonials?.length >= 3
        ? 3
        : testimonials?.length >= 2
          ? 2
          : testimonials?.length >= 1
            ? 1
            : 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: true,
    arrows: true,
    prevArrow: (
      <Box className="slick-prev">
        <Button className="slide-button prev-slide-btn">
          {" "}
          <PrvSlide height="28px" width="28px" />
        </Button>
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <Button className="slide-button next-slide-btn">
          {" "}
          <NextSlide height="28px" width="28px" />
        </Button>
      </Box>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: testimonials?.length > 2,
          slidesToShow:
            testimonials?.length >= 2 ? 2 : testimonials?.length >= 1 ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 799,
        settings: {
          infinite: testimonials?.length > 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const handlePrevClick = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.slickPrev();
  //   }
  // };

  // const handleNextClick = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.slickNext();
  //   }
  // };
  return (
    <>
      <Box className="soc-testimonial-wrap-section">
        <Box className="soc-testimonial-container">
          <Box className="soc-testimonial-wrap">
            <Typography className="testimonial-header">Testimonials</Typography>
            <Typography className="testimonial-details">
              Check out what our users are saying about us.
            </Typography>
            {testimonialLoading ? (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            ) : testimonials?.length > 0 ? (
              <Box className="testimonial-slider-wrap">
                <Slider ref={carouselRef} {...setting}>
                  {testimonials?.map((item, index) => {
                    return (
                      <>
                        <Box key={index} className="testimonial-details-data">
                          <Box className="testimonial-details-wrap">
                            <Box className="inverted-comma">
                              <img src={InvertedComma} alt="in" />
                            </Box>

                            <Typography className="testimonial-descriptions">
                              {item?.descriptions}
                            </Typography>
                          </Box>

                          <Box
                            style={{
                              display: "flex",
                            }}
                          >
                            <Box className="testimonial-user-details">
                              <Box className="user-img">
                                <img
                                  src={
                                    item?.Media
                                      ? item?.Media?.filePath?.includes(
                                          "uploads",
                                        )
                                        ? Config.mediaURL +
                                          item?.Media?.filePath
                                        : item?.Media?.filePath
                                      : playerAvtar
                                  }
                                  onError={(e) => (e.target.src = playerAvtar)}
                                  alt="in"
                                />
                              </Box>
                              <Box className="user-details-wrap">
                                <Typography className="user-name">
                                  {item?.firstname + " " + item?.lastname}
                                </Typography>
                                <Typography className="user-designation">
                                  {item?.role}
                                </Typography>
                              </Box>
                              <SubractIcon
                                fill={"#fff"}
                                className="white-curve-1"
                              />
                            </Box>
                            <Box style={{ width: "30%" }} className="blank-box">
                              <SubractIcon fill={"#e7e9ec"} className="curve" />
                              <SubractIcon
                                fill={"#fff"}
                                className="white-curve-2"
                              />
                            </Box>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </Slider>
                {/* <Box className="testimonial-arrow-section">
                  <Button
                    onClick={handlePrevClick}
                    className="slide-button prev-slide-btn"
                  >
                    {" "}
                    <PrvSlide height="28px" width="28px" />
                  </Button>
                  <Button
                    onClick={handleNextClick}
                    className="slide-button next-slide-btn"
                  >
                    {" "}
                    <NextSlide height="28px" width="28px" />
                  </Button>
                </Box> */}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SOCTestimonials;
