import React, { useEffect, useState } from "react";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useLocation, useParams } from "react-router";
import { SPORTS_TYPE } from "../teamCommonComponent/newSportsUtils";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const SeasonListSelect = ({
  selectOption,
  setSelectOption,
  selectedValue,
  setSelectedValue,
  handleSelectedValueChange,
  selectedLeague,
}) => {
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const urlTouranamentId = Number(searchParamsMenu.get("touranamentId"));
  const sportsId = Number(params?.sportsId);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedLeague) {
      fetchSeasonList(selectedLeague);
    }
  }, [selectedLeague, urlTouranamentId]);

  const fetchSeasonList = async (tId) => {
    setLoading(true);
    const isLeaderBoard =
      sportsId === SPORTS_TYPE.rugbyLeague ||
      sportsId === SPORTS_TYPE.australianrules;
    const passApi = `allsport/season?SportId=${params?.sportsId}&tournamentId=${tId}${isLeaderBoard ? "&leaderboard=true" : ""}`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        const latest = data?.result?.rows?.sort((a, b) => b?.year - a?.year);
        const leagueData = latest?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setSelectOption(leagueData);
        setSelectedValue(leagueData?.[0]?.value);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  return (
    <>
      <Select
        className="React league-select"
        value={
          selectedValue
            ? selectOption?.find((item) => {
                return item?.value === selectedValue;
              })
            : null
        }
        onChange={(e) => {
          handleSelectedValueChange(e);
        }}
        options={selectOption}
        classNamePrefix="select"
        placeholder="Season"
        isSearchable={true}
        isLoading={loading}
        components={{ DropdownIndicator }}
      />
    </>
  );
};

export default SeasonListSelect;
