import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Tweet } from "react-twitter-widgets";
import he from "he";
import "./DynamicNewsScript.scss";

const DynamicNewsScript = ({ newsContent }) => {
  const [parsedContent, setParsedContent] = useState([]);

  const extractTweetId = (text) => {
    const patterns = [
      /twitter\.com\/\w+\/status\/(\d+)/,
      /\/status\/(\d+)/,
      /elonmusk\/(\d+)/,
      /status\/(\d+)\?/,
      /PicturesFoIder\/status\/(\d+)/,
    ];

    for (const pattern of patterns) {
      const match = text.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }
    return null;
  };

  const findTweetId = (node) => {
    // Look for tweet ID in links
    const links = [];
    const findLinks = (element) => {
      if (
        element.name === "a" &&
        element.attribs?.href?.includes("twitter.com")
      ) {
        links.push(element.attribs.href);
      }
      if (element.children) {
        element.children.forEach((child) => {
          if (child.type === "tag") {
            findLinks(child);
          }
        });
      }
    };

    findLinks(node);

    for (const link of links) {
      const tweetId = extractTweetId(link);
      if (tweetId) return tweetId;
    }

    return null;
  };

  const transform = (node, index) => {
    if (node.type === "tag") {
      // Handle blockquotes (both direct and within spans)
      if (
        node.name === "blockquote" ||
        (node.name === "span" &&
          node.children?.some(
            (child) => child.type === "tag" && child.name === "blockquote",
          ))
      ) {
        let tweetId = null;

        // If it's a span, look for blockquote inside
        if (node.name === "span") {
          const blockquote = node.children.find(
            (child) => child.type === "tag" && child.name === "blockquote",
          );
          if (blockquote) {
            tweetId = findTweetId(blockquote);
          }
        } else {
          // Direct blockquote
          tweetId = findTweetId(node);
        }

        if (tweetId) {
          return (
            <div key={index} className="tweet-embed-container">
              <Tweet
                tweetId={tweetId}
                options={{
                  align: "center",
                  width: "550",
                  conversation: "none",
                  theme: "light",
                  dnt: true,
                }}
              />
            </div>
          );
        }
      }

      // Handle Spotify iframe
      if (
        node.name === "iframe" &&
        node.attribs?.src?.includes("spotify.com")
      ) {
        return (
          <div key={index} className="spotify-embed-container">
            <iframe
              src={node.attribs.src}
              width="100%"
              height="352"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
              style={{ borderRadius: "12px" }}
            />
          </div>
        );
      }

      // Handle regular iframes
      if (node.name === "iframe") {
        return (
          <div key={index} className="iframe-container">
            <iframe
              {...node.attribs}
              width="100%"
              height="315"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        );
      }

      // Handle images
      if (node.name === "img") {
        return (
          <div key={index} className="image-container">
            <img
              src={node.attribs.src}
              alt={node.attribs.alt || ""}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        );
      }
    }
  };

  useEffect(() => {
    // First decode the HTML entities
    const decodedHtml = he.decode(newsContent);

    const options = {
      replace: transform,
    };

    const parsed = parse(decodedHtml, options);
    setParsedContent(parsed);
  }, [newsContent]);

  return <div className="dynamic-news-content">{parsedContent}</div>;
};

export default DynamicNewsScript;
