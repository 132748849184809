import { useMemo } from "react";
import {
  NRLPlayerStaticColumn,
  NRLTeamStaticColumn,
} from "../Columns/Stats/NRL";
import {
  BattingStaticStatsColumn,
  BowlingStaticStatsColumn,
} from "../Columns/Stats/Cricket";
import { AFLStaticStatsColumns } from "../Columns/Stats/AFL";
import { usePlayerStats } from "src/helpers/context/PlayersStats/PlayerStatsContext";
import { columnCreator } from "../Columns/Stats/Cricket/columnCreator";
import { viwTipLadderStaticColumns } from "../Columns/Tipping/Ladder";

const useCloumn = () => {
  const { playerStats } = usePlayerStats();
  const NRLTeamStatsColumns = useMemo(() => NRLTeamStaticColumn, []);
  const NRLPlayerStatsColumns = useMemo(() => NRLPlayerStaticColumn, []);
  const CricketPlayerBattingStatsColum = useMemo(
    () => BattingStaticStatsColumn,
    [],
  );
  const CricketPlayerBowlingStatsColum = useMemo(
    () => BowlingStaticStatsColumn,
    [],
  );

  const cricketColumn = useMemo(
    () => columnCreator(playerStats?.headers ?? []),
    [playerStats?.headers],
  );
  const AFLStatsColumns = useMemo(() => AFLStaticStatsColumns, []);
  return {
    NRLTeamStatsColumns,
    NRLPlayerStatsColumns,
    AFLStatsColumns,
    CricketPlayerBattingStatsColum,
    CricketPlayerBowlingStatsColum,
    cricketColumn,
  };
};

export default useCloumn;
