import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      id="Group_114578"
      data-name="Group 114578"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <rect
        id="Rectangle_2967"
        data-name="Rectangle 2967"
        width="36"
        height="36"
        rx="18"
        fill="#fee8e1"
      />
      <g
        id="Group_24052"
        data-name="Group 24052"
        transform="translate(8.553 8.326)"
      >
        <path
          id="Path_12113"
          data-name="Path 12113"
          d="M4,6H19.512L17.98,19.787a1.939,1.939,0,0,1-1.927,1.726H7.459a1.939,1.939,0,0,1-1.927-1.726Z"
          transform="translate(-2.061 -2.122)"
          fill="none"
          stroke="#d84727"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_12114"
          data-name="Path 12114"
          d="M7.3,3.112A1.939,1.939,0,0,1,9.058,2h5.518a1.939,1.939,0,0,1,1.755,1.112l1.3,2.766H6Z"
          transform="translate(-2.122 -2)"
          fill="none"
          stroke="#d84727"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_12115"
          data-name="Path 12115"
          d="M2,6H21.39"
          transform="translate(-2 -2.122)"
          fill="none"
          stroke="#d84727"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_12116"
          data-name="Path 12116"
          d="M10,11v4.848"
          transform="translate(-2.244 -2.274)"
          fill="none"
          stroke="#d84727"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_12117"
          data-name="Path 12117"
          d="M14,11v4.848"
          transform="translate(-2.366 -2.274)"
          fill="none"
          stroke="#d84727"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
