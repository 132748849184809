import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Tooltip } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FeaturedNews1 from "../../../assets/images/featuredNews1.jpg";
import FeaturedNews2 from "../../../assets/images/featuredNews2.jpg";
import DefaultImg from "../../../assets/images/smartb_default.png";
import SliderDummy from "../../../assets/images/slider_dummy_img.png";
import { IntlContext } from "src/App";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosInstance from "../../../helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { release } from "src/helpers/context/release";
import { Config, fetchFromStorage } from "src/helpers/context";
import he from "he";
import { ReactComponent as BookmarkIcon } from "src/assets/images/icons/bookmark-icon.svg";
import { ReactComponent as ChatIcon } from "src/assets/images/icons/chat-icon.svg";
import { GettyEmbed, setApiMessage } from "src/helpers/commonFunctions";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

const FeaturedNews = ({ newsType, tag, teamSportType }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const [featureNewsData, setFeatureNewsData] = useState([]);
  const [featureNewsLoader, setFeatureNewsLoader] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  let searchParamsType = query.get("type");

  const settingsNews = {
    infinite: featureNewsData?.length > 1,
    speed: 500,
    slidesToShow: featureNewsData?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: featureNewsData?.length > 1,
    // adaptiveHeight: true,
    beforeChange: (current, next) => {
      handleBeforeChange();
    },
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 799,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  useEffect(() => {
    fetchFeaturedNews();
  }, [searchParamsType]);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const SportId =
    teamSportType === "cricket"
      ? 4
      : teamSportType === "rugbyleague"
        ? 12
        : teamSportType === "rugbyunion"
          ? 13
          : teamSportType === "basketball"
            ? 10
            : teamSportType === "americanfootball"
              ? 15
              : teamSportType === "australianrules"
                ? 9
                : teamSportType === "baseball"
                  ? 11
                  : teamSportType === "boxing"
                    ? 6
                    : teamSportType === "icehockey"
                      ? 17
                      : teamSportType === "mma"
                        ? 5
                        : teamSportType === "soccer"
                          ? 8
                          : teamSportType === "tennis"
                            ? 7
                            : teamSportType === "golf"
                              ? 16
                              : teamSportType === "racing"
                                ? searchParamsType
                                  ? searchParamsType
                                  : [1, 2, 3]
                                : "";
  const fetchFeaturedNews = async () => {
    setFeatureNewsLoader(true);
    const allSportId = [
      ...release[Config.release]?.sportId,
      ...release[Config.release]?.raceSportId,
    ];
    try {
      const passApi = teamSportType
        ? `/v2/news/feature/articles?SportId=${SportId}&tag=${
            tag ? tag.trim() : ""
          }`
        : `/v2/news/feature/articles?SportId=${
            Config.release == "IN" ? allSportId?.toString() : ""
          }`;
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setFeatureNewsLoader(false);
        const articalData = data?.result;
        setFeatureNewsData(articalData);
      }
    } catch (error) {
      setFeatureNewsLoader(false);
    }
  };

  const fetchNewsTime = (newsdate) => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours == 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes == 1 ? "minute" : "minutes"
      } ago`;
    }
  };

  const handleNavigate = useCallback(
    (item, event) => {
      const url = window?.location?.origin;

      if (!dragging) {
        if (event.ctrlKey || event.metaKey) {
          window.open(
            url +
              `/smartinfo/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticle?.id}`,
            "_blank",
          );
        } else {
          navigate(
            `/smartinfo/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticle?.id}`,
          );
        }
      }
    },
    [dragging],
  );
  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const fetchNewsSubtitle = (data) => {
    const parseHTMLContent = (body) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = (content) => {
      return he.decode(content);
    };
    const removeHtmlTags = (htmlString) => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = (content) => {
      return (
        <Typography className="sub-details">
          <span
            dangerouslySetInnerHTML={{
              __html:
                screenWidth >= 1023
                  ? content?.slice(0, 100)
                  : content?.slice(0, 50),
            }}
          ></span>
          {/* {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)} */}
          {content && <span className="read-more"> ...read more</span>}
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        (item) =>
          item?.type === "editor_block" && item?.data?.type === "paragraph",
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };

  const handleNewsSave = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    if (handleRestrictedUser()) {
      let payload = {
        status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted",
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/saved/${item?.NewsArticleId}`,
          payload,
        );
        if (status === 200) {
          setApiMessage(
            "success",
            item?.NewsArticle?.isSaved === 0
              ? "News successfully saved!"
              : "News removed from saved!",
          );

          const updateNews = featureNewsData?.map((article) =>
            article.NewsArticleId === item?.NewsArticleId
              ? {
                  ...article,
                  NewsArticle: {
                    ...article.NewsArticle,
                    isSaved: item?.NewsArticle?.isSaved === 0 ? 1 : 0,
                  },
                }
              : article,
          );
          setFeatureNewsData(updateNews);
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      setApiInfo({
        api: `/v2/news/saved/${item?.NewsArticleId}`,
        payload: {
          status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted",
        },
        method: "post",
      });
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  return (
    <>
      {featureNewsLoader ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : featureNewsData?.length > 0 ? (
        <Box className="racing-slider-wrap news-slider-wrap">
          <Box>
            <Typography variant="h3" className="home-slider-header">
              {localesData?.HOME?.FEATURED_NEWS}
            </Typography>

            <Slider
              {...settingsNews}
              className="racing-slick-slider"
              afterChange={handleAfterChange}
            >
              {featureNewsData?.map((item, index) => (
                <Box
                  key={index}
                  className="single-silder news-single-slider"
                  // onClick={e => handleNavigate(item, e)}
                >
                  <NavLink
                    to={`/smartinfo/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticle?.id}`}
                  >
                    <Box
                      className="background-overlay"
                      style={{
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url('${
                          item?.NewsArticle?.mainMedia?.gallery
                            ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.gallery?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.gallery?.url
                              : DefaultImg
                            : item?.NewsArticle?.mainMedia?.[0]?.gallery
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                  ?.url &&
                                item?.NewsArticle?.mainMedia?.[0]?.gallery
                                  ?.url !== ""
                                ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ?.url
                                : DefaultImg
                              : DefaultImg
                        }')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                        filter: "blur(10px)",
                        zIndex: -1,
                        borderRadius: "8px",
                      }}
                    ></Box>
                    <Box className="slider-img featured-news-img">
                      {item?.NewsArticle?.embeddedImageStatus ? (
                        <GettyEmbed
                          embedCode={item?.NewsArticle?.embeddedImage}
                          className={"single-news-flex-center"}
                        />
                      ) : (
                        <img
                          src={
                            item?.NewsArticle?.mainMedia?.gallery
                              ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                                item?.NewsArticle?.mainMedia?.gallery?.url !==
                                  ""
                                ? item?.NewsArticle?.mainMedia?.gallery?.url
                                : DefaultImg
                              : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ?.url &&
                                  item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ?.url !== ""
                                  ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url
                                  : DefaultImg
                                : DefaultImg
                          }
                          alt="slider-img"
                        />
                      )}
                    </Box>

                    <Box className="details-back">
                      <span className="news-tag">
                        {item?.NewsArticle?.NewsCategory?.initialTitle}
                      </span>
                      <Box className="slider-news-deatils">
                        <Typography
                          className="title-news"
                          dangerouslySetInnerHTML={{
                            __html: item?.NewsArticle?.title
                              ? item?.NewsArticle?.title
                              : item?.NewsArticle?.subTitle,
                          }}
                        ></Typography>
                        <Box className="sub-details-wrap">
                          {fetchNewsSubtitle(item?.NewsArticle)}
                        </Box>
                        {/* <Typography>
                          {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                        </Typography> */}
                        <Box className="news-time-comment-wrapper">
                          <Typography className="news-time">
                            {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                          </Typography>
                          <Box className="bookmark-chat-wrapper">
                            <Box onClick={(e) => handleNewsSave(e, item)}>
                              <Tooltip
                                placement="bottom"
                                arrow
                                title='Save to "Saved articles"'
                                classes={{
                                  tooltip: "news-tooltip",
                                }}
                              >
                                <BookmarkIcon
                                  className={
                                    item?.NewsArticle?.isSaved === 0
                                      ? "unsaved-bookmark-icon"
                                      : "saved-bookmark-icon"
                                  }
                                />
                              </Tooltip>
                            </Box>
                            <Tooltip
                              placement="bottom"
                              arrow
                              title="Comments"
                              classes={{
                                tooltip: "news-tooltip",
                              }}
                            >
                              <Box className="chat-details">
                                <ChatIcon />
                                <Typography className="news-time">
                                  {item?.NewsArticle?.totalComments}
                                </Typography>
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </NavLink>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default FeaturedNews;
