import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { useParams } from "react-router-dom";
import DefaultImg from "../../../assets/images/smartb_default.png";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { Config } from "src/helpers/context";
import { useSelector } from "react-redux";
import AdBannner from "../AdBanner/AdBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";

const newTabData = [
  {
    id: 0,
    name: "Stats for this comp",
  },
  { id: 1, name: "Stats for all comps" },
];

const TippingRightSideBar = ({
  allEventData,
  isLoading,
  stepperCount,
  eventByIdData,
}) => {
  const params = useParams();
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [AdData, setAdData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [tabvalue, setTabValue] = useState(0);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    fetchAdsData();
  }, []);
  const fetchAdsData = async () => {
    try {
      const passPageId = 61;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${passPageId}&type=web&timeZone=${timezone}`,
      );
      if (status === 200) {
        setAdData(data?.result);
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.position_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const TeamStats = (percentage, type) => {
    const width = percentage;
    return (
      <>
        <Box style={{ width: "50%", display: "flex" }}>
          <Box
            className="progress-bar"
            style={{
              width: `${type === "homeTeam" ? 100 - width : width}%`,
              background: percentage
                ? type === "homeTeam"
                  ? "#D4D6D8"
                  : "#003764"
                : "#D4D6D8",
            }}
          ></Box>
          <Box
            className="progress-bar"
            style={{
              width: `${type === "homeTeam" ? width : 100 - width}%`,
              background: percentage
                ? type === "homeTeam"
                  ? "#003764"
                  : "#D4D6D8"
                : "#D4D6D8",
            }}
          ></Box>
        </Box>
      </>
    );
  };

  const handleTabChange = (e, value) => {
    setTabValue(value);
  };

  return (
    <>
      <Box className="tipping-right-details">
        <Box className="ads-container">
          <Box className="ads-wrap">
            {/* <img src={DefaultImg} /> */}
            {fetchAds(1, "330px", DefaultImg)}
          </Box>
        </Box>
        {!isLoading && allEventData?.length > 0 && (
          <Box className="teams-details-wrap">
            <Typography className="round-stats-header">
              Round {stepperCount} Tipping Stats
            </Typography>
            <Box className="rightbar-tab-section">
              <Box className="team-sports-tab">
                <Tabs
                  value={tabvalue}
                  variant="scrollable"
                  scrollButtons={false}
                  indicatorColor="primary"
                  textColor="primary"
                  className="rightbar-tab-detail"
                >
                  {newTabData?.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Tab
                          label={item?.name}
                          value={item?.id}
                          className={item?.id === tabvalue ? "active " : ""}
                          onChange={(event, newValue) =>
                            handleTabChange(event, item?.id)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
            </Box>
            <Box className="team-stats">
              {allEventData?.map((item) => {
                const homeTeamCount =
                  tabvalue === 1
                    ? item?.homeTeam?.allTipCount
                    : item?.homeTeam?.TipCount;
                const awayTeamCount =
                  tabvalue === 1
                    ? item?.awayTeam?.allTipCount
                    : item?.awayTeam?.TipCount;
                const totalTip = homeTeamCount + awayTeamCount;
                const homePercentageCount =
                  totalTip !== 0
                    ? ((homeTeamCount / totalTip) * 100).toFixed(0)
                    : 0;
                const awayPercentageCount =
                  totalTip !== 0
                    ? ((awayTeamCount / totalTip) * 100).toFixed(0)
                    : 0;
                return (
                  <Box className="team-stats-card">
                    <Box className="teams-details">
                      <Box className="teams-wrap">
                        <Box
                          className={`${params?.SportId === "9" && params?.compsId === "57" ? "img-blur" : ""} flag-wrap`}
                        >
                          {" "}
                          <img
                            src={
                              item?.homeTeam?.flag
                                ? Config?.mediaURL + item?.homeTeam?.flag
                                : Melbourne
                            }
                            alt="team"
                          />
                        </Box>
                        <Box className="team-name">{item?.homeTeam?.name}</Box>
                      </Box>
                      <Box className="teams-wrap away-team-wrap">
                        <Box
                          className={`${params?.SportId === "9" && params?.compsId === "57" ? "img-blur" : ""} flag-wrap`}
                        >
                          {" "}
                          <img
                            src={
                              item?.awayTeam?.flag
                                ? Config?.mediaURL + item?.awayTeam?.flag
                                : Brisbane
                            }
                            alt="team"
                          />
                        </Box>
                        <Box className="team-name">{item?.awayTeam?.name}</Box>
                      </Box>
                    </Box>
                    <Box className="stats-progress-bar">
                      {TeamStats(homePercentageCount, "homeTeam")}
                      {TeamStats(awayPercentageCount, "awayTeam")}
                    </Box>
                    <Box className="progress-count-wrap">
                      <span className="progress-count">
                        {totalTip && totalTip !== 0
                          ? homePercentageCount + "%"
                          : ""}
                      </span>
                      <span className="progress-count">
                        {totalTip && totalTip !== 0
                          ? awayPercentageCount + "%"
                          : ""}
                      </span>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TippingRightSideBar;
