import React from "react";
import Page from "src/components/Page";
import InstallAPK from "src/views/component/installAPK";

const InstallApk = () => {
  return (
    <Page title="InstallApk">
      <InstallAPK />
    </Page>
  );
};

export default InstallApk;
