import React from "react";
import Page from "src/components/Page";
import Payment from "src/views/component/payment";

const PaymentPage = () => {
  return (
    <Page title="Payment">
      <Payment />
    </Page>
  );
};

export default PaymentPage;
