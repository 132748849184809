import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import moment from "moment";
import StatsLegend from "./statsLegend";

const stats = [
  { label: "M", text: "Matches played", column: 1 },
  { label: "W", text: "Wins", column: 1 },
  { label: "L", text: "Lost", column: 1 },
  { label: "T", text: "Ties", column: 1 },
  { label: "N/R", text: "No Results", column: 1 },
  { label: "NRR", text: "Net Run Rate", column: 2 },
  { label: "Pts", text: "Points", column: 2 },
];

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    backgroundColor: "#ffffff",
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    backgroundColor: "#ffffff",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

const RankingsLandingTable = ({ selectedLeague, selectedSeason }) => {
  const classes = useStyles();
  const [rankingsLandingList, setRankingsLandingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openRow, setOpenRow] = useState(null);
  const [sortingType, setSortingType] = useState({
    rankShort: null,
    teamShort: null,
    MShort: null,
    WShort: null,
    LShort: null,
    TShort: null,
    NRShort: null,
    NRRShort: null,
    pointShort: null,
  });

  useEffect(() => {
    if (selectedLeague) {
      fetchRankingsLandingList(selectedLeague, selectedSeason);
    }
  }, [selectedLeague, selectedSeason]);

  const fetchRankingsLandingList = async (tId, sId) => {
    setLoading(true);
    const passApi = `sports/statistics/cricket/table/tournament/${tId}?seasonId=${sId ? sId : ""}&eventList=true`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        const sortRank = data?.result?.result?.sort((a, b) => a?.rnk - b?.rnk);
        const groupedData = _.groupBy(sortRank, "name");

        // Sort keys alphabetically
        const sortedData = Object.keys(groupedData)
          .sort() // Sort keys alphabetically
          .reduce((sortedObj, key) => {
            sortedObj[key] = groupedData[key]; // Use groupedData to access the values
            return sortedObj;
          }, {});

        setRankingsLandingList(sortedData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleSorting = (field, isShorting, group) => {
    // Sort only the selected group
    const sortedData = { ...rankingsLandingList };

    sortedData[group] = [...rankingsLandingList[group]]; // Copy the specific group

    if (
      field === "rnk" ||
      field === "pld" ||
      field === "win" ||
      field === "lstn" ||
      field === "drw" ||
      field === "nr" ||
      field === "nrr" ||
      field === "pts"
    ) {
      sortedData[group].sort((a, b) => {
        if (isShorting === null || isShorting === false) {
          return a?.[field] - b?.[field];
        } else {
          return b?.[field] - a?.[field];
        }
      });
    } else if (field === "Tnm") {
      sortedData[group].sort((a, b) => {
        if (isShorting === null || isShorting === false) {
          return a?.Tnm.localeCompare(b?.Tnm);
        } else {
          return b?.Tnm.localeCompare(a?.Tnm);
        }
      });
    }

    setRankingsLandingList(sortedData);

    // Update sorting state for the specific group
    setSortingType({
      ...sortingType,
      [group]: {
        rankShort: field === "rnk" ? !isShorting : null,
        teamShort: field === "Tnm" ? !isShorting : null,
        MShort: field === "pld" ? !isShorting : null,
        WShort: field === "win" ? !isShorting : null,
        LShort: field === "lstn" ? !isShorting : null,
        TShort: field === "drw" ? !isShorting : null,
        NRShort: field === "nr" ? !isShorting : null,
        NRRShort: field === "nrr" ? !isShorting : null,
        pointShort: field === "pts" ? !isShorting : null,
      },
    });
  };

  const toggleRow = (id) => {
    setOpenRow(openRow === id ? null : id);
  };

  const winLossStatus = (item) => {
    if (!item?.eventList) return null;

    const getColor = (wlarr) =>
      (item?.teamId === wlarr?.homeTeamId && wlarr?.winnerCode === 1) ||
      (item?.teamId === wlarr?.awayTeamId && wlarr?.winnerCode === 2)
        ? "#1C9A6C"
        : "#D84727";

    const getStatus = (wlarr) =>
      (item?.teamId === wlarr?.homeTeamId && wlarr?.winnerCode === 1) ||
      (item?.teamId === wlarr?.awayTeamId && wlarr?.winnerCode === 2)
        ? "W"
        : "L";

    const winingStatusArray = [...item?.eventList]
      .sort((a, b) => new Date(b?.startTime) - new Date(a?.startTime))
      .slice(0, 5);

    return (
      <Box className="win-loss-status">
        {winingStatusArray?.map(
          (wlarr, index) =>
            wlarr?.winnerCode !== null && (
              <span key={index} style={{ backgroundColor: getColor(wlarr) }}>
                {getStatus(wlarr)}
              </span>
            ),
        )}
      </Box>
    );
  };

  const opponentTeamName = (item, detail) => {
    const opponentName =
      item?.teamId === detail?.homeTeamId
        ? detail?.awayTeam?.name
        : detail?.homeTeam?.name;
    return opponentName;
  };

  const getDescription = (item, detail) => {
    if (!detail) return null;

    const { winnerCode, homeTeamId, awayTeamId, ScoreBoard } = detail;
    const isWin =
      (item?.teamId === homeTeamId && winnerCode === 1) ||
      (item?.teamId === awayTeamId && winnerCode === 2);

    return (
      <Box className="description-status">
        {winnerCode !== null && (
          <span
            className="win-indicator-status"
            style={{ backgroundColor: isWin ? "#1C9A6C" : "#D84727" }}
          >
            {isWin ? "W" : "L"}
          </span>
        )}
        {ScoreBoard?.ECo}
      </Box>
    );
  };

  const formatTeamScore = (teamScore, prefix) => {
    if (!teamScore) return "-";

    const firstInnings = `${teamScore?.[`${prefix}C1`] ?? "-"}${
      teamScore?.[`${prefix}CW1`] === 10
        ? ""
        : teamScore?.[`${prefix}CW1`] || teamScore?.[`${prefix}CW1`] === 0
          ? `/${teamScore?.[`${prefix}CW1`]}${
              teamScore?.[`${prefix}CD1`] === 1 ? "d" : ""
            }`
          : ""
    }`;

    const secondInnings =
      teamScore?.[`${prefix}C2`] || teamScore?.[`${prefix}C2`] === 0
        ? `& ${teamScore?.[`${prefix}C2`]}${
            teamScore?.[`${prefix}CW2`] === 10
              ? ""
              : teamScore?.[`${prefix}CW2`] || teamScore?.[`${prefix}CW2`] === 0
                ? `/${teamScore?.[`${prefix}CW2`]}${
                    teamScore?.[`${prefix}CD2`] === 1 ? "d" : ""
                  }`
                : ""
          }`
        : "";

    const otherScore = teamScore?.[`${prefix}CO1`]
      ? `(${teamScore?.[`${prefix}CO1`]})`
      : "";

    return teamScore?.Exd > "1" ? (
      <span className="team-score">
        {firstInnings} {secondInnings}
      </span>
    ) : (
      <span className="team-score">
        {firstInnings} <span>{otherScore}</span>
      </span>
    );
  };

  const getTeamScores = (detail) => {
    const teamScore = detail?.ScoreBoard;

    return (
      <>
        {[
          { team: "homeTeam", prefix: "Tr1" },
          { team: "awayTeam", prefix: "Tr2" },
        ].map(({ team, prefix }) => (
          <Typography key={team} className="team-score">
            {detail?.[team]?.name} - {formatTeamScore(teamScore, prefix)}
          </Typography>
        ))}
      </>
    );
  };

  const renderInnerTable = (item) => {
    let finalData = item?.eventList?.sort(
      (a, b) => new Date(b?.startTime) - new Date(a?.startTime),
    );
    return (
      <>
        <TableRow className="inner-table-row">
          <TableCell colSpan={10} sx={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={openRow === item?.id} timeout="auto" unmountOnExit>
              <Table className="inner-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Opponent</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Scores</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finalData?.map((detail, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        {moment(detail?.startTime).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{opponentTeamName(item, detail)}</TableCell>
                      <TableCell>{detail.ScoreBoard?.ErnInf}</TableCell>
                      <TableCell>{getDescription(item, detail)}</TableCell>
                      <TableCell>{getTeamScores(detail)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const SortIcons = ({ isSorted }) => (
    <Box className="sort-icon-wrap">
      {/* Ascending Arrow */}
      <span>
        <SortingArrow className={isSorted === true ? "asc-sort" : ""} />
      </span>
      {/* Descending Arrow */}
      <span className="down-arrow">
        <SortingArrow className={isSorted === false ? "desc-sort" : ""} />
      </span>
    </Box>
  );
  return (
    <>
      <Box className="new-landing-table-container-details">
        {loading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : Object.keys(rankingsLandingList)?.length > 0 ? (
          Object.entries(rankingsLandingList)?.map(([category, items]) => (
            <TableContainer>
              <Table className="new-landing-landing-table">
                <TableHead>
                  <TableRow>
                    <StickyTableCell className={`${classes.head} rnk-cell`}>
                      <Box
                        className="sorting-cell"
                        onClick={() =>
                          handleSorting(
                            "rnk",
                            sortingType?.[category]?.rankShort,
                            category,
                          )
                        }
                      >
                        #{" "}
                        <SortIcons
                          isSorted={sortingType?.[category]?.rankShort}
                        />
                      </Box>
                    </StickyTableCell>
                    <StickyTableCell
                      className={`${classes.head} team-cell`}
                      align="left"
                    >
                      <Box
                        className="sorting-cell"
                        onClick={() =>
                          handleSorting(
                            "Tnm",
                            sortingType?.[category]?.teamShort,
                            category,
                          )
                        }
                      >
                        {category}{" "}
                        <SortIcons
                          isSorted={sortingType?.[category]?.teamShort}
                        />
                      </Box>
                    </StickyTableCell>
                    <TableCell
                      className={`${classes.head} text-align-center pts-head-cell`}
                      align="center"
                    >
                      <Box
                        className="sorting-cell text-align-center"
                        onClick={() =>
                          handleSorting(
                            "pts",
                            sortingType?.[category]?.pointShort,
                            category,
                          )
                        }
                      >
                        Pts{" "}
                        <SortIcons
                          isSorted={sortingType?.[category]?.pointShort}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={`${classes.head} text-align-center`}
                      align="center"
                    >
                      <Box
                        className="sorting-cell text-align-center"
                        onClick={() =>
                          handleSorting(
                            "pld",
                            sortingType?.[category]?.MShort,
                            category,
                          )
                        }
                      >
                        M{" "}
                        <SortIcons isSorted={sortingType?.[category]?.MShort} />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={`${classes.head} text-align-center`}
                      align="center"
                    >
                      <Box
                        className="sorting-cell text-align-center"
                        onClick={() =>
                          handleSorting(
                            "win",
                            sortingType?.[category]?.WShort,
                            category,
                          )
                        }
                      >
                        W{" "}
                        <SortIcons isSorted={sortingType?.[category]?.WShort} />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={`${classes.head} text-align-center`}
                      align="center"
                    >
                      <Box
                        className="sorting-cell text-align-center"
                        onClick={() =>
                          handleSorting(
                            "lstn",
                            sortingType?.[category]?.LShort,
                            category,
                          )
                        }
                      >
                        L{" "}
                        <SortIcons isSorted={sortingType?.[category]?.LShort} />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={`${classes.head} text-align-center`}
                      align="center"
                    >
                      <Box
                        className="sorting-cell text-align-center"
                        onClick={() =>
                          handleSorting(
                            "drw",
                            sortingType?.[category]?.TShort,
                            category,
                          )
                        }
                      >
                        T{" "}
                        <SortIcons isSorted={sortingType?.[category]?.TShort} />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={`${classes.head} text-align-center`}
                      align="center"
                    >
                      <Box
                        className="sorting-cell text-align-center"
                        onClick={() =>
                          handleSorting(
                            "nr",
                            sortingType?.[category]?.NRShort,
                            category,
                          )
                        }
                      >
                        NR{" "}
                        <SortIcons
                          isSorted={sortingType?.[category]?.NRShort}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={`${classes.head} text-align-center`}
                      align="center"
                    >
                      <Box
                        className="sorting-cell text-align-center"
                        onClick={() =>
                          handleSorting(
                            "nrr",
                            sortingType?.[category]?.NRRShort,
                            category,
                          )
                        }
                      >
                        NRR{" "}
                        <SortIcons
                          isSorted={sortingType?.[category]?.NRRShort}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={`${classes.head} text-align-center`}
                      align="center"
                    >
                      Form{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <div className="allsport-loader-center ">
                          <Loader />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && items?.length > 0
                    ? items?.map((item, index) => {
                        return (
                          <>
                            <TableRow
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#FFFFFF" : "#F7F7F8",
                              }}
                            >
                              <StickyTableCell
                                className={`${classes.cell} rnk-cell`}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#FFFFFF" : "#F7F7F8",
                                }}
                              >
                                {item?.rnk}
                              </StickyTableCell>
                              <StickyTableCell
                                className={`${classes.cell} team-cell`}
                                align="left"
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#FFFFFF" : "#F7F7F8",
                                }}
                              >
                                <Box className="team-wrap">
                                  <Box className="team-image-wrap">
                                    <img
                                      src={
                                        item?.team?.flag
                                          ? item?.team?.flag?.includes(
                                              "uploads",
                                            )
                                            ? Config.mediaURL + item?.team?.flag
                                            : item?.team?.flag
                                          : DefaultTeam
                                      }
                                      onError={(e) => {
                                        e.target.src = DefaultTeam;
                                      }}
                                      alt="Team 1"
                                      className="team-image"
                                    />
                                  </Box>
                                  <Box className="team-name-wrap">
                                    <Typography className="team-name">
                                      {item?.Tnm}
                                    </Typography>
                                  </Box>
                                </Box>
                              </StickyTableCell>
                              <TableCell
                                className={`${classes.cell} pts-body-cell`}
                                align="center"
                              >
                                {item?.pts}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.pld}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.win}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.lstn}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.drw}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.nr}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.nrr}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                <Box className="form-cell-details">
                                  {winLossStatus(item)}
                                  <Box>
                                    {item?.eventList?.length > 0 && (
                                      <IconButton
                                        onClick={() => toggleRow(item?.id)}
                                      >
                                        {openRow === item?.id ? (
                                          <KeyboardArrowUp />
                                        ) : (
                                          <KeyboardArrowDown />
                                        )}
                                      </IconButton>
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>

                            {openRow === item?.id &&
                              item?.eventList?.length > 0 &&
                              renderInnerTable(item)}
                          </>
                        );
                      })
                    : !loading && (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <div className="allsport-loader-center ">
                              No Data Available
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                </TableBody>
              </Table>
            </TableContainer>
          ))
        ) : (
          <div className="allsport-loader-center ">No Data Available</div>
        )}
      </Box>
      <Box>
        <StatsLegend stats={stats} />
      </Box>
    </>
  );
};

export default RankingsLandingTable;
