export const AUTH_USER = "AUTH_USER";
export const SET_UPCOMING_RACE = "SET_UPCOMING_RACE";
export const SET_INTERVAL = "SET_INTERVAL";
export const ADVERTISING_SCREEN_DATA = "ADVERTISING_SCREEN_DATA";
export const ADS_DATA = "ADS_DATA";
export const NEWS_RELATED = "NEWS_RELATED";
export const AF_DATA = "AF_DATA";
export const AR_DATA = "AR_DATA";
export const BASEBALL_DATA = "BASEBALL_DATA";
export const BASKETBALL_DATA = "BASKETBALL_DATA";
export const BOXING_DATA = "BOXING_DATA";
export const CRICKET_DATA = "CRICKET_DATA";
export const GOLF_DATA = "GOLF_DATA";
export const ICEHOCKEY_DATA = "ICEHOCKEY_DATA";
export const MMA_DATA = "MMA_DATA";
export const RL_DATA = "RL_DATA";
export const RU_DATA = "RU_DATA";
export const SOCCER_DATA = "SOCCER_DATA";
export const TENNIS_DATA = "TENNIS_DATA";
export const RESTRICTED_ROUTE = "RESTRICTED_ROUTE";
export const BET_SLIP_TOGGLE = "BET_SLIP_TOGGLE";
export const BETSLIP_COUNT = "BETSLIP_COUNT";
export const BETSLIP_DATA = "BETSLIP_DATA";
export const BETSLIP_SHARE = "BETSLIP_SHARE";
export const SUBSCRIPTION_DATA = "SUBSCRIPTION_DATA";
export const BOOKKEEPER_DATA = "BOOKKEEPER_DATA";
export const RUNNER_DATA = "RUNNER_DATA";
export const PUBLIC_BOOKKEEPER_DATA = "PUBLIC_BOOKKEEPER_DATA";
export const ALL_SPORT = "ALL_SPORT";
export const LAYOUT_DATA = "LAYOUT_DATA";
export const EDIT_FLUCS_LAYOUT_DATA = "EDIT_FLUCS_LAYOUT_DATA";
export const RESULT_TABLE_DATA_SOC = "RESULT_TABLE_DATA_SOC";
export const TABLE_HEAD_DATA_SOC = "TABLE_HEAD_DATA_SOC";
export const TOURNAMENT_DATA_STORE = "TOURNAMENT_DATA_STORE";
export const VIEW_EXPERT_TIPS_COUNT = "VIEW_EXPERT_TIPS_COUNT";
