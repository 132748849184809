import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Breadcrumbs, Tabs, Tab, Button } from "@mui/material";
import AdvertisingBannner from "../../../../advertisingBanner";
import OurPartner from "src/views/component/ourPartners";
import banner from "../../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../../../../AdBanner/AdBanner";
import "./stadiumData.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment";

const newsTitle = [
  { value: 0, label: "Summary" },
  // { value: 1, label: "Results" },
  // { value: 2, label: "Players" },
  // { value: 3, label: "Records" }
];

const StadiumDataPage = () => {
  // const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const tabsRef = useRef(null);
  const [teamTabvalue, setTeamTabValue] = useState(0);
  const [headerData, setHeaderData] = useState({});
  const [stadiumSummary, setStadiumSummary] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = (ReduxAdsData) => {
    let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const routeName =
    params?.sportId === "4"
      ? "cricket"
      : params?.sportId === "12"
        ? "rugbyleague"
        : params?.sportId === "13"
          ? "rugbyunion"
          : params?.sportId === "10"
            ? "basketball"
            : params?.sportId === "15"
              ? "americanfootball"
              : params?.sportId === "9"
                ? "australianrules"
                : params?.sportId === "16"
                  ? "golf"
                  : params?.sportId === "7"
                    ? "tennis"
                    : params?.sportId === "11"
                      ? "baseball"
                      : params?.sportId === "17"
                        ? "icehockey"
                        : params?.sportId === "6"
                          ? "boxing"
                          : params?.sportId === "5"
                            ? "mma"
                            : params?.sportId === "8"
                              ? "soccer"
                              : "";

  const handleChange = (id, value) => {
    setTeamTabValue(id);
  };

  useEffect(() => {
    fetchBreacrumnData();
    fetchStadiumSummaryDetails();
  }, [params, SeasonId]);

  const fetchBreacrumnData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/header/?SportId=${params?.sportId}&tournamentId=${
          params?.tournamentId
        }&teamId=${params?.teamId}&playerId=${params?.playerId}&stadiumId=${
          params?.stadiumId
        }&seasonId=${SeasonId ? SeasonId : ""}`,
      );
      if (status === 200) {
        setHeaderData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchStadiumSummaryDetails = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/stadium/${params?.stadiumId}?SportId=${
          params?.sportId
        }&tournamentId=${params?.tournamentId}&seasonId=${
          SeasonId ? SeasonId : ""
        }`,
      );
      if (status === 200) {
        setStadiumSummary(data?.result);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        <Box className="mobile-banner">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="league-data-wrap">
          <Box className="teamsport-data-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Link underline="hover" color="inherit" to="/">
                    Sports
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to={
                      params?.sportId === "4"
                        ? `/all-sports/cricket/${params?.sportId}/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=All+Cricket`
                        : params?.sportId === "12"
                          ? `/all-sports/rugbyleague/${params?.sportId}/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`
                          : params?.sportId === "9"
                            ? `/all-sports/australianrules/${params?.sportId}/odds/0/false?menu=fixtures_results&touranamentId=0&touranamentName=All+Leagues`
                            : `/teamsports/${params?.type}/odds/0/false`
                    }
                  >
                    {headerData?.sport?.sportName}
                  </Link>
                  {SeasonId || headerData?.tournament ? (
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/sport/statistics/${params?.sportId}/${
                        params?.tournamentId
                      }/league-data?seasonId=${SeasonId ? SeasonId : ""}`}
                    >
                      {SeasonId
                        ? headerData?.season?.name
                        : headerData?.tournament?.name}
                    </Link>
                  ) : (
                    <></>
                  )}
                  {headerData?.team && (
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/sport/statistics/${params?.sportId}/${
                        params?.tournamentId
                      }/league-data/${params?.teamId}/team-data?seasonId=${
                        SeasonId ? SeasonId : ""
                      }`}
                    >
                      {headerData?.team?.name}
                    </Link>
                  )}
                  {/* ) : (
                    <></>
                  )} */}
                  {headerData?.player && (
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/sport/statistics/${params?.sportId}/${
                        params?.tournamentId
                      }/league-data/${params?.teamId}/team-data/${
                        params?.playerId
                      }/player-data?seasonId=${SeasonId ? SeasonId : ""}`}
                    >
                      {headerData?.player?.name}
                    </Link>
                  )}
                  <Typography>{headerData?.stadium?.name}</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">{headerData?.stadium?.name}</Typography>
          </Box>
          <Box className="teamsport-data-tab">
            <Tabs
              value={teamTabvalue}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="teamsport-data-tab-details"
              // disableRipple
              // disableFocusRipple
              id="news-tab"
              ref={tabsRef}
            >
              {newsTitle.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={item?.label}
                      value={item?.value}
                      onChange={() => handleChange(item?.value, item?.label)}
                      className={item?.value == teamTabvalue ? "active" : ""}
                    />
                  </Box>
                );
              })}
            </Tabs>
            {teamTabvalue === 0 ? (
              <>
                <Box className="tab-deatils">
                  <Box className="tab-details-grid">
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Current Time:
                      </Typography>
                      <Typography className="details-text">
                        {`[${moment().format("h:mma")}] - [${moment().format(
                          "DD/MM/YYYY",
                        )}]`}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Location
                      </Typography>
                      <Typography className="details-text">
                        {stadiumSummary?.Country
                          ? stadiumSummary?.Country?.country
                          : ""}
                      </Typography>
                    </Box>
                    <Box className="details-box">
                      <Typography className="details-text bold">
                        Non-Commercial Name:
                      </Typography>
                      <Typography className="details-text">-</Typography>
                    </Box>
                  </Box>
                  {/* <Box className="stadium-other-name">
                    <Typography className="stadium-name bold">
                      Previous/Other Name(s):
                    </Typography>
                    <Typography className="stadium-name">
                      - Penrith Park (Until 1987)
                    </Typography>
                    <Typography className="stadium-name">
                      - Penrith Football Stadium (1988-2005)
                    </Typography>
                    <Typography className="stadium-name">
                      - Credit Union Australia Stadium (2005-2011)
                    </Typography>
                    <Typography className="stadium-name">
                      - Centrebet Stadium (2011-2014)
                    </Typography>
                    <Typography className="stadium-name">
                      - Sportingbet Stadium (2014-2015)
                    </Typography>
                    <Typography className="stadium-name">
                      - Pepper Stadium (2015-2018)
                    </Typography>
                    <Typography className="stadium-name">
                      - Panthers Stadium (2018-2021)
                    </Typography>
                  </Box> */}
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default StadiumDataPage;
