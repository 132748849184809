import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";

const TimeSection = () => {
  const [currentTime, setCurrentTime] = useState(
    moment().format("h:mm a dddd, MMMM Do, YYYY"),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("h:mm a dddd, MMMM Do, YYYY"));
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);
  return (
    <>
      <Box className="time-section">
        <Typography className="time">{currentTime}</Typography>
      </Box>
    </>
  );
};

export default TimeSection;
