import axiosInstance from "src/helpers/Axios/axiosInstance";

const { createContext, useState, useEffect, useContext } = require("react");

const playerContext = createContext();

const PlayerStatsProvider = ({ children }) => {
  const [playerStats, setPlayerStats] = useState({
    header: [],
    body: [],
  });
  const [statsType, setStatsType] = useState("mostRuns");
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStats, setSelectedStats] = useState(0);
  const [selectdStatsFilter, setSelectdStatsFilter] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [loading, setLoading] = useState(false);

  const getPlayerStats = async (
    category,
    statsType,
    tournamentId,
    seasonId,
  ) => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(
        `crickets/playerStats?category=${category}&statsType=${statsType}&tournamentId=${tournamentId}${seasonId ? `&seasonId=${seasonId}` : ""}`,
      );

      if (status === 200) {
        setPlayerStats({
          headers: data.headers,
          body: data.result,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedStats(stateOptions?.[0]?.value);
  }, [stateOptions]);

  const setDefaultStatsOption = () => {
    setSelectedStats(stateOptions?.[0]?.value);
  };

  useEffect(() => {
    if (selectedStats && selectdStatsFilter && selectedLeague)
      getPlayerStats(
        selectedStats,
        selectdStatsFilter,
        selectedLeague,
        selectedSeason,
      );
  }, [selectedStats, selectdStatsFilter, selectedLeague, selectedSeason]);

  const playerStatsContext = {
    playerStats,
    setStatsType,
    stateOptions,
    setStateOptions,
    selectedStats,
    setSelectedStats,
    selectdStatsFilter,
    setSelectdStatsFilter,
    selectedLeague,
    setSelectedLeague,
    selectedSeason,
    setSelectedSeason,
    setDefaultStatsOption,
    loading,
  };

  return (
    <playerContext.Provider value={playerStatsContext}>
      {children}
    </playerContext.Provider>
  );
};

export const usePlayerStats = () => {
  return useContext(playerContext);
};

export default PlayerStatsProvider;
