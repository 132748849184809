import { createColumnHelper } from "@tanstack/react-table";
import { Box, Typography } from "@mui/material";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import SortIcons from "src/components/Common/Icons/SortIcons";

const columnHelper = createColumnHelper();

const PlayerColumn = [
  columnHelper.accessor("rank", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        # <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => <Typography>{row.original.rank}</Typography>,
  }),
  columnHelper.accessor("player", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        Player <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => {
      const item = row.original;
      return (
        <Box className="team-wrap">
          <Box className="team-image-wrap">
            <img
              src={DefaultTeam}
              onError={(e) => (e.target.src = DefaultTeam)}
              alt={item.team}
              className="team-image"
            />
          </Box>
          <Box className="team-name-wrap">
            <Typography className="team-name">{item?.player}</Typography>
          </Box>
        </Box>
      );
    },
  }),
  columnHelper.accessor("team", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        Team <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => {
      const item = row.original;
      return (
        <Box className="team-wrap">
          <Box className="team-image-wrap">
            <img
              src={DefaultTeam}
              onError={(e) => (e.target.src = DefaultTeam)}
              alt={item.team}
              className="team-image"
            />
          </Box>
          <Box className="team-name-wrap">
            <Typography className="team-name">{item.team}</Typography>
          </Box>
        </Box>
      );
    },
  }),
  columnHelper.accessor("position", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        Position <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => <Typography>{row.original.position}</Typography>,
  }),
  columnHelper.accessor("pts", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        PTS <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => <Typography>{row.original.pts}</Typography>,
    meta: { isHighLighted: true },
  }),
  ...["t", "gls", "2pfg", "1pfg", "lb", "pcm", "le", "tb", "hb", "ta"].map(
    (key) =>
      columnHelper.accessor(key, {
        header: ({ column }) => (
          <Box
            className="sorting-cell"
            onClick={column.getToggleSortingHandler()}
          >
            {key.toUpperCase()} <SortIcons isSorted={column.getIsSorted()} />
          </Box>
        ),
        cell: ({ row }) => <Typography>{row.original[key]}</Typography>,
      }),
  ),
];

export default PlayerColumn;
