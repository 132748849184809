import { Box, Typography } from "@mui/material";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import SortIcons from "src/components/Common/Icons/SortIcons";
import { Config } from "src/helpers/context";
export const AFLStaticColumn = [
  {
    accessorKey: "position",
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        style={{ cursor: "pointer" }}
      >
        # <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ getValue }) => <>{getValue()}</>,
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        style={{ cursor: "pointer" }}
      >
        Team <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => (
      <Box className="team-wrap">
        <Box className="team-image-wrap">
          <img
            src={
              row.original.flag?.includes("uploads")
                ? Config.mediaURL + row.original.flag
                : row.original.flag || DefaultTeam
            }
            onError={(e) => (e.target.src = DefaultTeam)}
            alt={row.original.name}
            className="team-image"
          />
        </Box>
        <Box className="team-name-wrap">
          <Typography className="team-name" sx={{ textAlign: "start" }}>
            {row.original.name}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    accessorKey: "p",
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        style={{ cursor: "pointer" }}
      >
        P <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
  },
  {
    accessorKey: "points",
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        style={{ cursor: "pointer" }}
      >
        Pts <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
  },
  // {
  //   accessorKey: "difference",
  //   header: ({ column }) => (
  //     <Box
  //       className="sorting-cell"
  //       onClick={column.getToggleSortingHandler()}
  //       style={{ cursor: "pointer" }}
  //     >
  //       % <SortIcons isSorted={column.getIsSorted()} />
  //     </Box>
  //   ),
  // },
];
