import { Box, Button, Divider, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { calculateTotalWithCardFee } from "src/helpers/utils";

const Summary = ({
  isBnakDeposit,
  selectedCard,
  handlePaymentSubmit,
  isPayementLoading,
  cardFeeInfo,
  cardValidate,
}) => {
  const selectedPlanDetailsStore = {};
  const location = useLocation();
  const eventData = location.state;
  const result = calculateTotalWithCardFee(eventData?.entryFee ?? 0);

  return (
    <>
      <Box className="member-other-details-wraps">
        <Box className="left-your-selection">
          {/* <Box className="your-selection-wrap">
            <Box className="selection-header">
              <Typography className="header ">Apply coupon code</Typography>
            </Box>
            <Box className="subscription-type-details">
              <Box className="coupon-code-wrap">
                <TextField
                  placeholder="Coupon code (optional)"
                  variant="outlined"
                  type="text"
                  className="text-field details-textfield"
                  label="Coupon code (optional)"
                  name="couponCode"
                  onChange={(e) => {
                    setCouponCode(e?.target?.value);
                  }}
                  value={couponCode}
                />

                <Button
                  variant="contained"
                  className="apply-btn"
                  onClick={() => handleApplyCouponCode()}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box> */}

          <Box className="your-selection-wrap">
            <Box className="selection-header">
              <Typography variant="h1" sx={{ textTransform: "uppercase" }}>
                Summary
              </Typography>
              <Divider sx={{ bgcolor: "#4455c7" }} />
            </Box>
            <Box className="subscription-type-details">
              <Box
                className={
                  selectedPlanDetailsStore?.key === "odds"
                    ? "plan-deatils plan-deatils-mb-9"
                    : "plan-deatils plan-deatils-mb-19"
                }
              >
                <Typography className="plan-name subscription-text">
                  Entry Fee
                </Typography>
                <Typography className="plan-price subscription-text">
                  {eventData?.entryFee ? `$${eventData?.entryFee}` : "-"}
                </Typography>
              </Box>
              {!isBnakDeposit && (
                <Box
                  className={
                    selectedPlanDetailsStore?.key === "odds"
                      ? "plan-deatils plan-deatils-mb-9"
                      : "plan-deatils plan-deatils-mb-19"
                  }
                >
                  <Typography className="plan-name subscription-text">
                    Credit Card Fees
                  </Typography>
                  <Typography className="plan-price subscription-text">
                    {cardFeeInfo?.cardFee ? `$${cardFeeInfo?.cardFee}` : "-"}
                  </Typography>
                </Box>
              )}

              <Box sx={{ borderTop: "1px solid #4455c7" }}>
                <Box className="total-details">
                  <Typography className="total-name subscription-text">
                    Total
                  </Typography>
                  <Typography className="total-price subscription-text">
                    {`$${isBnakDeposit ? eventData?.entryFee : cardFeeInfo?.totalAmount ? cardFeeInfo?.totalAmount : eventData?.entryFee}`}
                  </Typography>
                </Box>
              </Box>
              {!isBnakDeposit && (
                <Box sx={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      marginTop: 1,
                      backgroundColor: "#4455c7",
                      color: "white",
                      borderRadius: 2,
                      textTransform: "unset",
                    }}
                    onClick={handlePaymentSubmit}
                    disabled={isPayementLoading || cardValidate}
                  >
                    {isPayementLoading
                      ? "Loading..."
                      : "Confirm payment to create competition"}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Summary;
