import React from "react";
import Page from "src/components/Page";
import MyCompsArchivePage from "src/views/component/myCompsArchive";

const MyCompArchive = () => {
  return (
    <Page title="My Competitions Archive">
      <MyCompsArchivePage />
    </Page>
  );
};

export default MyCompArchive;
