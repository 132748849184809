import { createColumnHelper } from "@tanstack/react-table";
import { Box, Typography } from "@mui/material";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import { Config } from "src/helpers/context";
import DefaultTeam from "src/assets/images/defaultTeam.png";

const columnHelper = createColumnHelper();

const SortIcons = ({ isSorted }) => {
  return (
    <Box className="sort-icon-wrap">
      {/* Ascending Arrow */}
      <span>
        <SortingArrow className={isSorted === true ? "asc-sort" : ""} />
      </span>
      {/* Descending Arrow */}
      <span className="down-arrow">
        <SortingArrow className={isSorted === false ? "desc-sort" : ""} />
      </span>
    </Box>
  );
};

const TeamColumn = [
  columnHelper.accessor("rank", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        # <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
  }),
  columnHelper.accessor("team", {
    header: ({ column }) => (
      <Box className="sorting-cell" onClick={column.getToggleSortingHandler()}>
        Team <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Box className="team-wrap">
          <Box className="team-image-wrap">
            <img
              src={
                item?.team?.flag
                  ? item?.team?.flag?.includes("uploads")
                    ? Config.mediaURL + item?.team?.flag
                    : item?.team?.flag
                  : DefaultTeam
              }
              onError={(e) => {
                e.target.src = DefaultTeam;
              }}
              alt="Team 1"
              className="team-image"
            />
          </Box>
          <Box className="team-name-wrap">
            <Typography className="team-name">{item?.team}</Typography>
          </Box>
        </Box>
      );
    },
  }),
  columnHelper.accessor("pts", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        PTS <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),
    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.pts}</Typography>;
    },
    meta: {
      isHighLighted: true,
    },
  }),

  columnHelper.accessor("T", {
    header: ({ column }) => (
      <Box
        className="sorting-cell text-align-center"
        onClick={column.getToggleSortingHandler()}
      >
        T <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.T}</Typography>;
    },
  }),
  columnHelper.accessor("GLS", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        GLS <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.GLS}</Typography>;
    },
  }),
  columnHelper.accessor("FG", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        FG <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return <Typography sx={{ textAlign: "center" }}>{item?.FG}</Typography>;
    },
  }),
  columnHelper.accessor("P%", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        P% <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Typography sx={{ textAlign: "center" }}>{item?.["P%"]}</Typography>
      );
    },
  }),
  columnHelper.accessor("SC%", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        SC% <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      const item = row.original;
      return (
        <Typography sx={{ textAlign: "center" }}>{item?.["SC%"]}</Typography>
      );
    },
  }),
  columnHelper.accessor("SUP", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        SUP <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.SUP}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("LE", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        LE <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.LE}</Typography>
      );
    },
  }),
  columnHelper.accessor("LB", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        LB <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.LB}</Typography>
      );
    },
  }),
  columnHelper.accessor("PCM", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        PCM <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.PCM}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("TB", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        TB <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.TB}</Typography>
      );
    },
  }),

  columnHelper.accessor("DR", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        DR <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.DR}</Typography>
      );
    },
  }),
  columnHelper.accessor("RM", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        RM <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>{row.original?.RM}</Typography>
      );
    },
  }),
  columnHelper.accessor("RUN", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        RUN <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.RUN}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("KRM", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        KRM <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.KRM}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("DHR", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        DHR <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.DHR}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("TAC", {
    header: ({ column }) => (
      <Box
        className="sorting-cell"
        onClick={column.getToggleSortingHandler()}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        TAC <SortIcons isSorted={column.getIsSorted()} />
      </Box>
    ),

    cell: ({ row }) => {
      return (
        <Typography sx={{ textAlign: "center" }}>
          {row.original?.TAC}
        </Typography>
      );
    },
  }),
];

export default TeamColumn;
