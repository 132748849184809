import React from "react";
import { useParams } from "react-router";
import FixtureInfoSport from "../fixtureInfoSport";
import SoccerFixtureInfoSport from "../SoccerfixtureInfoSport";
import RLFixtureInfoSport from "../RLfixtureInfoSport";

const AllFixtureInfoSport = () => {
  const params = useParams();
  return (
    <>
      {params?.type === "cricket" ? (
        <FixtureInfoSport />
      ) : params?.type === "soccer" ? (
        <SoccerFixtureInfoSport />
      ) : params?.type === "rugbyleague" ? (
        <RLFixtureInfoSport />
      ) : (
        <></>
      )}
    </>
  );
};

export default AllFixtureInfoSport;
